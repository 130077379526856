var chinaCities = [{
	'id': 1,
	'pid': 0,
	'name': '北京市',
	'merger_name': '中国,北京市',
	'level': 1,
	'cities': [{
		'id': 2,
		'pid': 1,
		'name': '北京市',
		'merger_name': '中国,北京,北京市',
		'level': 2,
		'areas': [{
			'id': 3,
			'pid': 2,
			'name': '东城区',
			'merger_name': '中国,北京,北京市,东城区',
			'level': 3
		}, {
			'id': 4,
			'pid': 2,
			'name': '西城区',
			'merger_name': '中国,北京,北京市,西城区',
			'level': 3
		}, {
			'id': 5,
			'pid': 2,
			'name': '朝阳区',
			'merger_name': '中国,北京,北京市,朝阳区',
			'level': 3
		}, {
			'id': 6,
			'pid': 2,
			'name': '丰台区',
			'merger_name': '中国,北京,北京市,丰台区',
			'level': 3
		}, {
			'id': 7,
			'pid': 2,
			'name': '石景山区',
			'merger_name': '中国,北京,北京市,石景山区',
			'level': 3
		}, {
			'id': 8,
			'pid': 2,
			'name': '海淀区',
			'merger_name': '中国,北京,北京市,海淀区',
			'level': 3
		}, {
			'id': 9,
			'pid': 2,
			'name': '门头沟区',
			'merger_name': '中国,北京,北京市,门头沟区',
			'level': 3
		}, {
			'id': 10,
			'pid': 2,
			'name': '房山区',
			'merger_name': '中国,北京,北京市,房山区',
			'level': 3
		}, {
			'id': 11,
			'pid': 2,
			'name': '通州区',
			'merger_name': '中国,北京,北京市,通州区',
			'level': 3
		}, {
			'id': 12,
			'pid': 2,
			'name': '顺义区',
			'merger_name': '中国,北京,北京市,顺义区',
			'level': 3
		}, {
			'id': 13,
			'pid': 2,
			'name': '昌平区',
			'merger_name': '中国,北京,北京市,昌平区',
			'level': 3
		}, {
			'id': 14,
			'pid': 2,
			'name': '大兴区',
			'merger_name': '中国,北京,北京市,大兴区',
			'level': 3
		}, {
			'id': 15,
			'pid': 2,
			'name': '怀柔区',
			'merger_name': '中国,北京,北京市,怀柔区',
			'level': 3
		}, {
			'id': 16,
			'pid': 2,
			'name': '平谷区',
			'merger_name': '中国,北京,北京市,平谷区',
			'level': 3
		}, {
			'id': 17,
			'pid': 2,
			'name': '密云县',
			'merger_name': '中国,北京,北京市,密云县',
			'level': 3
		}, {
			'id': 18,
			'pid': 2,
			'name': '延庆县',
			'merger_name': '中国,北京,北京市,延庆县',
			'level': 3
		}]
	}]
}, {
	'id': 19,
	'pid': 0,
	'name': '天津市',
	'merger_name': '中国,天津市',
	'level': 1,
	'cities': [{
		'id': 20,
		'pid': 19,
		'name': '天津市',
		'merger_name': '中国,天津,天津市',
		'level': 2,
		'areas': [{
			'id': 21,
			'pid': 20,
			'name': '和平区',
			'merger_name': '中国,天津,天津市,和平区',
			'level': 3
		}, {
			'id': 22,
			'pid': 20,
			'name': '河东区',
			'merger_name': '中国,天津,天津市,河东区',
			'level': 3
		}, {
			'id': 23,
			'pid': 20,
			'name': '河西区',
			'merger_name': '中国,天津,天津市,河西区',
			'level': 3
		}, {
			'id': 24,
			'pid': 20,
			'name': '南开区',
			'merger_name': '中国,天津,天津市,南开区',
			'level': 3
		}, {
			'id': 25,
			'pid': 20,
			'name': '河北区',
			'merger_name': '中国,天津,天津市,河北区',
			'level': 3
		}, {
			'id': 26,
			'pid': 20,
			'name': '红桥区',
			'merger_name': '中国,天津,天津市,红桥区',
			'level': 3
		}, {
			'id': 27,
			'pid': 20,
			'name': '东丽区',
			'merger_name': '中国,天津,天津市,东丽区',
			'level': 3
		}, {
			'id': 28,
			'pid': 20,
			'name': '西青区',
			'merger_name': '中国,天津,天津市,西青区',
			'level': 3
		}, {
			'id': 29,
			'pid': 20,
			'name': '津南区',
			'merger_name': '中国,天津,天津市,津南区',
			'level': 3
		}, {
			'id': 30,
			'pid': 20,
			'name': '北辰区',
			'merger_name': '中国,天津,天津市,北辰区',
			'level': 3
		}, {
			'id': 31,
			'pid': 20,
			'name': '武清区',
			'merger_name': '中国,天津,天津市,武清区',
			'level': 3
		}, {
			'id': 32,
			'pid': 20,
			'name': '宝坻区',
			'merger_name': '中国,天津,天津市,宝坻区',
			'level': 3
		}, {
			'id': 33,
			'pid': 20,
			'name': '滨海新区',
			'merger_name': '中国,天津,天津市,滨海新区',
			'level': 3
		}, {
			'id': 34,
			'pid': 20,
			'name': '宁河县',
			'merger_name': '中国,天津,天津市,宁河县',
			'level': 3
		}, {
			'id': 35,
			'pid': 20,
			'name': '静海县',
			'merger_name': '中国,天津,天津市,静海县',
			'level': 3
		}, {
			'id': 36,
			'pid': 20,
			'name': '蓟县',
			'merger_name': '中国,天津,天津市,蓟县',
			'level': 3
		}]
	}]
}, {
	'id': 37,
	'pid': 0,
	'name': '河北省',
	'merger_name': '中国,河北省',
	'level': 1,
	'cities': [{
		'id': 38,
		'pid': 37,
		'name': '石家庄市',
		'merger_name': '中国,河北省,石家庄市',
		'level': 2,
		'areas': [{
			'id': 39,
			'pid': 38,
			'name': '长安区',
			'merger_name': '中国,河北省,石家庄市,长安区',
			'level': 3
		}, {
			'id': 40,
			'pid': 38,
			'name': '桥西区',
			'merger_name': '中国,河北省,石家庄市,桥西区',
			'level': 3
		}, {
			'id': 41,
			'pid': 38,
			'name': '新华区',
			'merger_name': '中国,河北省,石家庄市,新华区',
			'level': 3
		}, {
			'id': 42,
			'pid': 38,
			'name': '井陉矿区',
			'merger_name': '中国,河北省,石家庄市,井陉矿区',
			'level': 3
		}, {
			'id': 43,
			'pid': 38,
			'name': '裕华区',
			'merger_name': '中国,河北省,石家庄市,裕华区',
			'level': 3
		}, {
			'id': 44,
			'pid': 38,
			'name': '藁城区',
			'merger_name': '中国,河北省,石家庄市,藁城区',
			'level': 3
		}, {
			'id': 45,
			'pid': 38,
			'name': '鹿泉区',
			'merger_name': '中国,河北省,石家庄市,鹿泉区',
			'level': 3
		}, {
			'id': 46,
			'pid': 38,
			'name': '栾城区',
			'merger_name': '中国,河北省,石家庄市,栾城区',
			'level': 3
		}, {
			'id': 47,
			'pid': 38,
			'name': '井陉县',
			'merger_name': '中国,河北省,石家庄市,井陉县',
			'level': 3
		}, {
			'id': 48,
			'pid': 38,
			'name': '正定县',
			'merger_name': '中国,河北省,石家庄市,正定县',
			'level': 3
		}, {
			'id': 49,
			'pid': 38,
			'name': '行唐县',
			'merger_name': '中国,河北省,石家庄市,行唐县',
			'level': 3
		}, {
			'id': 50,
			'pid': 38,
			'name': '灵寿县',
			'merger_name': '中国,河北省,石家庄市,灵寿县',
			'level': 3
		}, {
			'id': 51,
			'pid': 38,
			'name': '高邑县',
			'merger_name': '中国,河北省,石家庄市,高邑县',
			'level': 3
		}, {
			'id': 52,
			'pid': 38,
			'name': '深泽县',
			'merger_name': '中国,河北省,石家庄市,深泽县',
			'level': 3
		}, {
			'id': 53,
			'pid': 38,
			'name': '赞皇县',
			'merger_name': '中国,河北省,石家庄市,赞皇县',
			'level': 3
		}, {
			'id': 54,
			'pid': 38,
			'name': '无极县',
			'merger_name': '中国,河北省,石家庄市,无极县',
			'level': 3
		}, {
			'id': 55,
			'pid': 38,
			'name': '平山县',
			'merger_name': '中国,河北省,石家庄市,平山县',
			'level': 3
		}, {
			'id': 56,
			'pid': 38,
			'name': '元氏县',
			'merger_name': '中国,河北省,石家庄市,元氏县',
			'level': 3
		}, {
			'id': 57,
			'pid': 38,
			'name': '赵县',
			'merger_name': '中国,河北省,石家庄市,赵县',
			'level': 3
		}, {
			'id': 58,
			'pid': 38,
			'name': '辛集市',
			'merger_name': '中国,河北省,石家庄市,辛集市',
			'level': 3
		}, {
			'id': 59,
			'pid': 38,
			'name': '晋州市',
			'merger_name': '中国,河北省,石家庄市,晋州市',
			'level': 3
		}, {
			'id': 60,
			'pid': 38,
			'name': '新乐市',
			'merger_name': '中国,河北省,石家庄市,新乐市',
			'level': 3
		}]
	}, {
		'id': 61,
		'pid': 37,
		'name': '唐山市',
		'merger_name': '中国,河北省,唐山市',
		'level': 2,
		'areas': [{
			'id': 62,
			'pid': 61,
			'name': '路南区',
			'merger_name': '中国,河北省,唐山市,路南区',
			'level': 3
		}, {
			'id': 63,
			'pid': 61,
			'name': '路北区',
			'merger_name': '中国,河北省,唐山市,路北区',
			'level': 3
		}, {
			'id': 64,
			'pid': 61,
			'name': '古冶区',
			'merger_name': '中国,河北省,唐山市,古冶区',
			'level': 3
		}, {
			'id': 65,
			'pid': 61,
			'name': '开平区',
			'merger_name': '中国,河北省,唐山市,开平区',
			'level': 3
		}, {
			'id': 66,
			'pid': 61,
			'name': '丰南区',
			'merger_name': '中国,河北省,唐山市,丰南区',
			'level': 3
		}, {
			'id': 67,
			'pid': 61,
			'name': '丰润区',
			'merger_name': '中国,河北省,唐山市,丰润区',
			'level': 3
		}, {
			'id': 68,
			'pid': 61,
			'name': '曹妃甸区',
			'merger_name': '中国,河北省,唐山市,曹妃甸区',
			'level': 3
		}, {
			'id': 69,
			'pid': 61,
			'name': '滦县',
			'merger_name': '中国,河北省,唐山市,滦县',
			'level': 3
		}, {
			'id': 70,
			'pid': 61,
			'name': '滦南县',
			'merger_name': '中国,河北省,唐山市,滦南县',
			'level': 3
		}, {
			'id': 71,
			'pid': 61,
			'name': '乐亭县',
			'merger_name': '中国,河北省,唐山市,乐亭县',
			'level': 3
		}, {
			'id': 72,
			'pid': 61,
			'name': '迁西县',
			'merger_name': '中国,河北省,唐山市,迁西县',
			'level': 3
		}, {
			'id': 73,
			'pid': 61,
			'name': '玉田县',
			'merger_name': '中国,河北省,唐山市,玉田县',
			'level': 3
		}, {
			'id': 74,
			'pid': 61,
			'name': '遵化市',
			'merger_name': '中国,河北省,唐山市,遵化市',
			'level': 3
		}, {
			'id': 75,
			'pid': 61,
			'name': '迁安市',
			'merger_name': '中国,河北省,唐山市,迁安市',
			'level': 3
		}]
	}, {
		'id': 76,
		'pid': 37,
		'name': '秦皇岛市',
		'merger_name': '中国,河北省,秦皇岛市',
		'level': 2,
		'areas': [{
			'id': 77,
			'pid': 76,
			'name': '海港区',
			'merger_name': '中国,河北省,秦皇岛市,海港区',
			'level': 3
		}, {
			'id': 78,
			'pid': 76,
			'name': '山海关区',
			'merger_name': '中国,河北省,秦皇岛市,山海关区',
			'level': 3
		}, {
			'id': 79,
			'pid': 76,
			'name': '北戴河区',
			'merger_name': '中国,河北省,秦皇岛市,北戴河区',
			'level': 3
		}, {
			'id': 80,
			'pid': 76,
			'name': '青龙满族自治县',
			'merger_name': '中国,河北省,秦皇岛市,青龙满族自治县',
			'level': 3
		}, {
			'id': 81,
			'pid': 76,
			'name': '昌黎县',
			'merger_name': '中国,河北省,秦皇岛市,昌黎县',
			'level': 3
		}, {
			'id': 82,
			'pid': 76,
			'name': '抚宁县',
			'merger_name': '中国,河北省,秦皇岛市,抚宁县',
			'level': 3
		}, {
			'id': 83,
			'pid': 76,
			'name': '卢龙县',
			'merger_name': '中国,河北省,秦皇岛市,卢龙县',
			'level': 3
		}]
	}, {
		'id': 84,
		'pid': 37,
		'name': '邯郸市',
		'merger_name': '中国,河北省,邯郸市',
		'level': 2,
		'areas': [{
			'id': 85,
			'pid': 84,
			'name': '邯山区',
			'merger_name': '中国,河北省,邯郸市,邯山区',
			'level': 3
		}, {
			'id': 86,
			'pid': 84,
			'name': '丛台区',
			'merger_name': '中国,河北省,邯郸市,丛台区',
			'level': 3
		}, {
			'id': 87,
			'pid': 84,
			'name': '复兴区',
			'merger_name': '中国,河北省,邯郸市,复兴区',
			'level': 3
		}, {
			'id': 88,
			'pid': 84,
			'name': '峰峰矿区',
			'merger_name': '中国,河北省,邯郸市,峰峰矿区',
			'level': 3
		}, {
			'id': 89,
			'pid': 84,
			'name': '邯郸县',
			'merger_name': '中国,河北省,邯郸市,邯郸县',
			'level': 3
		}, {
			'id': 90,
			'pid': 84,
			'name': '临漳县',
			'merger_name': '中国,河北省,邯郸市,临漳县',
			'level': 3
		}, {
			'id': 91,
			'pid': 84,
			'name': '成安县',
			'merger_name': '中国,河北省,邯郸市,成安县',
			'level': 3
		}, {
			'id': 92,
			'pid': 84,
			'name': '大名县',
			'merger_name': '中国,河北省,邯郸市,大名县',
			'level': 3
		}, {
			'id': 93,
			'pid': 84,
			'name': '涉县',
			'merger_name': '中国,河北省,邯郸市,涉县',
			'level': 3
		}, {
			'id': 94,
			'pid': 84,
			'name': '磁县',
			'merger_name': '中国,河北省,邯郸市,磁县',
			'level': 3
		}, {
			'id': 95,
			'pid': 84,
			'name': '肥乡县',
			'merger_name': '中国,河北省,邯郸市,肥乡县',
			'level': 3
		}, {
			'id': 96,
			'pid': 84,
			'name': '永年县',
			'merger_name': '中国,河北省,邯郸市,永年县',
			'level': 3
		}, {
			'id': 97,
			'pid': 84,
			'name': '邱县',
			'merger_name': '中国,河北省,邯郸市,邱县',
			'level': 3
		}, {
			'id': 98,
			'pid': 84,
			'name': '鸡泽县',
			'merger_name': '中国,河北省,邯郸市,鸡泽县',
			'level': 3
		}, {
			'id': 99,
			'pid': 84,
			'name': '广平县',
			'merger_name': '中国,河北省,邯郸市,广平县',
			'level': 3
		}, {
			'id': 100,
			'pid': 84,
			'name': '馆陶县',
			'merger_name': '中国,河北省,邯郸市,馆陶县',
			'level': 3
		}, {
			'id': 101,
			'pid': 84,
			'name': '魏县',
			'merger_name': '中国,河北省,邯郸市,魏县',
			'level': 3
		}, {
			'id': 102,
			'pid': 84,
			'name': '曲周县',
			'merger_name': '中国,河北省,邯郸市,曲周县',
			'level': 3
		}, {
			'id': 103,
			'pid': 84,
			'name': '武安市',
			'merger_name': '中国,河北省,邯郸市,武安市',
			'level': 3
		}]
	}, {
		'id': 104,
		'pid': 37,
		'name': '邢台市',
		'merger_name': '中国,河北省,邢台市',
		'level': 2,
		'areas': [{
			'id': 105,
			'pid': 104,
			'name': '桥东区',
			'merger_name': '中国,河北省,邢台市,桥东区',
			'level': 3
		}, {
			'id': 106,
			'pid': 104,
			'name': '桥西区',
			'merger_name': '中国,河北省,邢台市,桥西区',
			'level': 3
		}, {
			'id': 107,
			'pid': 104,
			'name': '邢台县',
			'merger_name': '中国,河北省,邢台市,邢台县',
			'level': 3
		}, {
			'id': 108,
			'pid': 104,
			'name': '临城县',
			'merger_name': '中国,河北省,邢台市,临城县',
			'level': 3
		}, {
			'id': 109,
			'pid': 104,
			'name': '内丘县',
			'merger_name': '中国,河北省,邢台市,内丘县',
			'level': 3
		}, {
			'id': 110,
			'pid': 104,
			'name': '柏乡县',
			'merger_name': '中国,河北省,邢台市,柏乡县',
			'level': 3
		}, {
			'id': 111,
			'pid': 104,
			'name': '隆尧县',
			'merger_name': '中国,河北省,邢台市,隆尧县',
			'level': 3
		}, {
			'id': 112,
			'pid': 104,
			'name': '任县',
			'merger_name': '中国,河北省,邢台市,任县',
			'level': 3
		}, {
			'id': 113,
			'pid': 104,
			'name': '南和县',
			'merger_name': '中国,河北省,邢台市,南和县',
			'level': 3
		}, {
			'id': 114,
			'pid': 104,
			'name': '宁晋县',
			'merger_name': '中国,河北省,邢台市,宁晋县',
			'level': 3
		}, {
			'id': 115,
			'pid': 104,
			'name': '巨鹿县',
			'merger_name': '中国,河北省,邢台市,巨鹿县',
			'level': 3
		}, {
			'id': 116,
			'pid': 104,
			'name': '新河县',
			'merger_name': '中国,河北省,邢台市,新河县',
			'level': 3
		}, {
			'id': 117,
			'pid': 104,
			'name': '广宗县',
			'merger_name': '中国,河北省,邢台市,广宗县',
			'level': 3
		}, {
			'id': 118,
			'pid': 104,
			'name': '平乡县',
			'merger_name': '中国,河北省,邢台市,平乡县',
			'level': 3
		}, {
			'id': 119,
			'pid': 104,
			'name': '威县',
			'merger_name': '中国,河北省,邢台市,威县',
			'level': 3
		}, {
			'id': 120,
			'pid': 104,
			'name': '清河县',
			'merger_name': '中国,河北省,邢台市,清河县',
			'level': 3
		}, {
			'id': 121,
			'pid': 104,
			'name': '临西县',
			'merger_name': '中国,河北省,邢台市,临西县',
			'level': 3
		}, {
			'id': 122,
			'pid': 104,
			'name': '南宫市',
			'merger_name': '中国,河北省,邢台市,南宫市',
			'level': 3
		}, {
			'id': 123,
			'pid': 104,
			'name': '沙河市',
			'merger_name': '中国,河北省,邢台市,沙河市',
			'level': 3
		}]
	}, {
		'id': 124,
		'pid': 37,
		'name': '保定市',
		'merger_name': '中国,河北省,保定市',
		'level': 2,
		'areas': [{
			'id': 125,
			'pid': 124,
			'name': '新市区',
			'merger_name': '中国,河北省,保定市,新市区',
			'level': 3
		}, {
			'id': 126,
			'pid': 124,
			'name': '北市区',
			'merger_name': '中国,河北省,保定市,北市区',
			'level': 3
		}, {
			'id': 127,
			'pid': 124,
			'name': '南市区',
			'merger_name': '中国,河北省,保定市,南市区',
			'level': 3
		}, {
			'id': 128,
			'pid': 124,
			'name': '满城县',
			'merger_name': '中国,河北省,保定市,满城县',
			'level': 3
		}, {
			'id': 129,
			'pid': 124,
			'name': '清苑县',
			'merger_name': '中国,河北省,保定市,清苑县',
			'level': 3
		}, {
			'id': 130,
			'pid': 124,
			'name': '涞水县',
			'merger_name': '中国,河北省,保定市,涞水县',
			'level': 3
		}, {
			'id': 131,
			'pid': 124,
			'name': '阜平县',
			'merger_name': '中国,河北省,保定市,阜平县',
			'level': 3
		}, {
			'id': 132,
			'pid': 124,
			'name': '徐水县',
			'merger_name': '中国,河北省,保定市,徐水县',
			'level': 3
		}, {
			'id': 133,
			'pid': 124,
			'name': '定兴县',
			'merger_name': '中国,河北省,保定市,定兴县',
			'level': 3
		}, {
			'id': 134,
			'pid': 124,
			'name': '唐县',
			'merger_name': '中国,河北省,保定市,唐县',
			'level': 3
		}, {
			'id': 135,
			'pid': 124,
			'name': '高阳县',
			'merger_name': '中国,河北省,保定市,高阳县',
			'level': 3
		}, {
			'id': 136,
			'pid': 124,
			'name': '容城县',
			'merger_name': '中国,河北省,保定市,容城县',
			'level': 3
		}, {
			'id': 137,
			'pid': 124,
			'name': '涞源县',
			'merger_name': '中国,河北省,保定市,涞源县',
			'level': 3
		}, {
			'id': 138,
			'pid': 124,
			'name': '望都县',
			'merger_name': '中国,河北省,保定市,望都县',
			'level': 3
		}, {
			'id': 139,
			'pid': 124,
			'name': '安新县',
			'merger_name': '中国,河北省,保定市,安新县',
			'level': 3
		}, {
			'id': 140,
			'pid': 124,
			'name': '易县',
			'merger_name': '中国,河北省,保定市,易县',
			'level': 3
		}, {
			'id': 141,
			'pid': 124,
			'name': '曲阳县',
			'merger_name': '中国,河北省,保定市,曲阳县',
			'level': 3
		}, {
			'id': 142,
			'pid': 124,
			'name': '蠡县',
			'merger_name': '中国,河北省,保定市,蠡县',
			'level': 3
		}, {
			'id': 143,
			'pid': 124,
			'name': '顺平县',
			'merger_name': '中国,河北省,保定市,顺平县',
			'level': 3
		}, {
			'id': 144,
			'pid': 124,
			'name': '博野县',
			'merger_name': '中国,河北省,保定市,博野县',
			'level': 3
		}, {
			'id': 145,
			'pid': 124,
			'name': '雄县',
			'merger_name': '中国,河北省,保定市,雄县',
			'level': 3
		}, {
			'id': 146,
			'pid': 124,
			'name': '涿州市',
			'merger_name': '中国,河北省,保定市,涿州市',
			'level': 3
		}, {
			'id': 147,
			'pid': 124,
			'name': '定州市',
			'merger_name': '中国,河北省,保定市,定州市',
			'level': 3
		}, {
			'id': 148,
			'pid': 124,
			'name': '安国市',
			'merger_name': '中国,河北省,保定市,安国市',
			'level': 3
		}, {
			'id': 149,
			'pid': 124,
			'name': '高碑店市',
			'merger_name': '中国,河北省,保定市,高碑店市',
			'level': 3
		}]
	}, {
		'id': 150,
		'pid': 37,
		'name': '张家口市',
		'merger_name': '中国,河北省,张家口市',
		'level': 2,
		'areas': [{
			'id': 151,
			'pid': 150,
			'name': '桥东区',
			'merger_name': '中国,河北省,张家口市,桥东区',
			'level': 3
		}, {
			'id': 152,
			'pid': 150,
			'name': '桥西区',
			'merger_name': '中国,河北省,张家口市,桥西区',
			'level': 3
		}, {
			'id': 153,
			'pid': 150,
			'name': '宣化区',
			'merger_name': '中国,河北省,张家口市,宣化区',
			'level': 3
		}, {
			'id': 154,
			'pid': 150,
			'name': '下花园区',
			'merger_name': '中国,河北省,张家口市,下花园区',
			'level': 3
		}, {
			'id': 155,
			'pid': 150,
			'name': '宣化县',
			'merger_name': '中国,河北省,张家口市,宣化县',
			'level': 3
		}, {
			'id': 156,
			'pid': 150,
			'name': '张北县',
			'merger_name': '中国,河北省,张家口市,张北县',
			'level': 3
		}, {
			'id': 157,
			'pid': 150,
			'name': '康保县',
			'merger_name': '中国,河北省,张家口市,康保县',
			'level': 3
		}, {
			'id': 158,
			'pid': 150,
			'name': '沽源县',
			'merger_name': '中国,河北省,张家口市,沽源县',
			'level': 3
		}, {
			'id': 159,
			'pid': 150,
			'name': '尚义县',
			'merger_name': '中国,河北省,张家口市,尚义县',
			'level': 3
		}, {
			'id': 160,
			'pid': 150,
			'name': '蔚县',
			'merger_name': '中国,河北省,张家口市,蔚县',
			'level': 3
		}, {
			'id': 161,
			'pid': 150,
			'name': '阳原县',
			'merger_name': '中国,河北省,张家口市,阳原县',
			'level': 3
		}, {
			'id': 162,
			'pid': 150,
			'name': '怀安县',
			'merger_name': '中国,河北省,张家口市,怀安县',
			'level': 3
		}, {
			'id': 163,
			'pid': 150,
			'name': '万全县',
			'merger_name': '中国,河北省,张家口市,万全县',
			'level': 3
		}, {
			'id': 164,
			'pid': 150,
			'name': '怀来县',
			'merger_name': '中国,河北省,张家口市,怀来县',
			'level': 3
		}, {
			'id': 165,
			'pid': 150,
			'name': '涿鹿县',
			'merger_name': '中国,河北省,张家口市,涿鹿县',
			'level': 3
		}, {
			'id': 166,
			'pid': 150,
			'name': '赤城县',
			'merger_name': '中国,河北省,张家口市,赤城县',
			'level': 3
		}, {
			'id': 167,
			'pid': 150,
			'name': '崇礼县',
			'merger_name': '中国,河北省,张家口市,崇礼县',
			'level': 3
		}]
	}, {
		'id': 168,
		'pid': 37,
		'name': '承德市',
		'merger_name': '中国,河北省,承德市',
		'level': 2,
		'areas': [{
			'id': 169,
			'pid': 168,
			'name': '双桥区',
			'merger_name': '中国,河北省,承德市,双桥区',
			'level': 3
		}, {
			'id': 170,
			'pid': 168,
			'name': '双滦区',
			'merger_name': '中国,河北省,承德市,双滦区',
			'level': 3
		}, {
			'id': 171,
			'pid': 168,
			'name': '鹰手营子矿区',
			'merger_name': '中国,河北省,承德市,鹰手营子矿区',
			'level': 3
		}, {
			'id': 172,
			'pid': 168,
			'name': '承德县',
			'merger_name': '中国,河北省,承德市,承德县',
			'level': 3
		}, {
			'id': 173,
			'pid': 168,
			'name': '兴隆县',
			'merger_name': '中国,河北省,承德市,兴隆县',
			'level': 3
		}, {
			'id': 174,
			'pid': 168,
			'name': '平泉县',
			'merger_name': '中国,河北省,承德市,平泉县',
			'level': 3
		}, {
			'id': 175,
			'pid': 168,
			'name': '滦平县',
			'merger_name': '中国,河北省,承德市,滦平县',
			'level': 3
		}, {
			'id': 176,
			'pid': 168,
			'name': '隆化县',
			'merger_name': '中国,河北省,承德市,隆化县',
			'level': 3
		}, {
			'id': 177,
			'pid': 168,
			'name': '丰宁满族自治县',
			'merger_name': '中国,河北省,承德市,丰宁满族自治县',
			'level': 3
		}, {
			'id': 178,
			'pid': 168,
			'name': '宽城满族自治县',
			'merger_name': '中国,河北省,承德市,宽城满族自治县',
			'level': 3
		}, {
			'id': 179,
			'pid': 168,
			'name': '围场满族蒙古族自治县',
			'merger_name': '中国,河北省,承德市,围场满族蒙古族自治县',
			'level': 3
		}]
	}, {
		'id': 180,
		'pid': 37,
		'name': '沧州市',
		'merger_name': '中国,河北省,沧州市',
		'level': 2,
		'areas': [{
			'id': 181,
			'pid': 180,
			'name': '新华区',
			'merger_name': '中国,河北省,沧州市,新华区',
			'level': 3
		}, {
			'id': 182,
			'pid': 180,
			'name': '运河区',
			'merger_name': '中国,河北省,沧州市,运河区',
			'level': 3
		}, {
			'id': 183,
			'pid': 180,
			'name': '沧县',
			'merger_name': '中国,河北省,沧州市,沧县',
			'level': 3
		}, {
			'id': 184,
			'pid': 180,
			'name': '青县',
			'merger_name': '中国,河北省,沧州市,青县',
			'level': 3
		}, {
			'id': 185,
			'pid': 180,
			'name': '东光县',
			'merger_name': '中国,河北省,沧州市,东光县',
			'level': 3
		}, {
			'id': 186,
			'pid': 180,
			'name': '海兴县',
			'merger_name': '中国,河北省,沧州市,海兴县',
			'level': 3
		}, {
			'id': 187,
			'pid': 180,
			'name': '盐山县',
			'merger_name': '中国,河北省,沧州市,盐山县',
			'level': 3
		}, {
			'id': 188,
			'pid': 180,
			'name': '肃宁县',
			'merger_name': '中国,河北省,沧州市,肃宁县',
			'level': 3
		}, {
			'id': 189,
			'pid': 180,
			'name': '南皮县',
			'merger_name': '中国,河北省,沧州市,南皮县',
			'level': 3
		}, {
			'id': 190,
			'pid': 180,
			'name': '吴桥县',
			'merger_name': '中国,河北省,沧州市,吴桥县',
			'level': 3
		}, {
			'id': 191,
			'pid': 180,
			'name': '献县',
			'merger_name': '中国,河北省,沧州市,献县',
			'level': 3
		}, {
			'id': 192,
			'pid': 180,
			'name': '孟村回族自治县',
			'merger_name': '中国,河北省,沧州市,孟村回族自治县',
			'level': 3
		}, {
			'id': 193,
			'pid': 180,
			'name': '泊头市',
			'merger_name': '中国,河北省,沧州市,泊头市',
			'level': 3
		}, {
			'id': 194,
			'pid': 180,
			'name': '任丘市',
			'merger_name': '中国,河北省,沧州市,任丘市',
			'level': 3
		}, {
			'id': 195,
			'pid': 180,
			'name': '黄骅市',
			'merger_name': '中国,河北省,沧州市,黄骅市',
			'level': 3
		}, {
			'id': 196,
			'pid': 180,
			'name': '河间市',
			'merger_name': '中国,河北省,沧州市,河间市',
			'level': 3
		}]
	}, {
		'id': 197,
		'pid': 37,
		'name': '廊坊市',
		'merger_name': '中国,河北省,廊坊市',
		'level': 2,
		'areas': [{
			'id': 198,
			'pid': 197,
			'name': '安次区',
			'merger_name': '中国,河北省,廊坊市,安次区',
			'level': 3
		}, {
			'id': 199,
			'pid': 197,
			'name': '广阳区',
			'merger_name': '中国,河北省,廊坊市,广阳区',
			'level': 3
		}, {
			'id': 200,
			'pid': 197,
			'name': '固安县',
			'merger_name': '中国,河北省,廊坊市,固安县',
			'level': 3
		}, {
			'id': 201,
			'pid': 197,
			'name': '永清县',
			'merger_name': '中国,河北省,廊坊市,永清县',
			'level': 3
		}, {
			'id': 202,
			'pid': 197,
			'name': '香河县',
			'merger_name': '中国,河北省,廊坊市,香河县',
			'level': 3
		}, {
			'id': 203,
			'pid': 197,
			'name': '大城县',
			'merger_name': '中国,河北省,廊坊市,大城县',
			'level': 3
		}, {
			'id': 204,
			'pid': 197,
			'name': '文安县',
			'merger_name': '中国,河北省,廊坊市,文安县',
			'level': 3
		}, {
			'id': 205,
			'pid': 197,
			'name': '大厂回族自治县',
			'merger_name': '中国,河北省,廊坊市,大厂回族自治县',
			'level': 3
		}, {
			'id': 206,
			'pid': 197,
			'name': '霸州市',
			'merger_name': '中国,河北省,廊坊市,霸州市',
			'level': 3
		}, {
			'id': 207,
			'pid': 197,
			'name': '三河市',
			'merger_name': '中国,河北省,廊坊市,三河市',
			'level': 3
		}]
	}, {
		'id': 208,
		'pid': 37,
		'name': '衡水市',
		'merger_name': '中国,河北省,衡水市',
		'level': 2,
		'areas': [{
			'id': 209,
			'pid': 208,
			'name': '桃城区',
			'merger_name': '中国,河北省,衡水市,桃城区',
			'level': 3
		}, {
			'id': 210,
			'pid': 208,
			'name': '枣强县',
			'merger_name': '中国,河北省,衡水市,枣强县',
			'level': 3
		}, {
			'id': 211,
			'pid': 208,
			'name': '武邑县',
			'merger_name': '中国,河北省,衡水市,武邑县',
			'level': 3
		}, {
			'id': 212,
			'pid': 208,
			'name': '武强县',
			'merger_name': '中国,河北省,衡水市,武强县',
			'level': 3
		}, {
			'id': 213,
			'pid': 208,
			'name': '饶阳县',
			'merger_name': '中国,河北省,衡水市,饶阳县',
			'level': 3
		}, {
			'id': 214,
			'pid': 208,
			'name': '安平县',
			'merger_name': '中国,河北省,衡水市,安平县',
			'level': 3
		}, {
			'id': 215,
			'pid': 208,
			'name': '故城县',
			'merger_name': '中国,河北省,衡水市,故城县',
			'level': 3
		}, {
			'id': 216,
			'pid': 208,
			'name': '景县',
			'merger_name': '中国,河北省,衡水市,景县',
			'level': 3
		}, {
			'id': 217,
			'pid': 208,
			'name': '阜城县',
			'merger_name': '中国,河北省,衡水市,阜城县',
			'level': 3
		}, {
			'id': 218,
			'pid': 208,
			'name': '冀州市',
			'merger_name': '中国,河北省,衡水市,冀州市',
			'level': 3
		}, {
			'id': 219,
			'pid': 208,
			'name': '深州市',
			'merger_name': '中国,河北省,衡水市,深州市',
			'level': 3
		}]
	}]
}, {
	'id': 220,
	'pid': 0,
	'name': '山西省',
	'merger_name': '中国,山西省',
	'level': 1,
	'cities': [{
		'id': 221,
		'pid': 220,
		'name': '太原市',
		'merger_name': '中国,山西省,太原市',
		'level': 2,
		'areas': [{
			'id': 222,
			'pid': 221,
			'name': '小店区',
			'merger_name': '中国,山西省,太原市,小店区',
			'level': 3
		}, {
			'id': 223,
			'pid': 221,
			'name': '迎泽区',
			'merger_name': '中国,山西省,太原市,迎泽区',
			'level': 3
		}, {
			'id': 224,
			'pid': 221,
			'name': '杏花岭区',
			'merger_name': '中国,山西省,太原市,杏花岭区',
			'level': 3
		}, {
			'id': 225,
			'pid': 221,
			'name': '尖草坪区',
			'merger_name': '中国,山西省,太原市,尖草坪区',
			'level': 3
		}, {
			'id': 226,
			'pid': 221,
			'name': '万柏林区',
			'merger_name': '中国,山西省,太原市,万柏林区',
			'level': 3
		}, {
			'id': 227,
			'pid': 221,
			'name': '晋源区',
			'merger_name': '中国,山西省,太原市,晋源区',
			'level': 3
		}, {
			'id': 228,
			'pid': 221,
			'name': '清徐县',
			'merger_name': '中国,山西省,太原市,清徐县',
			'level': 3
		}, {
			'id': 229,
			'pid': 221,
			'name': '阳曲县',
			'merger_name': '中国,山西省,太原市,阳曲县',
			'level': 3
		}, {
			'id': 230,
			'pid': 221,
			'name': '娄烦县',
			'merger_name': '中国,山西省,太原市,娄烦县',
			'level': 3
		}, {
			'id': 231,
			'pid': 221,
			'name': '古交市',
			'merger_name': '中国,山西省,太原市,古交市',
			'level': 3
		}]
	}, {
		'id': 232,
		'pid': 220,
		'name': '大同市',
		'merger_name': '中国,山西省,大同市',
		'level': 2,
		'areas': [{
			'id': 233,
			'pid': 232,
			'name': '城区',
			'merger_name': '中国,山西省,大同市,城区',
			'level': 3
		}, {
			'id': 234,
			'pid': 232,
			'name': '矿区',
			'merger_name': '中国,山西省,大同市,矿区',
			'level': 3
		}, {
			'id': 235,
			'pid': 232,
			'name': '南郊区',
			'merger_name': '中国,山西省,大同市,南郊区',
			'level': 3
		}, {
			'id': 236,
			'pid': 232,
			'name': '新荣区',
			'merger_name': '中国,山西省,大同市,新荣区',
			'level': 3
		}, {
			'id': 237,
			'pid': 232,
			'name': '阳高县',
			'merger_name': '中国,山西省,大同市,阳高县',
			'level': 3
		}, {
			'id': 238,
			'pid': 232,
			'name': '天镇县',
			'merger_name': '中国,山西省,大同市,天镇县',
			'level': 3
		}, {
			'id': 239,
			'pid': 232,
			'name': '广灵县',
			'merger_name': '中国,山西省,大同市,广灵县',
			'level': 3
		}, {
			'id': 240,
			'pid': 232,
			'name': '灵丘县',
			'merger_name': '中国,山西省,大同市,灵丘县',
			'level': 3
		}, {
			'id': 241,
			'pid': 232,
			'name': '浑源县',
			'merger_name': '中国,山西省,大同市,浑源县',
			'level': 3
		}, {
			'id': 242,
			'pid': 232,
			'name': '左云县',
			'merger_name': '中国,山西省,大同市,左云县',
			'level': 3
		}, {
			'id': 243,
			'pid': 232,
			'name': '大同县',
			'merger_name': '中国,山西省,大同市,大同县',
			'level': 3
		}]
	}, {
		'id': 244,
		'pid': 220,
		'name': '阳泉市',
		'merger_name': '中国,山西省,阳泉市',
		'level': 2,
		'areas': [{
			'id': 245,
			'pid': 244,
			'name': '城区',
			'merger_name': '中国,山西省,阳泉市,城区',
			'level': 3
		}, {
			'id': 246,
			'pid': 244,
			'name': '矿区',
			'merger_name': '中国,山西省,阳泉市,矿区',
			'level': 3
		}, {
			'id': 247,
			'pid': 244,
			'name': '郊区',
			'merger_name': '中国,山西省,阳泉市,郊区',
			'level': 3
		}, {
			'id': 248,
			'pid': 244,
			'name': '平定县',
			'merger_name': '中国,山西省,阳泉市,平定县',
			'level': 3
		}, {
			'id': 249,
			'pid': 244,
			'name': '盂县',
			'merger_name': '中国,山西省,阳泉市,盂县',
			'level': 3
		}]
	}, {
		'id': 250,
		'pid': 220,
		'name': '长治市',
		'merger_name': '中国,山西省,长治市',
		'level': 2,
		'areas': [{
			'id': 251,
			'pid': 250,
			'name': '城区',
			'merger_name': '中国,山西省,长治市,城区',
			'level': 3
		}, {
			'id': 252,
			'pid': 250,
			'name': '郊区',
			'merger_name': '中国,山西省,长治市,郊区',
			'level': 3
		}, {
			'id': 253,
			'pid': 250,
			'name': '长治县',
			'merger_name': '中国,山西省,长治市,长治县',
			'level': 3
		}, {
			'id': 254,
			'pid': 250,
			'name': '襄垣县',
			'merger_name': '中国,山西省,长治市,襄垣县',
			'level': 3
		}, {
			'id': 255,
			'pid': 250,
			'name': '屯留县',
			'merger_name': '中国,山西省,长治市,屯留县',
			'level': 3
		}, {
			'id': 256,
			'pid': 250,
			'name': '平顺县',
			'merger_name': '中国,山西省,长治市,平顺县',
			'level': 3
		}, {
			'id': 257,
			'pid': 250,
			'name': '黎城县',
			'merger_name': '中国,山西省,长治市,黎城县',
			'level': 3
		}, {
			'id': 258,
			'pid': 250,
			'name': '壶关县',
			'merger_name': '中国,山西省,长治市,壶关县',
			'level': 3
		}, {
			'id': 259,
			'pid': 250,
			'name': '长子县',
			'merger_name': '中国,山西省,长治市,长子县',
			'level': 3
		}, {
			'id': 260,
			'pid': 250,
			'name': '武乡县',
			'merger_name': '中国,山西省,长治市,武乡县',
			'level': 3
		}, {
			'id': 261,
			'pid': 250,
			'name': '沁县',
			'merger_name': '中国,山西省,长治市,沁县',
			'level': 3
		}, {
			'id': 262,
			'pid': 250,
			'name': '沁源县',
			'merger_name': '中国,山西省,长治市,沁源县',
			'level': 3
		}, {
			'id': 263,
			'pid': 250,
			'name': '潞城市',
			'merger_name': '中国,山西省,长治市,潞城市',
			'level': 3
		}]
	}, {
		'id': 264,
		'pid': 220,
		'name': '晋城市',
		'merger_name': '中国,山西省,晋城市',
		'level': 2,
		'areas': [{
			'id': 265,
			'pid': 264,
			'name': '城区',
			'merger_name': '中国,山西省,晋城市,城区',
			'level': 3
		}, {
			'id': 266,
			'pid': 264,
			'name': '沁水县',
			'merger_name': '中国,山西省,晋城市,沁水县',
			'level': 3
		}, {
			'id': 267,
			'pid': 264,
			'name': '阳城县',
			'merger_name': '中国,山西省,晋城市,阳城县',
			'level': 3
		}, {
			'id': 268,
			'pid': 264,
			'name': '陵川县',
			'merger_name': '中国,山西省,晋城市,陵川县',
			'level': 3
		}, {
			'id': 269,
			'pid': 264,
			'name': '泽州县',
			'merger_name': '中国,山西省,晋城市,泽州县',
			'level': 3
		}, {
			'id': 270,
			'pid': 264,
			'name': '高平市',
			'merger_name': '中国,山西省,晋城市,高平市',
			'level': 3
		}]
	}, {
		'id': 271,
		'pid': 220,
		'name': '朔州市',
		'merger_name': '中国,山西省,朔州市',
		'level': 2,
		'areas': [{
			'id': 272,
			'pid': 271,
			'name': '朔城区',
			'merger_name': '中国,山西省,朔州市,朔城区',
			'level': 3
		}, {
			'id': 273,
			'pid': 271,
			'name': '平鲁区',
			'merger_name': '中国,山西省,朔州市,平鲁区',
			'level': 3
		}, {
			'id': 274,
			'pid': 271,
			'name': '山阴县',
			'merger_name': '中国,山西省,朔州市,山阴县',
			'level': 3
		}, {
			'id': 275,
			'pid': 271,
			'name': '应县',
			'merger_name': '中国,山西省,朔州市,应县',
			'level': 3
		}, {
			'id': 276,
			'pid': 271,
			'name': '右玉县',
			'merger_name': '中国,山西省,朔州市,右玉县',
			'level': 3
		}, {
			'id': 277,
			'pid': 271,
			'name': '怀仁县',
			'merger_name': '中国,山西省,朔州市,怀仁县',
			'level': 3
		}]
	}, {
		'id': 278,
		'pid': 220,
		'name': '晋中市',
		'merger_name': '中国,山西省,晋中市',
		'level': 2,
		'areas': [{
			'id': 279,
			'pid': 278,
			'name': '榆次区',
			'merger_name': '中国,山西省,晋中市,榆次区',
			'level': 3
		}, {
			'id': 280,
			'pid': 278,
			'name': '榆社县',
			'merger_name': '中国,山西省,晋中市,榆社县',
			'level': 3
		}, {
			'id': 281,
			'pid': 278,
			'name': '左权县',
			'merger_name': '中国,山西省,晋中市,左权县',
			'level': 3
		}, {
			'id': 282,
			'pid': 278,
			'name': '和顺县',
			'merger_name': '中国,山西省,晋中市,和顺县',
			'level': 3
		}, {
			'id': 283,
			'pid': 278,
			'name': '昔阳县',
			'merger_name': '中国,山西省,晋中市,昔阳县',
			'level': 3
		}, {
			'id': 284,
			'pid': 278,
			'name': '寿阳县',
			'merger_name': '中国,山西省,晋中市,寿阳县',
			'level': 3
		}, {
			'id': 285,
			'pid': 278,
			'name': '太谷县',
			'merger_name': '中国,山西省,晋中市,太谷县',
			'level': 3
		}, {
			'id': 286,
			'pid': 278,
			'name': '祁县',
			'merger_name': '中国,山西省,晋中市,祁县',
			'level': 3
		}, {
			'id': 287,
			'pid': 278,
			'name': '平遥县',
			'merger_name': '中国,山西省,晋中市,平遥县',
			'level': 3
		}, {
			'id': 288,
			'pid': 278,
			'name': '灵石县',
			'merger_name': '中国,山西省,晋中市,灵石县',
			'level': 3
		}, {
			'id': 289,
			'pid': 278,
			'name': '介休市',
			'merger_name': '中国,山西省,晋中市,介休市',
			'level': 3
		}]
	}, {
		'id': 290,
		'pid': 220,
		'name': '运城市',
		'merger_name': '中国,山西省,运城市',
		'level': 2,
		'areas': [{
			'id': 291,
			'pid': 290,
			'name': '盐湖区',
			'merger_name': '中国,山西省,运城市,盐湖区',
			'level': 3
		}, {
			'id': 292,
			'pid': 290,
			'name': '临猗县',
			'merger_name': '中国,山西省,运城市,临猗县',
			'level': 3
		}, {
			'id': 293,
			'pid': 290,
			'name': '万荣县',
			'merger_name': '中国,山西省,运城市,万荣县',
			'level': 3
		}, {
			'id': 294,
			'pid': 290,
			'name': '闻喜县',
			'merger_name': '中国,山西省,运城市,闻喜县',
			'level': 3
		}, {
			'id': 295,
			'pid': 290,
			'name': '稷山县',
			'merger_name': '中国,山西省,运城市,稷山县',
			'level': 3
		}, {
			'id': 296,
			'pid': 290,
			'name': '新绛县',
			'merger_name': '中国,山西省,运城市,新绛县',
			'level': 3
		}, {
			'id': 297,
			'pid': 290,
			'name': '绛县',
			'merger_name': '中国,山西省,运城市,绛县',
			'level': 3
		}, {
			'id': 298,
			'pid': 290,
			'name': '垣曲县',
			'merger_name': '中国,山西省,运城市,垣曲县',
			'level': 3
		}, {
			'id': 299,
			'pid': 290,
			'name': '夏县',
			'merger_name': '中国,山西省,运城市,夏县',
			'level': 3
		}, {
			'id': 300,
			'pid': 290,
			'name': '平陆县',
			'merger_name': '中国,山西省,运城市,平陆县',
			'level': 3
		}, {
			'id': 301,
			'pid': 290,
			'name': '芮城县',
			'merger_name': '中国,山西省,运城市,芮城县',
			'level': 3
		}, {
			'id': 302,
			'pid': 290,
			'name': '永济市',
			'merger_name': '中国,山西省,运城市,永济市',
			'level': 3
		}, {
			'id': 303,
			'pid': 290,
			'name': '河津市',
			'merger_name': '中国,山西省,运城市,河津市',
			'level': 3
		}]
	}, {
		'id': 304,
		'pid': 220,
		'name': '忻州市',
		'merger_name': '中国,山西省,忻州市',
		'level': 2,
		'areas': [{
			'id': 305,
			'pid': 304,
			'name': '忻府区',
			'merger_name': '中国,山西省,忻州市,忻府区',
			'level': 3
		}, {
			'id': 306,
			'pid': 304,
			'name': '定襄县',
			'merger_name': '中国,山西省,忻州市,定襄县',
			'level': 3
		}, {
			'id': 307,
			'pid': 304,
			'name': '五台县',
			'merger_name': '中国,山西省,忻州市,五台县',
			'level': 3
		}, {
			'id': 308,
			'pid': 304,
			'name': '代县',
			'merger_name': '中国,山西省,忻州市,代县',
			'level': 3
		}, {
			'id': 309,
			'pid': 304,
			'name': '繁峙县',
			'merger_name': '中国,山西省,忻州市,繁峙县',
			'level': 3
		}, {
			'id': 310,
			'pid': 304,
			'name': '宁武县',
			'merger_name': '中国,山西省,忻州市,宁武县',
			'level': 3
		}, {
			'id': 311,
			'pid': 304,
			'name': '静乐县',
			'merger_name': '中国,山西省,忻州市,静乐县',
			'level': 3
		}, {
			'id': 312,
			'pid': 304,
			'name': '神池县',
			'merger_name': '中国,山西省,忻州市,神池县',
			'level': 3
		}, {
			'id': 313,
			'pid': 304,
			'name': '五寨县',
			'merger_name': '中国,山西省,忻州市,五寨县',
			'level': 3
		}, {
			'id': 314,
			'pid': 304,
			'name': '岢岚县',
			'merger_name': '中国,山西省,忻州市,岢岚县',
			'level': 3
		}, {
			'id': 315,
			'pid': 304,
			'name': '河曲县',
			'merger_name': '中国,山西省,忻州市,河曲县',
			'level': 3
		}, {
			'id': 316,
			'pid': 304,
			'name': '保德县',
			'merger_name': '中国,山西省,忻州市,保德县',
			'level': 3
		}, {
			'id': 317,
			'pid': 304,
			'name': '偏关县',
			'merger_name': '中国,山西省,忻州市,偏关县',
			'level': 3
		}, {
			'id': 318,
			'pid': 304,
			'name': '原平市',
			'merger_name': '中国,山西省,忻州市,原平市',
			'level': 3
		}]
	}, {
		'id': 319,
		'pid': 220,
		'name': '临汾市',
		'merger_name': '中国,山西省,临汾市',
		'level': 2,
		'areas': [{
			'id': 320,
			'pid': 319,
			'name': '尧都区',
			'merger_name': '中国,山西省,临汾市,尧都区',
			'level': 3
		}, {
			'id': 321,
			'pid': 319,
			'name': '曲沃县',
			'merger_name': '中国,山西省,临汾市,曲沃县',
			'level': 3
		}, {
			'id': 322,
			'pid': 319,
			'name': '翼城县',
			'merger_name': '中国,山西省,临汾市,翼城县',
			'level': 3
		}, {
			'id': 323,
			'pid': 319,
			'name': '襄汾县',
			'merger_name': '中国,山西省,临汾市,襄汾县',
			'level': 3
		}, {
			'id': 324,
			'pid': 319,
			'name': '洪洞县',
			'merger_name': '中国,山西省,临汾市,洪洞县',
			'level': 3
		}, {
			'id': 325,
			'pid': 319,
			'name': '古县',
			'merger_name': '中国,山西省,临汾市,古县',
			'level': 3
		}, {
			'id': 326,
			'pid': 319,
			'name': '安泽县',
			'merger_name': '中国,山西省,临汾市,安泽县',
			'level': 3
		}, {
			'id': 327,
			'pid': 319,
			'name': '浮山县',
			'merger_name': '中国,山西省,临汾市,浮山县',
			'level': 3
		}, {
			'id': 328,
			'pid': 319,
			'name': '吉县',
			'merger_name': '中国,山西省,临汾市,吉县',
			'level': 3
		}, {
			'id': 329,
			'pid': 319,
			'name': '乡宁县',
			'merger_name': '中国,山西省,临汾市,乡宁县',
			'level': 3
		}, {
			'id': 330,
			'pid': 319,
			'name': '大宁县',
			'merger_name': '中国,山西省,临汾市,大宁县',
			'level': 3
		}, {
			'id': 331,
			'pid': 319,
			'name': '隰县',
			'merger_name': '中国,山西省,临汾市,隰县',
			'level': 3
		}, {
			'id': 332,
			'pid': 319,
			'name': '永和县',
			'merger_name': '中国,山西省,临汾市,永和县',
			'level': 3
		}, {
			'id': 333,
			'pid': 319,
			'name': '蒲县',
			'merger_name': '中国,山西省,临汾市,蒲县',
			'level': 3
		}, {
			'id': 334,
			'pid': 319,
			'name': '汾西县',
			'merger_name': '中国,山西省,临汾市,汾西县',
			'level': 3
		}, {
			'id': 335,
			'pid': 319,
			'name': '侯马市',
			'merger_name': '中国,山西省,临汾市,侯马市',
			'level': 3
		}, {
			'id': 336,
			'pid': 319,
			'name': '霍州市',
			'merger_name': '中国,山西省,临汾市,霍州市',
			'level': 3
		}]
	}, {
		'id': 337,
		'pid': 220,
		'name': '吕梁市',
		'merger_name': '中国,山西省,吕梁市',
		'level': 2,
		'areas': [{
			'id': 338,
			'pid': 337,
			'name': '离石区',
			'merger_name': '中国,山西省,吕梁市,离石区',
			'level': 3
		}, {
			'id': 339,
			'pid': 337,
			'name': '文水县',
			'merger_name': '中国,山西省,吕梁市,文水县',
			'level': 3
		}, {
			'id': 340,
			'pid': 337,
			'name': '交城县',
			'merger_name': '中国,山西省,吕梁市,交城县',
			'level': 3
		}, {
			'id': 341,
			'pid': 337,
			'name': '兴县',
			'merger_name': '中国,山西省,吕梁市,兴县',
			'level': 3
		}, {
			'id': 342,
			'pid': 337,
			'name': '临县',
			'merger_name': '中国,山西省,吕梁市,临县',
			'level': 3
		}, {
			'id': 343,
			'pid': 337,
			'name': '柳林县',
			'merger_name': '中国,山西省,吕梁市,柳林县',
			'level': 3
		}, {
			'id': 344,
			'pid': 337,
			'name': '石楼县',
			'merger_name': '中国,山西省,吕梁市,石楼县',
			'level': 3
		}, {
			'id': 345,
			'pid': 337,
			'name': '岚县',
			'merger_name': '中国,山西省,吕梁市,岚县',
			'level': 3
		}, {
			'id': 346,
			'pid': 337,
			'name': '方山县',
			'merger_name': '中国,山西省,吕梁市,方山县',
			'level': 3
		}, {
			'id': 347,
			'pid': 337,
			'name': '中阳县',
			'merger_name': '中国,山西省,吕梁市,中阳县',
			'level': 3
		}, {
			'id': 348,
			'pid': 337,
			'name': '交口县',
			'merger_name': '中国,山西省,吕梁市,交口县',
			'level': 3
		}, {
			'id': 349,
			'pid': 337,
			'name': '孝义市',
			'merger_name': '中国,山西省,吕梁市,孝义市',
			'level': 3
		}, {
			'id': 350,
			'pid': 337,
			'name': '汾阳市',
			'merger_name': '中国,山西省,吕梁市,汾阳市',
			'level': 3
		}]
	}]
}, {
	'id': 351,
	'pid': 0,
	'name': '内蒙古自治区',
	'merger_name': '中国,内蒙古自治区',
	'level': 1,
	'cities': [{
		'id': 352,
		'pid': 351,
		'name': '呼和浩特市',
		'merger_name': '中国,内蒙古自治区,呼和浩特市',
		'level': 2,
		'areas': [{
			'id': 353,
			'pid': 352,
			'name': '新城区',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,新城区',
			'level': 3
		}, {
			'id': 354,
			'pid': 352,
			'name': '回民区',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,回民区',
			'level': 3
		}, {
			'id': 355,
			'pid': 352,
			'name': '玉泉区',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,玉泉区',
			'level': 3
		}, {
			'id': 356,
			'pid': 352,
			'name': '赛罕区',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,赛罕区',
			'level': 3
		}, {
			'id': 357,
			'pid': 352,
			'name': '土默特左旗',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,土默特左旗',
			'level': 3
		}, {
			'id': 358,
			'pid': 352,
			'name': '托克托县',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,托克托县',
			'level': 3
		}, {
			'id': 359,
			'pid': 352,
			'name': '和林格尔县',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,和林格尔县',
			'level': 3
		}, {
			'id': 360,
			'pid': 352,
			'name': '清水河县',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,清水河县',
			'level': 3
		}, {
			'id': 361,
			'pid': 352,
			'name': '武川县',
			'merger_name': '中国,内蒙古自治区,呼和浩特市,武川县',
			'level': 3
		}]
	}, {
		'id': 362,
		'pid': 351,
		'name': '包头市',
		'merger_name': '中国,内蒙古自治区,包头市',
		'level': 2,
		'areas': [{
			'id': 363,
			'pid': 362,
			'name': '东河区',
			'merger_name': '中国,内蒙古自治区,包头市,东河区',
			'level': 3
		}, {
			'id': 364,
			'pid': 362,
			'name': '昆都仑区',
			'merger_name': '中国,内蒙古自治区,包头市,昆都仑区',
			'level': 3
		}, {
			'id': 365,
			'pid': 362,
			'name': '青山区',
			'merger_name': '中国,内蒙古自治区,包头市,青山区',
			'level': 3
		}, {
			'id': 366,
			'pid': 362,
			'name': '石拐区',
			'merger_name': '中国,内蒙古自治区,包头市,石拐区',
			'level': 3
		}, {
			'id': 367,
			'pid': 362,
			'name': '白云鄂博矿区',
			'merger_name': '中国,内蒙古自治区,包头市,白云鄂博矿区',
			'level': 3
		}, {
			'id': 368,
			'pid': 362,
			'name': '九原区',
			'merger_name': '中国,内蒙古自治区,包头市,九原区',
			'level': 3
		}, {
			'id': 369,
			'pid': 362,
			'name': '土默特右旗',
			'merger_name': '中国,内蒙古自治区,包头市,土默特右旗',
			'level': 3
		}, {
			'id': 370,
			'pid': 362,
			'name': '固阳县',
			'merger_name': '中国,内蒙古自治区,包头市,固阳县',
			'level': 3
		}, {
			'id': 371,
			'pid': 362,
			'name': '达尔罕茂明安联合旗',
			'merger_name': '中国,内蒙古自治区,包头市,达尔罕茂明安联合旗',
			'level': 3
		}]
	}, {
		'id': 372,
		'pid': 351,
		'name': '乌海市',
		'merger_name': '中国,内蒙古自治区,乌海市',
		'level': 2,
		'areas': [{
			'id': 373,
			'pid': 372,
			'name': '海勃湾区',
			'merger_name': '中国,内蒙古自治区,乌海市,海勃湾区',
			'level': 3
		}, {
			'id': 374,
			'pid': 372,
			'name': '海南区',
			'merger_name': '中国,内蒙古自治区,乌海市,海南区',
			'level': 3
		}, {
			'id': 375,
			'pid': 372,
			'name': '乌达区',
			'merger_name': '中国,内蒙古自治区,乌海市,乌达区',
			'level': 3
		}]
	}, {
		'id': 376,
		'pid': 351,
		'name': '赤峰市',
		'merger_name': '中国,内蒙古自治区,赤峰市',
		'level': 2,
		'areas': [{
			'id': 377,
			'pid': 376,
			'name': '红山区',
			'merger_name': '中国,内蒙古自治区,赤峰市,红山区',
			'level': 3
		}, {
			'id': 378,
			'pid': 376,
			'name': '元宝山区',
			'merger_name': '中国,内蒙古自治区,赤峰市,元宝山区',
			'level': 3
		}, {
			'id': 379,
			'pid': 376,
			'name': '松山区',
			'merger_name': '中国,内蒙古自治区,赤峰市,松山区',
			'level': 3
		}, {
			'id': 380,
			'pid': 376,
			'name': '阿鲁科尔沁旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,阿鲁科尔沁旗',
			'level': 3
		}, {
			'id': 381,
			'pid': 376,
			'name': '巴林左旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,巴林左旗',
			'level': 3
		}, {
			'id': 382,
			'pid': 376,
			'name': '巴林右旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,巴林右旗',
			'level': 3
		}, {
			'id': 383,
			'pid': 376,
			'name': '林西县',
			'merger_name': '中国,内蒙古自治区,赤峰市,林西县',
			'level': 3
		}, {
			'id': 384,
			'pid': 376,
			'name': '克什克腾旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,克什克腾旗',
			'level': 3
		}, {
			'id': 385,
			'pid': 376,
			'name': '翁牛特旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,翁牛特旗',
			'level': 3
		}, {
			'id': 386,
			'pid': 376,
			'name': '喀喇沁旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,喀喇沁旗',
			'level': 3
		}, {
			'id': 387,
			'pid': 376,
			'name': '宁城县',
			'merger_name': '中国,内蒙古自治区,赤峰市,宁城县',
			'level': 3
		}, {
			'id': 388,
			'pid': 376,
			'name': '敖汉旗',
			'merger_name': '中国,内蒙古自治区,赤峰市,敖汉旗',
			'level': 3
		}]
	}, {
		'id': 389,
		'pid': 351,
		'name': '通辽市',
		'merger_name': '中国,内蒙古自治区,通辽市',
		'level': 2,
		'areas': [{
			'id': 390,
			'pid': 389,
			'name': '科尔沁区',
			'merger_name': '中国,内蒙古自治区,通辽市,科尔沁区',
			'level': 3
		}, {
			'id': 391,
			'pid': 389,
			'name': '科尔沁左翼中旗',
			'merger_name': '中国,内蒙古自治区,通辽市,科尔沁左翼中旗',
			'level': 3
		}, {
			'id': 392,
			'pid': 389,
			'name': '科尔沁左翼后旗',
			'merger_name': '中国,内蒙古自治区,通辽市,科尔沁左翼后旗',
			'level': 3
		}, {
			'id': 393,
			'pid': 389,
			'name': '开鲁县',
			'merger_name': '中国,内蒙古自治区,通辽市,开鲁县',
			'level': 3
		}, {
			'id': 394,
			'pid': 389,
			'name': '库伦旗',
			'merger_name': '中国,内蒙古自治区,通辽市,库伦旗',
			'level': 3
		}, {
			'id': 395,
			'pid': 389,
			'name': '奈曼旗',
			'merger_name': '中国,内蒙古自治区,通辽市,奈曼旗',
			'level': 3
		}, {
			'id': 396,
			'pid': 389,
			'name': '扎鲁特旗',
			'merger_name': '中国,内蒙古自治区,通辽市,扎鲁特旗',
			'level': 3
		}, {
			'id': 397,
			'pid': 389,
			'name': '霍林郭勒市',
			'merger_name': '中国,内蒙古自治区,通辽市,霍林郭勒市',
			'level': 3
		}]
	}, {
		'id': 398,
		'pid': 351,
		'name': '鄂尔多斯市',
		'merger_name': '中国,内蒙古自治区,鄂尔多斯市',
		'level': 2,
		'areas': [{
			'id': 399,
			'pid': 398,
			'name': '东胜区',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,东胜区',
			'level': 3
		}, {
			'id': 400,
			'pid': 398,
			'name': '达拉特旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,达拉特旗',
			'level': 3
		}, {
			'id': 401,
			'pid': 398,
			'name': '准格尔旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,准格尔旗',
			'level': 3
		}, {
			'id': 402,
			'pid': 398,
			'name': '鄂托克前旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,鄂托克前旗',
			'level': 3
		}, {
			'id': 403,
			'pid': 398,
			'name': '鄂托克旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,鄂托克旗',
			'level': 3
		}, {
			'id': 404,
			'pid': 398,
			'name': '杭锦旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,杭锦旗',
			'level': 3
		}, {
			'id': 405,
			'pid': 398,
			'name': '乌审旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,乌审旗',
			'level': 3
		}, {
			'id': 406,
			'pid': 398,
			'name': '伊金霍洛旗',
			'merger_name': '中国,内蒙古自治区,鄂尔多斯市,伊金霍洛旗',
			'level': 3
		}]
	}, {
		'id': 407,
		'pid': 351,
		'name': '呼伦贝尔市',
		'merger_name': '中国,内蒙古自治区,呼伦贝尔市',
		'level': 2,
		'areas': [{
			'id': 408,
			'pid': 407,
			'name': '海拉尔区',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,海拉尔区',
			'level': 3
		}, {
			'id': 409,
			'pid': 407,
			'name': '扎赉诺尔区',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,扎赉诺尔区',
			'level': 3
		}, {
			'id': 410,
			'pid': 407,
			'name': '阿荣旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,阿荣旗',
			'level': 3
		}, {
			'id': 411,
			'pid': 407,
			'name': '莫力达瓦达斡尔族自治旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,莫力达瓦达斡尔族自治旗',
			'level': 3
		}, {
			'id': 412,
			'pid': 407,
			'name': '鄂伦春自治旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,鄂伦春自治旗',
			'level': 3
		}, {
			'id': 413,
			'pid': 407,
			'name': '鄂温克族自治旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,鄂温克族自治旗',
			'level': 3
		}, {
			'id': 414,
			'pid': 407,
			'name': '陈巴尔虎旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,陈巴尔虎旗',
			'level': 3
		}, {
			'id': 415,
			'pid': 407,
			'name': '新巴尔虎左旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,新巴尔虎左旗',
			'level': 3
		}, {
			'id': 416,
			'pid': 407,
			'name': '新巴尔虎右旗',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,新巴尔虎右旗',
			'level': 3
		}, {
			'id': 417,
			'pid': 407,
			'name': '满洲里市',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,满洲里市',
			'level': 3
		}, {
			'id': 418,
			'pid': 407,
			'name': '牙克石市',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,牙克石市',
			'level': 3
		}, {
			'id': 419,
			'pid': 407,
			'name': '扎兰屯市',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,扎兰屯市',
			'level': 3
		}, {
			'id': 420,
			'pid': 407,
			'name': '额尔古纳市',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,额尔古纳市',
			'level': 3
		}, {
			'id': 421,
			'pid': 407,
			'name': '根河市',
			'merger_name': '中国,内蒙古自治区,呼伦贝尔市,根河市',
			'level': 3
		}]
	}, {
		'id': 422,
		'pid': 351,
		'name': '巴彦淖尔市',
		'merger_name': '中国,内蒙古自治区,巴彦淖尔市',
		'level': 2,
		'areas': [{
			'id': 423,
			'pid': 422,
			'name': '临河区',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,临河区',
			'level': 3
		}, {
			'id': 424,
			'pid': 422,
			'name': '五原县',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,五原县',
			'level': 3
		}, {
			'id': 425,
			'pid': 422,
			'name': '磴口县',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,磴口县',
			'level': 3
		}, {
			'id': 426,
			'pid': 422,
			'name': '乌拉特前旗',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,乌拉特前旗',
			'level': 3
		}, {
			'id': 427,
			'pid': 422,
			'name': '乌拉特中旗',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,乌拉特中旗',
			'level': 3
		}, {
			'id': 428,
			'pid': 422,
			'name': '乌拉特后旗',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,乌拉特后旗',
			'level': 3
		}, {
			'id': 429,
			'pid': 422,
			'name': '杭锦后旗',
			'merger_name': '中国,内蒙古自治区,巴彦淖尔市,杭锦后旗',
			'level': 3
		}]
	}, {
		'id': 430,
		'pid': 351,
		'name': '乌兰察布市',
		'merger_name': '中国,内蒙古自治区,乌兰察布市',
		'level': 2,
		'areas': [{
			'id': 431,
			'pid': 430,
			'name': '集宁区',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,集宁区',
			'level': 3
		}, {
			'id': 432,
			'pid': 430,
			'name': '卓资县',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,卓资县',
			'level': 3
		}, {
			'id': 433,
			'pid': 430,
			'name': '化德县',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,化德县',
			'level': 3
		}, {
			'id': 434,
			'pid': 430,
			'name': '商都县',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,商都县',
			'level': 3
		}, {
			'id': 435,
			'pid': 430,
			'name': '兴和县',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,兴和县',
			'level': 3
		}, {
			'id': 436,
			'pid': 430,
			'name': '凉城县',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,凉城县',
			'level': 3
		}, {
			'id': 437,
			'pid': 430,
			'name': '察哈尔右翼前旗',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,察哈尔右翼前旗',
			'level': 3
		}, {
			'id': 438,
			'pid': 430,
			'name': '察哈尔右翼中旗',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,察哈尔右翼中旗',
			'level': 3
		}, {
			'id': 439,
			'pid': 430,
			'name': '察哈尔右翼后旗',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,察哈尔右翼后旗',
			'level': 3
		}, {
			'id': 440,
			'pid': 430,
			'name': '四子王旗',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,四子王旗',
			'level': 3
		}, {
			'id': 441,
			'pid': 430,
			'name': '丰镇市',
			'merger_name': '中国,内蒙古自治区,乌兰察布市,丰镇市',
			'level': 3
		}]
	}, {
		'id': 442,
		'pid': 351,
		'name': '兴安盟',
		'merger_name': '中国,内蒙古自治区,兴安盟',
		'level': 2,
		'areas': [{
			'id': 443,
			'pid': 442,
			'name': '乌兰浩特市',
			'merger_name': '中国,内蒙古自治区,兴安盟,乌兰浩特市',
			'level': 3
		}, {
			'id': 444,
			'pid': 442,
			'name': '阿尔山市',
			'merger_name': '中国,内蒙古自治区,兴安盟,阿尔山市',
			'level': 3
		}, {
			'id': 445,
			'pid': 442,
			'name': '科尔沁右翼前旗',
			'merger_name': '中国,内蒙古自治区,兴安盟,科尔沁右翼前旗',
			'level': 3
		}, {
			'id': 446,
			'pid': 442,
			'name': '科尔沁右翼中旗',
			'merger_name': '中国,内蒙古自治区,兴安盟,科尔沁右翼中旗',
			'level': 3
		}, {
			'id': 447,
			'pid': 442,
			'name': '扎赉特旗',
			'merger_name': '中国,内蒙古自治区,兴安盟,扎赉特旗',
			'level': 3
		}, {
			'id': 448,
			'pid': 442,
			'name': '突泉县',
			'merger_name': '中国,内蒙古自治区,兴安盟,突泉县',
			'level': 3
		}]
	}, {
		'id': 449,
		'pid': 351,
		'name': '锡林郭勒盟',
		'merger_name': '中国,内蒙古自治区,锡林郭勒盟',
		'level': 2,
		'areas': [{
			'id': 450,
			'pid': 449,
			'name': '二连浩特市',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,二连浩特市',
			'level': 3
		}, {
			'id': 451,
			'pid': 449,
			'name': '锡林浩特市',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,锡林浩特市',
			'level': 3
		}, {
			'id': 452,
			'pid': 449,
			'name': '阿巴嘎旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,阿巴嘎旗',
			'level': 3
		}, {
			'id': 453,
			'pid': 449,
			'name': '苏尼特左旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,苏尼特左旗',
			'level': 3
		}, {
			'id': 454,
			'pid': 449,
			'name': '苏尼特右旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,苏尼特右旗',
			'level': 3
		}, {
			'id': 455,
			'pid': 449,
			'name': '东乌珠穆沁旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,东乌珠穆沁旗',
			'level': 3
		}, {
			'id': 456,
			'pid': 449,
			'name': '西乌珠穆沁旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,西乌珠穆沁旗',
			'level': 3
		}, {
			'id': 457,
			'pid': 449,
			'name': '太仆寺旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,太仆寺旗',
			'level': 3
		}, {
			'id': 458,
			'pid': 449,
			'name': '镶黄旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,镶黄旗',
			'level': 3
		}, {
			'id': 459,
			'pid': 449,
			'name': '正镶白旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,正镶白旗',
			'level': 3
		}, {
			'id': 460,
			'pid': 449,
			'name': '正蓝旗',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,正蓝旗',
			'level': 3
		}, {
			'id': 461,
			'pid': 449,
			'name': '多伦县',
			'merger_name': '中国,内蒙古自治区,锡林郭勒盟,多伦县',
			'level': 3
		}]
	}, {
		'id': 462,
		'pid': 351,
		'name': '阿拉善盟',
		'merger_name': '中国,内蒙古自治区,阿拉善盟',
		'level': 2,
		'areas': [{
			'id': 463,
			'pid': 462,
			'name': '阿拉善左旗',
			'merger_name': '中国,内蒙古自治区,阿拉善盟,阿拉善左旗',
			'level': 3
		}, {
			'id': 464,
			'pid': 462,
			'name': '阿拉善右旗',
			'merger_name': '中国,内蒙古自治区,阿拉善盟,阿拉善右旗',
			'level': 3
		}, {
			'id': 465,
			'pid': 462,
			'name': '额济纳旗',
			'merger_name': '中国,内蒙古自治区,阿拉善盟,额济纳旗',
			'level': 3
		}]
	}]
}, {
	'id': 466,
	'pid': 0,
	'name': '辽宁省',
	'merger_name': '中国,辽宁省',
	'level': 1,
	'cities': [{
		'id': 467,
		'pid': 466,
		'name': '沈阳市',
		'merger_name': '中国,辽宁省,沈阳市',
		'level': 2,
		'areas': [{
			'id': 468,
			'pid': 467,
			'name': '和平区',
			'merger_name': '中国,辽宁省,沈阳市,和平区',
			'level': 3
		}, {
			'id': 469,
			'pid': 467,
			'name': '沈河区',
			'merger_name': '中国,辽宁省,沈阳市,沈河区',
			'level': 3
		}, {
			'id': 470,
			'pid': 467,
			'name': '大东区',
			'merger_name': '中国,辽宁省,沈阳市,大东区',
			'level': 3
		}, {
			'id': 471,
			'pid': 467,
			'name': '皇姑区',
			'merger_name': '中国,辽宁省,沈阳市,皇姑区',
			'level': 3
		}, {
			'id': 472,
			'pid': 467,
			'name': '铁西区',
			'merger_name': '中国,辽宁省,沈阳市,铁西区',
			'level': 3
		}, {
			'id': 473,
			'pid': 467,
			'name': '苏家屯区',
			'merger_name': '中国,辽宁省,沈阳市,苏家屯区',
			'level': 3
		}, {
			'id': 474,
			'pid': 467,
			'name': '浑南区',
			'merger_name': '中国,辽宁省,沈阳市,浑南区',
			'level': 3
		}, {
			'id': 475,
			'pid': 467,
			'name': '沈北新区',
			'merger_name': '中国,辽宁省,沈阳市,沈北新区',
			'level': 3
		}, {
			'id': 476,
			'pid': 467,
			'name': '于洪区',
			'merger_name': '中国,辽宁省,沈阳市,于洪区',
			'level': 3
		}, {
			'id': 477,
			'pid': 467,
			'name': '辽中县',
			'merger_name': '中国,辽宁省,沈阳市,辽中县',
			'level': 3
		}, {
			'id': 478,
			'pid': 467,
			'name': '康平县',
			'merger_name': '中国,辽宁省,沈阳市,康平县',
			'level': 3
		}, {
			'id': 479,
			'pid': 467,
			'name': '法库县',
			'merger_name': '中国,辽宁省,沈阳市,法库县',
			'level': 3
		}, {
			'id': 480,
			'pid': 467,
			'name': '新民市',
			'merger_name': '中国,辽宁省,沈阳市,新民市',
			'level': 3
		}]
	}, {
		'id': 481,
		'pid': 466,
		'name': '大连市',
		'merger_name': '中国,辽宁省,大连市',
		'level': 2,
		'areas': [{
			'id': 482,
			'pid': 481,
			'name': '中山区',
			'merger_name': '中国,辽宁省,大连市,中山区',
			'level': 3
		}, {
			'id': 483,
			'pid': 481,
			'name': '西岗区',
			'merger_name': '中国,辽宁省,大连市,西岗区',
			'level': 3
		}, {
			'id': 484,
			'pid': 481,
			'name': '沙河口区',
			'merger_name': '中国,辽宁省,大连市,沙河口区',
			'level': 3
		}, {
			'id': 485,
			'pid': 481,
			'name': '甘井子区',
			'merger_name': '中国,辽宁省,大连市,甘井子区',
			'level': 3
		}, {
			'id': 486,
			'pid': 481,
			'name': '旅顺口区',
			'merger_name': '中国,辽宁省,大连市,旅顺口区',
			'level': 3
		}, {
			'id': 487,
			'pid': 481,
			'name': '金州区',
			'merger_name': '中国,辽宁省,大连市,金州区',
			'level': 3
		}, {
			'id': 488,
			'pid': 481,
			'name': '长海县',
			'merger_name': '中国,辽宁省,大连市,长海县',
			'level': 3
		}, {
			'id': 489,
			'pid': 481,
			'name': '瓦房店市',
			'merger_name': '中国,辽宁省,大连市,瓦房店市',
			'level': 3
		}, {
			'id': 490,
			'pid': 481,
			'name': '普兰店市',
			'merger_name': '中国,辽宁省,大连市,普兰店市',
			'level': 3
		}, {
			'id': 491,
			'pid': 481,
			'name': '庄河市',
			'merger_name': '中国,辽宁省,大连市,庄河市',
			'level': 3
		}]
	}, {
		'id': 492,
		'pid': 466,
		'name': '鞍山市',
		'merger_name': '中国,辽宁省,鞍山市',
		'level': 2,
		'areas': [{
			'id': 493,
			'pid': 492,
			'name': '铁东区',
			'merger_name': '中国,辽宁省,鞍山市,铁东区',
			'level': 3
		}, {
			'id': 494,
			'pid': 492,
			'name': '铁西区',
			'merger_name': '中国,辽宁省,鞍山市,铁西区',
			'level': 3
		}, {
			'id': 495,
			'pid': 492,
			'name': '立山区',
			'merger_name': '中国,辽宁省,鞍山市,立山区',
			'level': 3
		}, {
			'id': 496,
			'pid': 492,
			'name': '千山区',
			'merger_name': '中国,辽宁省,鞍山市,千山区',
			'level': 3
		}, {
			'id': 497,
			'pid': 492,
			'name': '台安县',
			'merger_name': '中国,辽宁省,鞍山市,台安县',
			'level': 3
		}, {
			'id': 498,
			'pid': 492,
			'name': '岫岩满族自治县',
			'merger_name': '中国,辽宁省,鞍山市,岫岩满族自治县',
			'level': 3
		}, {
			'id': 499,
			'pid': 492,
			'name': '海城市',
			'merger_name': '中国,辽宁省,鞍山市,海城市',
			'level': 3
		}]
	}, {
		'id': 500,
		'pid': 466,
		'name': '抚顺市',
		'merger_name': '中国,辽宁省,抚顺市',
		'level': 2,
		'areas': [{
			'id': 501,
			'pid': 500,
			'name': '新抚区',
			'merger_name': '中国,辽宁省,抚顺市,新抚区',
			'level': 3
		}, {
			'id': 502,
			'pid': 500,
			'name': '东洲区',
			'merger_name': '中国,辽宁省,抚顺市,东洲区',
			'level': 3
		}, {
			'id': 503,
			'pid': 500,
			'name': '望花区',
			'merger_name': '中国,辽宁省,抚顺市,望花区',
			'level': 3
		}, {
			'id': 504,
			'pid': 500,
			'name': '顺城区',
			'merger_name': '中国,辽宁省,抚顺市,顺城区',
			'level': 3
		}, {
			'id': 505,
			'pid': 500,
			'name': '抚顺县',
			'merger_name': '中国,辽宁省,抚顺市,抚顺县',
			'level': 3
		}, {
			'id': 506,
			'pid': 500,
			'name': '新宾满族自治县',
			'merger_name': '中国,辽宁省,抚顺市,新宾满族自治县',
			'level': 3
		}, {
			'id': 507,
			'pid': 500,
			'name': '清原满族自治县',
			'merger_name': '中国,辽宁省,抚顺市,清原满族自治县',
			'level': 3
		}]
	}, {
		'id': 508,
		'pid': 466,
		'name': '本溪市',
		'merger_name': '中国,辽宁省,本溪市',
		'level': 2,
		'areas': [{
			'id': 509,
			'pid': 508,
			'name': '平山区',
			'merger_name': '中国,辽宁省,本溪市,平山区',
			'level': 3
		}, {
			'id': 510,
			'pid': 508,
			'name': '溪湖区',
			'merger_name': '中国,辽宁省,本溪市,溪湖区',
			'level': 3
		}, {
			'id': 511,
			'pid': 508,
			'name': '明山区',
			'merger_name': '中国,辽宁省,本溪市,明山区',
			'level': 3
		}, {
			'id': 512,
			'pid': 508,
			'name': '南芬区',
			'merger_name': '中国,辽宁省,本溪市,南芬区',
			'level': 3
		}, {
			'id': 513,
			'pid': 508,
			'name': '本溪满族自治县',
			'merger_name': '中国,辽宁省,本溪市,本溪满族自治县',
			'level': 3
		}, {
			'id': 514,
			'pid': 508,
			'name': '桓仁满族自治县',
			'merger_name': '中国,辽宁省,本溪市,桓仁满族自治县',
			'level': 3
		}]
	}, {
		'id': 515,
		'pid': 466,
		'name': '丹东市',
		'merger_name': '中国,辽宁省,丹东市',
		'level': 2,
		'areas': [{
			'id': 516,
			'pid': 515,
			'name': '元宝区',
			'merger_name': '中国,辽宁省,丹东市,元宝区',
			'level': 3
		}, {
			'id': 517,
			'pid': 515,
			'name': '振兴区',
			'merger_name': '中国,辽宁省,丹东市,振兴区',
			'level': 3
		}, {
			'id': 518,
			'pid': 515,
			'name': '振安区',
			'merger_name': '中国,辽宁省,丹东市,振安区',
			'level': 3
		}, {
			'id': 519,
			'pid': 515,
			'name': '宽甸满族自治县',
			'merger_name': '中国,辽宁省,丹东市,宽甸满族自治县',
			'level': 3
		}, {
			'id': 520,
			'pid': 515,
			'name': '东港市',
			'merger_name': '中国,辽宁省,丹东市,东港市',
			'level': 3
		}, {
			'id': 521,
			'pid': 515,
			'name': '凤城市',
			'merger_name': '中国,辽宁省,丹东市,凤城市',
			'level': 3
		}]
	}, {
		'id': 522,
		'pid': 466,
		'name': '锦州市',
		'merger_name': '中国,辽宁省,锦州市',
		'level': 2,
		'areas': [{
			'id': 523,
			'pid': 522,
			'name': '古塔区',
			'merger_name': '中国,辽宁省,锦州市,古塔区',
			'level': 3
		}, {
			'id': 524,
			'pid': 522,
			'name': '凌河区',
			'merger_name': '中国,辽宁省,锦州市,凌河区',
			'level': 3
		}, {
			'id': 525,
			'pid': 522,
			'name': '太和区',
			'merger_name': '中国,辽宁省,锦州市,太和区',
			'level': 3
		}, {
			'id': 526,
			'pid': 522,
			'name': '黑山县',
			'merger_name': '中国,辽宁省,锦州市,黑山县',
			'level': 3
		}, {
			'id': 527,
			'pid': 522,
			'name': '义县',
			'merger_name': '中国,辽宁省,锦州市,义县',
			'level': 3
		}, {
			'id': 528,
			'pid': 522,
			'name': '凌海市',
			'merger_name': '中国,辽宁省,锦州市,凌海市',
			'level': 3
		}, {
			'id': 529,
			'pid': 522,
			'name': '北镇市',
			'merger_name': '中国,辽宁省,锦州市,北镇市',
			'level': 3
		}]
	}, {
		'id': 530,
		'pid': 466,
		'name': '营口市',
		'merger_name': '中国,辽宁省,营口市',
		'level': 2,
		'areas': [{
			'id': 531,
			'pid': 530,
			'name': '站前区',
			'merger_name': '中国,辽宁省,营口市,站前区',
			'level': 3
		}, {
			'id': 532,
			'pid': 530,
			'name': '西市区',
			'merger_name': '中国,辽宁省,营口市,西市区',
			'level': 3
		}, {
			'id': 533,
			'pid': 530,
			'name': '鲅鱼圈区',
			'merger_name': '中国,辽宁省,营口市,鲅鱼圈区',
			'level': 3
		}, {
			'id': 534,
			'pid': 530,
			'name': '老边区',
			'merger_name': '中国,辽宁省,营口市,老边区',
			'level': 3
		}, {
			'id': 535,
			'pid': 530,
			'name': '盖州市',
			'merger_name': '中国,辽宁省,营口市,盖州市',
			'level': 3
		}, {
			'id': 536,
			'pid': 530,
			'name': '大石桥市',
			'merger_name': '中国,辽宁省,营口市,大石桥市',
			'level': 3
		}]
	}, {
		'id': 537,
		'pid': 466,
		'name': '阜新市',
		'merger_name': '中国,辽宁省,阜新市',
		'level': 2,
		'areas': [{
			'id': 538,
			'pid': 537,
			'name': '海州区',
			'merger_name': '中国,辽宁省,阜新市,海州区',
			'level': 3
		}, {
			'id': 539,
			'pid': 537,
			'name': '新邱区',
			'merger_name': '中国,辽宁省,阜新市,新邱区',
			'level': 3
		}, {
			'id': 540,
			'pid': 537,
			'name': '太平区',
			'merger_name': '中国,辽宁省,阜新市,太平区',
			'level': 3
		}, {
			'id': 541,
			'pid': 537,
			'name': '清河门区',
			'merger_name': '中国,辽宁省,阜新市,清河门区',
			'level': 3
		}, {
			'id': 542,
			'pid': 537,
			'name': '细河区',
			'merger_name': '中国,辽宁省,阜新市,细河区',
			'level': 3
		}, {
			'id': 543,
			'pid': 537,
			'name': '阜新蒙古族自治县',
			'merger_name': '中国,辽宁省,阜新市,阜新蒙古族自治县',
			'level': 3
		}, {
			'id': 544,
			'pid': 537,
			'name': '彰武县',
			'merger_name': '中国,辽宁省,阜新市,彰武县',
			'level': 3
		}]
	}, {
		'id': 545,
		'pid': 466,
		'name': '辽阳市',
		'merger_name': '中国,辽宁省,辽阳市',
		'level': 2,
		'areas': [{
			'id': 546,
			'pid': 545,
			'name': '白塔区',
			'merger_name': '中国,辽宁省,辽阳市,白塔区',
			'level': 3
		}, {
			'id': 547,
			'pid': 545,
			'name': '文圣区',
			'merger_name': '中国,辽宁省,辽阳市,文圣区',
			'level': 3
		}, {
			'id': 548,
			'pid': 545,
			'name': '宏伟区',
			'merger_name': '中国,辽宁省,辽阳市,宏伟区',
			'level': 3
		}, {
			'id': 549,
			'pid': 545,
			'name': '弓长岭区',
			'merger_name': '中国,辽宁省,辽阳市,弓长岭区',
			'level': 3
		}, {
			'id': 550,
			'pid': 545,
			'name': '太子河区',
			'merger_name': '中国,辽宁省,辽阳市,太子河区',
			'level': 3
		}, {
			'id': 551,
			'pid': 545,
			'name': '辽阳县',
			'merger_name': '中国,辽宁省,辽阳市,辽阳县',
			'level': 3
		}, {
			'id': 552,
			'pid': 545,
			'name': '灯塔市',
			'merger_name': '中国,辽宁省,辽阳市,灯塔市',
			'level': 3
		}]
	}, {
		'id': 553,
		'pid': 466,
		'name': '盘锦市',
		'merger_name': '中国,辽宁省,盘锦市',
		'level': 2,
		'areas': [{
			'id': 554,
			'pid': 553,
			'name': '双台子区',
			'merger_name': '中国,辽宁省,盘锦市,双台子区',
			'level': 3
		}, {
			'id': 555,
			'pid': 553,
			'name': '兴隆台区',
			'merger_name': '中国,辽宁省,盘锦市,兴隆台区',
			'level': 3
		}, {
			'id': 556,
			'pid': 553,
			'name': '大洼县',
			'merger_name': '中国,辽宁省,盘锦市,大洼县',
			'level': 3
		}, {
			'id': 557,
			'pid': 553,
			'name': '盘山县',
			'merger_name': '中国,辽宁省,盘锦市,盘山县',
			'level': 3
		}]
	}, {
		'id': 558,
		'pid': 466,
		'name': '铁岭市',
		'merger_name': '中国,辽宁省,铁岭市',
		'level': 2,
		'areas': [{
			'id': 559,
			'pid': 558,
			'name': '银州区',
			'merger_name': '中国,辽宁省,铁岭市,银州区',
			'level': 3
		}, {
			'id': 560,
			'pid': 558,
			'name': '清河区',
			'merger_name': '中国,辽宁省,铁岭市,清河区',
			'level': 3
		}, {
			'id': 561,
			'pid': 558,
			'name': '铁岭县',
			'merger_name': '中国,辽宁省,铁岭市,铁岭县',
			'level': 3
		}, {
			'id': 562,
			'pid': 558,
			'name': '西丰县',
			'merger_name': '中国,辽宁省,铁岭市,西丰县',
			'level': 3
		}, {
			'id': 563,
			'pid': 558,
			'name': '昌图县',
			'merger_name': '中国,辽宁省,铁岭市,昌图县',
			'level': 3
		}, {
			'id': 564,
			'pid': 558,
			'name': '调兵山市',
			'merger_name': '中国,辽宁省,铁岭市,调兵山市',
			'level': 3
		}, {
			'id': 565,
			'pid': 558,
			'name': '开原市',
			'merger_name': '中国,辽宁省,铁岭市,开原市',
			'level': 3
		}]
	}, {
		'id': 566,
		'pid': 466,
		'name': '朝阳市',
		'merger_name': '中国,辽宁省,朝阳市',
		'level': 2,
		'areas': [{
			'id': 567,
			'pid': 566,
			'name': '双塔区',
			'merger_name': '中国,辽宁省,朝阳市,双塔区',
			'level': 3
		}, {
			'id': 568,
			'pid': 566,
			'name': '龙城区',
			'merger_name': '中国,辽宁省,朝阳市,龙城区',
			'level': 3
		}, {
			'id': 569,
			'pid': 566,
			'name': '朝阳县',
			'merger_name': '中国,辽宁省,朝阳市,朝阳县',
			'level': 3
		}, {
			'id': 570,
			'pid': 566,
			'name': '建平县',
			'merger_name': '中国,辽宁省,朝阳市,建平县',
			'level': 3
		}, {
			'id': 571,
			'pid': 566,
			'name': '喀喇沁左翼蒙古族自治县',
			'merger_name': '中国,辽宁省,朝阳市,喀喇沁左翼蒙古族自治县',
			'level': 3
		}, {
			'id': 572,
			'pid': 566,
			'name': '北票市',
			'merger_name': '中国,辽宁省,朝阳市,北票市',
			'level': 3
		}, {
			'id': 573,
			'pid': 566,
			'name': '凌源市',
			'merger_name': '中国,辽宁省,朝阳市,凌源市',
			'level': 3
		}]
	}, {
		'id': 574,
		'pid': 466,
		'name': '葫芦岛市',
		'merger_name': '中国,辽宁省,葫芦岛市',
		'level': 2,
		'areas': [{
			'id': 575,
			'pid': 574,
			'name': '连山区',
			'merger_name': '中国,辽宁省,葫芦岛市,连山区',
			'level': 3
		}, {
			'id': 576,
			'pid': 574,
			'name': '龙港区',
			'merger_name': '中国,辽宁省,葫芦岛市,龙港区',
			'level': 3
		}, {
			'id': 577,
			'pid': 574,
			'name': '南票区',
			'merger_name': '中国,辽宁省,葫芦岛市,南票区',
			'level': 3
		}, {
			'id': 578,
			'pid': 574,
			'name': '绥中县',
			'merger_name': '中国,辽宁省,葫芦岛市,绥中县',
			'level': 3
		}, {
			'id': 579,
			'pid': 574,
			'name': '建昌县',
			'merger_name': '中国,辽宁省,葫芦岛市,建昌县',
			'level': 3
		}, {
			'id': 580,
			'pid': 574,
			'name': '兴城市',
			'merger_name': '中国,辽宁省,葫芦岛市,兴城市',
			'level': 3
		}]
	}, {
		'id': 581,
		'pid': 466,
		'name': '金普新区',
		'merger_name': '中国,辽宁省,金普新区',
		'level': 2,
		'areas': [{
			'id': 582,
			'pid': 581,
			'name': '金州新区',
			'merger_name': '中国,辽宁省,金普新区,金州新区',
			'level': 3
		}, {
			'id': 583,
			'pid': 581,
			'name': '普湾新区',
			'merger_name': '中国,辽宁省,金普新区,普湾新区',
			'level': 3
		}, {
			'id': 584,
			'pid': 581,
			'name': '保税区',
			'merger_name': '中国,辽宁省,金普新区,保税区',
			'level': 3
		}]
	}]
}, {
	'id': 585,
	'pid': 0,
	'name': '吉林省',
	'merger_name': '中国,吉林省',
	'level': 1,
	'cities': [{
		'id': 586,
		'pid': 585,
		'name': '长春市',
		'merger_name': '中国,吉林省,长春市',
		'level': 2,
		'areas': [{
			'id': 587,
			'pid': 586,
			'name': '南关区',
			'merger_name': '中国,吉林省,长春市,南关区',
			'level': 3
		}, {
			'id': 588,
			'pid': 586,
			'name': '宽城区',
			'merger_name': '中国,吉林省,长春市,宽城区',
			'level': 3
		}, {
			'id': 589,
			'pid': 586,
			'name': '朝阳区',
			'merger_name': '中国,吉林省,长春市,朝阳区',
			'level': 3
		}, {
			'id': 590,
			'pid': 586,
			'name': '二道区',
			'merger_name': '中国,吉林省,长春市,二道区',
			'level': 3
		}, {
			'id': 591,
			'pid': 586,
			'name': '绿园区',
			'merger_name': '中国,吉林省,长春市,绿园区',
			'level': 3
		}, {
			'id': 592,
			'pid': 586,
			'name': '双阳区',
			'merger_name': '中国,吉林省,长春市,双阳区',
			'level': 3
		}, {
			'id': 593,
			'pid': 586,
			'name': '九台区',
			'merger_name': '中国,吉林省,长春市,九台区',
			'level': 3
		}, {
			'id': 594,
			'pid': 586,
			'name': '农安县',
			'merger_name': '中国,吉林省,长春市,农安县',
			'level': 3
		}, {
			'id': 595,
			'pid': 586,
			'name': '榆树市',
			'merger_name': '中国,吉林省,长春市,榆树市',
			'level': 3
		}, {
			'id': 596,
			'pid': 586,
			'name': '德惠市',
			'merger_name': '中国,吉林省,长春市,德惠市',
			'level': 3
		}]
	}, {
		'id': 597,
		'pid': 585,
		'name': '吉林市',
		'merger_name': '中国,吉林省,吉林市',
		'level': 2,
		'areas': [{
			'id': 598,
			'pid': 597,
			'name': '昌邑区',
			'merger_name': '中国,吉林省,吉林市,昌邑区',
			'level': 3
		}, {
			'id': 599,
			'pid': 597,
			'name': '龙潭区',
			'merger_name': '中国,吉林省,吉林市,龙潭区',
			'level': 3
		}, {
			'id': 600,
			'pid': 597,
			'name': '船营区',
			'merger_name': '中国,吉林省,吉林市,船营区',
			'level': 3
		}, {
			'id': 601,
			'pid': 597,
			'name': '丰满区',
			'merger_name': '中国,吉林省,吉林市,丰满区',
			'level': 3
		}, {
			'id': 602,
			'pid': 597,
			'name': '永吉县',
			'merger_name': '中国,吉林省,吉林市,永吉县',
			'level': 3
		}, {
			'id': 603,
			'pid': 597,
			'name': '蛟河市',
			'merger_name': '中国,吉林省,吉林市,蛟河市',
			'level': 3
		}, {
			'id': 604,
			'pid': 597,
			'name': '桦甸市',
			'merger_name': '中国,吉林省,吉林市,桦甸市',
			'level': 3
		}, {
			'id': 605,
			'pid': 597,
			'name': '舒兰市',
			'merger_name': '中国,吉林省,吉林市,舒兰市',
			'level': 3
		}, {
			'id': 606,
			'pid': 597,
			'name': '磐石市',
			'merger_name': '中国,吉林省,吉林市,磐石市',
			'level': 3
		}]
	}, {
		'id': 607,
		'pid': 585,
		'name': '四平市',
		'merger_name': '中国,吉林省,四平市',
		'level': 2,
		'areas': [{
			'id': 608,
			'pid': 607,
			'name': '铁西区',
			'merger_name': '中国,吉林省,四平市,铁西区',
			'level': 3
		}, {
			'id': 609,
			'pid': 607,
			'name': '铁东区',
			'merger_name': '中国,吉林省,四平市,铁东区',
			'level': 3
		}, {
			'id': 610,
			'pid': 607,
			'name': '梨树县',
			'merger_name': '中国,吉林省,四平市,梨树县',
			'level': 3
		}, {
			'id': 611,
			'pid': 607,
			'name': '伊通满族自治县',
			'merger_name': '中国,吉林省,四平市,伊通满族自治县',
			'level': 3
		}, {
			'id': 612,
			'pid': 607,
			'name': '公主岭市',
			'merger_name': '中国,吉林省,四平市,公主岭市',
			'level': 3
		}, {
			'id': 613,
			'pid': 607,
			'name': '双辽市',
			'merger_name': '中国,吉林省,四平市,双辽市',
			'level': 3
		}]
	}, {
		'id': 614,
		'pid': 585,
		'name': '辽源市',
		'merger_name': '中国,吉林省,辽源市',
		'level': 2,
		'areas': [{
			'id': 615,
			'pid': 614,
			'name': '龙山区',
			'merger_name': '中国,吉林省,辽源市,龙山区',
			'level': 3
		}, {
			'id': 616,
			'pid': 614,
			'name': '西安区',
			'merger_name': '中国,吉林省,辽源市,西安区',
			'level': 3
		}, {
			'id': 617,
			'pid': 614,
			'name': '东丰县',
			'merger_name': '中国,吉林省,辽源市,东丰县',
			'level': 3
		}, {
			'id': 618,
			'pid': 614,
			'name': '东辽县',
			'merger_name': '中国,吉林省,辽源市,东辽县',
			'level': 3
		}]
	}, {
		'id': 619,
		'pid': 585,
		'name': '通化市',
		'merger_name': '中国,吉林省,通化市',
		'level': 2,
		'areas': [{
			'id': 620,
			'pid': 619,
			'name': '东昌区',
			'merger_name': '中国,吉林省,通化市,东昌区',
			'level': 3
		}, {
			'id': 621,
			'pid': 619,
			'name': '二道江区',
			'merger_name': '中国,吉林省,通化市,二道江区',
			'level': 3
		}, {
			'id': 622,
			'pid': 619,
			'name': '通化县',
			'merger_name': '中国,吉林省,通化市,通化县',
			'level': 3
		}, {
			'id': 623,
			'pid': 619,
			'name': '辉南县',
			'merger_name': '中国,吉林省,通化市,辉南县',
			'level': 3
		}, {
			'id': 624,
			'pid': 619,
			'name': '柳河县',
			'merger_name': '中国,吉林省,通化市,柳河县',
			'level': 3
		}, {
			'id': 625,
			'pid': 619,
			'name': '梅河口市',
			'merger_name': '中国,吉林省,通化市,梅河口市',
			'level': 3
		}, {
			'id': 626,
			'pid': 619,
			'name': '集安市',
			'merger_name': '中国,吉林省,通化市,集安市',
			'level': 3
		}]
	}, {
		'id': 627,
		'pid': 585,
		'name': '白山市',
		'merger_name': '中国,吉林省,白山市',
		'level': 2,
		'areas': [{
			'id': 628,
			'pid': 627,
			'name': '浑江区',
			'merger_name': '中国,吉林省,白山市,浑江区',
			'level': 3
		}, {
			'id': 629,
			'pid': 627,
			'name': '江源区',
			'merger_name': '中国,吉林省,白山市,江源区',
			'level': 3
		}, {
			'id': 630,
			'pid': 627,
			'name': '抚松县',
			'merger_name': '中国,吉林省,白山市,抚松县',
			'level': 3
		}, {
			'id': 631,
			'pid': 627,
			'name': '靖宇县',
			'merger_name': '中国,吉林省,白山市,靖宇县',
			'level': 3
		}, {
			'id': 632,
			'pid': 627,
			'name': '长白朝鲜族自治县',
			'merger_name': '中国,吉林省,白山市,长白朝鲜族自治县',
			'level': 3
		}, {
			'id': 633,
			'pid': 627,
			'name': '临江市',
			'merger_name': '中国,吉林省,白山市,临江市',
			'level': 3
		}]
	}, {
		'id': 634,
		'pid': 585,
		'name': '松原市',
		'merger_name': '中国,吉林省,松原市',
		'level': 2,
		'areas': [{
			'id': 635,
			'pid': 634,
			'name': '宁江区',
			'merger_name': '中国,吉林省,松原市,宁江区',
			'level': 3
		}, {
			'id': 636,
			'pid': 634,
			'name': '前郭尔罗斯蒙古族自治县',
			'merger_name': '中国,吉林省,松原市,前郭尔罗斯蒙古族自治县',
			'level': 3
		}, {
			'id': 637,
			'pid': 634,
			'name': '长岭县',
			'merger_name': '中国,吉林省,松原市,长岭县',
			'level': 3
		}, {
			'id': 638,
			'pid': 634,
			'name': '乾安县',
			'merger_name': '中国,吉林省,松原市,乾安县',
			'level': 3
		}, {
			'id': 639,
			'pid': 634,
			'name': '扶余市',
			'merger_name': '中国,吉林省,松原市,扶余市',
			'level': 3
		}]
	}, {
		'id': 640,
		'pid': 585,
		'name': '白城市',
		'merger_name': '中国,吉林省,白城市',
		'level': 2,
		'areas': [{
			'id': 641,
			'pid': 640,
			'name': '洮北区',
			'merger_name': '中国,吉林省,白城市,洮北区',
			'level': 3
		}, {
			'id': 642,
			'pid': 640,
			'name': '镇赉县',
			'merger_name': '中国,吉林省,白城市,镇赉县',
			'level': 3
		}, {
			'id': 643,
			'pid': 640,
			'name': '通榆县',
			'merger_name': '中国,吉林省,白城市,通榆县',
			'level': 3
		}, {
			'id': 644,
			'pid': 640,
			'name': '洮南市',
			'merger_name': '中国,吉林省,白城市,洮南市',
			'level': 3
		}, {
			'id': 645,
			'pid': 640,
			'name': '大安市',
			'merger_name': '中国,吉林省,白城市,大安市',
			'level': 3
		}]
	}, {
		'id': 646,
		'pid': 585,
		'name': '延边朝鲜族自治州',
		'merger_name': '中国,吉林省,延边朝鲜族自治州',
		'level': 2,
		'areas': [{
			'id': 647,
			'pid': 646,
			'name': '延吉市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,延吉市',
			'level': 3
		}, {
			'id': 648,
			'pid': 646,
			'name': '图们市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,图们市',
			'level': 3
		}, {
			'id': 649,
			'pid': 646,
			'name': '敦化市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,敦化市',
			'level': 3
		}, {
			'id': 650,
			'pid': 646,
			'name': '珲春市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,珲春市',
			'level': 3
		}, {
			'id': 651,
			'pid': 646,
			'name': '龙井市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,龙井市',
			'level': 3
		}, {
			'id': 652,
			'pid': 646,
			'name': '和龙市',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,和龙市',
			'level': 3
		}, {
			'id': 653,
			'pid': 646,
			'name': '汪清县',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,汪清县',
			'level': 3
		}, {
			'id': 654,
			'pid': 646,
			'name': '安图县',
			'merger_name': '中国,吉林省,延边朝鲜族自治州,安图县',
			'level': 3
		}]
	}]
}, {
	'id': 655,
	'pid': 0,
	'name': '黑龙江省',
	'merger_name': '中国,黑龙江省',
	'level': 1,
	'cities': [{
		'id': 656,
		'pid': 655,
		'name': '哈尔滨市',
		'merger_name': '中国,黑龙江省,哈尔滨市',
		'level': 2,
		'areas': [{
			'id': 657,
			'pid': 656,
			'name': '道里区',
			'merger_name': '中国,黑龙江省,哈尔滨市,道里区',
			'level': 3
		}, {
			'id': 658,
			'pid': 656,
			'name': '南岗区',
			'merger_name': '中国,黑龙江省,哈尔滨市,南岗区',
			'level': 3
		}, {
			'id': 659,
			'pid': 656,
			'name': '道外区',
			'merger_name': '中国,黑龙江省,哈尔滨市,道外区',
			'level': 3
		}, {
			'id': 660,
			'pid': 656,
			'name': '平房区',
			'merger_name': '中国,黑龙江省,哈尔滨市,平房区',
			'level': 3
		}, {
			'id': 661,
			'pid': 656,
			'name': '松北区',
			'merger_name': '中国,黑龙江省,哈尔滨市,松北区',
			'level': 3
		}, {
			'id': 662,
			'pid': 656,
			'name': '香坊区',
			'merger_name': '中国,黑龙江省,哈尔滨市,香坊区',
			'level': 3
		}, {
			'id': 663,
			'pid': 656,
			'name': '呼兰区',
			'merger_name': '中国,黑龙江省,哈尔滨市,呼兰区',
			'level': 3
		}, {
			'id': 664,
			'pid': 656,
			'name': '阿城区',
			'merger_name': '中国,黑龙江省,哈尔滨市,阿城区',
			'level': 3
		}, {
			'id': 665,
			'pid': 656,
			'name': '双城区',
			'merger_name': '中国,黑龙江省,哈尔滨市,双城区',
			'level': 3
		}, {
			'id': 666,
			'pid': 656,
			'name': '依兰县',
			'merger_name': '中国,黑龙江省,哈尔滨市,依兰县',
			'level': 3
		}, {
			'id': 667,
			'pid': 656,
			'name': '方正县',
			'merger_name': '中国,黑龙江省,哈尔滨市,方正县',
			'level': 3
		}, {
			'id': 668,
			'pid': 656,
			'name': '宾县',
			'merger_name': '中国,黑龙江省,哈尔滨市,宾县',
			'level': 3
		}, {
			'id': 669,
			'pid': 656,
			'name': '巴彦县',
			'merger_name': '中国,黑龙江省,哈尔滨市,巴彦县',
			'level': 3
		}, {
			'id': 670,
			'pid': 656,
			'name': '木兰县',
			'merger_name': '中国,黑龙江省,哈尔滨市,木兰县',
			'level': 3
		}, {
			'id': 671,
			'pid': 656,
			'name': '通河县',
			'merger_name': '中国,黑龙江省,哈尔滨市,通河县',
			'level': 3
		}, {
			'id': 672,
			'pid': 656,
			'name': '延寿县',
			'merger_name': '中国,黑龙江省,哈尔滨市,延寿县',
			'level': 3
		}, {
			'id': 673,
			'pid': 656,
			'name': '尚志市',
			'merger_name': '中国,黑龙江省,哈尔滨市,尚志市',
			'level': 3
		}, {
			'id': 674,
			'pid': 656,
			'name': '五常市',
			'merger_name': '中国,黑龙江省,哈尔滨市,五常市',
			'level': 3
		}]
	}, {
		'id': 675,
		'pid': 655,
		'name': '齐齐哈尔市',
		'merger_name': '中国,黑龙江省,齐齐哈尔市',
		'level': 2,
		'areas': [{
			'id': 676,
			'pid': 675,
			'name': '龙沙区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,龙沙区',
			'level': 3
		}, {
			'id': 677,
			'pid': 675,
			'name': '建华区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,建华区',
			'level': 3
		}, {
			'id': 678,
			'pid': 675,
			'name': '铁锋区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,铁锋区',
			'level': 3
		}, {
			'id': 679,
			'pid': 675,
			'name': '昂昂溪区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,昂昂溪区',
			'level': 3
		}, {
			'id': 680,
			'pid': 675,
			'name': '富拉尔基区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,富拉尔基区',
			'level': 3
		}, {
			'id': 681,
			'pid': 675,
			'name': '碾子山区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,碾子山区',
			'level': 3
		}, {
			'id': 682,
			'pid': 675,
			'name': '梅里斯达斡尔族区',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,梅里斯达斡尔族区',
			'level': 3
		}, {
			'id': 683,
			'pid': 675,
			'name': '龙江县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,龙江县',
			'level': 3
		}, {
			'id': 684,
			'pid': 675,
			'name': '依安县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,依安县',
			'level': 3
		}, {
			'id': 685,
			'pid': 675,
			'name': '泰来县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,泰来县',
			'level': 3
		}, {
			'id': 686,
			'pid': 675,
			'name': '甘南县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,甘南县',
			'level': 3
		}, {
			'id': 687,
			'pid': 675,
			'name': '富裕县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,富裕县',
			'level': 3
		}, {
			'id': 688,
			'pid': 675,
			'name': '克山县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,克山县',
			'level': 3
		}, {
			'id': 689,
			'pid': 675,
			'name': '克东县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,克东县',
			'level': 3
		}, {
			'id': 690,
			'pid': 675,
			'name': '拜泉县',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,拜泉县',
			'level': 3
		}, {
			'id': 691,
			'pid': 675,
			'name': '讷河市',
			'merger_name': '中国,黑龙江省,齐齐哈尔市,讷河市',
			'level': 3
		}]
	}, {
		'id': 692,
		'pid': 655,
		'name': '鸡西市',
		'merger_name': '中国,黑龙江省,鸡西市',
		'level': 2,
		'areas': [{
			'id': 693,
			'pid': 692,
			'name': '鸡冠区',
			'merger_name': '中国,黑龙江省,鸡西市,鸡冠区',
			'level': 3
		}, {
			'id': 694,
			'pid': 692,
			'name': '恒山区',
			'merger_name': '中国,黑龙江省,鸡西市,恒山区',
			'level': 3
		}, {
			'id': 695,
			'pid': 692,
			'name': '滴道区',
			'merger_name': '中国,黑龙江省,鸡西市,滴道区',
			'level': 3
		}, {
			'id': 696,
			'pid': 692,
			'name': '梨树区',
			'merger_name': '中国,黑龙江省,鸡西市,梨树区',
			'level': 3
		}, {
			'id': 697,
			'pid': 692,
			'name': '城子河区',
			'merger_name': '中国,黑龙江省,鸡西市,城子河区',
			'level': 3
		}, {
			'id': 698,
			'pid': 692,
			'name': '麻山区',
			'merger_name': '中国,黑龙江省,鸡西市,麻山区',
			'level': 3
		}, {
			'id': 699,
			'pid': 692,
			'name': '鸡东县',
			'merger_name': '中国,黑龙江省,鸡西市,鸡东县',
			'level': 3
		}, {
			'id': 700,
			'pid': 692,
			'name': '虎林市',
			'merger_name': '中国,黑龙江省,鸡西市,虎林市',
			'level': 3
		}, {
			'id': 701,
			'pid': 692,
			'name': '密山市',
			'merger_name': '中国,黑龙江省,鸡西市,密山市',
			'level': 3
		}]
	}, {
		'id': 702,
		'pid': 655,
		'name': '鹤岗市',
		'merger_name': '中国,黑龙江省,鹤岗市',
		'level': 2,
		'areas': [{
			'id': 703,
			'pid': 702,
			'name': '向阳区',
			'merger_name': '中国,黑龙江省,鹤岗市,向阳区',
			'level': 3
		}, {
			'id': 704,
			'pid': 702,
			'name': '工农区',
			'merger_name': '中国,黑龙江省,鹤岗市,工农区',
			'level': 3
		}, {
			'id': 705,
			'pid': 702,
			'name': '南山区',
			'merger_name': '中国,黑龙江省,鹤岗市,南山区',
			'level': 3
		}, {
			'id': 706,
			'pid': 702,
			'name': '兴安区',
			'merger_name': '中国,黑龙江省,鹤岗市,兴安区',
			'level': 3
		}, {
			'id': 707,
			'pid': 702,
			'name': '东山区',
			'merger_name': '中国,黑龙江省,鹤岗市,东山区',
			'level': 3
		}, {
			'id': 708,
			'pid': 702,
			'name': '兴山区',
			'merger_name': '中国,黑龙江省,鹤岗市,兴山区',
			'level': 3
		}, {
			'id': 709,
			'pid': 702,
			'name': '萝北县',
			'merger_name': '中国,黑龙江省,鹤岗市,萝北县',
			'level': 3
		}, {
			'id': 710,
			'pid': 702,
			'name': '绥滨县',
			'merger_name': '中国,黑龙江省,鹤岗市,绥滨县',
			'level': 3
		}]
	}, {
		'id': 711,
		'pid': 655,
		'name': '双鸭山市',
		'merger_name': '中国,黑龙江省,双鸭山市',
		'level': 2,
		'areas': [{
			'id': 712,
			'pid': 711,
			'name': '尖山区',
			'merger_name': '中国,黑龙江省,双鸭山市,尖山区',
			'level': 3
		}, {
			'id': 713,
			'pid': 711,
			'name': '岭东区',
			'merger_name': '中国,黑龙江省,双鸭山市,岭东区',
			'level': 3
		}, {
			'id': 714,
			'pid': 711,
			'name': '四方台区',
			'merger_name': '中国,黑龙江省,双鸭山市,四方台区',
			'level': 3
		}, {
			'id': 715,
			'pid': 711,
			'name': '宝山区',
			'merger_name': '中国,黑龙江省,双鸭山市,宝山区',
			'level': 3
		}, {
			'id': 716,
			'pid': 711,
			'name': '集贤县',
			'merger_name': '中国,黑龙江省,双鸭山市,集贤县',
			'level': 3
		}, {
			'id': 717,
			'pid': 711,
			'name': '友谊县',
			'merger_name': '中国,黑龙江省,双鸭山市,友谊县',
			'level': 3
		}, {
			'id': 718,
			'pid': 711,
			'name': '宝清县',
			'merger_name': '中国,黑龙江省,双鸭山市,宝清县',
			'level': 3
		}, {
			'id': 719,
			'pid': 711,
			'name': '饶河县',
			'merger_name': '中国,黑龙江省,双鸭山市,饶河县',
			'level': 3
		}]
	}, {
		'id': 720,
		'pid': 655,
		'name': '大庆市',
		'merger_name': '中国,黑龙江省,大庆市',
		'level': 2,
		'areas': [{
			'id': 721,
			'pid': 720,
			'name': '萨尔图区',
			'merger_name': '中国,黑龙江省,大庆市,萨尔图区',
			'level': 3
		}, {
			'id': 722,
			'pid': 720,
			'name': '龙凤区',
			'merger_name': '中国,黑龙江省,大庆市,龙凤区',
			'level': 3
		}, {
			'id': 723,
			'pid': 720,
			'name': '让胡路区',
			'merger_name': '中国,黑龙江省,大庆市,让胡路区',
			'level': 3
		}, {
			'id': 724,
			'pid': 720,
			'name': '红岗区',
			'merger_name': '中国,黑龙江省,大庆市,红岗区',
			'level': 3
		}, {
			'id': 725,
			'pid': 720,
			'name': '大同区',
			'merger_name': '中国,黑龙江省,大庆市,大同区',
			'level': 3
		}, {
			'id': 726,
			'pid': 720,
			'name': '肇州县',
			'merger_name': '中国,黑龙江省,大庆市,肇州县',
			'level': 3
		}, {
			'id': 727,
			'pid': 720,
			'name': '肇源县',
			'merger_name': '中国,黑龙江省,大庆市,肇源县',
			'level': 3
		}, {
			'id': 728,
			'pid': 720,
			'name': '林甸县',
			'merger_name': '中国,黑龙江省,大庆市,林甸县',
			'level': 3
		}, {
			'id': 729,
			'pid': 720,
			'name': '杜尔伯特蒙古族自治县',
			'merger_name': '中国,黑龙江省,大庆市,杜尔伯特蒙古族自治县',
			'level': 3
		}]
	}, {
		'id': 730,
		'pid': 655,
		'name': '伊春市',
		'merger_name': '中国,黑龙江省,伊春市',
		'level': 2,
		'areas': [{
			'id': 731,
			'pid': 730,
			'name': '伊春区',
			'merger_name': '中国,黑龙江省,伊春市,伊春区',
			'level': 3
		}, {
			'id': 732,
			'pid': 730,
			'name': '南岔区',
			'merger_name': '中国,黑龙江省,伊春市,南岔区',
			'level': 3
		}, {
			'id': 733,
			'pid': 730,
			'name': '友好区',
			'merger_name': '中国,黑龙江省,伊春市,友好区',
			'level': 3
		}, {
			'id': 734,
			'pid': 730,
			'name': '西林区',
			'merger_name': '中国,黑龙江省,伊春市,西林区',
			'level': 3
		}, {
			'id': 735,
			'pid': 730,
			'name': '翠峦区',
			'merger_name': '中国,黑龙江省,伊春市,翠峦区',
			'level': 3
		}, {
			'id': 736,
			'pid': 730,
			'name': '新青区',
			'merger_name': '中国,黑龙江省,伊春市,新青区',
			'level': 3
		}, {
			'id': 737,
			'pid': 730,
			'name': '美溪区',
			'merger_name': '中国,黑龙江省,伊春市,美溪区',
			'level': 3
		}, {
			'id': 738,
			'pid': 730,
			'name': '金山屯区',
			'merger_name': '中国,黑龙江省,伊春市,金山屯区',
			'level': 3
		}, {
			'id': 739,
			'pid': 730,
			'name': '五营区',
			'merger_name': '中国,黑龙江省,伊春市,五营区',
			'level': 3
		}, {
			'id': 740,
			'pid': 730,
			'name': '乌马河区',
			'merger_name': '中国,黑龙江省,伊春市,乌马河区',
			'level': 3
		}, {
			'id': 741,
			'pid': 730,
			'name': '汤旺河区',
			'merger_name': '中国,黑龙江省,伊春市,汤旺河区',
			'level': 3
		}, {
			'id': 742,
			'pid': 730,
			'name': '带岭区',
			'merger_name': '中国,黑龙江省,伊春市,带岭区',
			'level': 3
		}, {
			'id': 743,
			'pid': 730,
			'name': '乌伊岭区',
			'merger_name': '中国,黑龙江省,伊春市,乌伊岭区',
			'level': 3
		}, {
			'id': 744,
			'pid': 730,
			'name': '红星区',
			'merger_name': '中国,黑龙江省,伊春市,红星区',
			'level': 3
		}, {
			'id': 745,
			'pid': 730,
			'name': '上甘岭区',
			'merger_name': '中国,黑龙江省,伊春市,上甘岭区',
			'level': 3
		}, {
			'id': 746,
			'pid': 730,
			'name': '嘉荫县',
			'merger_name': '中国,黑龙江省,伊春市,嘉荫县',
			'level': 3
		}, {
			'id': 747,
			'pid': 730,
			'name': '铁力市',
			'merger_name': '中国,黑龙江省,伊春市,铁力市',
			'level': 3
		}]
	}, {
		'id': 748,
		'pid': 655,
		'name': '佳木斯市',
		'merger_name': '中国,黑龙江省,佳木斯市',
		'level': 2,
		'areas': [{
			'id': 749,
			'pid': 748,
			'name': '向阳区',
			'merger_name': '中国,黑龙江省,佳木斯市,向阳区',
			'level': 3
		}, {
			'id': 750,
			'pid': 748,
			'name': '前进区',
			'merger_name': '中国,黑龙江省,佳木斯市,前进区',
			'level': 3
		}, {
			'id': 751,
			'pid': 748,
			'name': '东风区',
			'merger_name': '中国,黑龙江省,佳木斯市,东风区',
			'level': 3
		}, {
			'id': 752,
			'pid': 748,
			'name': '郊区',
			'merger_name': '中国,黑龙江省,佳木斯市,郊区',
			'level': 3
		}, {
			'id': 753,
			'pid': 748,
			'name': '桦南县',
			'merger_name': '中国,黑龙江省,佳木斯市,桦南县',
			'level': 3
		}, {
			'id': 754,
			'pid': 748,
			'name': '桦川县',
			'merger_name': '中国,黑龙江省,佳木斯市,桦川县',
			'level': 3
		}, {
			'id': 755,
			'pid': 748,
			'name': '汤原县',
			'merger_name': '中国,黑龙江省,佳木斯市,汤原县',
			'level': 3
		}, {
			'id': 756,
			'pid': 748,
			'name': '抚远县',
			'merger_name': '中国,黑龙江省,佳木斯市,抚远县',
			'level': 3
		}, {
			'id': 757,
			'pid': 748,
			'name': '同江市',
			'merger_name': '中国,黑龙江省,佳木斯市,同江市',
			'level': 3
		}, {
			'id': 758,
			'pid': 748,
			'name': '富锦市',
			'merger_name': '中国,黑龙江省,佳木斯市,富锦市',
			'level': 3
		}]
	}, {
		'id': 759,
		'pid': 655,
		'name': '七台河市',
		'merger_name': '中国,黑龙江省,七台河市',
		'level': 2,
		'areas': [{
			'id': 760,
			'pid': 759,
			'name': '新兴区',
			'merger_name': '中国,黑龙江省,七台河市,新兴区',
			'level': 3
		}, {
			'id': 761,
			'pid': 759,
			'name': '桃山区',
			'merger_name': '中国,黑龙江省,七台河市,桃山区',
			'level': 3
		}, {
			'id': 762,
			'pid': 759,
			'name': '茄子河区',
			'merger_name': '中国,黑龙江省,七台河市,茄子河区',
			'level': 3
		}, {
			'id': 763,
			'pid': 759,
			'name': '勃利县',
			'merger_name': '中国,黑龙江省,七台河市,勃利县',
			'level': 3
		}]
	}, {
		'id': 764,
		'pid': 655,
		'name': '牡丹江市',
		'merger_name': '中国,黑龙江省,牡丹江市',
		'level': 2,
		'areas': [{
			'id': 765,
			'pid': 764,
			'name': '东安区',
			'merger_name': '中国,黑龙江省,牡丹江市,东安区',
			'level': 3
		}, {
			'id': 766,
			'pid': 764,
			'name': '阳明区',
			'merger_name': '中国,黑龙江省,牡丹江市,阳明区',
			'level': 3
		}, {
			'id': 767,
			'pid': 764,
			'name': '爱民区',
			'merger_name': '中国,黑龙江省,牡丹江市,爱民区',
			'level': 3
		}, {
			'id': 768,
			'pid': 764,
			'name': '西安区',
			'merger_name': '中国,黑龙江省,牡丹江市,西安区',
			'level': 3
		}, {
			'id': 769,
			'pid': 764,
			'name': '东宁县',
			'merger_name': '中国,黑龙江省,牡丹江市,东宁县',
			'level': 3
		}, {
			'id': 770,
			'pid': 764,
			'name': '林口县',
			'merger_name': '中国,黑龙江省,牡丹江市,林口县',
			'level': 3
		}, {
			'id': 771,
			'pid': 764,
			'name': '绥芬河市',
			'merger_name': '中国,黑龙江省,牡丹江市,绥芬河市',
			'level': 3
		}, {
			'id': 772,
			'pid': 764,
			'name': '海林市',
			'merger_name': '中国,黑龙江省,牡丹江市,海林市',
			'level': 3
		}, {
			'id': 773,
			'pid': 764,
			'name': '宁安市',
			'merger_name': '中国,黑龙江省,牡丹江市,宁安市',
			'level': 3
		}, {
			'id': 774,
			'pid': 764,
			'name': '穆棱市',
			'merger_name': '中国,黑龙江省,牡丹江市,穆棱市',
			'level': 3
		}]
	}, {
		'id': 775,
		'pid': 655,
		'name': '黑河市',
		'merger_name': '中国,黑龙江省,黑河市',
		'level': 2,
		'areas': [{
			'id': 776,
			'pid': 775,
			'name': '爱辉区',
			'merger_name': '中国,黑龙江省,黑河市,爱辉区',
			'level': 3
		}, {
			'id': 777,
			'pid': 775,
			'name': '嫩江县',
			'merger_name': '中国,黑龙江省,黑河市,嫩江县',
			'level': 3
		}, {
			'id': 778,
			'pid': 775,
			'name': '逊克县',
			'merger_name': '中国,黑龙江省,黑河市,逊克县',
			'level': 3
		}, {
			'id': 779,
			'pid': 775,
			'name': '孙吴县',
			'merger_name': '中国,黑龙江省,黑河市,孙吴县',
			'level': 3
		}, {
			'id': 780,
			'pid': 775,
			'name': '北安市',
			'merger_name': '中国,黑龙江省,黑河市,北安市',
			'level': 3
		}, {
			'id': 781,
			'pid': 775,
			'name': '五大连池市',
			'merger_name': '中国,黑龙江省,黑河市,五大连池市',
			'level': 3
		}]
	}, {
		'id': 782,
		'pid': 655,
		'name': '绥化市',
		'merger_name': '中国,黑龙江省,绥化市',
		'level': 2,
		'areas': [{
			'id': 783,
			'pid': 782,
			'name': '北林区',
			'merger_name': '中国,黑龙江省,绥化市,北林区',
			'level': 3
		}, {
			'id': 784,
			'pid': 782,
			'name': '望奎县',
			'merger_name': '中国,黑龙江省,绥化市,望奎县',
			'level': 3
		}, {
			'id': 785,
			'pid': 782,
			'name': '兰西县',
			'merger_name': '中国,黑龙江省,绥化市,兰西县',
			'level': 3
		}, {
			'id': 786,
			'pid': 782,
			'name': '青冈县',
			'merger_name': '中国,黑龙江省,绥化市,青冈县',
			'level': 3
		}, {
			'id': 787,
			'pid': 782,
			'name': '庆安县',
			'merger_name': '中国,黑龙江省,绥化市,庆安县',
			'level': 3
		}, {
			'id': 788,
			'pid': 782,
			'name': '明水县',
			'merger_name': '中国,黑龙江省,绥化市,明水县',
			'level': 3
		}, {
			'id': 789,
			'pid': 782,
			'name': '绥棱县',
			'merger_name': '中国,黑龙江省,绥化市,绥棱县',
			'level': 3
		}, {
			'id': 790,
			'pid': 782,
			'name': '安达市',
			'merger_name': '中国,黑龙江省,绥化市,安达市',
			'level': 3
		}, {
			'id': 791,
			'pid': 782,
			'name': '肇东市',
			'merger_name': '中国,黑龙江省,绥化市,肇东市',
			'level': 3
		}, {
			'id': 792,
			'pid': 782,
			'name': '海伦市',
			'merger_name': '中国,黑龙江省,绥化市,海伦市',
			'level': 3
		}]
	}, {
		'id': 793,
		'pid': 655,
		'name': '大兴安岭地区',
		'merger_name': '中国,黑龙江省,大兴安岭地区',
		'level': 2,
		'areas': [{
			'id': 794,
			'pid': 793,
			'name': '加格达奇区',
			'merger_name': '中国,黑龙江省,大兴安岭地区,加格达奇区',
			'level': 3
		}, {
			'id': 795,
			'pid': 793,
			'name': '新林区',
			'merger_name': '中国,黑龙江省,大兴安岭地区,新林区',
			'level': 3
		}, {
			'id': 796,
			'pid': 793,
			'name': '松岭区',
			'merger_name': '中国,黑龙江省,大兴安岭地区,松岭区',
			'level': 3
		}, {
			'id': 797,
			'pid': 793,
			'name': '呼中区',
			'merger_name': '中国,黑龙江省,大兴安岭地区,呼中区',
			'level': 3
		}, {
			'id': 798,
			'pid': 793,
			'name': '呼玛县',
			'merger_name': '中国,黑龙江省,大兴安岭地区,呼玛县',
			'level': 3
		}, {
			'id': 799,
			'pid': 793,
			'name': '塔河县',
			'merger_name': '中国,黑龙江省,大兴安岭地区,塔河县',
			'level': 3
		}, {
			'id': 800,
			'pid': 793,
			'name': '漠河县',
			'merger_name': '中国,黑龙江省,大兴安岭地区,漠河县',
			'level': 3
		}]
	}]
}, {
	'id': 801,
	'pid': 0,
	'name': '上海市',
	'merger_name': '中国,上海市',
	'level': 1,
	'cities': [{
		'id': 802,
		'pid': 801,
		'name': '上海市',
		'merger_name': '中国,上海,上海市',
		'level': 2,
		'areas': [{
			'id': 803,
			'pid': 802,
			'name': '黄浦区',
			'merger_name': '中国,上海,上海市,黄浦区',
			'level': 3
		}, {
			'id': 804,
			'pid': 802,
			'name': '徐汇区',
			'merger_name': '中国,上海,上海市,徐汇区',
			'level': 3
		}, {
			'id': 805,
			'pid': 802,
			'name': '长宁区',
			'merger_name': '中国,上海,上海市,长宁区',
			'level': 3
		}, {
			'id': 806,
			'pid': 802,
			'name': '静安区',
			'merger_name': '中国,上海,上海市,静安区',
			'level': 3
		}, {
			'id': 807,
			'pid': 802,
			'name': '普陀区',
			'merger_name': '中国,上海,上海市,普陀区',
			'level': 3
		}, {
			'id': 808,
			'pid': 802,
			'name': '闸北区',
			'merger_name': '中国,上海,上海市,闸北区',
			'level': 3
		}, {
			'id': 809,
			'pid': 802,
			'name': '虹口区',
			'merger_name': '中国,上海,上海市,虹口区',
			'level': 3
		}, {
			'id': 810,
			'pid': 802,
			'name': '杨浦区',
			'merger_name': '中国,上海,上海市,杨浦区',
			'level': 3
		}, {
			'id': 811,
			'pid': 802,
			'name': '闵行区',
			'merger_name': '中国,上海,上海市,闵行区',
			'level': 3
		}, {
			'id': 812,
			'pid': 802,
			'name': '宝山区',
			'merger_name': '中国,上海,上海市,宝山区',
			'level': 3
		}, {
			'id': 813,
			'pid': 802,
			'name': '嘉定区',
			'merger_name': '中国,上海,上海市,嘉定区',
			'level': 3
		}, {
			'id': 814,
			'pid': 802,
			'name': '浦东新区',
			'merger_name': '中国,上海,上海市,浦东新区',
			'level': 3
		}, {
			'id': 815,
			'pid': 802,
			'name': '金山区',
			'merger_name': '中国,上海,上海市,金山区',
			'level': 3
		}, {
			'id': 816,
			'pid': 802,
			'name': '松江区',
			'merger_name': '中国,上海,上海市,松江区',
			'level': 3
		}, {
			'id': 817,
			'pid': 802,
			'name': '青浦区',
			'merger_name': '中国,上海,上海市,青浦区',
			'level': 3
		}, {
			'id': 818,
			'pid': 802,
			'name': '奉贤区',
			'merger_name': '中国,上海,上海市,奉贤区',
			'level': 3
		}, {
			'id': 819,
			'pid': 802,
			'name': '崇明县',
			'merger_name': '中国,上海,上海市,崇明县',
			'level': 3
		}]
	}]
}, {
	'id': 820,
	'pid': 0,
	'name': '江苏省',
	'merger_name': '中国,江苏省',
	'level': 1,
	'cities': [{
		'id': 821,
		'pid': 820,
		'name': '南京市',
		'merger_name': '中国,江苏省,南京市',
		'level': 2,
		'areas': [{
			'id': 822,
			'pid': 821,
			'name': '玄武区',
			'merger_name': '中国,江苏省,南京市,玄武区',
			'level': 3
		}, {
			'id': 823,
			'pid': 821,
			'name': '秦淮区',
			'merger_name': '中国,江苏省,南京市,秦淮区',
			'level': 3
		}, {
			'id': 824,
			'pid': 821,
			'name': '建邺区',
			'merger_name': '中国,江苏省,南京市,建邺区',
			'level': 3
		}, {
			'id': 825,
			'pid': 821,
			'name': '鼓楼区',
			'merger_name': '中国,江苏省,南京市,鼓楼区',
			'level': 3
		}, {
			'id': 826,
			'pid': 821,
			'name': '浦口区',
			'merger_name': '中国,江苏省,南京市,浦口区',
			'level': 3
		}, {
			'id': 827,
			'pid': 821,
			'name': '栖霞区',
			'merger_name': '中国,江苏省,南京市,栖霞区',
			'level': 3
		}, {
			'id': 828,
			'pid': 821,
			'name': '雨花台区',
			'merger_name': '中国,江苏省,南京市,雨花台区',
			'level': 3
		}, {
			'id': 829,
			'pid': 821,
			'name': '江宁区',
			'merger_name': '中国,江苏省,南京市,江宁区',
			'level': 3
		}, {
			'id': 830,
			'pid': 821,
			'name': '六合区',
			'merger_name': '中国,江苏省,南京市,六合区',
			'level': 3
		}, {
			'id': 831,
			'pid': 821,
			'name': '溧水区',
			'merger_name': '中国,江苏省,南京市,溧水区',
			'level': 3
		}, {
			'id': 832,
			'pid': 821,
			'name': '高淳区',
			'merger_name': '中国,江苏省,南京市,高淳区',
			'level': 3
		}]
	}, {
		'id': 833,
		'pid': 820,
		'name': '无锡市',
		'merger_name': '中国,江苏省,无锡市',
		'level': 2,
		'areas': [{
			'id': 834,
			'pid': 833,
			'name': '崇安区',
			'merger_name': '中国,江苏省,无锡市,崇安区',
			'level': 3
		}, {
			'id': 835,
			'pid': 833,
			'name': '南长区',
			'merger_name': '中国,江苏省,无锡市,南长区',
			'level': 3
		}, {
			'id': 836,
			'pid': 833,
			'name': '北塘区',
			'merger_name': '中国,江苏省,无锡市,北塘区',
			'level': 3
		}, {
			'id': 837,
			'pid': 833,
			'name': '锡山区',
			'merger_name': '中国,江苏省,无锡市,锡山区',
			'level': 3
		}, {
			'id': 838,
			'pid': 833,
			'name': '惠山区',
			'merger_name': '中国,江苏省,无锡市,惠山区',
			'level': 3
		}, {
			'id': 839,
			'pid': 833,
			'name': '滨湖区',
			'merger_name': '中国,江苏省,无锡市,滨湖区',
			'level': 3
		}, {
			'id': 840,
			'pid': 833,
			'name': '江阴市',
			'merger_name': '中国,江苏省,无锡市,江阴市',
			'level': 3
		}, {
			'id': 841,
			'pid': 833,
			'name': '宜兴市',
			'merger_name': '中国,江苏省,无锡市,宜兴市',
			'level': 3
		}]
	}, {
		'id': 842,
		'pid': 820,
		'name': '徐州市',
		'merger_name': '中国,江苏省,徐州市',
		'level': 2,
		'areas': [{
			'id': 843,
			'pid': 842,
			'name': '鼓楼区',
			'merger_name': '中国,江苏省,徐州市,鼓楼区',
			'level': 3
		}, {
			'id': 844,
			'pid': 842,
			'name': '云龙区',
			'merger_name': '中国,江苏省,徐州市,云龙区',
			'level': 3
		}, {
			'id': 845,
			'pid': 842,
			'name': '贾汪区',
			'merger_name': '中国,江苏省,徐州市,贾汪区',
			'level': 3
		}, {
			'id': 846,
			'pid': 842,
			'name': '泉山区',
			'merger_name': '中国,江苏省,徐州市,泉山区',
			'level': 3
		}, {
			'id': 847,
			'pid': 842,
			'name': '铜山区',
			'merger_name': '中国,江苏省,徐州市,铜山区',
			'level': 3
		}, {
			'id': 848,
			'pid': 842,
			'name': '丰县',
			'merger_name': '中国,江苏省,徐州市,丰县',
			'level': 3
		}, {
			'id': 849,
			'pid': 842,
			'name': '沛县',
			'merger_name': '中国,江苏省,徐州市,沛县',
			'level': 3
		}, {
			'id': 850,
			'pid': 842,
			'name': '睢宁县',
			'merger_name': '中国,江苏省,徐州市,睢宁县',
			'level': 3
		}, {
			'id': 851,
			'pid': 842,
			'name': '新沂市',
			'merger_name': '中国,江苏省,徐州市,新沂市',
			'level': 3
		}, {
			'id': 852,
			'pid': 842,
			'name': '邳州市',
			'merger_name': '中国,江苏省,徐州市,邳州市',
			'level': 3
		}]
	}, {
		'id': 853,
		'pid': 820,
		'name': '常州市',
		'merger_name': '中国,江苏省,常州市',
		'level': 2,
		'areas': [{
			'id': 854,
			'pid': 853,
			'name': '天宁区',
			'merger_name': '中国,江苏省,常州市,天宁区',
			'level': 3
		}, {
			'id': 855,
			'pid': 853,
			'name': '钟楼区',
			'merger_name': '中国,江苏省,常州市,钟楼区',
			'level': 3
		}, {
			'id': 856,
			'pid': 853,
			'name': '戚墅堰区',
			'merger_name': '中国,江苏省,常州市,戚墅堰区',
			'level': 3
		}, {
			'id': 857,
			'pid': 853,
			'name': '新北区',
			'merger_name': '中国,江苏省,常州市,新北区',
			'level': 3
		}, {
			'id': 858,
			'pid': 853,
			'name': '武进区',
			'merger_name': '中国,江苏省,常州市,武进区',
			'level': 3
		}, {
			'id': 859,
			'pid': 853,
			'name': '溧阳市',
			'merger_name': '中国,江苏省,常州市,溧阳市',
			'level': 3
		}, {
			'id': 860,
			'pid': 853,
			'name': '金坛市',
			'merger_name': '中国,江苏省,常州市,金坛市',
			'level': 3
		}]
	}, {
		'id': 861,
		'pid': 820,
		'name': '苏州市',
		'merger_name': '中国,江苏省,苏州市',
		'level': 2,
		'areas': [{
			'id': 862,
			'pid': 861,
			'name': '虎丘区',
			'merger_name': '中国,江苏省,苏州市,虎丘区',
			'level': 3
		}, {
			'id': 863,
			'pid': 861,
			'name': '吴中区',
			'merger_name': '中国,江苏省,苏州市,吴中区',
			'level': 3
		}, {
			'id': 864,
			'pid': 861,
			'name': '相城区',
			'merger_name': '中国,江苏省,苏州市,相城区',
			'level': 3
		}, {
			'id': 865,
			'pid': 861,
			'name': '姑苏区',
			'merger_name': '中国,江苏省,苏州市,姑苏区',
			'level': 3
		}, {
			'id': 866,
			'pid': 861,
			'name': '吴江区',
			'merger_name': '中国,江苏省,苏州市,吴江区',
			'level': 3
		}, {
			'id': 867,
			'pid': 861,
			'name': '常熟市',
			'merger_name': '中国,江苏省,苏州市,常熟市',
			'level': 3
		}, {
			'id': 868,
			'pid': 861,
			'name': '张家港市',
			'merger_name': '中国,江苏省,苏州市,张家港市',
			'level': 3
		}, {
			'id': 869,
			'pid': 861,
			'name': '昆山市',
			'merger_name': '中国,江苏省,苏州市,昆山市',
			'level': 3
		}, {
			'id': 870,
			'pid': 861,
			'name': '太仓市',
			'merger_name': '中国,江苏省,苏州市,太仓市',
			'level': 3
		}]
	}, {
		'id': 871,
		'pid': 820,
		'name': '南通市',
		'merger_name': '中国,江苏省,南通市',
		'level': 2,
		'areas': [{
			'id': 872,
			'pid': 871,
			'name': '崇川区',
			'merger_name': '中国,江苏省,南通市,崇川区',
			'level': 3
		}, {
			'id': 873,
			'pid': 871,
			'name': '港闸区',
			'merger_name': '中国,江苏省,南通市,港闸区',
			'level': 3
		}, {
			'id': 874,
			'pid': 871,
			'name': '通州区',
			'merger_name': '中国,江苏省,南通市,通州区',
			'level': 3
		}, {
			'id': 875,
			'pid': 871,
			'name': '海安县',
			'merger_name': '中国,江苏省,南通市,海安县',
			'level': 3
		}, {
			'id': 876,
			'pid': 871,
			'name': '如东县',
			'merger_name': '中国,江苏省,南通市,如东县',
			'level': 3
		}, {
			'id': 877,
			'pid': 871,
			'name': '启东市',
			'merger_name': '中国,江苏省,南通市,启东市',
			'level': 3
		}, {
			'id': 878,
			'pid': 871,
			'name': '如皋市',
			'merger_name': '中国,江苏省,南通市,如皋市',
			'level': 3
		}, {
			'id': 879,
			'pid': 871,
			'name': '海门市',
			'merger_name': '中国,江苏省,南通市,海门市',
			'level': 3
		}]
	}, {
		'id': 880,
		'pid': 820,
		'name': '连云港市',
		'merger_name': '中国,江苏省,连云港市',
		'level': 2,
		'areas': [{
			'id': 881,
			'pid': 880,
			'name': '连云区',
			'merger_name': '中国,江苏省,连云港市,连云区',
			'level': 3
		}, {
			'id': 882,
			'pid': 880,
			'name': '海州区',
			'merger_name': '中国,江苏省,连云港市,海州区',
			'level': 3
		}, {
			'id': 883,
			'pid': 880,
			'name': '赣榆区',
			'merger_name': '中国,江苏省,连云港市,赣榆区',
			'level': 3
		}, {
			'id': 884,
			'pid': 880,
			'name': '东海县',
			'merger_name': '中国,江苏省,连云港市,东海县',
			'level': 3
		}, {
			'id': 885,
			'pid': 880,
			'name': '灌云县',
			'merger_name': '中国,江苏省,连云港市,灌云县',
			'level': 3
		}, {
			'id': 886,
			'pid': 880,
			'name': '灌南县',
			'merger_name': '中国,江苏省,连云港市,灌南县',
			'level': 3
		}]
	}, {
		'id': 887,
		'pid': 820,
		'name': '淮安市',
		'merger_name': '中国,江苏省,淮安市',
		'level': 2,
		'areas': [{
			'id': 888,
			'pid': 887,
			'name': '清河区',
			'merger_name': '中国,江苏省,淮安市,清河区',
			'level': 3
		}, {
			'id': 889,
			'pid': 887,
			'name': '淮安区',
			'merger_name': '中国,江苏省,淮安市,淮安区',
			'level': 3
		}, {
			'id': 890,
			'pid': 887,
			'name': '淮阴区',
			'merger_name': '中国,江苏省,淮安市,淮阴区',
			'level': 3
		}, {
			'id': 891,
			'pid': 887,
			'name': '清浦区',
			'merger_name': '中国,江苏省,淮安市,清浦区',
			'level': 3
		}, {
			'id': 892,
			'pid': 887,
			'name': '涟水县',
			'merger_name': '中国,江苏省,淮安市,涟水县',
			'level': 3
		}, {
			'id': 893,
			'pid': 887,
			'name': '洪泽县',
			'merger_name': '中国,江苏省,淮安市,洪泽县',
			'level': 3
		}, {
			'id': 894,
			'pid': 887,
			'name': '盱眙县',
			'merger_name': '中国,江苏省,淮安市,盱眙县',
			'level': 3
		}, {
			'id': 895,
			'pid': 887,
			'name': '金湖县',
			'merger_name': '中国,江苏省,淮安市,金湖县',
			'level': 3
		}]
	}, {
		'id': 896,
		'pid': 820,
		'name': '盐城市',
		'merger_name': '中国,江苏省,盐城市',
		'level': 2,
		'areas': [{
			'id': 897,
			'pid': 896,
			'name': '亭湖区',
			'merger_name': '中国,江苏省,盐城市,亭湖区',
			'level': 3
		}, {
			'id': 898,
			'pid': 896,
			'name': '盐都区',
			'merger_name': '中国,江苏省,盐城市,盐都区',
			'level': 3
		}, {
			'id': 899,
			'pid': 896,
			'name': '响水县',
			'merger_name': '中国,江苏省,盐城市,响水县',
			'level': 3
		}, {
			'id': 900,
			'pid': 896,
			'name': '滨海县',
			'merger_name': '中国,江苏省,盐城市,滨海县',
			'level': 3
		}, {
			'id': 901,
			'pid': 896,
			'name': '阜宁县',
			'merger_name': '中国,江苏省,盐城市,阜宁县',
			'level': 3
		}, {
			'id': 902,
			'pid': 896,
			'name': '射阳县',
			'merger_name': '中国,江苏省,盐城市,射阳县',
			'level': 3
		}, {
			'id': 903,
			'pid': 896,
			'name': '建湖县',
			'merger_name': '中国,江苏省,盐城市,建湖县',
			'level': 3
		}, {
			'id': 904,
			'pid': 896,
			'name': '东台市',
			'merger_name': '中国,江苏省,盐城市,东台市',
			'level': 3
		}, {
			'id': 905,
			'pid': 896,
			'name': '大丰市',
			'merger_name': '中国,江苏省,盐城市,大丰市',
			'level': 3
		}]
	}, {
		'id': 906,
		'pid': 820,
		'name': '扬州市',
		'merger_name': '中国,江苏省,扬州市',
		'level': 2,
		'areas': [{
			'id': 907,
			'pid': 906,
			'name': '广陵区',
			'merger_name': '中国,江苏省,扬州市,广陵区',
			'level': 3
		}, {
			'id': 908,
			'pid': 906,
			'name': '邗江区',
			'merger_name': '中国,江苏省,扬州市,邗江区',
			'level': 3
		}, {
			'id': 909,
			'pid': 906,
			'name': '江都区',
			'merger_name': '中国,江苏省,扬州市,江都区',
			'level': 3
		}, {
			'id': 910,
			'pid': 906,
			'name': '宝应县',
			'merger_name': '中国,江苏省,扬州市,宝应县',
			'level': 3
		}, {
			'id': 911,
			'pid': 906,
			'name': '仪征市',
			'merger_name': '中国,江苏省,扬州市,仪征市',
			'level': 3
		}, {
			'id': 912,
			'pid': 906,
			'name': '高邮市',
			'merger_name': '中国,江苏省,扬州市,高邮市',
			'level': 3
		}]
	}, {
		'id': 913,
		'pid': 820,
		'name': '镇江市',
		'merger_name': '中国,江苏省,镇江市',
		'level': 2,
		'areas': [{
			'id': 914,
			'pid': 913,
			'name': '京口区',
			'merger_name': '中国,江苏省,镇江市,京口区',
			'level': 3
		}, {
			'id': 915,
			'pid': 913,
			'name': '润州区',
			'merger_name': '中国,江苏省,镇江市,润州区',
			'level': 3
		}, {
			'id': 916,
			'pid': 913,
			'name': '丹徒区',
			'merger_name': '中国,江苏省,镇江市,丹徒区',
			'level': 3
		}, {
			'id': 917,
			'pid': 913,
			'name': '丹阳市',
			'merger_name': '中国,江苏省,镇江市,丹阳市',
			'level': 3
		}, {
			'id': 918,
			'pid': 913,
			'name': '扬中市',
			'merger_name': '中国,江苏省,镇江市,扬中市',
			'level': 3
		}, {
			'id': 919,
			'pid': 913,
			'name': '句容市',
			'merger_name': '中国,江苏省,镇江市,句容市',
			'level': 3
		}]
	}, {
		'id': 920,
		'pid': 820,
		'name': '泰州市',
		'merger_name': '中国,江苏省,泰州市',
		'level': 2,
		'areas': [{
			'id': 921,
			'pid': 920,
			'name': '海陵区',
			'merger_name': '中国,江苏省,泰州市,海陵区',
			'level': 3
		}, {
			'id': 922,
			'pid': 920,
			'name': '高港区',
			'merger_name': '中国,江苏省,泰州市,高港区',
			'level': 3
		}, {
			'id': 923,
			'pid': 920,
			'name': '姜堰区',
			'merger_name': '中国,江苏省,泰州市,姜堰区',
			'level': 3
		}, {
			'id': 924,
			'pid': 920,
			'name': '兴化市',
			'merger_name': '中国,江苏省,泰州市,兴化市',
			'level': 3
		}, {
			'id': 925,
			'pid': 920,
			'name': '靖江市',
			'merger_name': '中国,江苏省,泰州市,靖江市',
			'level': 3
		}, {
			'id': 926,
			'pid': 920,
			'name': '泰兴市',
			'merger_name': '中国,江苏省,泰州市,泰兴市',
			'level': 3
		}]
	}, {
		'id': 927,
		'pid': 820,
		'name': '宿迁市',
		'merger_name': '中国,江苏省,宿迁市',
		'level': 2,
		'areas': [{
			'id': 928,
			'pid': 927,
			'name': '宿城区',
			'merger_name': '中国,江苏省,宿迁市,宿城区',
			'level': 3
		}, {
			'id': 929,
			'pid': 927,
			'name': '宿豫区',
			'merger_name': '中国,江苏省,宿迁市,宿豫区',
			'level': 3
		}, {
			'id': 930,
			'pid': 927,
			'name': '沭阳县',
			'merger_name': '中国,江苏省,宿迁市,沭阳县',
			'level': 3
		}, {
			'id': 931,
			'pid': 927,
			'name': '泗阳县',
			'merger_name': '中国,江苏省,宿迁市,泗阳县',
			'level': 3
		}, {
			'id': 932,
			'pid': 927,
			'name': '泗洪县',
			'merger_name': '中国,江苏省,宿迁市,泗洪县',
			'level': 3
		}]
	}]
}, {
	'id': 933,
	'pid': 0,
	'name': '浙江省',
	'merger_name': '中国,浙江省',
	'level': 1,
	'cities': [{
		'id': 934,
		'pid': 933,
		'name': '杭州市',
		'merger_name': '中国,浙江省,杭州市',
		'level': 2,
		'areas': [{
			'id': 935,
			'pid': 934,
			'name': '上城区',
			'merger_name': '中国,浙江省,杭州市,上城区',
			'level': 3
		}, {
			'id': 936,
			'pid': 934,
			'name': '下城区',
			'merger_name': '中国,浙江省,杭州市,下城区',
			'level': 3
		}, {
			'id': 937,
			'pid': 934,
			'name': '江干区',
			'merger_name': '中国,浙江省,杭州市,江干区',
			'level': 3
		}, {
			'id': 938,
			'pid': 934,
			'name': '拱墅区',
			'merger_name': '中国,浙江省,杭州市,拱墅区',
			'level': 3
		}, {
			'id': 939,
			'pid': 934,
			'name': '西湖区',
			'merger_name': '中国,浙江省,杭州市,西湖区',
			'level': 3
		}, {
			'id': 940,
			'pid': 934,
			'name': '滨江区',
			'merger_name': '中国,浙江省,杭州市,滨江区',
			'level': 3
		}, {
			'id': 941,
			'pid': 934,
			'name': '萧山区',
			'merger_name': '中国,浙江省,杭州市,萧山区',
			'level': 3
		}, {
			'id': 942,
			'pid': 934,
			'name': '余杭区',
			'merger_name': '中国,浙江省,杭州市,余杭区',
			'level': 3
		}, {
			'id': 943,
			'pid': 934,
			'name': '桐庐县',
			'merger_name': '中国,浙江省,杭州市,桐庐县',
			'level': 3
		}, {
			'id': 944,
			'pid': 934,
			'name': '淳安县',
			'merger_name': '中国,浙江省,杭州市,淳安县',
			'level': 3
		}, {
			'id': 945,
			'pid': 934,
			'name': '建德市',
			'merger_name': '中国,浙江省,杭州市,建德市',
			'level': 3
		}, {
			'id': 946,
			'pid': 934,
			'name': '富阳区',
			'merger_name': '中国,浙江省,杭州市,富阳区',
			'level': 3
		}, {
			'id': 947,
			'pid': 934,
			'name': '临安市',
			'merger_name': '中国,浙江省,杭州市,临安市',
			'level': 3
		}]
	}, {
		'id': 948,
		'pid': 933,
		'name': '宁波市',
		'merger_name': '中国,浙江省,宁波市',
		'level': 2,
		'areas': [{
			'id': 949,
			'pid': 948,
			'name': '海曙区',
			'merger_name': '中国,浙江省,宁波市,海曙区',
			'level': 3
		}, {
			'id': 950,
			'pid': 948,
			'name': '江东区',
			'merger_name': '中国,浙江省,宁波市,江东区',
			'level': 3
		}, {
			'id': 951,
			'pid': 948,
			'name': '江北区',
			'merger_name': '中国,浙江省,宁波市,江北区',
			'level': 3
		}, {
			'id': 952,
			'pid': 948,
			'name': '北仑区',
			'merger_name': '中国,浙江省,宁波市,北仑区',
			'level': 3
		}, {
			'id': 953,
			'pid': 948,
			'name': '镇海区',
			'merger_name': '中国,浙江省,宁波市,镇海区',
			'level': 3
		}, {
			'id': 954,
			'pid': 948,
			'name': '鄞州区',
			'merger_name': '中国,浙江省,宁波市,鄞州区',
			'level': 3
		}, {
			'id': 955,
			'pid': 948,
			'name': '象山县',
			'merger_name': '中国,浙江省,宁波市,象山县',
			'level': 3
		}, {
			'id': 956,
			'pid': 948,
			'name': '宁海县',
			'merger_name': '中国,浙江省,宁波市,宁海县',
			'level': 3
		}, {
			'id': 957,
			'pid': 948,
			'name': '余姚市',
			'merger_name': '中国,浙江省,宁波市,余姚市',
			'level': 3
		}, {
			'id': 958,
			'pid': 948,
			'name': '慈溪市',
			'merger_name': '中国,浙江省,宁波市,慈溪市',
			'level': 3
		}, {
			'id': 959,
			'pid': 948,
			'name': '奉化市',
			'merger_name': '中国,浙江省,宁波市,奉化市',
			'level': 3
		}]
	}, {
		'id': 960,
		'pid': 933,
		'name': '温州市',
		'merger_name': '中国,浙江省,温州市',
		'level': 2,
		'areas': [{
			'id': 961,
			'pid': 960,
			'name': '鹿城区',
			'merger_name': '中国,浙江省,温州市,鹿城区',
			'level': 3
		}, {
			'id': 962,
			'pid': 960,
			'name': '龙湾区',
			'merger_name': '中国,浙江省,温州市,龙湾区',
			'level': 3
		}, {
			'id': 963,
			'pid': 960,
			'name': '瓯海区',
			'merger_name': '中国,浙江省,温州市,瓯海区',
			'level': 3
		}, {
			'id': 964,
			'pid': 960,
			'name': '洞头县',
			'merger_name': '中国,浙江省,温州市,洞头县',
			'level': 3
		}, {
			'id': 965,
			'pid': 960,
			'name': '永嘉县',
			'merger_name': '中国,浙江省,温州市,永嘉县',
			'level': 3
		}, {
			'id': 966,
			'pid': 960,
			'name': '平阳县',
			'merger_name': '中国,浙江省,温州市,平阳县',
			'level': 3
		}, {
			'id': 967,
			'pid': 960,
			'name': '苍南县',
			'merger_name': '中国,浙江省,温州市,苍南县',
			'level': 3
		}, {
			'id': 968,
			'pid': 960,
			'name': '文成县',
			'merger_name': '中国,浙江省,温州市,文成县',
			'level': 3
		}, {
			'id': 969,
			'pid': 960,
			'name': '泰顺县',
			'merger_name': '中国,浙江省,温州市,泰顺县',
			'level': 3
		}, {
			'id': 970,
			'pid': 960,
			'name': '瑞安市',
			'merger_name': '中国,浙江省,温州市,瑞安市',
			'level': 3
		}, {
			'id': 971,
			'pid': 960,
			'name': '乐清市',
			'merger_name': '中国,浙江省,温州市,乐清市',
			'level': 3
		}]
	}, {
		'id': 972,
		'pid': 933,
		'name': '嘉兴市',
		'merger_name': '中国,浙江省,嘉兴市',
		'level': 2,
		'areas': [{
			'id': 973,
			'pid': 972,
			'name': '南湖区',
			'merger_name': '中国,浙江省,嘉兴市,南湖区',
			'level': 3
		}, {
			'id': 974,
			'pid': 972,
			'name': '秀洲区',
			'merger_name': '中国,浙江省,嘉兴市,秀洲区',
			'level': 3
		}, {
			'id': 975,
			'pid': 972,
			'name': '嘉善县',
			'merger_name': '中国,浙江省,嘉兴市,嘉善县',
			'level': 3
		}, {
			'id': 976,
			'pid': 972,
			'name': '海盐县',
			'merger_name': '中国,浙江省,嘉兴市,海盐县',
			'level': 3
		}, {
			'id': 977,
			'pid': 972,
			'name': '海宁市',
			'merger_name': '中国,浙江省,嘉兴市,海宁市',
			'level': 3
		}, {
			'id': 978,
			'pid': 972,
			'name': '平湖市',
			'merger_name': '中国,浙江省,嘉兴市,平湖市',
			'level': 3
		}, {
			'id': 979,
			'pid': 972,
			'name': '桐乡市',
			'merger_name': '中国,浙江省,嘉兴市,桐乡市',
			'level': 3
		}]
	}, {
		'id': 980,
		'pid': 933,
		'name': '湖州市',
		'merger_name': '中国,浙江省,湖州市',
		'level': 2,
		'areas': [{
			'id': 981,
			'pid': 980,
			'name': '吴兴区',
			'merger_name': '中国,浙江省,湖州市,吴兴区',
			'level': 3
		}, {
			'id': 982,
			'pid': 980,
			'name': '南浔区',
			'merger_name': '中国,浙江省,湖州市,南浔区',
			'level': 3
		}, {
			'id': 983,
			'pid': 980,
			'name': '德清县',
			'merger_name': '中国,浙江省,湖州市,德清县',
			'level': 3
		}, {
			'id': 984,
			'pid': 980,
			'name': '长兴县',
			'merger_name': '中国,浙江省,湖州市,长兴县',
			'level': 3
		}, {
			'id': 985,
			'pid': 980,
			'name': '安吉县',
			'merger_name': '中国,浙江省,湖州市,安吉县',
			'level': 3
		}]
	}, {
		'id': 986,
		'pid': 933,
		'name': '绍兴市',
		'merger_name': '中国,浙江省,绍兴市',
		'level': 2,
		'areas': [{
			'id': 987,
			'pid': 986,
			'name': '越城区',
			'merger_name': '中国,浙江省,绍兴市,越城区',
			'level': 3
		}, {
			'id': 988,
			'pid': 986,
			'name': '柯桥区',
			'merger_name': '中国,浙江省,绍兴市,柯桥区',
			'level': 3
		}, {
			'id': 989,
			'pid': 986,
			'name': '上虞区',
			'merger_name': '中国,浙江省,绍兴市,上虞区',
			'level': 3
		}, {
			'id': 990,
			'pid': 986,
			'name': '新昌县',
			'merger_name': '中国,浙江省,绍兴市,新昌县',
			'level': 3
		}, {
			'id': 991,
			'pid': 986,
			'name': '诸暨市',
			'merger_name': '中国,浙江省,绍兴市,诸暨市',
			'level': 3
		}, {
			'id': 992,
			'pid': 986,
			'name': '嵊州市',
			'merger_name': '中国,浙江省,绍兴市,嵊州市',
			'level': 3
		}]
	}, {
		'id': 993,
		'pid': 933,
		'name': '金华市',
		'merger_name': '中国,浙江省,金华市',
		'level': 2,
		'areas': [{
			'id': 994,
			'pid': 993,
			'name': '婺城区',
			'merger_name': '中国,浙江省,金华市,婺城区',
			'level': 3
		}, {
			'id': 995,
			'pid': 993,
			'name': '金东区',
			'merger_name': '中国,浙江省,金华市,金东区',
			'level': 3
		}, {
			'id': 996,
			'pid': 993,
			'name': '武义县',
			'merger_name': '中国,浙江省,金华市,武义县',
			'level': 3
		}, {
			'id': 997,
			'pid': 993,
			'name': '浦江县',
			'merger_name': '中国,浙江省,金华市,浦江县',
			'level': 3
		}, {
			'id': 998,
			'pid': 993,
			'name': '磐安县',
			'merger_name': '中国,浙江省,金华市,磐安县',
			'level': 3
		}, {
			'id': 999,
			'pid': 993,
			'name': '兰溪市',
			'merger_name': '中国,浙江省,金华市,兰溪市',
			'level': 3
		}, {
			'id': 1000,
			'pid': 993,
			'name': '义乌市',
			'merger_name': '中国,浙江省,金华市,义乌市',
			'level': 3
		}, {
			'id': 1001,
			'pid': 993,
			'name': '东阳市',
			'merger_name': '中国,浙江省,金华市,东阳市',
			'level': 3
		}, {
			'id': 1002,
			'pid': 993,
			'name': '永康市',
			'merger_name': '中国,浙江省,金华市,永康市',
			'level': 3
		}]
	}, {
		'id': 1003,
		'pid': 933,
		'name': '衢州市',
		'merger_name': '中国,浙江省,衢州市',
		'level': 2,
		'areas': [{
			'id': 1004,
			'pid': 1003,
			'name': '柯城区',
			'merger_name': '中国,浙江省,衢州市,柯城区',
			'level': 3
		}, {
			'id': 1005,
			'pid': 1003,
			'name': '衢江区',
			'merger_name': '中国,浙江省,衢州市,衢江区',
			'level': 3
		}, {
			'id': 1006,
			'pid': 1003,
			'name': '常山县',
			'merger_name': '中国,浙江省,衢州市,常山县',
			'level': 3
		}, {
			'id': 1007,
			'pid': 1003,
			'name': '开化县',
			'merger_name': '中国,浙江省,衢州市,开化县',
			'level': 3
		}, {
			'id': 1008,
			'pid': 1003,
			'name': '龙游县',
			'merger_name': '中国,浙江省,衢州市,龙游县',
			'level': 3
		}, {
			'id': 1009,
			'pid': 1003,
			'name': '江山市',
			'merger_name': '中国,浙江省,衢州市,江山市',
			'level': 3
		}]
	}, {
		'id': 1010,
		'pid': 933,
		'name': '舟山市',
		'merger_name': '中国,浙江省,舟山市',
		'level': 2,
		'areas': [{
			'id': 1011,
			'pid': 1010,
			'name': '定海区',
			'merger_name': '中国,浙江省,舟山市,定海区',
			'level': 3
		}, {
			'id': 1012,
			'pid': 1010,
			'name': '普陀区',
			'merger_name': '中国,浙江省,舟山市,普陀区',
			'level': 3
		}, {
			'id': 1013,
			'pid': 1010,
			'name': '岱山县',
			'merger_name': '中国,浙江省,舟山市,岱山县',
			'level': 3
		}, {
			'id': 1014,
			'pid': 1010,
			'name': '嵊泗县',
			'merger_name': '中国,浙江省,舟山市,嵊泗县',
			'level': 3
		}]
	}, {
		'id': 1015,
		'pid': 933,
		'name': '台州市',
		'merger_name': '中国,浙江省,台州市',
		'level': 2,
		'areas': [{
			'id': 1016,
			'pid': 1015,
			'name': '椒江区',
			'merger_name': '中国,浙江省,台州市,椒江区',
			'level': 3
		}, {
			'id': 1017,
			'pid': 1015,
			'name': '黄岩区',
			'merger_name': '中国,浙江省,台州市,黄岩区',
			'level': 3
		}, {
			'id': 1018,
			'pid': 1015,
			'name': '路桥区',
			'merger_name': '中国,浙江省,台州市,路桥区',
			'level': 3
		}, {
			'id': 1019,
			'pid': 1015,
			'name': '玉环县',
			'merger_name': '中国,浙江省,台州市,玉环县',
			'level': 3
		}, {
			'id': 1020,
			'pid': 1015,
			'name': '三门县',
			'merger_name': '中国,浙江省,台州市,三门县',
			'level': 3
		}, {
			'id': 1021,
			'pid': 1015,
			'name': '天台县',
			'merger_name': '中国,浙江省,台州市,天台县',
			'level': 3
		}, {
			'id': 1022,
			'pid': 1015,
			'name': '仙居县',
			'merger_name': '中国,浙江省,台州市,仙居县',
			'level': 3
		}, {
			'id': 1023,
			'pid': 1015,
			'name': '温岭市',
			'merger_name': '中国,浙江省,台州市,温岭市',
			'level': 3
		}, {
			'id': 1024,
			'pid': 1015,
			'name': '临海市',
			'merger_name': '中国,浙江省,台州市,临海市',
			'level': 3
		}]
	}, {
		'id': 1025,
		'pid': 933,
		'name': '丽水市',
		'merger_name': '中国,浙江省,丽水市',
		'level': 2,
		'areas': [{
			'id': 1026,
			'pid': 1025,
			'name': '莲都区',
			'merger_name': '中国,浙江省,丽水市,莲都区',
			'level': 3
		}, {
			'id': 1027,
			'pid': 1025,
			'name': '青田县',
			'merger_name': '中国,浙江省,丽水市,青田县',
			'level': 3
		}, {
			'id': 1028,
			'pid': 1025,
			'name': '缙云县',
			'merger_name': '中国,浙江省,丽水市,缙云县',
			'level': 3
		}, {
			'id': 1029,
			'pid': 1025,
			'name': '遂昌县',
			'merger_name': '中国,浙江省,丽水市,遂昌县',
			'level': 3
		}, {
			'id': 1030,
			'pid': 1025,
			'name': '松阳县',
			'merger_name': '中国,浙江省,丽水市,松阳县',
			'level': 3
		}, {
			'id': 1031,
			'pid': 1025,
			'name': '云和县',
			'merger_name': '中国,浙江省,丽水市,云和县',
			'level': 3
		}, {
			'id': 1032,
			'pid': 1025,
			'name': '庆元县',
			'merger_name': '中国,浙江省,丽水市,庆元县',
			'level': 3
		}, {
			'id': 1033,
			'pid': 1025,
			'name': '景宁畲族自治县',
			'merger_name': '中国,浙江省,丽水市,景宁畲族自治县',
			'level': 3
		}, {
			'id': 1034,
			'pid': 1025,
			'name': '龙泉市',
			'merger_name': '中国,浙江省,丽水市,龙泉市',
			'level': 3
		}]
	}, {
		'id': 1035,
		'pid': 933,
		'name': '舟山群岛新区',
		'merger_name': '中国,浙江省,舟山群岛新区',
		'level': 2,
		'areas': [{
			'id': 1036,
			'pid': 1035,
			'name': '金塘岛',
			'merger_name': '中国,浙江省,舟山群岛新区,金塘岛',
			'level': 3
		}, {
			'id': 1037,
			'pid': 1035,
			'name': '六横岛',
			'merger_name': '中国,浙江省,舟山群岛新区,六横岛',
			'level': 3
		}, {
			'id': 1038,
			'pid': 1035,
			'name': '衢山岛',
			'merger_name': '中国,浙江省,舟山群岛新区,衢山岛',
			'level': 3
		}, {
			'id': 1039,
			'pid': 1035,
			'name': '舟山本岛西北部',
			'merger_name': '中国,浙江省,舟山群岛新区,舟山本岛西北部',
			'level': 3
		}, {
			'id': 1040,
			'pid': 1035,
			'name': '岱山岛西南部',
			'merger_name': '中国,浙江省,舟山群岛新区,岱山岛西南部',
			'level': 3
		}, {
			'id': 1041,
			'pid': 1035,
			'name': '泗礁岛',
			'merger_name': '中国,浙江省,舟山群岛新区,泗礁岛',
			'level': 3
		}, {
			'id': 1042,
			'pid': 1035,
			'name': '朱家尖岛',
			'merger_name': '中国,浙江省,舟山群岛新区,朱家尖岛',
			'level': 3
		}, {
			'id': 1043,
			'pid': 1035,
			'name': '洋山岛',
			'merger_name': '中国,浙江省,舟山群岛新区,洋山岛',
			'level': 3
		}, {
			'id': 1044,
			'pid': 1035,
			'name': '长涂岛',
			'merger_name': '中国,浙江省,舟山群岛新区,长涂岛',
			'level': 3
		}, {
			'id': 1045,
			'pid': 1035,
			'name': '虾峙岛',
			'merger_name': '中国,浙江省,舟山群岛新区,虾峙岛',
			'level': 3
		}]
	}]
}, {
	'id': 1046,
	'pid': 0,
	'name': '安徽省',
	'merger_name': '中国,安徽省',
	'level': 1,
	'cities': [{
		'id': 1047,
		'pid': 1046,
		'name': '合肥市',
		'merger_name': '中国,安徽省,合肥市',
		'level': 2,
		'areas': [{
			'id': 1048,
			'pid': 1047,
			'name': '瑶海区',
			'merger_name': '中国,安徽省,合肥市,瑶海区',
			'level': 3
		}, {
			'id': 1049,
			'pid': 1047,
			'name': '庐阳区',
			'merger_name': '中国,安徽省,合肥市,庐阳区',
			'level': 3
		}, {
			'id': 1050,
			'pid': 1047,
			'name': '蜀山区',
			'merger_name': '中国,安徽省,合肥市,蜀山区',
			'level': 3
		}, {
			'id': 1051,
			'pid': 1047,
			'name': '包河区',
			'merger_name': '中国,安徽省,合肥市,包河区',
			'level': 3
		}, {
			'id': 1052,
			'pid': 1047,
			'name': '长丰县',
			'merger_name': '中国,安徽省,合肥市,长丰县',
			'level': 3
		}, {
			'id': 1053,
			'pid': 1047,
			'name': '肥东县',
			'merger_name': '中国,安徽省,合肥市,肥东县',
			'level': 3
		}, {
			'id': 1054,
			'pid': 1047,
			'name': '肥西县',
			'merger_name': '中国,安徽省,合肥市,肥西县',
			'level': 3
		}, {
			'id': 1055,
			'pid': 1047,
			'name': '庐江县',
			'merger_name': '中国,安徽省,合肥市,庐江县',
			'level': 3
		}, {
			'id': 1056,
			'pid': 1047,
			'name': '巢湖市',
			'merger_name': '中国,安徽省,合肥市,巢湖市',
			'level': 3
		}]
	}, {
		'id': 1057,
		'pid': 1046,
		'name': '芜湖市',
		'merger_name': '中国,安徽省,芜湖市',
		'level': 2,
		'areas': [{
			'id': 1058,
			'pid': 1057,
			'name': '镜湖区',
			'merger_name': '中国,安徽省,芜湖市,镜湖区',
			'level': 3
		}, {
			'id': 1059,
			'pid': 1057,
			'name': '弋江区',
			'merger_name': '中国,安徽省,芜湖市,弋江区',
			'level': 3
		}, {
			'id': 1060,
			'pid': 1057,
			'name': '鸠江区',
			'merger_name': '中国,安徽省,芜湖市,鸠江区',
			'level': 3
		}, {
			'id': 1061,
			'pid': 1057,
			'name': '三山区',
			'merger_name': '中国,安徽省,芜湖市,三山区',
			'level': 3
		}, {
			'id': 1062,
			'pid': 1057,
			'name': '芜湖县',
			'merger_name': '中国,安徽省,芜湖市,芜湖县',
			'level': 3
		}, {
			'id': 1063,
			'pid': 1057,
			'name': '繁昌县',
			'merger_name': '中国,安徽省,芜湖市,繁昌县',
			'level': 3
		}, {
			'id': 1064,
			'pid': 1057,
			'name': '南陵县',
			'merger_name': '中国,安徽省,芜湖市,南陵县',
			'level': 3
		}, {
			'id': 1065,
			'pid': 1057,
			'name': '无为县',
			'merger_name': '中国,安徽省,芜湖市,无为县',
			'level': 3
		}]
	}, {
		'id': 1066,
		'pid': 1046,
		'name': '蚌埠市',
		'merger_name': '中国,安徽省,蚌埠市',
		'level': 2,
		'areas': [{
			'id': 1067,
			'pid': 1066,
			'name': '龙子湖区',
			'merger_name': '中国,安徽省,蚌埠市,龙子湖区',
			'level': 3
		}, {
			'id': 1068,
			'pid': 1066,
			'name': '蚌山区',
			'merger_name': '中国,安徽省,蚌埠市,蚌山区',
			'level': 3
		}, {
			'id': 1069,
			'pid': 1066,
			'name': '禹会区',
			'merger_name': '中国,安徽省,蚌埠市,禹会区',
			'level': 3
		}, {
			'id': 1070,
			'pid': 1066,
			'name': '淮上区',
			'merger_name': '中国,安徽省,蚌埠市,淮上区',
			'level': 3
		}, {
			'id': 1071,
			'pid': 1066,
			'name': '怀远县',
			'merger_name': '中国,安徽省,蚌埠市,怀远县',
			'level': 3
		}, {
			'id': 1072,
			'pid': 1066,
			'name': '五河县',
			'merger_name': '中国,安徽省,蚌埠市,五河县',
			'level': 3
		}, {
			'id': 1073,
			'pid': 1066,
			'name': '固镇县',
			'merger_name': '中国,安徽省,蚌埠市,固镇县',
			'level': 3
		}]
	}, {
		'id': 1074,
		'pid': 1046,
		'name': '淮南市',
		'merger_name': '中国,安徽省,淮南市',
		'level': 2,
		'areas': [{
			'id': 1075,
			'pid': 1074,
			'name': '大通区',
			'merger_name': '中国,安徽省,淮南市,大通区',
			'level': 3
		}, {
			'id': 1076,
			'pid': 1074,
			'name': '田家庵区',
			'merger_name': '中国,安徽省,淮南市,田家庵区',
			'level': 3
		}, {
			'id': 1077,
			'pid': 1074,
			'name': '谢家集区',
			'merger_name': '中国,安徽省,淮南市,谢家集区',
			'level': 3
		}, {
			'id': 1078,
			'pid': 1074,
			'name': '八公山区',
			'merger_name': '中国,安徽省,淮南市,八公山区',
			'level': 3
		}, {
			'id': 1079,
			'pid': 1074,
			'name': '潘集区',
			'merger_name': '中国,安徽省,淮南市,潘集区',
			'level': 3
		}, {
			'id': 1080,
			'pid': 1074,
			'name': '凤台县',
			'merger_name': '中国,安徽省,淮南市,凤台县',
			'level': 3
		}]
	}, {
		'id': 1081,
		'pid': 1046,
		'name': '马鞍山市',
		'merger_name': '中国,安徽省,马鞍山市',
		'level': 2,
		'areas': [{
			'id': 1082,
			'pid': 1081,
			'name': '花山区',
			'merger_name': '中国,安徽省,马鞍山市,花山区',
			'level': 3
		}, {
			'id': 1083,
			'pid': 1081,
			'name': '雨山区',
			'merger_name': '中国,安徽省,马鞍山市,雨山区',
			'level': 3
		}, {
			'id': 1084,
			'pid': 1081,
			'name': '博望区',
			'merger_name': '中国,安徽省,马鞍山市,博望区',
			'level': 3
		}, {
			'id': 1085,
			'pid': 1081,
			'name': '当涂县',
			'merger_name': '中国,安徽省,马鞍山市,当涂县',
			'level': 3
		}, {
			'id': 1086,
			'pid': 1081,
			'name': '含山县',
			'merger_name': '中国,安徽省,马鞍山市,含山县',
			'level': 3
		}, {
			'id': 1087,
			'pid': 1081,
			'name': '和县',
			'merger_name': '中国,安徽省,马鞍山市,和县',
			'level': 3
		}]
	}, {
		'id': 1088,
		'pid': 1046,
		'name': '淮北市',
		'merger_name': '中国,安徽省,淮北市',
		'level': 2,
		'areas': [{
			'id': 1089,
			'pid': 1088,
			'name': '杜集区',
			'merger_name': '中国,安徽省,淮北市,杜集区',
			'level': 3
		}, {
			'id': 1090,
			'pid': 1088,
			'name': '相山区',
			'merger_name': '中国,安徽省,淮北市,相山区',
			'level': 3
		}, {
			'id': 1091,
			'pid': 1088,
			'name': '烈山区',
			'merger_name': '中国,安徽省,淮北市,烈山区',
			'level': 3
		}, {
			'id': 1092,
			'pid': 1088,
			'name': '濉溪县',
			'merger_name': '中国,安徽省,淮北市,濉溪县',
			'level': 3
		}]
	}, {
		'id': 1093,
		'pid': 1046,
		'name': '铜陵市',
		'merger_name': '中国,安徽省,铜陵市',
		'level': 2,
		'areas': [{
			'id': 1094,
			'pid': 1093,
			'name': '铜官山区',
			'merger_name': '中国,安徽省,铜陵市,铜官山区',
			'level': 3
		}, {
			'id': 1095,
			'pid': 1093,
			'name': '狮子山区',
			'merger_name': '中国,安徽省,铜陵市,狮子山区',
			'level': 3
		}, {
			'id': 1096,
			'pid': 1093,
			'name': '郊区',
			'merger_name': '中国,安徽省,铜陵市,郊区',
			'level': 3
		}, {
			'id': 1097,
			'pid': 1093,
			'name': '铜陵县',
			'merger_name': '中国,安徽省,铜陵市,铜陵县',
			'level': 3
		}]
	}, {
		'id': 1098,
		'pid': 1046,
		'name': '安庆市',
		'merger_name': '中国,安徽省,安庆市',
		'level': 2,
		'areas': [{
			'id': 1099,
			'pid': 1098,
			'name': '迎江区',
			'merger_name': '中国,安徽省,安庆市,迎江区',
			'level': 3
		}, {
			'id': 1100,
			'pid': 1098,
			'name': '大观区',
			'merger_name': '中国,安徽省,安庆市,大观区',
			'level': 3
		}, {
			'id': 1101,
			'pid': 1098,
			'name': '宜秀区',
			'merger_name': '中国,安徽省,安庆市,宜秀区',
			'level': 3
		}, {
			'id': 1102,
			'pid': 1098,
			'name': '怀宁县',
			'merger_name': '中国,安徽省,安庆市,怀宁县',
			'level': 3
		}, {
			'id': 1103,
			'pid': 1098,
			'name': '枞阳县',
			'merger_name': '中国,安徽省,安庆市,枞阳县',
			'level': 3
		}, {
			'id': 1104,
			'pid': 1098,
			'name': '潜山县',
			'merger_name': '中国,安徽省,安庆市,潜山县',
			'level': 3
		}, {
			'id': 1105,
			'pid': 1098,
			'name': '太湖县',
			'merger_name': '中国,安徽省,安庆市,太湖县',
			'level': 3
		}, {
			'id': 1106,
			'pid': 1098,
			'name': '宿松县',
			'merger_name': '中国,安徽省,安庆市,宿松县',
			'level': 3
		}, {
			'id': 1107,
			'pid': 1098,
			'name': '望江县',
			'merger_name': '中国,安徽省,安庆市,望江县',
			'level': 3
		}, {
			'id': 1108,
			'pid': 1098,
			'name': '岳西县',
			'merger_name': '中国,安徽省,安庆市,岳西县',
			'level': 3
		}, {
			'id': 1109,
			'pid': 1098,
			'name': '桐城市',
			'merger_name': '中国,安徽省,安庆市,桐城市',
			'level': 3
		}]
	}, {
		'id': 1110,
		'pid': 1046,
		'name': '黄山市',
		'merger_name': '中国,安徽省,黄山市',
		'level': 2,
		'areas': [{
			'id': 1111,
			'pid': 1110,
			'name': '屯溪区',
			'merger_name': '中国,安徽省,黄山市,屯溪区',
			'level': 3
		}, {
			'id': 1112,
			'pid': 1110,
			'name': '黄山区',
			'merger_name': '中国,安徽省,黄山市,黄山区',
			'level': 3
		}, {
			'id': 1113,
			'pid': 1110,
			'name': '徽州区',
			'merger_name': '中国,安徽省,黄山市,徽州区',
			'level': 3
		}, {
			'id': 1114,
			'pid': 1110,
			'name': '歙县',
			'merger_name': '中国,安徽省,黄山市,歙县',
			'level': 3
		}, {
			'id': 1115,
			'pid': 1110,
			'name': '休宁县',
			'merger_name': '中国,安徽省,黄山市,休宁县',
			'level': 3
		}, {
			'id': 1116,
			'pid': 1110,
			'name': '黟县',
			'merger_name': '中国,安徽省,黄山市,黟县',
			'level': 3
		}, {
			'id': 1117,
			'pid': 1110,
			'name': '祁门县',
			'merger_name': '中国,安徽省,黄山市,祁门县',
			'level': 3
		}]
	}, {
		'id': 1118,
		'pid': 1046,
		'name': '滁州市',
		'merger_name': '中国,安徽省,滁州市',
		'level': 2,
		'areas': [{
			'id': 1119,
			'pid': 1118,
			'name': '琅琊区',
			'merger_name': '中国,安徽省,滁州市,琅琊区',
			'level': 3
		}, {
			'id': 1120,
			'pid': 1118,
			'name': '南谯区',
			'merger_name': '中国,安徽省,滁州市,南谯区',
			'level': 3
		}, {
			'id': 1121,
			'pid': 1118,
			'name': '来安县',
			'merger_name': '中国,安徽省,滁州市,来安县',
			'level': 3
		}, {
			'id': 1122,
			'pid': 1118,
			'name': '全椒县',
			'merger_name': '中国,安徽省,滁州市,全椒县',
			'level': 3
		}, {
			'id': 1123,
			'pid': 1118,
			'name': '定远县',
			'merger_name': '中国,安徽省,滁州市,定远县',
			'level': 3
		}, {
			'id': 1124,
			'pid': 1118,
			'name': '凤阳县',
			'merger_name': '中国,安徽省,滁州市,凤阳县',
			'level': 3
		}, {
			'id': 1125,
			'pid': 1118,
			'name': '天长市',
			'merger_name': '中国,安徽省,滁州市,天长市',
			'level': 3
		}, {
			'id': 1126,
			'pid': 1118,
			'name': '明光市',
			'merger_name': '中国,安徽省,滁州市,明光市',
			'level': 3
		}]
	}, {
		'id': 1127,
		'pid': 1046,
		'name': '阜阳市',
		'merger_name': '中国,安徽省,阜阳市',
		'level': 2,
		'areas': [{
			'id': 1128,
			'pid': 1127,
			'name': '颍州区',
			'merger_name': '中国,安徽省,阜阳市,颍州区',
			'level': 3
		}, {
			'id': 1129,
			'pid': 1127,
			'name': '颍东区',
			'merger_name': '中国,安徽省,阜阳市,颍东区',
			'level': 3
		}, {
			'id': 1130,
			'pid': 1127,
			'name': '颍泉区',
			'merger_name': '中国,安徽省,阜阳市,颍泉区',
			'level': 3
		}, {
			'id': 1131,
			'pid': 1127,
			'name': '临泉县',
			'merger_name': '中国,安徽省,阜阳市,临泉县',
			'level': 3
		}, {
			'id': 1132,
			'pid': 1127,
			'name': '太和县',
			'merger_name': '中国,安徽省,阜阳市,太和县',
			'level': 3
		}, {
			'id': 1133,
			'pid': 1127,
			'name': '阜南县',
			'merger_name': '中国,安徽省,阜阳市,阜南县',
			'level': 3
		}, {
			'id': 1134,
			'pid': 1127,
			'name': '颍上县',
			'merger_name': '中国,安徽省,阜阳市,颍上县',
			'level': 3
		}, {
			'id': 1135,
			'pid': 1127,
			'name': '界首市',
			'merger_name': '中国,安徽省,阜阳市,界首市',
			'level': 3
		}]
	}, {
		'id': 1136,
		'pid': 1046,
		'name': '宿州市',
		'merger_name': '中国,安徽省,宿州市',
		'level': 2,
		'areas': [{
			'id': 1137,
			'pid': 1136,
			'name': '埇桥区',
			'merger_name': '中国,安徽省,宿州市,埇桥区',
			'level': 3
		}, {
			'id': 1138,
			'pid': 1136,
			'name': '砀山县',
			'merger_name': '中国,安徽省,宿州市,砀山县',
			'level': 3
		}, {
			'id': 1139,
			'pid': 1136,
			'name': '萧县',
			'merger_name': '中国,安徽省,宿州市,萧县',
			'level': 3
		}, {
			'id': 1140,
			'pid': 1136,
			'name': '灵璧县',
			'merger_name': '中国,安徽省,宿州市,灵璧县',
			'level': 3
		}, {
			'id': 1141,
			'pid': 1136,
			'name': '泗县',
			'merger_name': '中国,安徽省,宿州市,泗县',
			'level': 3
		}]
	}, {
		'id': 1142,
		'pid': 1046,
		'name': '六安市',
		'merger_name': '中国,安徽省,六安市',
		'level': 2,
		'areas': [{
			'id': 1143,
			'pid': 1142,
			'name': '金安区',
			'merger_name': '中国,安徽省,六安市,金安区',
			'level': 3
		}, {
			'id': 1144,
			'pid': 1142,
			'name': '裕安区',
			'merger_name': '中国,安徽省,六安市,裕安区',
			'level': 3
		}, {
			'id': 1145,
			'pid': 1142,
			'name': '寿县',
			'merger_name': '中国,安徽省,六安市,寿县',
			'level': 3
		}, {
			'id': 1146,
			'pid': 1142,
			'name': '霍邱县',
			'merger_name': '中国,安徽省,六安市,霍邱县',
			'level': 3
		}, {
			'id': 1147,
			'pid': 1142,
			'name': '舒城县',
			'merger_name': '中国,安徽省,六安市,舒城县',
			'level': 3
		}, {
			'id': 1148,
			'pid': 1142,
			'name': '金寨县',
			'merger_name': '中国,安徽省,六安市,金寨县',
			'level': 3
		}, {
			'id': 1149,
			'pid': 1142,
			'name': '霍山县',
			'merger_name': '中国,安徽省,六安市,霍山县',
			'level': 3
		}]
	}, {
		'id': 1150,
		'pid': 1046,
		'name': '亳州市',
		'merger_name': '中国,安徽省,亳州市',
		'level': 2,
		'areas': [{
			'id': 1151,
			'pid': 1150,
			'name': '谯城区',
			'merger_name': '中国,安徽省,亳州市,谯城区',
			'level': 3
		}, {
			'id': 1152,
			'pid': 1150,
			'name': '涡阳县',
			'merger_name': '中国,安徽省,亳州市,涡阳县',
			'level': 3
		}, {
			'id': 1153,
			'pid': 1150,
			'name': '蒙城县',
			'merger_name': '中国,安徽省,亳州市,蒙城县',
			'level': 3
		}, {
			'id': 1154,
			'pid': 1150,
			'name': '利辛县',
			'merger_name': '中国,安徽省,亳州市,利辛县',
			'level': 3
		}]
	}, {
		'id': 1155,
		'pid': 1046,
		'name': '池州市',
		'merger_name': '中国,安徽省,池州市',
		'level': 2,
		'areas': [{
			'id': 1156,
			'pid': 1155,
			'name': '贵池区',
			'merger_name': '中国,安徽省,池州市,贵池区',
			'level': 3
		}, {
			'id': 1157,
			'pid': 1155,
			'name': '东至县',
			'merger_name': '中国,安徽省,池州市,东至县',
			'level': 3
		}, {
			'id': 1158,
			'pid': 1155,
			'name': '石台县',
			'merger_name': '中国,安徽省,池州市,石台县',
			'level': 3
		}, {
			'id': 1159,
			'pid': 1155,
			'name': '青阳县',
			'merger_name': '中国,安徽省,池州市,青阳县',
			'level': 3
		}]
	}, {
		'id': 1160,
		'pid': 1046,
		'name': '宣城市',
		'merger_name': '中国,安徽省,宣城市',
		'level': 2,
		'areas': [{
			'id': 1161,
			'pid': 1160,
			'name': '宣州区',
			'merger_name': '中国,安徽省,宣城市,宣州区',
			'level': 3
		}, {
			'id': 1162,
			'pid': 1160,
			'name': '郎溪县',
			'merger_name': '中国,安徽省,宣城市,郎溪县',
			'level': 3
		}, {
			'id': 1163,
			'pid': 1160,
			'name': '广德县',
			'merger_name': '中国,安徽省,宣城市,广德县',
			'level': 3
		}, {
			'id': 1164,
			'pid': 1160,
			'name': '泾县',
			'merger_name': '中国,安徽省,宣城市,泾县',
			'level': 3
		}, {
			'id': 1165,
			'pid': 1160,
			'name': '绩溪县',
			'merger_name': '中国,安徽省,宣城市,绩溪县',
			'level': 3
		}, {
			'id': 1166,
			'pid': 1160,
			'name': '旌德县',
			'merger_name': '中国,安徽省,宣城市,旌德县',
			'level': 3
		}, {
			'id': 1167,
			'pid': 1160,
			'name': '宁国市',
			'merger_name': '中国,安徽省,宣城市,宁国市',
			'level': 3
		}]
	}]
}, {
	'id': 1168,
	'pid': 0,
	'name': '福建省',
	'merger_name': '中国,福建省',
	'level': 1,
	'cities': [{
		'id': 1169,
		'pid': 1168,
		'name': '福州市',
		'merger_name': '中国,福建省,福州市',
		'level': 2,
		'areas': [{
			'id': 1170,
			'pid': 1169,
			'name': '鼓楼区',
			'merger_name': '中国,福建省,福州市,鼓楼区',
			'level': 3
		}, {
			'id': 1171,
			'pid': 1169,
			'name': '台江区',
			'merger_name': '中国,福建省,福州市,台江区',
			'level': 3
		}, {
			'id': 1172,
			'pid': 1169,
			'name': '仓山区',
			'merger_name': '中国,福建省,福州市,仓山区',
			'level': 3
		}, {
			'id': 1173,
			'pid': 1169,
			'name': '马尾区',
			'merger_name': '中国,福建省,福州市,马尾区',
			'level': 3
		}, {
			'id': 1174,
			'pid': 1169,
			'name': '晋安区',
			'merger_name': '中国,福建省,福州市,晋安区',
			'level': 3
		}, {
			'id': 1175,
			'pid': 1169,
			'name': '闽侯县',
			'merger_name': '中国,福建省,福州市,闽侯县',
			'level': 3
		}, {
			'id': 1176,
			'pid': 1169,
			'name': '连江县',
			'merger_name': '中国,福建省,福州市,连江县',
			'level': 3
		}, {
			'id': 1177,
			'pid': 1169,
			'name': '罗源县',
			'merger_name': '中国,福建省,福州市,罗源县',
			'level': 3
		}, {
			'id': 1178,
			'pid': 1169,
			'name': '闽清县',
			'merger_name': '中国,福建省,福州市,闽清县',
			'level': 3
		}, {
			'id': 1179,
			'pid': 1169,
			'name': '永泰县',
			'merger_name': '中国,福建省,福州市,永泰县',
			'level': 3
		}, {
			'id': 1180,
			'pid': 1169,
			'name': '平潭县',
			'merger_name': '中国,福建省,福州市,平潭县',
			'level': 3
		}, {
			'id': 1181,
			'pid': 1169,
			'name': '福清市',
			'merger_name': '中国,福建省,福州市,福清市',
			'level': 3
		}, {
			'id': 1182,
			'pid': 1169,
			'name': '长乐市',
			'merger_name': '中国,福建省,福州市,长乐市',
			'level': 3
		}]
	}, {
		'id': 1183,
		'pid': 1168,
		'name': '厦门市',
		'merger_name': '中国,福建省,厦门市',
		'level': 2,
		'areas': [{
			'id': 1184,
			'pid': 1183,
			'name': '思明区',
			'merger_name': '中国,福建省,厦门市,思明区',
			'level': 3
		}, {
			'id': 1185,
			'pid': 1183,
			'name': '海沧区',
			'merger_name': '中国,福建省,厦门市,海沧区',
			'level': 3
		}, {
			'id': 1186,
			'pid': 1183,
			'name': '湖里区',
			'merger_name': '中国,福建省,厦门市,湖里区',
			'level': 3
		}, {
			'id': 1187,
			'pid': 1183,
			'name': '集美区',
			'merger_name': '中国,福建省,厦门市,集美区',
			'level': 3
		}, {
			'id': 1188,
			'pid': 1183,
			'name': '同安区',
			'merger_name': '中国,福建省,厦门市,同安区',
			'level': 3
		}, {
			'id': 1189,
			'pid': 1183,
			'name': '翔安区',
			'merger_name': '中国,福建省,厦门市,翔安区',
			'level': 3
		}]
	}, {
		'id': 1190,
		'pid': 1168,
		'name': '莆田市',
		'merger_name': '中国,福建省,莆田市',
		'level': 2,
		'areas': [{
			'id': 1191,
			'pid': 1190,
			'name': '城厢区',
			'merger_name': '中国,福建省,莆田市,城厢区',
			'level': 3
		}, {
			'id': 1192,
			'pid': 1190,
			'name': '涵江区',
			'merger_name': '中国,福建省,莆田市,涵江区',
			'level': 3
		}, {
			'id': 1193,
			'pid': 1190,
			'name': '荔城区',
			'merger_name': '中国,福建省,莆田市,荔城区',
			'level': 3
		}, {
			'id': 1194,
			'pid': 1190,
			'name': '秀屿区',
			'merger_name': '中国,福建省,莆田市,秀屿区',
			'level': 3
		}, {
			'id': 1195,
			'pid': 1190,
			'name': '仙游县',
			'merger_name': '中国,福建省,莆田市,仙游县',
			'level': 3
		}]
	}, {
		'id': 1196,
		'pid': 1168,
		'name': '三明市',
		'merger_name': '中国,福建省,三明市',
		'level': 2,
		'areas': [{
			'id': 1197,
			'pid': 1196,
			'name': '梅列区',
			'merger_name': '中国,福建省,三明市,梅列区',
			'level': 3
		}, {
			'id': 1198,
			'pid': 1196,
			'name': '三元区',
			'merger_name': '中国,福建省,三明市,三元区',
			'level': 3
		}, {
			'id': 1199,
			'pid': 1196,
			'name': '明溪县',
			'merger_name': '中国,福建省,三明市,明溪县',
			'level': 3
		}, {
			'id': 1200,
			'pid': 1196,
			'name': '清流县',
			'merger_name': '中国,福建省,三明市,清流县',
			'level': 3
		}, {
			'id': 1201,
			'pid': 1196,
			'name': '宁化县',
			'merger_name': '中国,福建省,三明市,宁化县',
			'level': 3
		}, {
			'id': 1202,
			'pid': 1196,
			'name': '大田县',
			'merger_name': '中国,福建省,三明市,大田县',
			'level': 3
		}, {
			'id': 1203,
			'pid': 1196,
			'name': '尤溪县',
			'merger_name': '中国,福建省,三明市,尤溪县',
			'level': 3
		}, {
			'id': 1204,
			'pid': 1196,
			'name': '沙县',
			'merger_name': '中国,福建省,三明市,沙县',
			'level': 3
		}, {
			'id': 1205,
			'pid': 1196,
			'name': '将乐县',
			'merger_name': '中国,福建省,三明市,将乐县',
			'level': 3
		}, {
			'id': 1206,
			'pid': 1196,
			'name': '泰宁县',
			'merger_name': '中国,福建省,三明市,泰宁县',
			'level': 3
		}, {
			'id': 1207,
			'pid': 1196,
			'name': '建宁县',
			'merger_name': '中国,福建省,三明市,建宁县',
			'level': 3
		}, {
			'id': 1208,
			'pid': 1196,
			'name': '永安市',
			'merger_name': '中国,福建省,三明市,永安市',
			'level': 3
		}]
	}, {
		'id': 1209,
		'pid': 1168,
		'name': '泉州市',
		'merger_name': '中国,福建省,泉州市',
		'level': 2,
		'areas': [{
			'id': 1210,
			'pid': 1209,
			'name': '鲤城区',
			'merger_name': '中国,福建省,泉州市,鲤城区',
			'level': 3
		}, {
			'id': 1211,
			'pid': 1209,
			'name': '丰泽区',
			'merger_name': '中国,福建省,泉州市,丰泽区',
			'level': 3
		}, {
			'id': 1212,
			'pid': 1209,
			'name': '洛江区',
			'merger_name': '中国,福建省,泉州市,洛江区',
			'level': 3
		}, {
			'id': 1213,
			'pid': 1209,
			'name': '泉港区',
			'merger_name': '中国,福建省,泉州市,泉港区',
			'level': 3
		}, {
			'id': 1214,
			'pid': 1209,
			'name': '惠安县',
			'merger_name': '中国,福建省,泉州市,惠安县',
			'level': 3
		}, {
			'id': 1215,
			'pid': 1209,
			'name': '安溪县',
			'merger_name': '中国,福建省,泉州市,安溪县',
			'level': 3
		}, {
			'id': 1216,
			'pid': 1209,
			'name': '永春县',
			'merger_name': '中国,福建省,泉州市,永春县',
			'level': 3
		}, {
			'id': 1217,
			'pid': 1209,
			'name': '德化县',
			'merger_name': '中国,福建省,泉州市,德化县',
			'level': 3
		}, {
			'id': 1218,
			'pid': 1209,
			'name': '金门县',
			'merger_name': '中国,福建省,泉州市,金门县',
			'level': 3
		}, {
			'id': 1219,
			'pid': 1209,
			'name': '石狮市',
			'merger_name': '中国,福建省,泉州市,石狮市',
			'level': 3
		}, {
			'id': 1220,
			'pid': 1209,
			'name': '晋江市',
			'merger_name': '中国,福建省,泉州市,晋江市',
			'level': 3
		}, {
			'id': 1221,
			'pid': 1209,
			'name': '南安市',
			'merger_name': '中国,福建省,泉州市,南安市',
			'level': 3
		}]
	}, {
		'id': 1222,
		'pid': 1168,
		'name': '漳州市',
		'merger_name': '中国,福建省,漳州市',
		'level': 2,
		'areas': [{
			'id': 1223,
			'pid': 1222,
			'name': '芗城区',
			'merger_name': '中国,福建省,漳州市,芗城区',
			'level': 3
		}, {
			'id': 1224,
			'pid': 1222,
			'name': '龙文区',
			'merger_name': '中国,福建省,漳州市,龙文区',
			'level': 3
		}, {
			'id': 1225,
			'pid': 1222,
			'name': '云霄县',
			'merger_name': '中国,福建省,漳州市,云霄县',
			'level': 3
		}, {
			'id': 1226,
			'pid': 1222,
			'name': '漳浦县',
			'merger_name': '中国,福建省,漳州市,漳浦县',
			'level': 3
		}, {
			'id': 1227,
			'pid': 1222,
			'name': '诏安县',
			'merger_name': '中国,福建省,漳州市,诏安县',
			'level': 3
		}, {
			'id': 1228,
			'pid': 1222,
			'name': '长泰县',
			'merger_name': '中国,福建省,漳州市,长泰县',
			'level': 3
		}, {
			'id': 1229,
			'pid': 1222,
			'name': '东山县',
			'merger_name': '中国,福建省,漳州市,东山县',
			'level': 3
		}, {
			'id': 1230,
			'pid': 1222,
			'name': '南靖县',
			'merger_name': '中国,福建省,漳州市,南靖县',
			'level': 3
		}, {
			'id': 1231,
			'pid': 1222,
			'name': '平和县',
			'merger_name': '中国,福建省,漳州市,平和县',
			'level': 3
		}, {
			'id': 1232,
			'pid': 1222,
			'name': '华安县',
			'merger_name': '中国,福建省,漳州市,华安县',
			'level': 3
		}, {
			'id': 1233,
			'pid': 1222,
			'name': '龙海市',
			'merger_name': '中国,福建省,漳州市,龙海市',
			'level': 3
		}]
	}, {
		'id': 1234,
		'pid': 1168,
		'name': '南平市',
		'merger_name': '中国,福建省,南平市',
		'level': 2,
		'areas': [{
			'id': 1235,
			'pid': 1234,
			'name': '延平区',
			'merger_name': '中国,福建省,南平市,延平区',
			'level': 3
		}, {
			'id': 1236,
			'pid': 1234,
			'name': '建阳区',
			'merger_name': '中国,福建省,南平市,建阳区',
			'level': 3
		}, {
			'id': 1237,
			'pid': 1234,
			'name': '顺昌县',
			'merger_name': '中国,福建省,南平市,顺昌县',
			'level': 3
		}, {
			'id': 1238,
			'pid': 1234,
			'name': '浦城县',
			'merger_name': '中国,福建省,南平市,浦城县',
			'level': 3
		}, {
			'id': 1239,
			'pid': 1234,
			'name': '光泽县',
			'merger_name': '中国,福建省,南平市,光泽县',
			'level': 3
		}, {
			'id': 1240,
			'pid': 1234,
			'name': '松溪县',
			'merger_name': '中国,福建省,南平市,松溪县',
			'level': 3
		}, {
			'id': 1241,
			'pid': 1234,
			'name': '政和县',
			'merger_name': '中国,福建省,南平市,政和县',
			'level': 3
		}, {
			'id': 1242,
			'pid': 1234,
			'name': '邵武市',
			'merger_name': '中国,福建省,南平市,邵武市',
			'level': 3
		}, {
			'id': 1243,
			'pid': 1234,
			'name': '武夷山市',
			'merger_name': '中国,福建省,南平市,武夷山市',
			'level': 3
		}, {
			'id': 1244,
			'pid': 1234,
			'name': '建瓯市',
			'merger_name': '中国,福建省,南平市,建瓯市',
			'level': 3
		}]
	}, {
		'id': 1245,
		'pid': 1168,
		'name': '龙岩市',
		'merger_name': '中国,福建省,龙岩市',
		'level': 2,
		'areas': [{
			'id': 1246,
			'pid': 1245,
			'name': '新罗区',
			'merger_name': '中国,福建省,龙岩市,新罗区',
			'level': 3
		}, {
			'id': 1247,
			'pid': 1245,
			'name': '长汀县',
			'merger_name': '中国,福建省,龙岩市,长汀县',
			'level': 3
		}, {
			'id': 1248,
			'pid': 1245,
			'name': '永定区',
			'merger_name': '中国,福建省,龙岩市,永定区',
			'level': 3
		}, {
			'id': 1249,
			'pid': 1245,
			'name': '上杭县',
			'merger_name': '中国,福建省,龙岩市,上杭县',
			'level': 3
		}, {
			'id': 1250,
			'pid': 1245,
			'name': '武平县',
			'merger_name': '中国,福建省,龙岩市,武平县',
			'level': 3
		}, {
			'id': 1251,
			'pid': 1245,
			'name': '连城县',
			'merger_name': '中国,福建省,龙岩市,连城县',
			'level': 3
		}, {
			'id': 1252,
			'pid': 1245,
			'name': '漳平市',
			'merger_name': '中国,福建省,龙岩市,漳平市',
			'level': 3
		}]
	}, {
		'id': 1253,
		'pid': 1168,
		'name': '宁德市',
		'merger_name': '中国,福建省,宁德市',
		'level': 2,
		'areas': [{
			'id': 1254,
			'pid': 1253,
			'name': '蕉城区',
			'merger_name': '中国,福建省,宁德市,蕉城区',
			'level': 3
		}, {
			'id': 1255,
			'pid': 1253,
			'name': '霞浦县',
			'merger_name': '中国,福建省,宁德市,霞浦县',
			'level': 3
		}, {
			'id': 1256,
			'pid': 1253,
			'name': '古田县',
			'merger_name': '中国,福建省,宁德市,古田县',
			'level': 3
		}, {
			'id': 1257,
			'pid': 1253,
			'name': '屏南县',
			'merger_name': '中国,福建省,宁德市,屏南县',
			'level': 3
		}, {
			'id': 1258,
			'pid': 1253,
			'name': '寿宁县',
			'merger_name': '中国,福建省,宁德市,寿宁县',
			'level': 3
		}, {
			'id': 1259,
			'pid': 1253,
			'name': '周宁县',
			'merger_name': '中国,福建省,宁德市,周宁县',
			'level': 3
		}, {
			'id': 1260,
			'pid': 1253,
			'name': '柘荣县',
			'merger_name': '中国,福建省,宁德市,柘荣县',
			'level': 3
		}, {
			'id': 1261,
			'pid': 1253,
			'name': '福安市',
			'merger_name': '中国,福建省,宁德市,福安市',
			'level': 3
		}, {
			'id': 1262,
			'pid': 1253,
			'name': '福鼎市',
			'merger_name': '中国,福建省,宁德市,福鼎市',
			'level': 3
		}]
	}]
}, {
	'id': 1263,
	'pid': 0,
	'name': '江西省',
	'merger_name': '中国,江西省',
	'level': 1,
	'cities': [{
		'id': 1264,
		'pid': 1263,
		'name': '南昌市',
		'merger_name': '中国,江西省,南昌市',
		'level': 2,
		'areas': [{
			'id': 1265,
			'pid': 1264,
			'name': '东湖区',
			'merger_name': '中国,江西省,南昌市,东湖区',
			'level': 3
		}, {
			'id': 1266,
			'pid': 1264,
			'name': '西湖区',
			'merger_name': '中国,江西省,南昌市,西湖区',
			'level': 3
		}, {
			'id': 1267,
			'pid': 1264,
			'name': '青云谱区',
			'merger_name': '中国,江西省,南昌市,青云谱区',
			'level': 3
		}, {
			'id': 1268,
			'pid': 1264,
			'name': '湾里区',
			'merger_name': '中国,江西省,南昌市,湾里区',
			'level': 3
		}, {
			'id': 1269,
			'pid': 1264,
			'name': '青山湖区',
			'merger_name': '中国,江西省,南昌市,青山湖区',
			'level': 3
		}, {
			'id': 1270,
			'pid': 1264,
			'name': '南昌县',
			'merger_name': '中国,江西省,南昌市,南昌县',
			'level': 3
		}, {
			'id': 1271,
			'pid': 1264,
			'name': '新建县',
			'merger_name': '中国,江西省,南昌市,新建县',
			'level': 3
		}, {
			'id': 1272,
			'pid': 1264,
			'name': '安义县',
			'merger_name': '中国,江西省,南昌市,安义县',
			'level': 3
		}, {
			'id': 1273,
			'pid': 1264,
			'name': '进贤县',
			'merger_name': '中国,江西省,南昌市,进贤县',
			'level': 3
		}]
	}, {
		'id': 1274,
		'pid': 1263,
		'name': '景德镇市',
		'merger_name': '中国,江西省,景德镇市',
		'level': 2,
		'areas': [{
			'id': 1275,
			'pid': 1274,
			'name': '昌江区',
			'merger_name': '中国,江西省,景德镇市,昌江区',
			'level': 3
		}, {
			'id': 1276,
			'pid': 1274,
			'name': '珠山区',
			'merger_name': '中国,江西省,景德镇市,珠山区',
			'level': 3
		}, {
			'id': 1277,
			'pid': 1274,
			'name': '浮梁县',
			'merger_name': '中国,江西省,景德镇市,浮梁县',
			'level': 3
		}, {
			'id': 1278,
			'pid': 1274,
			'name': '乐平市',
			'merger_name': '中国,江西省,景德镇市,乐平市',
			'level': 3
		}]
	}, {
		'id': 1279,
		'pid': 1263,
		'name': '萍乡市',
		'merger_name': '中国,江西省,萍乡市',
		'level': 2,
		'areas': [{
			'id': 1280,
			'pid': 1279,
			'name': '安源区',
			'merger_name': '中国,江西省,萍乡市,安源区',
			'level': 3
		}, {
			'id': 1281,
			'pid': 1279,
			'name': '湘东区',
			'merger_name': '中国,江西省,萍乡市,湘东区',
			'level': 3
		}, {
			'id': 1282,
			'pid': 1279,
			'name': '莲花县',
			'merger_name': '中国,江西省,萍乡市,莲花县',
			'level': 3
		}, {
			'id': 1283,
			'pid': 1279,
			'name': '上栗县',
			'merger_name': '中国,江西省,萍乡市,上栗县',
			'level': 3
		}, {
			'id': 1284,
			'pid': 1279,
			'name': '芦溪县',
			'merger_name': '中国,江西省,萍乡市,芦溪县',
			'level': 3
		}]
	}, {
		'id': 1285,
		'pid': 1263,
		'name': '九江市',
		'merger_name': '中国,江西省,九江市',
		'level': 2,
		'areas': [{
			'id': 1286,
			'pid': 1285,
			'name': '庐山区',
			'merger_name': '中国,江西省,九江市,庐山区',
			'level': 3
		}, {
			'id': 1287,
			'pid': 1285,
			'name': '浔阳区',
			'merger_name': '中国,江西省,九江市,浔阳区',
			'level': 3
		}, {
			'id': 1288,
			'pid': 1285,
			'name': '九江县',
			'merger_name': '中国,江西省,九江市,九江县',
			'level': 3
		}, {
			'id': 1289,
			'pid': 1285,
			'name': '武宁县',
			'merger_name': '中国,江西省,九江市,武宁县',
			'level': 3
		}, {
			'id': 1290,
			'pid': 1285,
			'name': '修水县',
			'merger_name': '中国,江西省,九江市,修水县',
			'level': 3
		}, {
			'id': 1291,
			'pid': 1285,
			'name': '永修县',
			'merger_name': '中国,江西省,九江市,永修县',
			'level': 3
		}, {
			'id': 1292,
			'pid': 1285,
			'name': '德安县',
			'merger_name': '中国,江西省,九江市,德安县',
			'level': 3
		}, {
			'id': 1293,
			'pid': 1285,
			'name': '星子县',
			'merger_name': '中国,江西省,九江市,星子县',
			'level': 3
		}, {
			'id': 1294,
			'pid': 1285,
			'name': '都昌县',
			'merger_name': '中国,江西省,九江市,都昌县',
			'level': 3
		}, {
			'id': 1295,
			'pid': 1285,
			'name': '湖口县',
			'merger_name': '中国,江西省,九江市,湖口县',
			'level': 3
		}, {
			'id': 1296,
			'pid': 1285,
			'name': '彭泽县',
			'merger_name': '中国,江西省,九江市,彭泽县',
			'level': 3
		}, {
			'id': 1297,
			'pid': 1285,
			'name': '瑞昌市',
			'merger_name': '中国,江西省,九江市,瑞昌市',
			'level': 3
		}, {
			'id': 1298,
			'pid': 1285,
			'name': '共青城市',
			'merger_name': '中国,江西省,九江市,共青城市',
			'level': 3
		}]
	}, {
		'id': 1299,
		'pid': 1263,
		'name': '新余市',
		'merger_name': '中国,江西省,新余市',
		'level': 2,
		'areas': [{
			'id': 1300,
			'pid': 1299,
			'name': '渝水区',
			'merger_name': '中国,江西省,新余市,渝水区',
			'level': 3
		}, {
			'id': 1301,
			'pid': 1299,
			'name': '分宜县',
			'merger_name': '中国,江西省,新余市,分宜县',
			'level': 3
		}]
	}, {
		'id': 1302,
		'pid': 1263,
		'name': '鹰潭市',
		'merger_name': '中国,江西省,鹰潭市',
		'level': 2,
		'areas': [{
			'id': 1303,
			'pid': 1302,
			'name': '月湖区',
			'merger_name': '中国,江西省,鹰潭市,月湖区',
			'level': 3
		}, {
			'id': 1304,
			'pid': 1302,
			'name': '余江县',
			'merger_name': '中国,江西省,鹰潭市,余江县',
			'level': 3
		}, {
			'id': 1305,
			'pid': 1302,
			'name': '贵溪市',
			'merger_name': '中国,江西省,鹰潭市,贵溪市',
			'level': 3
		}]
	}, {
		'id': 1306,
		'pid': 1263,
		'name': '赣州市',
		'merger_name': '中国,江西省,赣州市',
		'level': 2,
		'areas': [{
			'id': 1307,
			'pid': 1306,
			'name': '章贡区',
			'merger_name': '中国,江西省,赣州市,章贡区',
			'level': 3
		}, {
			'id': 1308,
			'pid': 1306,
			'name': '南康区',
			'merger_name': '中国,江西省,赣州市,南康区',
			'level': 3
		}, {
			'id': 1309,
			'pid': 1306,
			'name': '赣县',
			'merger_name': '中国,江西省,赣州市,赣县',
			'level': 3
		}, {
			'id': 1310,
			'pid': 1306,
			'name': '信丰县',
			'merger_name': '中国,江西省,赣州市,信丰县',
			'level': 3
		}, {
			'id': 1311,
			'pid': 1306,
			'name': '大余县',
			'merger_name': '中国,江西省,赣州市,大余县',
			'level': 3
		}, {
			'id': 1312,
			'pid': 1306,
			'name': '上犹县',
			'merger_name': '中国,江西省,赣州市,上犹县',
			'level': 3
		}, {
			'id': 1313,
			'pid': 1306,
			'name': '崇义县',
			'merger_name': '中国,江西省,赣州市,崇义县',
			'level': 3
		}, {
			'id': 1314,
			'pid': 1306,
			'name': '安远县',
			'merger_name': '中国,江西省,赣州市,安远县',
			'level': 3
		}, {
			'id': 1315,
			'pid': 1306,
			'name': '龙南县',
			'merger_name': '中国,江西省,赣州市,龙南县',
			'level': 3
		}, {
			'id': 1316,
			'pid': 1306,
			'name': '定南县',
			'merger_name': '中国,江西省,赣州市,定南县',
			'level': 3
		}, {
			'id': 1317,
			'pid': 1306,
			'name': '全南县',
			'merger_name': '中国,江西省,赣州市,全南县',
			'level': 3
		}, {
			'id': 1318,
			'pid': 1306,
			'name': '宁都县',
			'merger_name': '中国,江西省,赣州市,宁都县',
			'level': 3
		}, {
			'id': 1319,
			'pid': 1306,
			'name': '于都县',
			'merger_name': '中国,江西省,赣州市,于都县',
			'level': 3
		}, {
			'id': 1320,
			'pid': 1306,
			'name': '兴国县',
			'merger_name': '中国,江西省,赣州市,兴国县',
			'level': 3
		}, {
			'id': 1321,
			'pid': 1306,
			'name': '会昌县',
			'merger_name': '中国,江西省,赣州市,会昌县',
			'level': 3
		}, {
			'id': 1322,
			'pid': 1306,
			'name': '寻乌县',
			'merger_name': '中国,江西省,赣州市,寻乌县',
			'level': 3
		}, {
			'id': 1323,
			'pid': 1306,
			'name': '石城县',
			'merger_name': '中国,江西省,赣州市,石城县',
			'level': 3
		}, {
			'id': 1324,
			'pid': 1306,
			'name': '瑞金市',
			'merger_name': '中国,江西省,赣州市,瑞金市',
			'level': 3
		}]
	}, {
		'id': 1325,
		'pid': 1263,
		'name': '吉安市',
		'merger_name': '中国,江西省,吉安市',
		'level': 2,
		'areas': [{
			'id': 1326,
			'pid': 1325,
			'name': '吉州区',
			'merger_name': '中国,江西省,吉安市,吉州区',
			'level': 3
		}, {
			'id': 1327,
			'pid': 1325,
			'name': '青原区',
			'merger_name': '中国,江西省,吉安市,青原区',
			'level': 3
		}, {
			'id': 1328,
			'pid': 1325,
			'name': '吉安县',
			'merger_name': '中国,江西省,吉安市,吉安县',
			'level': 3
		}, {
			'id': 1329,
			'pid': 1325,
			'name': '吉水县',
			'merger_name': '中国,江西省,吉安市,吉水县',
			'level': 3
		}, {
			'id': 1330,
			'pid': 1325,
			'name': '峡江县',
			'merger_name': '中国,江西省,吉安市,峡江县',
			'level': 3
		}, {
			'id': 1331,
			'pid': 1325,
			'name': '新干县',
			'merger_name': '中国,江西省,吉安市,新干县',
			'level': 3
		}, {
			'id': 1332,
			'pid': 1325,
			'name': '永丰县',
			'merger_name': '中国,江西省,吉安市,永丰县',
			'level': 3
		}, {
			'id': 1333,
			'pid': 1325,
			'name': '泰和县',
			'merger_name': '中国,江西省,吉安市,泰和县',
			'level': 3
		}, {
			'id': 1334,
			'pid': 1325,
			'name': '遂川县',
			'merger_name': '中国,江西省,吉安市,遂川县',
			'level': 3
		}, {
			'id': 1335,
			'pid': 1325,
			'name': '万安县',
			'merger_name': '中国,江西省,吉安市,万安县',
			'level': 3
		}, {
			'id': 1336,
			'pid': 1325,
			'name': '安福县',
			'merger_name': '中国,江西省,吉安市,安福县',
			'level': 3
		}, {
			'id': 1337,
			'pid': 1325,
			'name': '永新县',
			'merger_name': '中国,江西省,吉安市,永新县',
			'level': 3
		}, {
			'id': 1338,
			'pid': 1325,
			'name': '井冈山市',
			'merger_name': '中国,江西省,吉安市,井冈山市',
			'level': 3
		}]
	}, {
		'id': 1339,
		'pid': 1263,
		'name': '宜春市',
		'merger_name': '中国,江西省,宜春市',
		'level': 2,
		'areas': [{
			'id': 1340,
			'pid': 1339,
			'name': '袁州区',
			'merger_name': '中国,江西省,宜春市,袁州区',
			'level': 3
		}, {
			'id': 1341,
			'pid': 1339,
			'name': '奉新县',
			'merger_name': '中国,江西省,宜春市,奉新县',
			'level': 3
		}, {
			'id': 1342,
			'pid': 1339,
			'name': '万载县',
			'merger_name': '中国,江西省,宜春市,万载县',
			'level': 3
		}, {
			'id': 1343,
			'pid': 1339,
			'name': '上高县',
			'merger_name': '中国,江西省,宜春市,上高县',
			'level': 3
		}, {
			'id': 1344,
			'pid': 1339,
			'name': '宜丰县',
			'merger_name': '中国,江西省,宜春市,宜丰县',
			'level': 3
		}, {
			'id': 1345,
			'pid': 1339,
			'name': '靖安县',
			'merger_name': '中国,江西省,宜春市,靖安县',
			'level': 3
		}, {
			'id': 1346,
			'pid': 1339,
			'name': '铜鼓县',
			'merger_name': '中国,江西省,宜春市,铜鼓县',
			'level': 3
		}, {
			'id': 1347,
			'pid': 1339,
			'name': '丰城市',
			'merger_name': '中国,江西省,宜春市,丰城市',
			'level': 3
		}, {
			'id': 1348,
			'pid': 1339,
			'name': '樟树市',
			'merger_name': '中国,江西省,宜春市,樟树市',
			'level': 3
		}, {
			'id': 1349,
			'pid': 1339,
			'name': '高安市',
			'merger_name': '中国,江西省,宜春市,高安市',
			'level': 3
		}]
	}, {
		'id': 1350,
		'pid': 1263,
		'name': '抚州市',
		'merger_name': '中国,江西省,抚州市',
		'level': 2,
		'areas': [{
			'id': 1351,
			'pid': 1350,
			'name': '临川区',
			'merger_name': '中国,江西省,抚州市,临川区',
			'level': 3
		}, {
			'id': 1352,
			'pid': 1350,
			'name': '南城县',
			'merger_name': '中国,江西省,抚州市,南城县',
			'level': 3
		}, {
			'id': 1353,
			'pid': 1350,
			'name': '黎川县',
			'merger_name': '中国,江西省,抚州市,黎川县',
			'level': 3
		}, {
			'id': 1354,
			'pid': 1350,
			'name': '南丰县',
			'merger_name': '中国,江西省,抚州市,南丰县',
			'level': 3
		}, {
			'id': 1355,
			'pid': 1350,
			'name': '崇仁县',
			'merger_name': '中国,江西省,抚州市,崇仁县',
			'level': 3
		}, {
			'id': 1356,
			'pid': 1350,
			'name': '乐安县',
			'merger_name': '中国,江西省,抚州市,乐安县',
			'level': 3
		}, {
			'id': 1357,
			'pid': 1350,
			'name': '宜黄县',
			'merger_name': '中国,江西省,抚州市,宜黄县',
			'level': 3
		}, {
			'id': 1358,
			'pid': 1350,
			'name': '金溪县',
			'merger_name': '中国,江西省,抚州市,金溪县',
			'level': 3
		}, {
			'id': 1359,
			'pid': 1350,
			'name': '资溪县',
			'merger_name': '中国,江西省,抚州市,资溪县',
			'level': 3
		}, {
			'id': 1360,
			'pid': 1350,
			'name': '东乡县',
			'merger_name': '中国,江西省,抚州市,东乡县',
			'level': 3
		}, {
			'id': 1361,
			'pid': 1350,
			'name': '广昌县',
			'merger_name': '中国,江西省,抚州市,广昌县',
			'level': 3
		}]
	}, {
		'id': 1362,
		'pid': 1263,
		'name': '上饶市',
		'merger_name': '中国,江西省,上饶市',
		'level': 2,
		'areas': [{
			'id': 1363,
			'pid': 1362,
			'name': '信州区',
			'merger_name': '中国,江西省,上饶市,信州区',
			'level': 3
		}, {
			'id': 1364,
			'pid': 1362,
			'name': '上饶县',
			'merger_name': '中国,江西省,上饶市,上饶县',
			'level': 3
		}, {
			'id': 1365,
			'pid': 1362,
			'name': '广丰县',
			'merger_name': '中国,江西省,上饶市,广丰县',
			'level': 3
		}, {
			'id': 1366,
			'pid': 1362,
			'name': '玉山县',
			'merger_name': '中国,江西省,上饶市,玉山县',
			'level': 3
		}, {
			'id': 1367,
			'pid': 1362,
			'name': '铅山县',
			'merger_name': '中国,江西省,上饶市,铅山县',
			'level': 3
		}, {
			'id': 1368,
			'pid': 1362,
			'name': '横峰县',
			'merger_name': '中国,江西省,上饶市,横峰县',
			'level': 3
		}, {
			'id': 1369,
			'pid': 1362,
			'name': '弋阳县',
			'merger_name': '中国,江西省,上饶市,弋阳县',
			'level': 3
		}, {
			'id': 1370,
			'pid': 1362,
			'name': '余干县',
			'merger_name': '中国,江西省,上饶市,余干县',
			'level': 3
		}, {
			'id': 1371,
			'pid': 1362,
			'name': '鄱阳县',
			'merger_name': '中国,江西省,上饶市,鄱阳县',
			'level': 3
		}, {
			'id': 1372,
			'pid': 1362,
			'name': '万年县',
			'merger_name': '中国,江西省,上饶市,万年县',
			'level': 3
		}, {
			'id': 1373,
			'pid': 1362,
			'name': '婺源县',
			'merger_name': '中国,江西省,上饶市,婺源县',
			'level': 3
		}, {
			'id': 1374,
			'pid': 1362,
			'name': '德兴市',
			'merger_name': '中国,江西省,上饶市,德兴市',
			'level': 3
		}]
	}]
}, {
	'id': 1375,
	'pid': 0,
	'name': '山东省',
	'merger_name': '中国,山东省',
	'level': 1,
	'cities': [{
		'id': 1376,
		'pid': 1375,
		'name': '济南市',
		'merger_name': '中国,山东省,济南市',
		'level': 2,
		'areas': [{
			'id': 1377,
			'pid': 1376,
			'name': '历下区',
			'merger_name': '中国,山东省,济南市,历下区',
			'level': 3
		}, {
			'id': 1378,
			'pid': 1376,
			'name': '市中区',
			'merger_name': '中国,山东省,济南市,市中区',
			'level': 3
		}, {
			'id': 1379,
			'pid': 1376,
			'name': '槐荫区',
			'merger_name': '中国,山东省,济南市,槐荫区',
			'level': 3
		}, {
			'id': 1380,
			'pid': 1376,
			'name': '天桥区',
			'merger_name': '中国,山东省,济南市,天桥区',
			'level': 3
		}, {
			'id': 1381,
			'pid': 1376,
			'name': '历城区',
			'merger_name': '中国,山东省,济南市,历城区',
			'level': 3
		}, {
			'id': 1382,
			'pid': 1376,
			'name': '长清区',
			'merger_name': '中国,山东省,济南市,长清区',
			'level': 3
		}, {
			'id': 1383,
			'pid': 1376,
			'name': '平阴县',
			'merger_name': '中国,山东省,济南市,平阴县',
			'level': 3
		}, {
			'id': 1384,
			'pid': 1376,
			'name': '济阳县',
			'merger_name': '中国,山东省,济南市,济阳县',
			'level': 3
		}, {
			'id': 1385,
			'pid': 1376,
			'name': '商河县',
			'merger_name': '中国,山东省,济南市,商河县',
			'level': 3
		}, {
			'id': 1386,
			'pid': 1376,
			'name': '章丘市',
			'merger_name': '中国,山东省,济南市,章丘市',
			'level': 3
		}]
	}, {
		'id': 1387,
		'pid': 1375,
		'name': '青岛市',
		'merger_name': '中国,山东省,青岛市',
		'level': 2,
		'areas': [{
			'id': 1388,
			'pid': 1387,
			'name': '市南区',
			'merger_name': '中国,山东省,青岛市,市南区',
			'level': 3
		}, {
			'id': 1389,
			'pid': 1387,
			'name': '市北区',
			'merger_name': '中国,山东省,青岛市,市北区',
			'level': 3
		}, {
			'id': 1390,
			'pid': 1387,
			'name': '黄岛区',
			'merger_name': '中国,山东省,青岛市,黄岛区',
			'level': 3
		}, {
			'id': 1391,
			'pid': 1387,
			'name': '崂山区',
			'merger_name': '中国,山东省,青岛市,崂山区',
			'level': 3
		}, {
			'id': 1392,
			'pid': 1387,
			'name': '李沧区',
			'merger_name': '中国,山东省,青岛市,李沧区',
			'level': 3
		}, {
			'id': 1393,
			'pid': 1387,
			'name': '城阳区',
			'merger_name': '中国,山东省,青岛市,城阳区',
			'level': 3
		}, {
			'id': 1394,
			'pid': 1387,
			'name': '胶州市',
			'merger_name': '中国,山东省,青岛市,胶州市',
			'level': 3
		}, {
			'id': 1395,
			'pid': 1387,
			'name': '即墨市',
			'merger_name': '中国,山东省,青岛市,即墨市',
			'level': 3
		}, {
			'id': 1396,
			'pid': 1387,
			'name': '平度市',
			'merger_name': '中国,山东省,青岛市,平度市',
			'level': 3
		}, {
			'id': 1397,
			'pid': 1387,
			'name': '莱西市',
			'merger_name': '中国,山东省,青岛市,莱西市',
			'level': 3
		}, {
			'id': 1398,
			'pid': 1387,
			'name': '西海岸新区',
			'merger_name': '中国,山东省,青岛市,西海岸新区',
			'level': 3
		}]
	}, {
		'id': 1399,
		'pid': 1375,
		'name': '淄博市',
		'merger_name': '中国,山东省,淄博市',
		'level': 2,
		'areas': [{
			'id': 1400,
			'pid': 1399,
			'name': '淄川区',
			'merger_name': '中国,山东省,淄博市,淄川区',
			'level': 3
		}, {
			'id': 1401,
			'pid': 1399,
			'name': '张店区',
			'merger_name': '中国,山东省,淄博市,张店区',
			'level': 3
		}, {
			'id': 1402,
			'pid': 1399,
			'name': '博山区',
			'merger_name': '中国,山东省,淄博市,博山区',
			'level': 3
		}, {
			'id': 1403,
			'pid': 1399,
			'name': '临淄区',
			'merger_name': '中国,山东省,淄博市,临淄区',
			'level': 3
		}, {
			'id': 1404,
			'pid': 1399,
			'name': '周村区',
			'merger_name': '中国,山东省,淄博市,周村区',
			'level': 3
		}, {
			'id': 1405,
			'pid': 1399,
			'name': '桓台县',
			'merger_name': '中国,山东省,淄博市,桓台县',
			'level': 3
		}, {
			'id': 1406,
			'pid': 1399,
			'name': '高青县',
			'merger_name': '中国,山东省,淄博市,高青县',
			'level': 3
		}, {
			'id': 1407,
			'pid': 1399,
			'name': '沂源县',
			'merger_name': '中国,山东省,淄博市,沂源县',
			'level': 3
		}]
	}, {
		'id': 1408,
		'pid': 1375,
		'name': '枣庄市',
		'merger_name': '中国,山东省,枣庄市',
		'level': 2,
		'areas': [{
			'id': 1409,
			'pid': 1408,
			'name': '市中区',
			'merger_name': '中国,山东省,枣庄市,市中区',
			'level': 3
		}, {
			'id': 1410,
			'pid': 1408,
			'name': '薛城区',
			'merger_name': '中国,山东省,枣庄市,薛城区',
			'level': 3
		}, {
			'id': 1411,
			'pid': 1408,
			'name': '峄城区',
			'merger_name': '中国,山东省,枣庄市,峄城区',
			'level': 3
		}, {
			'id': 1412,
			'pid': 1408,
			'name': '台儿庄区',
			'merger_name': '中国,山东省,枣庄市,台儿庄区',
			'level': 3
		}, {
			'id': 1413,
			'pid': 1408,
			'name': '山亭区',
			'merger_name': '中国,山东省,枣庄市,山亭区',
			'level': 3
		}, {
			'id': 1414,
			'pid': 1408,
			'name': '滕州市',
			'merger_name': '中国,山东省,枣庄市,滕州市',
			'level': 3
		}]
	}, {
		'id': 1415,
		'pid': 1375,
		'name': '东营市',
		'merger_name': '中国,山东省,东营市',
		'level': 2,
		'areas': [{
			'id': 1416,
			'pid': 1415,
			'name': '东营区',
			'merger_name': '中国,山东省,东营市,东营区',
			'level': 3
		}, {
			'id': 1417,
			'pid': 1415,
			'name': '河口区',
			'merger_name': '中国,山东省,东营市,河口区',
			'level': 3
		}, {
			'id': 1418,
			'pid': 1415,
			'name': '垦利县',
			'merger_name': '中国,山东省,东营市,垦利县',
			'level': 3
		}, {
			'id': 1419,
			'pid': 1415,
			'name': '利津县',
			'merger_name': '中国,山东省,东营市,利津县',
			'level': 3
		}, {
			'id': 1420,
			'pid': 1415,
			'name': '广饶县',
			'merger_name': '中国,山东省,东营市,广饶县',
			'level': 3
		}]
	}, {
		'id': 1421,
		'pid': 1375,
		'name': '烟台市',
		'merger_name': '中国,山东省,烟台市',
		'level': 2,
		'areas': [{
			'id': 1422,
			'pid': 1421,
			'name': '芝罘区',
			'merger_name': '中国,山东省,烟台市,芝罘区',
			'level': 3
		}, {
			'id': 1423,
			'pid': 1421,
			'name': '福山区',
			'merger_name': '中国,山东省,烟台市,福山区',
			'level': 3
		}, {
			'id': 1424,
			'pid': 1421,
			'name': '牟平区',
			'merger_name': '中国,山东省,烟台市,牟平区',
			'level': 3
		}, {
			'id': 1425,
			'pid': 1421,
			'name': '莱山区',
			'merger_name': '中国,山东省,烟台市,莱山区',
			'level': 3
		}, {
			'id': 1426,
			'pid': 1421,
			'name': '长岛县',
			'merger_name': '中国,山东省,烟台市,长岛县',
			'level': 3
		}, {
			'id': 1427,
			'pid': 1421,
			'name': '龙口市',
			'merger_name': '中国,山东省,烟台市,龙口市',
			'level': 3
		}, {
			'id': 1428,
			'pid': 1421,
			'name': '莱阳市',
			'merger_name': '中国,山东省,烟台市,莱阳市',
			'level': 3
		}, {
			'id': 1429,
			'pid': 1421,
			'name': '莱州市',
			'merger_name': '中国,山东省,烟台市,莱州市',
			'level': 3
		}, {
			'id': 1430,
			'pid': 1421,
			'name': '蓬莱市',
			'merger_name': '中国,山东省,烟台市,蓬莱市',
			'level': 3
		}, {
			'id': 1431,
			'pid': 1421,
			'name': '招远市',
			'merger_name': '中国,山东省,烟台市,招远市',
			'level': 3
		}, {
			'id': 1432,
			'pid': 1421,
			'name': '栖霞市',
			'merger_name': '中国,山东省,烟台市,栖霞市',
			'level': 3
		}, {
			'id': 1433,
			'pid': 1421,
			'name': '海阳市',
			'merger_name': '中国,山东省,烟台市,海阳市',
			'level': 3
		}]
	}, {
		'id': 1434,
		'pid': 1375,
		'name': '潍坊市',
		'merger_name': '中国,山东省,潍坊市',
		'level': 2,
		'areas': [{
			'id': 1435,
			'pid': 1434,
			'name': '潍城区',
			'merger_name': '中国,山东省,潍坊市,潍城区',
			'level': 3
		}, {
			'id': 1436,
			'pid': 1434,
			'name': '寒亭区',
			'merger_name': '中国,山东省,潍坊市,寒亭区',
			'level': 3
		}, {
			'id': 1437,
			'pid': 1434,
			'name': '坊子区',
			'merger_name': '中国,山东省,潍坊市,坊子区',
			'level': 3
		}, {
			'id': 1438,
			'pid': 1434,
			'name': '奎文区',
			'merger_name': '中国,山东省,潍坊市,奎文区',
			'level': 3
		}, {
			'id': 1439,
			'pid': 1434,
			'name': '临朐县',
			'merger_name': '中国,山东省,潍坊市,临朐县',
			'level': 3
		}, {
			'id': 1440,
			'pid': 1434,
			'name': '昌乐县',
			'merger_name': '中国,山东省,潍坊市,昌乐县',
			'level': 3
		}, {
			'id': 1441,
			'pid': 1434,
			'name': '青州市',
			'merger_name': '中国,山东省,潍坊市,青州市',
			'level': 3
		}, {
			'id': 1442,
			'pid': 1434,
			'name': '诸城市',
			'merger_name': '中国,山东省,潍坊市,诸城市',
			'level': 3
		}, {
			'id': 1443,
			'pid': 1434,
			'name': '寿光市',
			'merger_name': '中国,山东省,潍坊市,寿光市',
			'level': 3
		}, {
			'id': 1444,
			'pid': 1434,
			'name': '安丘市',
			'merger_name': '中国,山东省,潍坊市,安丘市',
			'level': 3
		}, {
			'id': 1445,
			'pid': 1434,
			'name': '高密市',
			'merger_name': '中国,山东省,潍坊市,高密市',
			'level': 3
		}, {
			'id': 1446,
			'pid': 1434,
			'name': '昌邑市',
			'merger_name': '中国,山东省,潍坊市,昌邑市',
			'level': 3
		}]
	}, {
		'id': 1447,
		'pid': 1375,
		'name': '济宁市',
		'merger_name': '中国,山东省,济宁市',
		'level': 2,
		'areas': [{
			'id': 1448,
			'pid': 1447,
			'name': '任城区',
			'merger_name': '中国,山东省,济宁市,任城区',
			'level': 3
		}, {
			'id': 1449,
			'pid': 1447,
			'name': '兖州区',
			'merger_name': '中国,山东省,济宁市,兖州区',
			'level': 3
		}, {
			'id': 1450,
			'pid': 1447,
			'name': '微山县',
			'merger_name': '中国,山东省,济宁市,微山县',
			'level': 3
		}, {
			'id': 1451,
			'pid': 1447,
			'name': '鱼台县',
			'merger_name': '中国,山东省,济宁市,鱼台县',
			'level': 3
		}, {
			'id': 1452,
			'pid': 1447,
			'name': '金乡县',
			'merger_name': '中国,山东省,济宁市,金乡县',
			'level': 3
		}, {
			'id': 1453,
			'pid': 1447,
			'name': '嘉祥县',
			'merger_name': '中国,山东省,济宁市,嘉祥县',
			'level': 3
		}, {
			'id': 1454,
			'pid': 1447,
			'name': '汶上县',
			'merger_name': '中国,山东省,济宁市,汶上县',
			'level': 3
		}, {
			'id': 1455,
			'pid': 1447,
			'name': '泗水县',
			'merger_name': '中国,山东省,济宁市,泗水县',
			'level': 3
		}, {
			'id': 1456,
			'pid': 1447,
			'name': '梁山县',
			'merger_name': '中国,山东省,济宁市,梁山县',
			'level': 3
		}, {
			'id': 1457,
			'pid': 1447,
			'name': '曲阜市',
			'merger_name': '中国,山东省,济宁市,曲阜市',
			'level': 3
		}, {
			'id': 1458,
			'pid': 1447,
			'name': '邹城市',
			'merger_name': '中国,山东省,济宁市,邹城市',
			'level': 3
		}]
	}, {
		'id': 1459,
		'pid': 1375,
		'name': '泰安市',
		'merger_name': '中国,山东省,泰安市',
		'level': 2,
		'areas': [{
			'id': 1460,
			'pid': 1459,
			'name': '泰山区',
			'merger_name': '中国,山东省,泰安市,泰山区',
			'level': 3
		}, {
			'id': 1461,
			'pid': 1459,
			'name': '岱岳区',
			'merger_name': '中国,山东省,泰安市,岱岳区',
			'level': 3
		}, {
			'id': 1462,
			'pid': 1459,
			'name': '宁阳县',
			'merger_name': '中国,山东省,泰安市,宁阳县',
			'level': 3
		}, {
			'id': 1463,
			'pid': 1459,
			'name': '东平县',
			'merger_name': '中国,山东省,泰安市,东平县',
			'level': 3
		}, {
			'id': 1464,
			'pid': 1459,
			'name': '新泰市',
			'merger_name': '中国,山东省,泰安市,新泰市',
			'level': 3
		}, {
			'id': 1465,
			'pid': 1459,
			'name': '肥城市',
			'merger_name': '中国,山东省,泰安市,肥城市',
			'level': 3
		}]
	}, {
		'id': 1466,
		'pid': 1375,
		'name': '威海市',
		'merger_name': '中国,山东省,威海市',
		'level': 2,
		'areas': [{
			'id': 1467,
			'pid': 1466,
			'name': '环翠区',
			'merger_name': '中国,山东省,威海市,环翠区',
			'level': 3
		}, {
			'id': 1468,
			'pid': 1466,
			'name': '文登区',
			'merger_name': '中国,山东省,威海市,文登区',
			'level': 3
		}, {
			'id': 1469,
			'pid': 1466,
			'name': '荣成市',
			'merger_name': '中国,山东省,威海市,荣成市',
			'level': 3
		}, {
			'id': 1470,
			'pid': 1466,
			'name': '乳山市',
			'merger_name': '中国,山东省,威海市,乳山市',
			'level': 3
		}]
	}, {
		'id': 1471,
		'pid': 1375,
		'name': '日照市',
		'merger_name': '中国,山东省,日照市',
		'level': 2,
		'areas': [{
			'id': 1472,
			'pid': 1471,
			'name': '东港区',
			'merger_name': '中国,山东省,日照市,东港区',
			'level': 3
		}, {
			'id': 1473,
			'pid': 1471,
			'name': '岚山区',
			'merger_name': '中国,山东省,日照市,岚山区',
			'level': 3
		}, {
			'id': 1474,
			'pid': 1471,
			'name': '五莲县',
			'merger_name': '中国,山东省,日照市,五莲县',
			'level': 3
		}, {
			'id': 1475,
			'pid': 1471,
			'name': '莒县',
			'merger_name': '中国,山东省,日照市,莒县',
			'level': 3
		}]
	}, {
		'id': 1476,
		'pid': 1375,
		'name': '莱芜市',
		'merger_name': '中国,山东省,莱芜市',
		'level': 2,
		'areas': [{
			'id': 1477,
			'pid': 1476,
			'name': '莱城区',
			'merger_name': '中国,山东省,莱芜市,莱城区',
			'level': 3
		}, {
			'id': 1478,
			'pid': 1476,
			'name': '钢城区',
			'merger_name': '中国,山东省,莱芜市,钢城区',
			'level': 3
		}]
	}, {
		'id': 1479,
		'pid': 1375,
		'name': '临沂市',
		'merger_name': '中国,山东省,临沂市',
		'level': 2,
		'areas': [{
			'id': 1480,
			'pid': 1479,
			'name': '兰山区',
			'merger_name': '中国,山东省,临沂市,兰山区',
			'level': 3
		}, {
			'id': 1481,
			'pid': 1479,
			'name': '罗庄区',
			'merger_name': '中国,山东省,临沂市,罗庄区',
			'level': 3
		}, {
			'id': 1482,
			'pid': 1479,
			'name': '河东区',
			'merger_name': '中国,山东省,临沂市,河东区',
			'level': 3
		}, {
			'id': 1483,
			'pid': 1479,
			'name': '沂南县',
			'merger_name': '中国,山东省,临沂市,沂南县',
			'level': 3
		}, {
			'id': 1484,
			'pid': 1479,
			'name': '郯城县',
			'merger_name': '中国,山东省,临沂市,郯城县',
			'level': 3
		}, {
			'id': 1485,
			'pid': 1479,
			'name': '沂水县',
			'merger_name': '中国,山东省,临沂市,沂水县',
			'level': 3
		}, {
			'id': 1486,
			'pid': 1479,
			'name': '兰陵县',
			'merger_name': '中国,山东省,临沂市,兰陵县',
			'level': 3
		}, {
			'id': 1487,
			'pid': 1479,
			'name': '费县',
			'merger_name': '中国,山东省,临沂市,费县',
			'level': 3
		}, {
			'id': 1488,
			'pid': 1479,
			'name': '平邑县',
			'merger_name': '中国,山东省,临沂市,平邑县',
			'level': 3
		}, {
			'id': 1489,
			'pid': 1479,
			'name': '莒南县',
			'merger_name': '中国,山东省,临沂市,莒南县',
			'level': 3
		}, {
			'id': 1490,
			'pid': 1479,
			'name': '蒙阴县',
			'merger_name': '中国,山东省,临沂市,蒙阴县',
			'level': 3
		}, {
			'id': 1491,
			'pid': 1479,
			'name': '临沭县',
			'merger_name': '中国,山东省,临沂市,临沭县',
			'level': 3
		}]
	}, {
		'id': 1492,
		'pid': 1375,
		'name': '德州市',
		'merger_name': '中国,山东省,德州市',
		'level': 2,
		'areas': [{
			'id': 1493,
			'pid': 1492,
			'name': '德城区',
			'merger_name': '中国,山东省,德州市,德城区',
			'level': 3
		}, {
			'id': 1494,
			'pid': 1492,
			'name': '陵城区',
			'merger_name': '中国,山东省,德州市,陵城区',
			'level': 3
		}, {
			'id': 1495,
			'pid': 1492,
			'name': '宁津县',
			'merger_name': '中国,山东省,德州市,宁津县',
			'level': 3
		}, {
			'id': 1496,
			'pid': 1492,
			'name': '庆云县',
			'merger_name': '中国,山东省,德州市,庆云县',
			'level': 3
		}, {
			'id': 1497,
			'pid': 1492,
			'name': '临邑县',
			'merger_name': '中国,山东省,德州市,临邑县',
			'level': 3
		}, {
			'id': 1498,
			'pid': 1492,
			'name': '齐河县',
			'merger_name': '中国,山东省,德州市,齐河县',
			'level': 3
		}, {
			'id': 1499,
			'pid': 1492,
			'name': '平原县',
			'merger_name': '中国,山东省,德州市,平原县',
			'level': 3
		}, {
			'id': 1500,
			'pid': 1492,
			'name': '夏津县',
			'merger_name': '中国,山东省,德州市,夏津县',
			'level': 3
		}, {
			'id': 1501,
			'pid': 1492,
			'name': '武城县',
			'merger_name': '中国,山东省,德州市,武城县',
			'level': 3
		}, {
			'id': 1502,
			'pid': 1492,
			'name': '乐陵市',
			'merger_name': '中国,山东省,德州市,乐陵市',
			'level': 3
		}, {
			'id': 1503,
			'pid': 1492,
			'name': '禹城市',
			'merger_name': '中国,山东省,德州市,禹城市',
			'level': 3
		}]
	}, {
		'id': 1504,
		'pid': 1375,
		'name': '聊城市',
		'merger_name': '中国,山东省,聊城市',
		'level': 2,
		'areas': [{
			'id': 1505,
			'pid': 1504,
			'name': '东昌府区',
			'merger_name': '中国,山东省,聊城市,东昌府区',
			'level': 3
		}, {
			'id': 1506,
			'pid': 1504,
			'name': '阳谷县',
			'merger_name': '中国,山东省,聊城市,阳谷县',
			'level': 3
		}, {
			'id': 1507,
			'pid': 1504,
			'name': '莘县',
			'merger_name': '中国,山东省,聊城市,莘县',
			'level': 3
		}, {
			'id': 1508,
			'pid': 1504,
			'name': '茌平县',
			'merger_name': '中国,山东省,聊城市,茌平县',
			'level': 3
		}, {
			'id': 1509,
			'pid': 1504,
			'name': '东阿县',
			'merger_name': '中国,山东省,聊城市,东阿县',
			'level': 3
		}, {
			'id': 1510,
			'pid': 1504,
			'name': '冠县',
			'merger_name': '中国,山东省,聊城市,冠县',
			'level': 3
		}, {
			'id': 1511,
			'pid': 1504,
			'name': '高唐县',
			'merger_name': '中国,山东省,聊城市,高唐县',
			'level': 3
		}, {
			'id': 1512,
			'pid': 1504,
			'name': '临清市',
			'merger_name': '中国,山东省,聊城市,临清市',
			'level': 3
		}]
	}, {
		'id': 1513,
		'pid': 1375,
		'name': '滨州市',
		'merger_name': '中国,山东省,滨州市',
		'level': 2,
		'areas': [{
			'id': 1514,
			'pid': 1513,
			'name': '滨城区',
			'merger_name': '中国,山东省,滨州市,滨城区',
			'level': 3
		}, {
			'id': 1515,
			'pid': 1513,
			'name': '沾化区',
			'merger_name': '中国,山东省,滨州市,沾化区',
			'level': 3
		}, {
			'id': 1516,
			'pid': 1513,
			'name': '惠民县',
			'merger_name': '中国,山东省,滨州市,惠民县',
			'level': 3
		}, {
			'id': 1517,
			'pid': 1513,
			'name': '阳信县',
			'merger_name': '中国,山东省,滨州市,阳信县',
			'level': 3
		}, {
			'id': 1518,
			'pid': 1513,
			'name': '无棣县',
			'merger_name': '中国,山东省,滨州市,无棣县',
			'level': 3
		}, {
			'id': 1519,
			'pid': 1513,
			'name': '博兴县',
			'merger_name': '中国,山东省,滨州市,博兴县',
			'level': 3
		}, {
			'id': 1520,
			'pid': 1513,
			'name': '邹平县',
			'merger_name': '中国,山东省,滨州市,邹平县',
			'level': 3
		}, {
			'id': 1521,
			'pid': 1513,
			'name': '北海新区',
			'merger_name': '中国,山东省,滨州市,北海新区',
			'level': 3
		}]
	}, {
		'id': 1522,
		'pid': 1375,
		'name': '菏泽市',
		'merger_name': '中国,山东省,菏泽市',
		'level': 2,
		'areas': [{
			'id': 1523,
			'pid': 1522,
			'name': '牡丹区',
			'merger_name': '中国,山东省,菏泽市,牡丹区',
			'level': 3
		}, {
			'id': 1524,
			'pid': 1522,
			'name': '曹县',
			'merger_name': '中国,山东省,菏泽市,曹县',
			'level': 3
		}, {
			'id': 1525,
			'pid': 1522,
			'name': '单县',
			'merger_name': '中国,山东省,菏泽市,单县',
			'level': 3
		}, {
			'id': 1526,
			'pid': 1522,
			'name': '成武县',
			'merger_name': '中国,山东省,菏泽市,成武县',
			'level': 3
		}, {
			'id': 1527,
			'pid': 1522,
			'name': '巨野县',
			'merger_name': '中国,山东省,菏泽市,巨野县',
			'level': 3
		}, {
			'id': 1528,
			'pid': 1522,
			'name': '郓城县',
			'merger_name': '中国,山东省,菏泽市,郓城县',
			'level': 3
		}, {
			'id': 1529,
			'pid': 1522,
			'name': '鄄城县',
			'merger_name': '中国,山东省,菏泽市,鄄城县',
			'level': 3
		}, {
			'id': 1530,
			'pid': 1522,
			'name': '定陶县',
			'merger_name': '中国,山东省,菏泽市,定陶县',
			'level': 3
		}, {
			'id': 1531,
			'pid': 1522,
			'name': '东明县',
			'merger_name': '中国,山东省,菏泽市,东明县',
			'level': 3
		}]
	}]
}, {
	'id': 1532,
	'pid': 0,
	'name': '河南省',
	'merger_name': '中国,河南省',
	'level': 1,
	'cities': [{
		'id': 1533,
		'pid': 1532,
		'name': '郑州市',
		'merger_name': '中国,河南省,郑州市',
		'level': 2,
		'areas': [{
			'id': 1534,
			'pid': 1533,
			'name': '中原区',
			'merger_name': '中国,河南省,郑州市,中原区',
			'level': 3
		}, {
			'id': 1535,
			'pid': 1533,
			'name': '二七区',
			'merger_name': '中国,河南省,郑州市,二七区',
			'level': 3
		}, {
			'id': 1536,
			'pid': 1533,
			'name': '管城回族区',
			'merger_name': '中国,河南省,郑州市,管城回族区',
			'level': 3
		}, {
			'id': 1537,
			'pid': 1533,
			'name': '金水区',
			'merger_name': '中国,河南省,郑州市,金水区',
			'level': 3
		}, {
			'id': 1538,
			'pid': 1533,
			'name': '上街区',
			'merger_name': '中国,河南省,郑州市,上街区',
			'level': 3
		}, {
			'id': 1539,
			'pid': 1533,
			'name': '惠济区',
			'merger_name': '中国,河南省,郑州市,惠济区',
			'level': 3
		}, {
			'id': 1540,
			'pid': 1533,
			'name': '中牟县',
			'merger_name': '中国,河南省,郑州市,中牟县',
			'level': 3
		}, {
			'id': 1541,
			'pid': 1533,
			'name': '巩义市',
			'merger_name': '中国,河南省,郑州市,巩义市',
			'level': 3
		}, {
			'id': 1542,
			'pid': 1533,
			'name': '荥阳市',
			'merger_name': '中国,河南省,郑州市,荥阳市',
			'level': 3
		}, {
			'id': 1543,
			'pid': 1533,
			'name': '新密市',
			'merger_name': '中国,河南省,郑州市,新密市',
			'level': 3
		}, {
			'id': 1544,
			'pid': 1533,
			'name': '新郑市',
			'merger_name': '中国,河南省,郑州市,新郑市',
			'level': 3
		}, {
			'id': 1545,
			'pid': 1533,
			'name': '登封市',
			'merger_name': '中国,河南省,郑州市,登封市',
			'level': 3
		}]
	}, {
		'id': 1546,
		'pid': 1532,
		'name': '开封市',
		'merger_name': '中国,河南省,开封市',
		'level': 2,
		'areas': [{
			'id': 1547,
			'pid': 1546,
			'name': '龙亭区',
			'merger_name': '中国,河南省,开封市,龙亭区',
			'level': 3
		}, {
			'id': 1548,
			'pid': 1546,
			'name': '顺河回族区',
			'merger_name': '中国,河南省,开封市,顺河回族区',
			'level': 3
		}, {
			'id': 1549,
			'pid': 1546,
			'name': '鼓楼区',
			'merger_name': '中国,河南省,开封市,鼓楼区',
			'level': 3
		}, {
			'id': 1550,
			'pid': 1546,
			'name': '禹王台区',
			'merger_name': '中国,河南省,开封市,禹王台区',
			'level': 3
		}, {
			'id': 1551,
			'pid': 1546,
			'name': '祥符区',
			'merger_name': '中国,河南省,开封市,祥符区',
			'level': 3
		}, {
			'id': 1552,
			'pid': 1546,
			'name': '杞县',
			'merger_name': '中国,河南省,开封市,杞县',
			'level': 3
		}, {
			'id': 1553,
			'pid': 1546,
			'name': '通许县',
			'merger_name': '中国,河南省,开封市,通许县',
			'level': 3
		}, {
			'id': 1554,
			'pid': 1546,
			'name': '尉氏县',
			'merger_name': '中国,河南省,开封市,尉氏县',
			'level': 3
		}, {
			'id': 1555,
			'pid': 1546,
			'name': '兰考县',
			'merger_name': '中国,河南省,开封市,兰考县',
			'level': 3
		}]
	}, {
		'id': 1556,
		'pid': 1532,
		'name': '洛阳市',
		'merger_name': '中国,河南省,洛阳市',
		'level': 2,
		'areas': [{
			'id': 1557,
			'pid': 1556,
			'name': '老城区',
			'merger_name': '中国,河南省,洛阳市,老城区',
			'level': 3
		}, {
			'id': 1558,
			'pid': 1556,
			'name': '西工区',
			'merger_name': '中国,河南省,洛阳市,西工区',
			'level': 3
		}, {
			'id': 1559,
			'pid': 1556,
			'name': '瀍河回族区',
			'merger_name': '中国,河南省,洛阳市,瀍河回族区',
			'level': 3
		}, {
			'id': 1560,
			'pid': 1556,
			'name': '涧西区',
			'merger_name': '中国,河南省,洛阳市,涧西区',
			'level': 3
		}, {
			'id': 1561,
			'pid': 1556,
			'name': '吉利区',
			'merger_name': '中国,河南省,洛阳市,吉利区',
			'level': 3
		}, {
			'id': 1562,
			'pid': 1556,
			'name': '洛龙区',
			'merger_name': '中国,河南省,洛阳市,洛龙区',
			'level': 3
		}, {
			'id': 1563,
			'pid': 1556,
			'name': '孟津县',
			'merger_name': '中国,河南省,洛阳市,孟津县',
			'level': 3
		}, {
			'id': 1564,
			'pid': 1556,
			'name': '新安县',
			'merger_name': '中国,河南省,洛阳市,新安县',
			'level': 3
		}, {
			'id': 1565,
			'pid': 1556,
			'name': '栾川县',
			'merger_name': '中国,河南省,洛阳市,栾川县',
			'level': 3
		}, {
			'id': 1566,
			'pid': 1556,
			'name': '嵩县',
			'merger_name': '中国,河南省,洛阳市,嵩县',
			'level': 3
		}, {
			'id': 1567,
			'pid': 1556,
			'name': '汝阳县',
			'merger_name': '中国,河南省,洛阳市,汝阳县',
			'level': 3
		}, {
			'id': 1568,
			'pid': 1556,
			'name': '宜阳县',
			'merger_name': '中国,河南省,洛阳市,宜阳县',
			'level': 3
		}, {
			'id': 1569,
			'pid': 1556,
			'name': '洛宁县',
			'merger_name': '中国,河南省,洛阳市,洛宁县',
			'level': 3
		}, {
			'id': 1570,
			'pid': 1556,
			'name': '伊川县',
			'merger_name': '中国,河南省,洛阳市,伊川县',
			'level': 3
		}, {
			'id': 1571,
			'pid': 1556,
			'name': '偃师市',
			'merger_name': '中国,河南省,洛阳市,偃师市',
			'level': 3
		}]
	}, {
		'id': 1572,
		'pid': 1532,
		'name': '平顶山市',
		'merger_name': '中国,河南省,平顶山市',
		'level': 2,
		'areas': [{
			'id': 1573,
			'pid': 1572,
			'name': '新华区',
			'merger_name': '中国,河南省,平顶山市,新华区',
			'level': 3
		}, {
			'id': 1574,
			'pid': 1572,
			'name': '卫东区',
			'merger_name': '中国,河南省,平顶山市,卫东区',
			'level': 3
		}, {
			'id': 1575,
			'pid': 1572,
			'name': '石龙区',
			'merger_name': '中国,河南省,平顶山市,石龙区',
			'level': 3
		}, {
			'id': 1576,
			'pid': 1572,
			'name': '湛河区',
			'merger_name': '中国,河南省,平顶山市,湛河区',
			'level': 3
		}, {
			'id': 1577,
			'pid': 1572,
			'name': '宝丰县',
			'merger_name': '中国,河南省,平顶山市,宝丰县',
			'level': 3
		}, {
			'id': 1578,
			'pid': 1572,
			'name': '叶县',
			'merger_name': '中国,河南省,平顶山市,叶县',
			'level': 3
		}, {
			'id': 1579,
			'pid': 1572,
			'name': '鲁山县',
			'merger_name': '中国,河南省,平顶山市,鲁山县',
			'level': 3
		}, {
			'id': 1580,
			'pid': 1572,
			'name': '郏县',
			'merger_name': '中国,河南省,平顶山市,郏县',
			'level': 3
		}, {
			'id': 1581,
			'pid': 1572,
			'name': '舞钢市',
			'merger_name': '中国,河南省,平顶山市,舞钢市',
			'level': 3
		}, {
			'id': 1582,
			'pid': 1572,
			'name': '汝州市',
			'merger_name': '中国,河南省,平顶山市,汝州市',
			'level': 3
		}]
	}, {
		'id': 1583,
		'pid': 1532,
		'name': '安阳市',
		'merger_name': '中国,河南省,安阳市',
		'level': 2,
		'areas': [{
			'id': 1584,
			'pid': 1583,
			'name': '文峰区',
			'merger_name': '中国,河南省,安阳市,文峰区',
			'level': 3
		}, {
			'id': 1585,
			'pid': 1583,
			'name': '北关区',
			'merger_name': '中国,河南省,安阳市,北关区',
			'level': 3
		}, {
			'id': 1586,
			'pid': 1583,
			'name': '殷都区',
			'merger_name': '中国,河南省,安阳市,殷都区',
			'level': 3
		}, {
			'id': 1587,
			'pid': 1583,
			'name': '龙安区',
			'merger_name': '中国,河南省,安阳市,龙安区',
			'level': 3
		}, {
			'id': 1588,
			'pid': 1583,
			'name': '安阳县',
			'merger_name': '中国,河南省,安阳市,安阳县',
			'level': 3
		}, {
			'id': 1589,
			'pid': 1583,
			'name': '汤阴县',
			'merger_name': '中国,河南省,安阳市,汤阴县',
			'level': 3
		}, {
			'id': 1590,
			'pid': 1583,
			'name': '滑县',
			'merger_name': '中国,河南省,安阳市,滑县',
			'level': 3
		}, {
			'id': 1591,
			'pid': 1583,
			'name': '内黄县',
			'merger_name': '中国,河南省,安阳市,内黄县',
			'level': 3
		}, {
			'id': 1592,
			'pid': 1583,
			'name': '林州市',
			'merger_name': '中国,河南省,安阳市,林州市',
			'level': 3
		}]
	}, {
		'id': 1593,
		'pid': 1532,
		'name': '鹤壁市',
		'merger_name': '中国,河南省,鹤壁市',
		'level': 2,
		'areas': [{
			'id': 1594,
			'pid': 1593,
			'name': '鹤山区',
			'merger_name': '中国,河南省,鹤壁市,鹤山区',
			'level': 3
		}, {
			'id': 1595,
			'pid': 1593,
			'name': '山城区',
			'merger_name': '中国,河南省,鹤壁市,山城区',
			'level': 3
		}, {
			'id': 1596,
			'pid': 1593,
			'name': '淇滨区',
			'merger_name': '中国,河南省,鹤壁市,淇滨区',
			'level': 3
		}, {
			'id': 1597,
			'pid': 1593,
			'name': '浚县',
			'merger_name': '中国,河南省,鹤壁市,浚县',
			'level': 3
		}, {
			'id': 1598,
			'pid': 1593,
			'name': '淇县',
			'merger_name': '中国,河南省,鹤壁市,淇县',
			'level': 3
		}]
	}, {
		'id': 1599,
		'pid': 1532,
		'name': '新乡市',
		'merger_name': '中国,河南省,新乡市',
		'level': 2,
		'areas': [{
			'id': 1600,
			'pid': 1599,
			'name': '红旗区',
			'merger_name': '中国,河南省,新乡市,红旗区',
			'level': 3
		}, {
			'id': 1601,
			'pid': 1599,
			'name': '卫滨区',
			'merger_name': '中国,河南省,新乡市,卫滨区',
			'level': 3
		}, {
			'id': 1602,
			'pid': 1599,
			'name': '凤泉区',
			'merger_name': '中国,河南省,新乡市,凤泉区',
			'level': 3
		}, {
			'id': 1603,
			'pid': 1599,
			'name': '牧野区',
			'merger_name': '中国,河南省,新乡市,牧野区',
			'level': 3
		}, {
			'id': 1604,
			'pid': 1599,
			'name': '新乡县',
			'merger_name': '中国,河南省,新乡市,新乡县',
			'level': 3
		}, {
			'id': 1605,
			'pid': 1599,
			'name': '获嘉县',
			'merger_name': '中国,河南省,新乡市,获嘉县',
			'level': 3
		}, {
			'id': 1606,
			'pid': 1599,
			'name': '原阳县',
			'merger_name': '中国,河南省,新乡市,原阳县',
			'level': 3
		}, {
			'id': 1607,
			'pid': 1599,
			'name': '延津县',
			'merger_name': '中国,河南省,新乡市,延津县',
			'level': 3
		}, {
			'id': 1608,
			'pid': 1599,
			'name': '封丘县',
			'merger_name': '中国,河南省,新乡市,封丘县',
			'level': 3
		}, {
			'id': 1609,
			'pid': 1599,
			'name': '长垣县',
			'merger_name': '中国,河南省,新乡市,长垣县',
			'level': 3
		}, {
			'id': 1610,
			'pid': 1599,
			'name': '卫辉市',
			'merger_name': '中国,河南省,新乡市,卫辉市',
			'level': 3
		}, {
			'id': 1611,
			'pid': 1599,
			'name': '辉县市',
			'merger_name': '中国,河南省,新乡市,辉县市',
			'level': 3
		}]
	}, {
		'id': 1612,
		'pid': 1532,
		'name': '焦作市',
		'merger_name': '中国,河南省,焦作市',
		'level': 2,
		'areas': [{
			'id': 1613,
			'pid': 1612,
			'name': '解放区',
			'merger_name': '中国,河南省,焦作市,解放区',
			'level': 3
		}, {
			'id': 1614,
			'pid': 1612,
			'name': '中站区',
			'merger_name': '中国,河南省,焦作市,中站区',
			'level': 3
		}, {
			'id': 1615,
			'pid': 1612,
			'name': '马村区',
			'merger_name': '中国,河南省,焦作市,马村区',
			'level': 3
		}, {
			'id': 1616,
			'pid': 1612,
			'name': '山阳区',
			'merger_name': '中国,河南省,焦作市,山阳区',
			'level': 3
		}, {
			'id': 1617,
			'pid': 1612,
			'name': '修武县',
			'merger_name': '中国,河南省,焦作市,修武县',
			'level': 3
		}, {
			'id': 1618,
			'pid': 1612,
			'name': '博爱县',
			'merger_name': '中国,河南省,焦作市,博爱县',
			'level': 3
		}, {
			'id': 1619,
			'pid': 1612,
			'name': '武陟县',
			'merger_name': '中国,河南省,焦作市,武陟县',
			'level': 3
		}, {
			'id': 1620,
			'pid': 1612,
			'name': '温县',
			'merger_name': '中国,河南省,焦作市,温县',
			'level': 3
		}, {
			'id': 1621,
			'pid': 1612,
			'name': '沁阳市',
			'merger_name': '中国,河南省,焦作市,沁阳市',
			'level': 3
		}, {
			'id': 1622,
			'pid': 1612,
			'name': '孟州市',
			'merger_name': '中国,河南省,焦作市,孟州市',
			'level': 3
		}]
	}, {
		'id': 1623,
		'pid': 1532,
		'name': '濮阳市',
		'merger_name': '中国,河南省,濮阳市',
		'level': 2,
		'areas': [{
			'id': 1624,
			'pid': 1623,
			'name': '华龙区',
			'merger_name': '中国,河南省,濮阳市,华龙区',
			'level': 3
		}, {
			'id': 1625,
			'pid': 1623,
			'name': '清丰县',
			'merger_name': '中国,河南省,濮阳市,清丰县',
			'level': 3
		}, {
			'id': 1626,
			'pid': 1623,
			'name': '南乐县',
			'merger_name': '中国,河南省,濮阳市,南乐县',
			'level': 3
		}, {
			'id': 1627,
			'pid': 1623,
			'name': '范县',
			'merger_name': '中国,河南省,濮阳市,范县',
			'level': 3
		}, {
			'id': 1628,
			'pid': 1623,
			'name': '台前县',
			'merger_name': '中国,河南省,濮阳市,台前县',
			'level': 3
		}, {
			'id': 1629,
			'pid': 1623,
			'name': '濮阳县',
			'merger_name': '中国,河南省,濮阳市,濮阳县',
			'level': 3
		}]
	}, {
		'id': 1630,
		'pid': 1532,
		'name': '许昌市',
		'merger_name': '中国,河南省,许昌市',
		'level': 2,
		'areas': [{
			'id': 1631,
			'pid': 1630,
			'name': '魏都区',
			'merger_name': '中国,河南省,许昌市,魏都区',
			'level': 3
		}, {
			'id': 1632,
			'pid': 1630,
			'name': '许昌县',
			'merger_name': '中国,河南省,许昌市,许昌县',
			'level': 3
		}, {
			'id': 1633,
			'pid': 1630,
			'name': '鄢陵县',
			'merger_name': '中国,河南省,许昌市,鄢陵县',
			'level': 3
		}, {
			'id': 1634,
			'pid': 1630,
			'name': '襄城县',
			'merger_name': '中国,河南省,许昌市,襄城县',
			'level': 3
		}, {
			'id': 1635,
			'pid': 1630,
			'name': '禹州市',
			'merger_name': '中国,河南省,许昌市,禹州市',
			'level': 3
		}, {
			'id': 1636,
			'pid': 1630,
			'name': '长葛市',
			'merger_name': '中国,河南省,许昌市,长葛市',
			'level': 3
		}]
	}, {
		'id': 1637,
		'pid': 1532,
		'name': '漯河市',
		'merger_name': '中国,河南省,漯河市',
		'level': 2,
		'areas': [{
			'id': 1638,
			'pid': 1637,
			'name': '源汇区',
			'merger_name': '中国,河南省,漯河市,源汇区',
			'level': 3
		}, {
			'id': 1639,
			'pid': 1637,
			'name': '郾城区',
			'merger_name': '中国,河南省,漯河市,郾城区',
			'level': 3
		}, {
			'id': 1640,
			'pid': 1637,
			'name': '召陵区',
			'merger_name': '中国,河南省,漯河市,召陵区',
			'level': 3
		}, {
			'id': 1641,
			'pid': 1637,
			'name': '舞阳县',
			'merger_name': '中国,河南省,漯河市,舞阳县',
			'level': 3
		}, {
			'id': 1642,
			'pid': 1637,
			'name': '临颍县',
			'merger_name': '中国,河南省,漯河市,临颍县',
			'level': 3
		}]
	}, {
		'id': 1643,
		'pid': 1532,
		'name': '三门峡市',
		'merger_name': '中国,河南省,三门峡市',
		'level': 2,
		'areas': [{
			'id': 1644,
			'pid': 1643,
			'name': '湖滨区',
			'merger_name': '中国,河南省,三门峡市,湖滨区',
			'level': 3
		}, {
			'id': 1645,
			'pid': 1643,
			'name': '渑池县',
			'merger_name': '中国,河南省,三门峡市,渑池县',
			'level': 3
		}, {
			'id': 1646,
			'pid': 1643,
			'name': '陕县',
			'merger_name': '中国,河南省,三门峡市,陕县',
			'level': 3
		}, {
			'id': 1647,
			'pid': 1643,
			'name': '卢氏县',
			'merger_name': '中国,河南省,三门峡市,卢氏县',
			'level': 3
		}, {
			'id': 1648,
			'pid': 1643,
			'name': '义马市',
			'merger_name': '中国,河南省,三门峡市,义马市',
			'level': 3
		}, {
			'id': 1649,
			'pid': 1643,
			'name': '灵宝市',
			'merger_name': '中国,河南省,三门峡市,灵宝市',
			'level': 3
		}]
	}, {
		'id': 1650,
		'pid': 1532,
		'name': '南阳市',
		'merger_name': '中国,河南省,南阳市',
		'level': 2,
		'areas': [{
			'id': 1651,
			'pid': 1650,
			'name': '宛城区',
			'merger_name': '中国,河南省,南阳市,宛城区',
			'level': 3
		}, {
			'id': 1652,
			'pid': 1650,
			'name': '卧龙区',
			'merger_name': '中国,河南省,南阳市,卧龙区',
			'level': 3
		}, {
			'id': 1653,
			'pid': 1650,
			'name': '南召县',
			'merger_name': '中国,河南省,南阳市,南召县',
			'level': 3
		}, {
			'id': 1654,
			'pid': 1650,
			'name': '方城县',
			'merger_name': '中国,河南省,南阳市,方城县',
			'level': 3
		}, {
			'id': 1655,
			'pid': 1650,
			'name': '西峡县',
			'merger_name': '中国,河南省,南阳市,西峡县',
			'level': 3
		}, {
			'id': 1656,
			'pid': 1650,
			'name': '镇平县',
			'merger_name': '中国,河南省,南阳市,镇平县',
			'level': 3
		}, {
			'id': 1657,
			'pid': 1650,
			'name': '内乡县',
			'merger_name': '中国,河南省,南阳市,内乡县',
			'level': 3
		}, {
			'id': 1658,
			'pid': 1650,
			'name': '淅川县',
			'merger_name': '中国,河南省,南阳市,淅川县',
			'level': 3
		}, {
			'id': 1659,
			'pid': 1650,
			'name': '社旗县',
			'merger_name': '中国,河南省,南阳市,社旗县',
			'level': 3
		}, {
			'id': 1660,
			'pid': 1650,
			'name': '唐河县',
			'merger_name': '中国,河南省,南阳市,唐河县',
			'level': 3
		}, {
			'id': 1661,
			'pid': 1650,
			'name': '新野县',
			'merger_name': '中国,河南省,南阳市,新野县',
			'level': 3
		}, {
			'id': 1662,
			'pid': 1650,
			'name': '桐柏县',
			'merger_name': '中国,河南省,南阳市,桐柏县',
			'level': 3
		}, {
			'id': 1663,
			'pid': 1650,
			'name': '邓州市',
			'merger_name': '中国,河南省,南阳市,邓州市',
			'level': 3
		}]
	}, {
		'id': 1664,
		'pid': 1532,
		'name': '商丘市',
		'merger_name': '中国,河南省,商丘市',
		'level': 2,
		'areas': [{
			'id': 1665,
			'pid': 1664,
			'name': '梁园区',
			'merger_name': '中国,河南省,商丘市,梁园区',
			'level': 3
		}, {
			'id': 1666,
			'pid': 1664,
			'name': '睢阳区',
			'merger_name': '中国,河南省,商丘市,睢阳区',
			'level': 3
		}, {
			'id': 1667,
			'pid': 1664,
			'name': '民权县',
			'merger_name': '中国,河南省,商丘市,民权县',
			'level': 3
		}, {
			'id': 1668,
			'pid': 1664,
			'name': '睢县',
			'merger_name': '中国,河南省,商丘市,睢县',
			'level': 3
		}, {
			'id': 1669,
			'pid': 1664,
			'name': '宁陵县',
			'merger_name': '中国,河南省,商丘市,宁陵县',
			'level': 3
		}, {
			'id': 1670,
			'pid': 1664,
			'name': '柘城县',
			'merger_name': '中国,河南省,商丘市,柘城县',
			'level': 3
		}, {
			'id': 1671,
			'pid': 1664,
			'name': '虞城县',
			'merger_name': '中国,河南省,商丘市,虞城县',
			'level': 3
		}, {
			'id': 1672,
			'pid': 1664,
			'name': '夏邑县',
			'merger_name': '中国,河南省,商丘市,夏邑县',
			'level': 3
		}, {
			'id': 1673,
			'pid': 1664,
			'name': '永城市',
			'merger_name': '中国,河南省,商丘市,永城市',
			'level': 3
		}]
	}, {
		'id': 1674,
		'pid': 1532,
		'name': '信阳市',
		'merger_name': '中国,河南省,信阳市',
		'level': 2,
		'areas': [{
			'id': 1675,
			'pid': 1674,
			'name': '浉河区',
			'merger_name': '中国,河南省,信阳市,浉河区',
			'level': 3
		}, {
			'id': 1676,
			'pid': 1674,
			'name': '平桥区',
			'merger_name': '中国,河南省,信阳市,平桥区',
			'level': 3
		}, {
			'id': 1677,
			'pid': 1674,
			'name': '罗山县',
			'merger_name': '中国,河南省,信阳市,罗山县',
			'level': 3
		}, {
			'id': 1678,
			'pid': 1674,
			'name': '光山县',
			'merger_name': '中国,河南省,信阳市,光山县',
			'level': 3
		}, {
			'id': 1679,
			'pid': 1674,
			'name': '新县',
			'merger_name': '中国,河南省,信阳市,新县',
			'level': 3
		}, {
			'id': 1680,
			'pid': 1674,
			'name': '商城县',
			'merger_name': '中国,河南省,信阳市,商城县',
			'level': 3
		}, {
			'id': 1681,
			'pid': 1674,
			'name': '固始县',
			'merger_name': '中国,河南省,信阳市,固始县',
			'level': 3
		}, {
			'id': 1682,
			'pid': 1674,
			'name': '潢川县',
			'merger_name': '中国,河南省,信阳市,潢川县',
			'level': 3
		}, {
			'id': 1683,
			'pid': 1674,
			'name': '淮滨县',
			'merger_name': '中国,河南省,信阳市,淮滨县',
			'level': 3
		}, {
			'id': 1684,
			'pid': 1674,
			'name': '息县',
			'merger_name': '中国,河南省,信阳市,息县',
			'level': 3
		}]
	}, {
		'id': 1685,
		'pid': 1532,
		'name': '周口市',
		'merger_name': '中国,河南省,周口市',
		'level': 2,
		'areas': [{
			'id': 1686,
			'pid': 1685,
			'name': '川汇区',
			'merger_name': '中国,河南省,周口市,川汇区',
			'level': 3
		}, {
			'id': 1687,
			'pid': 1685,
			'name': '扶沟县',
			'merger_name': '中国,河南省,周口市,扶沟县',
			'level': 3
		}, {
			'id': 1688,
			'pid': 1685,
			'name': '西华县',
			'merger_name': '中国,河南省,周口市,西华县',
			'level': 3
		}, {
			'id': 1689,
			'pid': 1685,
			'name': '商水县',
			'merger_name': '中国,河南省,周口市,商水县',
			'level': 3
		}, {
			'id': 1690,
			'pid': 1685,
			'name': '沈丘县',
			'merger_name': '中国,河南省,周口市,沈丘县',
			'level': 3
		}, {
			'id': 1691,
			'pid': 1685,
			'name': '郸城县',
			'merger_name': '中国,河南省,周口市,郸城县',
			'level': 3
		}, {
			'id': 1692,
			'pid': 1685,
			'name': '淮阳县',
			'merger_name': '中国,河南省,周口市,淮阳县',
			'level': 3
		}, {
			'id': 1693,
			'pid': 1685,
			'name': '太康县',
			'merger_name': '中国,河南省,周口市,太康县',
			'level': 3
		}, {
			'id': 1694,
			'pid': 1685,
			'name': '鹿邑县',
			'merger_name': '中国,河南省,周口市,鹿邑县',
			'level': 3
		}, {
			'id': 1695,
			'pid': 1685,
			'name': '项城市',
			'merger_name': '中国,河南省,周口市,项城市',
			'level': 3
		}]
	}, {
		'id': 1696,
		'pid': 1532,
		'name': '驻马店市',
		'merger_name': '中国,河南省,驻马店市',
		'level': 2,
		'areas': [{
			'id': 1697,
			'pid': 1696,
			'name': '驿城区',
			'merger_name': '中国,河南省,驻马店市,驿城区',
			'level': 3
		}, {
			'id': 1698,
			'pid': 1696,
			'name': '西平县',
			'merger_name': '中国,河南省,驻马店市,西平县',
			'level': 3
		}, {
			'id': 1699,
			'pid': 1696,
			'name': '上蔡县',
			'merger_name': '中国,河南省,驻马店市,上蔡县',
			'level': 3
		}, {
			'id': 1700,
			'pid': 1696,
			'name': '平舆县',
			'merger_name': '中国,河南省,驻马店市,平舆县',
			'level': 3
		}, {
			'id': 1701,
			'pid': 1696,
			'name': '正阳县',
			'merger_name': '中国,河南省,驻马店市,正阳县',
			'level': 3
		}, {
			'id': 1702,
			'pid': 1696,
			'name': '确山县',
			'merger_name': '中国,河南省,驻马店市,确山县',
			'level': 3
		}, {
			'id': 1703,
			'pid': 1696,
			'name': '泌阳县',
			'merger_name': '中国,河南省,驻马店市,泌阳县',
			'level': 3
		}, {
			'id': 1704,
			'pid': 1696,
			'name': '汝南县',
			'merger_name': '中国,河南省,驻马店市,汝南县',
			'level': 3
		}, {
			'id': 1705,
			'pid': 1696,
			'name': '遂平县',
			'merger_name': '中国,河南省,驻马店市,遂平县',
			'level': 3
		}, {
			'id': 1706,
			'pid': 1696,
			'name': '新蔡县',
			'merger_name': '中国,河南省,驻马店市,新蔡县',
			'level': 3
		}]
	}, {
		'id': 1707,
		'pid': 1532,
		'name': '直辖县级',
		'merger_name': '中国,河南省,直辖县级',
		'level': 2,
		'areas': [{
			'id': 1708,
			'pid': 1707,
			'name': '济源市',
			'merger_name': '中国,河南省,直辖县级,济源市',
			'level': 3
		}]
	}]
}, {
	'id': 1709,
	'pid': 0,
	'name': '湖北省',
	'merger_name': '中国,湖北省',
	'level': 1,
	'cities': [{
		'id': 1710,
		'pid': 1709,
		'name': '武汉市',
		'merger_name': '中国,湖北省,武汉市',
		'level': 2,
		'areas': [{
			'id': 1711,
			'pid': 1710,
			'name': '江岸区',
			'merger_name': '中国,湖北省,武汉市,江岸区',
			'level': 3
		}, {
			'id': 1712,
			'pid': 1710,
			'name': '江汉区',
			'merger_name': '中国,湖北省,武汉市,江汉区',
			'level': 3
		}, {
			'id': 1713,
			'pid': 1710,
			'name': '硚口区',
			'merger_name': '中国,湖北省,武汉市,硚口区',
			'level': 3
		}, {
			'id': 1714,
			'pid': 1710,
			'name': '汉阳区',
			'merger_name': '中国,湖北省,武汉市,汉阳区',
			'level': 3
		}, {
			'id': 1715,
			'pid': 1710,
			'name': '武昌区',
			'merger_name': '中国,湖北省,武汉市,武昌区',
			'level': 3
		}, {
			'id': 1716,
			'pid': 1710,
			'name': '青山区',
			'merger_name': '中国,湖北省,武汉市,青山区',
			'level': 3
		}, {
			'id': 1717,
			'pid': 1710,
			'name': '洪山区',
			'merger_name': '中国,湖北省,武汉市,洪山区',
			'level': 3
		}, {
			'id': 1718,
			'pid': 1710,
			'name': '东西湖区',
			'merger_name': '中国,湖北省,武汉市,东西湖区',
			'level': 3
		}, {
			'id': 1719,
			'pid': 1710,
			'name': '汉南区',
			'merger_name': '中国,湖北省,武汉市,汉南区',
			'level': 3
		}, {
			'id': 1720,
			'pid': 1710,
			'name': '蔡甸区',
			'merger_name': '中国,湖北省,武汉市,蔡甸区',
			'level': 3
		}, {
			'id': 1721,
			'pid': 1710,
			'name': '江夏区',
			'merger_name': '中国,湖北省,武汉市,江夏区',
			'level': 3
		}, {
			'id': 1722,
			'pid': 1710,
			'name': '黄陂区',
			'merger_name': '中国,湖北省,武汉市,黄陂区',
			'level': 3
		}, {
			'id': 1723,
			'pid': 1710,
			'name': '新洲区',
			'merger_name': '中国,湖北省,武汉市,新洲区',
			'level': 3
		}]
	}, {
		'id': 1724,
		'pid': 1709,
		'name': '黄石市',
		'merger_name': '中国,湖北省,黄石市',
		'level': 2,
		'areas': [{
			'id': 1725,
			'pid': 1724,
			'name': '黄石港区',
			'merger_name': '中国,湖北省,黄石市,黄石港区',
			'level': 3
		}, {
			'id': 1726,
			'pid': 1724,
			'name': '西塞山区',
			'merger_name': '中国,湖北省,黄石市,西塞山区',
			'level': 3
		}, {
			'id': 1727,
			'pid': 1724,
			'name': '下陆区',
			'merger_name': '中国,湖北省,黄石市,下陆区',
			'level': 3
		}, {
			'id': 1728,
			'pid': 1724,
			'name': '铁山区',
			'merger_name': '中国,湖北省,黄石市,铁山区',
			'level': 3
		}, {
			'id': 1729,
			'pid': 1724,
			'name': '阳新县',
			'merger_name': '中国,湖北省,黄石市,阳新县',
			'level': 3
		}, {
			'id': 1730,
			'pid': 1724,
			'name': '大冶市',
			'merger_name': '中国,湖北省,黄石市,大冶市',
			'level': 3
		}]
	}, {
		'id': 1731,
		'pid': 1709,
		'name': '十堰市',
		'merger_name': '中国,湖北省,十堰市',
		'level': 2,
		'areas': [{
			'id': 1732,
			'pid': 1731,
			'name': '茅箭区',
			'merger_name': '中国,湖北省,十堰市,茅箭区',
			'level': 3
		}, {
			'id': 1733,
			'pid': 1731,
			'name': '张湾区',
			'merger_name': '中国,湖北省,十堰市,张湾区',
			'level': 3
		}, {
			'id': 1734,
			'pid': 1731,
			'name': '郧阳区',
			'merger_name': '中国,湖北省,十堰市,郧阳区',
			'level': 3
		}, {
			'id': 1735,
			'pid': 1731,
			'name': '郧西县',
			'merger_name': '中国,湖北省,十堰市,郧西县',
			'level': 3
		}, {
			'id': 1736,
			'pid': 1731,
			'name': '竹山县',
			'merger_name': '中国,湖北省,十堰市,竹山县',
			'level': 3
		}, {
			'id': 1737,
			'pid': 1731,
			'name': '竹溪县',
			'merger_name': '中国,湖北省,十堰市,竹溪县',
			'level': 3
		}, {
			'id': 1738,
			'pid': 1731,
			'name': '房县',
			'merger_name': '中国,湖北省,十堰市,房县',
			'level': 3
		}, {
			'id': 1739,
			'pid': 1731,
			'name': '丹江口市',
			'merger_name': '中国,湖北省,十堰市,丹江口市',
			'level': 3
		}]
	}, {
		'id': 1740,
		'pid': 1709,
		'name': '宜昌市',
		'merger_name': '中国,湖北省,宜昌市',
		'level': 2,
		'areas': [{
			'id': 1741,
			'pid': 1740,
			'name': '西陵区',
			'merger_name': '中国,湖北省,宜昌市,西陵区',
			'level': 3
		}, {
			'id': 1742,
			'pid': 1740,
			'name': '伍家岗区',
			'merger_name': '中国,湖北省,宜昌市,伍家岗区',
			'level': 3
		}, {
			'id': 1743,
			'pid': 1740,
			'name': '点军区',
			'merger_name': '中国,湖北省,宜昌市,点军区',
			'level': 3
		}, {
			'id': 1744,
			'pid': 1740,
			'name': '猇亭区',
			'merger_name': '中国,湖北省,宜昌市,猇亭区',
			'level': 3
		}, {
			'id': 1745,
			'pid': 1740,
			'name': '夷陵区',
			'merger_name': '中国,湖北省,宜昌市,夷陵区',
			'level': 3
		}, {
			'id': 1746,
			'pid': 1740,
			'name': '远安县',
			'merger_name': '中国,湖北省,宜昌市,远安县',
			'level': 3
		}, {
			'id': 1747,
			'pid': 1740,
			'name': '兴山县',
			'merger_name': '中国,湖北省,宜昌市,兴山县',
			'level': 3
		}, {
			'id': 1748,
			'pid': 1740,
			'name': '秭归县',
			'merger_name': '中国,湖北省,宜昌市,秭归县',
			'level': 3
		}, {
			'id': 1749,
			'pid': 1740,
			'name': '长阳土家族自治县',
			'merger_name': '中国,湖北省,宜昌市,长阳土家族自治县',
			'level': 3
		}, {
			'id': 1750,
			'pid': 1740,
			'name': '五峰土家族自治县',
			'merger_name': '中国,湖北省,宜昌市,五峰土家族自治县',
			'level': 3
		}, {
			'id': 1751,
			'pid': 1740,
			'name': '宜都市',
			'merger_name': '中国,湖北省,宜昌市,宜都市',
			'level': 3
		}, {
			'id': 1752,
			'pid': 1740,
			'name': '当阳市',
			'merger_name': '中国,湖北省,宜昌市,当阳市',
			'level': 3
		}, {
			'id': 1753,
			'pid': 1740,
			'name': '枝江市',
			'merger_name': '中国,湖北省,宜昌市,枝江市',
			'level': 3
		}]
	}, {
		'id': 1754,
		'pid': 1709,
		'name': '襄阳市',
		'merger_name': '中国,湖北省,襄阳市',
		'level': 2,
		'areas': [{
			'id': 1755,
			'pid': 1754,
			'name': '襄城区',
			'merger_name': '中国,湖北省,襄阳市,襄城区',
			'level': 3
		}, {
			'id': 1756,
			'pid': 1754,
			'name': '樊城区',
			'merger_name': '中国,湖北省,襄阳市,樊城区',
			'level': 3
		}, {
			'id': 1757,
			'pid': 1754,
			'name': '襄州区',
			'merger_name': '中国,湖北省,襄阳市,襄州区',
			'level': 3
		}, {
			'id': 1758,
			'pid': 1754,
			'name': '南漳县',
			'merger_name': '中国,湖北省,襄阳市,南漳县',
			'level': 3
		}, {
			'id': 1759,
			'pid': 1754,
			'name': '谷城县',
			'merger_name': '中国,湖北省,襄阳市,谷城县',
			'level': 3
		}, {
			'id': 1760,
			'pid': 1754,
			'name': '保康县',
			'merger_name': '中国,湖北省,襄阳市,保康县',
			'level': 3
		}, {
			'id': 1761,
			'pid': 1754,
			'name': '老河口市',
			'merger_name': '中国,湖北省,襄阳市,老河口市',
			'level': 3
		}, {
			'id': 1762,
			'pid': 1754,
			'name': '枣阳市',
			'merger_name': '中国,湖北省,襄阳市,枣阳市',
			'level': 3
		}, {
			'id': 1763,
			'pid': 1754,
			'name': '宜城市',
			'merger_name': '中国,湖北省,襄阳市,宜城市',
			'level': 3
		}]
	}, {
		'id': 1764,
		'pid': 1709,
		'name': '鄂州市',
		'merger_name': '中国,湖北省,鄂州市',
		'level': 2,
		'areas': [{
			'id': 1765,
			'pid': 1764,
			'name': '梁子湖区',
			'merger_name': '中国,湖北省,鄂州市,梁子湖区',
			'level': 3
		}, {
			'id': 1766,
			'pid': 1764,
			'name': '华容区',
			'merger_name': '中国,湖北省,鄂州市,华容区',
			'level': 3
		}, {
			'id': 1767,
			'pid': 1764,
			'name': '鄂城区',
			'merger_name': '中国,湖北省,鄂州市,鄂城区',
			'level': 3
		}]
	}, {
		'id': 1768,
		'pid': 1709,
		'name': '荆门市',
		'merger_name': '中国,湖北省,荆门市',
		'level': 2,
		'areas': [{
			'id': 1769,
			'pid': 1768,
			'name': '东宝区',
			'merger_name': '中国,湖北省,荆门市,东宝区',
			'level': 3
		}, {
			'id': 1770,
			'pid': 1768,
			'name': '掇刀区',
			'merger_name': '中国,湖北省,荆门市,掇刀区',
			'level': 3
		}, {
			'id': 1771,
			'pid': 1768,
			'name': '京山县',
			'merger_name': '中国,湖北省,荆门市,京山县',
			'level': 3
		}, {
			'id': 1772,
			'pid': 1768,
			'name': '沙洋县',
			'merger_name': '中国,湖北省,荆门市,沙洋县',
			'level': 3
		}, {
			'id': 1773,
			'pid': 1768,
			'name': '钟祥市',
			'merger_name': '中国,湖北省,荆门市,钟祥市',
			'level': 3
		}]
	}, {
		'id': 1774,
		'pid': 1709,
		'name': '孝感市',
		'merger_name': '中国,湖北省,孝感市',
		'level': 2,
		'areas': [{
			'id': 1775,
			'pid': 1774,
			'name': '孝南区',
			'merger_name': '中国,湖北省,孝感市,孝南区',
			'level': 3
		}, {
			'id': 1776,
			'pid': 1774,
			'name': '孝昌县',
			'merger_name': '中国,湖北省,孝感市,孝昌县',
			'level': 3
		}, {
			'id': 1777,
			'pid': 1774,
			'name': '大悟县',
			'merger_name': '中国,湖北省,孝感市,大悟县',
			'level': 3
		}, {
			'id': 1778,
			'pid': 1774,
			'name': '云梦县',
			'merger_name': '中国,湖北省,孝感市,云梦县',
			'level': 3
		}, {
			'id': 1779,
			'pid': 1774,
			'name': '应城市',
			'merger_name': '中国,湖北省,孝感市,应城市',
			'level': 3
		}, {
			'id': 1780,
			'pid': 1774,
			'name': '安陆市',
			'merger_name': '中国,湖北省,孝感市,安陆市',
			'level': 3
		}, {
			'id': 1781,
			'pid': 1774,
			'name': '汉川市',
			'merger_name': '中国,湖北省,孝感市,汉川市',
			'level': 3
		}]
	}, {
		'id': 1782,
		'pid': 1709,
		'name': '荆州市',
		'merger_name': '中国,湖北省,荆州市',
		'level': 2,
		'areas': [{
			'id': 1783,
			'pid': 1782,
			'name': '沙市区',
			'merger_name': '中国,湖北省,荆州市,沙市区',
			'level': 3
		}, {
			'id': 1784,
			'pid': 1782,
			'name': '荆州区',
			'merger_name': '中国,湖北省,荆州市,荆州区',
			'level': 3
		}, {
			'id': 1785,
			'pid': 1782,
			'name': '公安县',
			'merger_name': '中国,湖北省,荆州市,公安县',
			'level': 3
		}, {
			'id': 1786,
			'pid': 1782,
			'name': '监利县',
			'merger_name': '中国,湖北省,荆州市,监利县',
			'level': 3
		}, {
			'id': 1787,
			'pid': 1782,
			'name': '江陵县',
			'merger_name': '中国,湖北省,荆州市,江陵县',
			'level': 3
		}, {
			'id': 1788,
			'pid': 1782,
			'name': '石首市',
			'merger_name': '中国,湖北省,荆州市,石首市',
			'level': 3
		}, {
			'id': 1789,
			'pid': 1782,
			'name': '洪湖市',
			'merger_name': '中国,湖北省,荆州市,洪湖市',
			'level': 3
		}, {
			'id': 1790,
			'pid': 1782,
			'name': '松滋市',
			'merger_name': '中国,湖北省,荆州市,松滋市',
			'level': 3
		}]
	}, {
		'id': 1791,
		'pid': 1709,
		'name': '黄冈市',
		'merger_name': '中国,湖北省,黄冈市',
		'level': 2,
		'areas': [{
			'id': 1792,
			'pid': 1791,
			'name': '黄州区',
			'merger_name': '中国,湖北省,黄冈市,黄州区',
			'level': 3
		}, {
			'id': 1793,
			'pid': 1791,
			'name': '团风县',
			'merger_name': '中国,湖北省,黄冈市,团风县',
			'level': 3
		}, {
			'id': 1794,
			'pid': 1791,
			'name': '红安县',
			'merger_name': '中国,湖北省,黄冈市,红安县',
			'level': 3
		}, {
			'id': 1795,
			'pid': 1791,
			'name': '罗田县',
			'merger_name': '中国,湖北省,黄冈市,罗田县',
			'level': 3
		}, {
			'id': 1796,
			'pid': 1791,
			'name': '英山县',
			'merger_name': '中国,湖北省,黄冈市,英山县',
			'level': 3
		}, {
			'id': 1797,
			'pid': 1791,
			'name': '浠水县',
			'merger_name': '中国,湖北省,黄冈市,浠水县',
			'level': 3
		}, {
			'id': 1798,
			'pid': 1791,
			'name': '蕲春县',
			'merger_name': '中国,湖北省,黄冈市,蕲春县',
			'level': 3
		}, {
			'id': 1799,
			'pid': 1791,
			'name': '黄梅县',
			'merger_name': '中国,湖北省,黄冈市,黄梅县',
			'level': 3
		}, {
			'id': 1800,
			'pid': 1791,
			'name': '麻城市',
			'merger_name': '中国,湖北省,黄冈市,麻城市',
			'level': 3
		}, {
			'id': 1801,
			'pid': 1791,
			'name': '武穴市',
			'merger_name': '中国,湖北省,黄冈市,武穴市',
			'level': 3
		}]
	}, {
		'id': 1802,
		'pid': 1709,
		'name': '咸宁市',
		'merger_name': '中国,湖北省,咸宁市',
		'level': 2,
		'areas': [{
			'id': 1803,
			'pid': 1802,
			'name': '咸安区',
			'merger_name': '中国,湖北省,咸宁市,咸安区',
			'level': 3
		}, {
			'id': 1804,
			'pid': 1802,
			'name': '嘉鱼县',
			'merger_name': '中国,湖北省,咸宁市,嘉鱼县',
			'level': 3
		}, {
			'id': 1805,
			'pid': 1802,
			'name': '通城县',
			'merger_name': '中国,湖北省,咸宁市,通城县',
			'level': 3
		}, {
			'id': 1806,
			'pid': 1802,
			'name': '崇阳县',
			'merger_name': '中国,湖北省,咸宁市,崇阳县',
			'level': 3
		}, {
			'id': 1807,
			'pid': 1802,
			'name': '通山县',
			'merger_name': '中国,湖北省,咸宁市,通山县',
			'level': 3
		}, {
			'id': 1808,
			'pid': 1802,
			'name': '赤壁市',
			'merger_name': '中国,湖北省,咸宁市,赤壁市',
			'level': 3
		}]
	}, {
		'id': 1809,
		'pid': 1709,
		'name': '随州市',
		'merger_name': '中国,湖北省,随州市',
		'level': 2,
		'areas': [{
			'id': 1810,
			'pid': 1809,
			'name': '曾都区',
			'merger_name': '中国,湖北省,随州市,曾都区',
			'level': 3
		}, {
			'id': 1811,
			'pid': 1809,
			'name': '随县',
			'merger_name': '中国,湖北省,随州市,随县',
			'level': 3
		}, {
			'id': 1812,
			'pid': 1809,
			'name': '广水市',
			'merger_name': '中国,湖北省,随州市,广水市',
			'level': 3
		}]
	}, {
		'id': 1813,
		'pid': 1709,
		'name': '恩施土家族苗族自治州',
		'merger_name': '中国,湖北省,恩施土家族苗族自治州',
		'level': 2,
		'areas': [{
			'id': 1814,
			'pid': 1813,
			'name': '恩施市',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,恩施市',
			'level': 3
		}, {
			'id': 1815,
			'pid': 1813,
			'name': '利川市',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,利川市',
			'level': 3
		}, {
			'id': 1816,
			'pid': 1813,
			'name': '建始县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,建始县',
			'level': 3
		}, {
			'id': 1817,
			'pid': 1813,
			'name': '巴东县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,巴东县',
			'level': 3
		}, {
			'id': 1818,
			'pid': 1813,
			'name': '宣恩县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,宣恩县',
			'level': 3
		}, {
			'id': 1819,
			'pid': 1813,
			'name': '咸丰县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,咸丰县',
			'level': 3
		}, {
			'id': 1820,
			'pid': 1813,
			'name': '来凤县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,来凤县',
			'level': 3
		}, {
			'id': 1821,
			'pid': 1813,
			'name': '鹤峰县',
			'merger_name': '中国,湖北省,恩施土家族苗族自治州,鹤峰县',
			'level': 3
		}]
	}, {
		'id': 1822,
		'pid': 1709,
		'name': '直辖县级',
		'merger_name': '中国,湖北省,直辖县级',
		'level': 2,
		'areas': [{
			'id': 1823,
			'pid': 1822,
			'name': '仙桃市',
			'merger_name': '中国,湖北省,直辖县级,仙桃市',
			'level': 3
		}, {
			'id': 1824,
			'pid': 1822,
			'name': '潜江市',
			'merger_name': '中国,湖北省,直辖县级,潜江市',
			'level': 3
		}, {
			'id': 1825,
			'pid': 1822,
			'name': '天门市',
			'merger_name': '中国,湖北省,直辖县级,天门市',
			'level': 3
		}, {
			'id': 1826,
			'pid': 1822,
			'name': '神农架林区',
			'merger_name': '中国,湖北省,直辖县级,神农架林区',
			'level': 3
		}]
	}]
}, {
	'id': 1827,
	'pid': 0,
	'name': '湖南省',
	'merger_name': '中国,湖南省',
	'level': 1,
	'cities': [{
		'id': 1828,
		'pid': 1827,
		'name': '长沙市',
		'merger_name': '中国,湖南省,长沙市',
		'level': 2,
		'areas': [{
			'id': 1829,
			'pid': 1828,
			'name': '芙蓉区',
			'merger_name': '中国,湖南省,长沙市,芙蓉区',
			'level': 3
		}, {
			'id': 1830,
			'pid': 1828,
			'name': '天心区',
			'merger_name': '中国,湖南省,长沙市,天心区',
			'level': 3
		}, {
			'id': 1831,
			'pid': 1828,
			'name': '岳麓区',
			'merger_name': '中国,湖南省,长沙市,岳麓区',
			'level': 3
		}, {
			'id': 1832,
			'pid': 1828,
			'name': '开福区',
			'merger_name': '中国,湖南省,长沙市,开福区',
			'level': 3
		}, {
			'id': 1833,
			'pid': 1828,
			'name': '雨花区',
			'merger_name': '中国,湖南省,长沙市,雨花区',
			'level': 3
		}, {
			'id': 1834,
			'pid': 1828,
			'name': '望城区',
			'merger_name': '中国,湖南省,长沙市,望城区',
			'level': 3
		}, {
			'id': 1835,
			'pid': 1828,
			'name': '长沙县',
			'merger_name': '中国,湖南省,长沙市,长沙县',
			'level': 3
		}, {
			'id': 1836,
			'pid': 1828,
			'name': '宁乡县',
			'merger_name': '中国,湖南省,长沙市,宁乡县',
			'level': 3
		}, {
			'id': 1837,
			'pid': 1828,
			'name': '浏阳市',
			'merger_name': '中国,湖南省,长沙市,浏阳市',
			'level': 3
		}]
	}, {
		'id': 1838,
		'pid': 1827,
		'name': '株洲市',
		'merger_name': '中国,湖南省,株洲市',
		'level': 2,
		'areas': [{
			'id': 1839,
			'pid': 1838,
			'name': '荷塘区',
			'merger_name': '中国,湖南省,株洲市,荷塘区',
			'level': 3
		}, {
			'id': 1840,
			'pid': 1838,
			'name': '芦淞区',
			'merger_name': '中国,湖南省,株洲市,芦淞区',
			'level': 3
		}, {
			'id': 1841,
			'pid': 1838,
			'name': '石峰区',
			'merger_name': '中国,湖南省,株洲市,石峰区',
			'level': 3
		}, {
			'id': 1842,
			'pid': 1838,
			'name': '天元区',
			'merger_name': '中国,湖南省,株洲市,天元区',
			'level': 3
		}, {
			'id': 1843,
			'pid': 1838,
			'name': '株洲县',
			'merger_name': '中国,湖南省,株洲市,株洲县',
			'level': 3
		}, {
			'id': 1844,
			'pid': 1838,
			'name': '攸县',
			'merger_name': '中国,湖南省,株洲市,攸县',
			'level': 3
		}, {
			'id': 1845,
			'pid': 1838,
			'name': '茶陵县',
			'merger_name': '中国,湖南省,株洲市,茶陵县',
			'level': 3
		}, {
			'id': 1846,
			'pid': 1838,
			'name': '炎陵县',
			'merger_name': '中国,湖南省,株洲市,炎陵县',
			'level': 3
		}, {
			'id': 1847,
			'pid': 1838,
			'name': '醴陵市',
			'merger_name': '中国,湖南省,株洲市,醴陵市',
			'level': 3
		}]
	}, {
		'id': 1848,
		'pid': 1827,
		'name': '湘潭市',
		'merger_name': '中国,湖南省,湘潭市',
		'level': 2,
		'areas': [{
			'id': 1849,
			'pid': 1848,
			'name': '雨湖区',
			'merger_name': '中国,湖南省,湘潭市,雨湖区',
			'level': 3
		}, {
			'id': 1850,
			'pid': 1848,
			'name': '岳塘区',
			'merger_name': '中国,湖南省,湘潭市,岳塘区',
			'level': 3
		}, {
			'id': 1851,
			'pid': 1848,
			'name': '湘潭县',
			'merger_name': '中国,湖南省,湘潭市,湘潭县',
			'level': 3
		}, {
			'id': 1852,
			'pid': 1848,
			'name': '湘乡市',
			'merger_name': '中国,湖南省,湘潭市,湘乡市',
			'level': 3
		}, {
			'id': 1853,
			'pid': 1848,
			'name': '韶山市',
			'merger_name': '中国,湖南省,湘潭市,韶山市',
			'level': 3
		}]
	}, {
		'id': 1854,
		'pid': 1827,
		'name': '衡阳市',
		'merger_name': '中国,湖南省,衡阳市',
		'level': 2,
		'areas': [{
			'id': 1855,
			'pid': 1854,
			'name': '珠晖区',
			'merger_name': '中国,湖南省,衡阳市,珠晖区',
			'level': 3
		}, {
			'id': 1856,
			'pid': 1854,
			'name': '雁峰区',
			'merger_name': '中国,湖南省,衡阳市,雁峰区',
			'level': 3
		}, {
			'id': 1857,
			'pid': 1854,
			'name': '石鼓区',
			'merger_name': '中国,湖南省,衡阳市,石鼓区',
			'level': 3
		}, {
			'id': 1858,
			'pid': 1854,
			'name': '蒸湘区',
			'merger_name': '中国,湖南省,衡阳市,蒸湘区',
			'level': 3
		}, {
			'id': 1859,
			'pid': 1854,
			'name': '南岳区',
			'merger_name': '中国,湖南省,衡阳市,南岳区',
			'level': 3
		}, {
			'id': 1860,
			'pid': 1854,
			'name': '衡阳县',
			'merger_name': '中国,湖南省,衡阳市,衡阳县',
			'level': 3
		}, {
			'id': 1861,
			'pid': 1854,
			'name': '衡南县',
			'merger_name': '中国,湖南省,衡阳市,衡南县',
			'level': 3
		}, {
			'id': 1862,
			'pid': 1854,
			'name': '衡山县',
			'merger_name': '中国,湖南省,衡阳市,衡山县',
			'level': 3
		}, {
			'id': 1863,
			'pid': 1854,
			'name': '衡东县',
			'merger_name': '中国,湖南省,衡阳市,衡东县',
			'level': 3
		}, {
			'id': 1864,
			'pid': 1854,
			'name': '祁东县',
			'merger_name': '中国,湖南省,衡阳市,祁东县',
			'level': 3
		}, {
			'id': 1865,
			'pid': 1854,
			'name': '耒阳市',
			'merger_name': '中国,湖南省,衡阳市,耒阳市',
			'level': 3
		}, {
			'id': 1866,
			'pid': 1854,
			'name': '常宁市',
			'merger_name': '中国,湖南省,衡阳市,常宁市',
			'level': 3
		}]
	}, {
		'id': 1867,
		'pid': 1827,
		'name': '邵阳市',
		'merger_name': '中国,湖南省,邵阳市',
		'level': 2,
		'areas': [{
			'id': 1868,
			'pid': 1867,
			'name': '双清区',
			'merger_name': '中国,湖南省,邵阳市,双清区',
			'level': 3
		}, {
			'id': 1869,
			'pid': 1867,
			'name': '大祥区',
			'merger_name': '中国,湖南省,邵阳市,大祥区',
			'level': 3
		}, {
			'id': 1870,
			'pid': 1867,
			'name': '北塔区',
			'merger_name': '中国,湖南省,邵阳市,北塔区',
			'level': 3
		}, {
			'id': 1871,
			'pid': 1867,
			'name': '邵东县',
			'merger_name': '中国,湖南省,邵阳市,邵东县',
			'level': 3
		}, {
			'id': 1872,
			'pid': 1867,
			'name': '新邵县',
			'merger_name': '中国,湖南省,邵阳市,新邵县',
			'level': 3
		}, {
			'id': 1873,
			'pid': 1867,
			'name': '邵阳县',
			'merger_name': '中国,湖南省,邵阳市,邵阳县',
			'level': 3
		}, {
			'id': 1874,
			'pid': 1867,
			'name': '隆回县',
			'merger_name': '中国,湖南省,邵阳市,隆回县',
			'level': 3
		}, {
			'id': 1875,
			'pid': 1867,
			'name': '洞口县',
			'merger_name': '中国,湖南省,邵阳市,洞口县',
			'level': 3
		}, {
			'id': 1876,
			'pid': 1867,
			'name': '绥宁县',
			'merger_name': '中国,湖南省,邵阳市,绥宁县',
			'level': 3
		}, {
			'id': 1877,
			'pid': 1867,
			'name': '新宁县',
			'merger_name': '中国,湖南省,邵阳市,新宁县',
			'level': 3
		}, {
			'id': 1878,
			'pid': 1867,
			'name': '城步苗族自治县',
			'merger_name': '中国,湖南省,邵阳市,城步苗族自治县',
			'level': 3
		}, {
			'id': 1879,
			'pid': 1867,
			'name': '武冈市',
			'merger_name': '中国,湖南省,邵阳市,武冈市',
			'level': 3
		}]
	}, {
		'id': 1880,
		'pid': 1827,
		'name': '岳阳市',
		'merger_name': '中国,湖南省,岳阳市',
		'level': 2,
		'areas': [{
			'id': 1881,
			'pid': 1880,
			'name': '岳阳楼区',
			'merger_name': '中国,湖南省,岳阳市,岳阳楼区',
			'level': 3
		}, {
			'id': 1882,
			'pid': 1880,
			'name': '云溪区',
			'merger_name': '中国,湖南省,岳阳市,云溪区',
			'level': 3
		}, {
			'id': 1883,
			'pid': 1880,
			'name': '君山区',
			'merger_name': '中国,湖南省,岳阳市,君山区',
			'level': 3
		}, {
			'id': 1884,
			'pid': 1880,
			'name': '岳阳县',
			'merger_name': '中国,湖南省,岳阳市,岳阳县',
			'level': 3
		}, {
			'id': 1885,
			'pid': 1880,
			'name': '华容县',
			'merger_name': '中国,湖南省,岳阳市,华容县',
			'level': 3
		}, {
			'id': 1886,
			'pid': 1880,
			'name': '湘阴县',
			'merger_name': '中国,湖南省,岳阳市,湘阴县',
			'level': 3
		}, {
			'id': 1887,
			'pid': 1880,
			'name': '平江县',
			'merger_name': '中国,湖南省,岳阳市,平江县',
			'level': 3
		}, {
			'id': 1888,
			'pid': 1880,
			'name': '汨罗市',
			'merger_name': '中国,湖南省,岳阳市,汨罗市',
			'level': 3
		}, {
			'id': 1889,
			'pid': 1880,
			'name': '临湘市',
			'merger_name': '中国,湖南省,岳阳市,临湘市',
			'level': 3
		}]
	}, {
		'id': 1890,
		'pid': 1827,
		'name': '常德市',
		'merger_name': '中国,湖南省,常德市',
		'level': 2,
		'areas': [{
			'id': 1891,
			'pid': 1890,
			'name': '武陵区',
			'merger_name': '中国,湖南省,常德市,武陵区',
			'level': 3
		}, {
			'id': 1892,
			'pid': 1890,
			'name': '鼎城区',
			'merger_name': '中国,湖南省,常德市,鼎城区',
			'level': 3
		}, {
			'id': 1893,
			'pid': 1890,
			'name': '安乡县',
			'merger_name': '中国,湖南省,常德市,安乡县',
			'level': 3
		}, {
			'id': 1894,
			'pid': 1890,
			'name': '汉寿县',
			'merger_name': '中国,湖南省,常德市,汉寿县',
			'level': 3
		}, {
			'id': 1895,
			'pid': 1890,
			'name': '澧县',
			'merger_name': '中国,湖南省,常德市,澧县',
			'level': 3
		}, {
			'id': 1896,
			'pid': 1890,
			'name': '临澧县',
			'merger_name': '中国,湖南省,常德市,临澧县',
			'level': 3
		}, {
			'id': 1897,
			'pid': 1890,
			'name': '桃源县',
			'merger_name': '中国,湖南省,常德市,桃源县',
			'level': 3
		}, {
			'id': 1898,
			'pid': 1890,
			'name': '石门县',
			'merger_name': '中国,湖南省,常德市,石门县',
			'level': 3
		}, {
			'id': 1899,
			'pid': 1890,
			'name': '津市市',
			'merger_name': '中国,湖南省,常德市,津市市',
			'level': 3
		}]
	}, {
		'id': 1900,
		'pid': 1827,
		'name': '张家界市',
		'merger_name': '中国,湖南省,张家界市',
		'level': 2,
		'areas': [{
			'id': 1901,
			'pid': 1900,
			'name': '永定区',
			'merger_name': '中国,湖南省,张家界市,永定区',
			'level': 3
		}, {
			'id': 1902,
			'pid': 1900,
			'name': '武陵源区',
			'merger_name': '中国,湖南省,张家界市,武陵源区',
			'level': 3
		}, {
			'id': 1903,
			'pid': 1900,
			'name': '慈利县',
			'merger_name': '中国,湖南省,张家界市,慈利县',
			'level': 3
		}, {
			'id': 1904,
			'pid': 1900,
			'name': '桑植县',
			'merger_name': '中国,湖南省,张家界市,桑植县',
			'level': 3
		}]
	}, {
		'id': 1905,
		'pid': 1827,
		'name': '益阳市',
		'merger_name': '中国,湖南省,益阳市',
		'level': 2,
		'areas': [{
			'id': 1906,
			'pid': 1905,
			'name': '资阳区',
			'merger_name': '中国,湖南省,益阳市,资阳区',
			'level': 3
		}, {
			'id': 1907,
			'pid': 1905,
			'name': '赫山区',
			'merger_name': '中国,湖南省,益阳市,赫山区',
			'level': 3
		}, {
			'id': 1908,
			'pid': 1905,
			'name': '南县',
			'merger_name': '中国,湖南省,益阳市,南县',
			'level': 3
		}, {
			'id': 1909,
			'pid': 1905,
			'name': '桃江县',
			'merger_name': '中国,湖南省,益阳市,桃江县',
			'level': 3
		}, {
			'id': 1910,
			'pid': 1905,
			'name': '安化县',
			'merger_name': '中国,湖南省,益阳市,安化县',
			'level': 3
		}, {
			'id': 1911,
			'pid': 1905,
			'name': '沅江市',
			'merger_name': '中国,湖南省,益阳市,沅江市',
			'level': 3
		}]
	}, {
		'id': 1912,
		'pid': 1827,
		'name': '郴州市',
		'merger_name': '中国,湖南省,郴州市',
		'level': 2,
		'areas': [{
			'id': 1913,
			'pid': 1912,
			'name': '北湖区',
			'merger_name': '中国,湖南省,郴州市,北湖区',
			'level': 3
		}, {
			'id': 1914,
			'pid': 1912,
			'name': '苏仙区',
			'merger_name': '中国,湖南省,郴州市,苏仙区',
			'level': 3
		}, {
			'id': 1915,
			'pid': 1912,
			'name': '桂阳县',
			'merger_name': '中国,湖南省,郴州市,桂阳县',
			'level': 3
		}, {
			'id': 1916,
			'pid': 1912,
			'name': '宜章县',
			'merger_name': '中国,湖南省,郴州市,宜章县',
			'level': 3
		}, {
			'id': 1917,
			'pid': 1912,
			'name': '永兴县',
			'merger_name': '中国,湖南省,郴州市,永兴县',
			'level': 3
		}, {
			'id': 1918,
			'pid': 1912,
			'name': '嘉禾县',
			'merger_name': '中国,湖南省,郴州市,嘉禾县',
			'level': 3
		}, {
			'id': 1919,
			'pid': 1912,
			'name': '临武县',
			'merger_name': '中国,湖南省,郴州市,临武县',
			'level': 3
		}, {
			'id': 1920,
			'pid': 1912,
			'name': '汝城县',
			'merger_name': '中国,湖南省,郴州市,汝城县',
			'level': 3
		}, {
			'id': 1921,
			'pid': 1912,
			'name': '桂东县',
			'merger_name': '中国,湖南省,郴州市,桂东县',
			'level': 3
		}, {
			'id': 1922,
			'pid': 1912,
			'name': '安仁县',
			'merger_name': '中国,湖南省,郴州市,安仁县',
			'level': 3
		}, {
			'id': 1923,
			'pid': 1912,
			'name': '资兴市',
			'merger_name': '中国,湖南省,郴州市,资兴市',
			'level': 3
		}]
	}, {
		'id': 1924,
		'pid': 1827,
		'name': '永州市',
		'merger_name': '中国,湖南省,永州市',
		'level': 2,
		'areas': [{
			'id': 1925,
			'pid': 1924,
			'name': '零陵区',
			'merger_name': '中国,湖南省,永州市,零陵区',
			'level': 3
		}, {
			'id': 1926,
			'pid': 1924,
			'name': '冷水滩区',
			'merger_name': '中国,湖南省,永州市,冷水滩区',
			'level': 3
		}, {
			'id': 1927,
			'pid': 1924,
			'name': '祁阳县',
			'merger_name': '中国,湖南省,永州市,祁阳县',
			'level': 3
		}, {
			'id': 1928,
			'pid': 1924,
			'name': '东安县',
			'merger_name': '中国,湖南省,永州市,东安县',
			'level': 3
		}, {
			'id': 1929,
			'pid': 1924,
			'name': '双牌县',
			'merger_name': '中国,湖南省,永州市,双牌县',
			'level': 3
		}, {
			'id': 1930,
			'pid': 1924,
			'name': '道县',
			'merger_name': '中国,湖南省,永州市,道县',
			'level': 3
		}, {
			'id': 1931,
			'pid': 1924,
			'name': '江永县',
			'merger_name': '中国,湖南省,永州市,江永县',
			'level': 3
		}, {
			'id': 1932,
			'pid': 1924,
			'name': '宁远县',
			'merger_name': '中国,湖南省,永州市,宁远县',
			'level': 3
		}, {
			'id': 1933,
			'pid': 1924,
			'name': '蓝山县',
			'merger_name': '中国,湖南省,永州市,蓝山县',
			'level': 3
		}, {
			'id': 1934,
			'pid': 1924,
			'name': '新田县',
			'merger_name': '中国,湖南省,永州市,新田县',
			'level': 3
		}, {
			'id': 1935,
			'pid': 1924,
			'name': '江华瑶族自治县',
			'merger_name': '中国,湖南省,永州市,江华瑶族自治县',
			'level': 3
		}]
	}, {
		'id': 1936,
		'pid': 1827,
		'name': '怀化市',
		'merger_name': '中国,湖南省,怀化市',
		'level': 2,
		'areas': [{
			'id': 1937,
			'pid': 1936,
			'name': '鹤城区',
			'merger_name': '中国,湖南省,怀化市,鹤城区',
			'level': 3
		}, {
			'id': 1938,
			'pid': 1936,
			'name': '中方县',
			'merger_name': '中国,湖南省,怀化市,中方县',
			'level': 3
		}, {
			'id': 1939,
			'pid': 1936,
			'name': '沅陵县',
			'merger_name': '中国,湖南省,怀化市,沅陵县',
			'level': 3
		}, {
			'id': 1940,
			'pid': 1936,
			'name': '辰溪县',
			'merger_name': '中国,湖南省,怀化市,辰溪县',
			'level': 3
		}, {
			'id': 1941,
			'pid': 1936,
			'name': '溆浦县',
			'merger_name': '中国,湖南省,怀化市,溆浦县',
			'level': 3
		}, {
			'id': 1942,
			'pid': 1936,
			'name': '会同县',
			'merger_name': '中国,湖南省,怀化市,会同县',
			'level': 3
		}, {
			'id': 1943,
			'pid': 1936,
			'name': '麻阳苗族自治县',
			'merger_name': '中国,湖南省,怀化市,麻阳苗族自治县',
			'level': 3
		}, {
			'id': 1944,
			'pid': 1936,
			'name': '新晃侗族自治县',
			'merger_name': '中国,湖南省,怀化市,新晃侗族自治县',
			'level': 3
		}, {
			'id': 1945,
			'pid': 1936,
			'name': '芷江侗族自治县',
			'merger_name': '中国,湖南省,怀化市,芷江侗族自治县',
			'level': 3
		}, {
			'id': 1946,
			'pid': 1936,
			'name': '靖州苗族侗族自治县',
			'merger_name': '中国,湖南省,怀化市,靖州苗族侗族自治县',
			'level': 3
		}, {
			'id': 1947,
			'pid': 1936,
			'name': '通道侗族自治县',
			'merger_name': '中国,湖南省,怀化市,通道侗族自治县',
			'level': 3
		}, {
			'id': 1948,
			'pid': 1936,
			'name': '洪江市',
			'merger_name': '中国,湖南省,怀化市,洪江市',
			'level': 3
		}]
	}, {
		'id': 1949,
		'pid': 1827,
		'name': '娄底市',
		'merger_name': '中国,湖南省,娄底市',
		'level': 2,
		'areas': [{
			'id': 1950,
			'pid': 1949,
			'name': '娄星区',
			'merger_name': '中国,湖南省,娄底市,娄星区',
			'level': 3
		}, {
			'id': 1951,
			'pid': 1949,
			'name': '双峰县',
			'merger_name': '中国,湖南省,娄底市,双峰县',
			'level': 3
		}, {
			'id': 1952,
			'pid': 1949,
			'name': '新化县',
			'merger_name': '中国,湖南省,娄底市,新化县',
			'level': 3
		}, {
			'id': 1953,
			'pid': 1949,
			'name': '冷水江市',
			'merger_name': '中国,湖南省,娄底市,冷水江市',
			'level': 3
		}, {
			'id': 1954,
			'pid': 1949,
			'name': '涟源市',
			'merger_name': '中国,湖南省,娄底市,涟源市',
			'level': 3
		}]
	}, {
		'id': 1955,
		'pid': 1827,
		'name': '湘西土家族苗族自治州',
		'merger_name': '中国,湖南省,湘西土家族苗族自治州',
		'level': 2,
		'areas': [{
			'id': 1956,
			'pid': 1955,
			'name': '吉首市',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,吉首市',
			'level': 3
		}, {
			'id': 1957,
			'pid': 1955,
			'name': '泸溪县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,泸溪县',
			'level': 3
		}, {
			'id': 1958,
			'pid': 1955,
			'name': '凤凰县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,凤凰县',
			'level': 3
		}, {
			'id': 1959,
			'pid': 1955,
			'name': '花垣县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,花垣县',
			'level': 3
		}, {
			'id': 1960,
			'pid': 1955,
			'name': '保靖县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,保靖县',
			'level': 3
		}, {
			'id': 1961,
			'pid': 1955,
			'name': '古丈县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,古丈县',
			'level': 3
		}, {
			'id': 1962,
			'pid': 1955,
			'name': '永顺县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,永顺县',
			'level': 3
		}, {
			'id': 1963,
			'pid': 1955,
			'name': '龙山县',
			'merger_name': '中国,湖南省,湘西土家族苗族自治州,龙山县',
			'level': 3
		}]
	}]
}, {
	'id': 1964,
	'pid': 0,
	'name': '广东省',
	'merger_name': '中国,广东省',
	'level': 1,
	'cities': [{
		'id': 1965,
		'pid': 1964,
		'name': '广州市',
		'merger_name': '中国,广东省,广州市',
		'level': 2,
		'areas': [{
			'id': 1966,
			'pid': 1965,
			'name': '荔湾区',
			'merger_name': '中国,广东省,广州市,荔湾区',
			'level': 3
		}, {
			'id': 1967,
			'pid': 1965,
			'name': '越秀区',
			'merger_name': '中国,广东省,广州市,越秀区',
			'level': 3
		}, {
			'id': 1968,
			'pid': 1965,
			'name': '海珠区',
			'merger_name': '中国,广东省,广州市,海珠区',
			'level': 3
		}, {
			'id': 1969,
			'pid': 1965,
			'name': '天河区',
			'merger_name': '中国,广东省,广州市,天河区',
			'level': 3
		}, {
			'id': 1970,
			'pid': 1965,
			'name': '白云区',
			'merger_name': '中国,广东省,广州市,白云区',
			'level': 3
		}, {
			'id': 1971,
			'pid': 1965,
			'name': '黄埔区',
			'merger_name': '中国,广东省,广州市,黄埔区',
			'level': 3
		}, {
			'id': 1972,
			'pid': 1965,
			'name': '番禺区',
			'merger_name': '中国,广东省,广州市,番禺区',
			'level': 3
		}, {
			'id': 1973,
			'pid': 1965,
			'name': '花都区',
			'merger_name': '中国,广东省,广州市,花都区',
			'level': 3
		}, {
			'id': 1974,
			'pid': 1965,
			'name': '南沙区',
			'merger_name': '中国,广东省,广州市,南沙区',
			'level': 3
		}, {
			'id': 1975,
			'pid': 1965,
			'name': '从化区',
			'merger_name': '中国,广东省,广州市,从化区',
			'level': 3
		}, {
			'id': 1976,
			'pid': 1965,
			'name': '增城区',
			'merger_name': '中国,广东省,广州市,增城区',
			'level': 3
		}]
	}, {
		'id': 1977,
		'pid': 1964,
		'name': '韶关市',
		'merger_name': '中国,广东省,韶关市',
		'level': 2,
		'areas': [{
			'id': 1978,
			'pid': 1977,
			'name': '武江区',
			'merger_name': '中国,广东省,韶关市,武江区',
			'level': 3
		}, {
			'id': 1979,
			'pid': 1977,
			'name': '浈江区',
			'merger_name': '中国,广东省,韶关市,浈江区',
			'level': 3
		}, {
			'id': 1980,
			'pid': 1977,
			'name': '曲江区',
			'merger_name': '中国,广东省,韶关市,曲江区',
			'level': 3
		}, {
			'id': 1981,
			'pid': 1977,
			'name': '始兴县',
			'merger_name': '中国,广东省,韶关市,始兴县',
			'level': 3
		}, {
			'id': 1982,
			'pid': 1977,
			'name': '仁化县',
			'merger_name': '中国,广东省,韶关市,仁化县',
			'level': 3
		}, {
			'id': 1983,
			'pid': 1977,
			'name': '翁源县',
			'merger_name': '中国,广东省,韶关市,翁源县',
			'level': 3
		}, {
			'id': 1984,
			'pid': 1977,
			'name': '乳源瑶族自治县',
			'merger_name': '中国,广东省,韶关市,乳源瑶族自治县',
			'level': 3
		}, {
			'id': 1985,
			'pid': 1977,
			'name': '新丰县',
			'merger_name': '中国,广东省,韶关市,新丰县',
			'level': 3
		}, {
			'id': 1986,
			'pid': 1977,
			'name': '乐昌市',
			'merger_name': '中国,广东省,韶关市,乐昌市',
			'level': 3
		}, {
			'id': 1987,
			'pid': 1977,
			'name': '南雄市',
			'merger_name': '中国,广东省,韶关市,南雄市',
			'level': 3
		}]
	}, {
		'id': 1988,
		'pid': 1964,
		'name': '深圳市',
		'merger_name': '中国,广东省,深圳市',
		'level': 2,
		'areas': [{
			'id': 1989,
			'pid': 1988,
			'name': '罗湖区',
			'merger_name': '中国,广东省,深圳市,罗湖区',
			'level': 3
		}, {
			'id': 1990,
			'pid': 1988,
			'name': '福田区',
			'merger_name': '中国,广东省,深圳市,福田区',
			'level': 3
		}, {
			'id': 1991,
			'pid': 1988,
			'name': '南山区',
			'merger_name': '中国,广东省,深圳市,南山区',
			'level': 3
		}, {
			'id': 1992,
			'pid': 1988,
			'name': '宝安区',
			'merger_name': '中国,广东省,深圳市,宝安区',
			'level': 3
		}, {
			'id': 1993,
			'pid': 1988,
			'name': '龙岗区',
			'merger_name': '中国,广东省,深圳市,龙岗区',
			'level': 3
		}, {
			'id': 1994,
			'pid': 1988,
			'name': '盐田区',
			'merger_name': '中国,广东省,深圳市,盐田区',
			'level': 3
		}, {
			'id': 1995,
			'pid': 1988,
			'name': '光明新区',
			'merger_name': '中国,广东省,深圳市,光明新区',
			'level': 3
		}, {
			'id': 1996,
			'pid': 1988,
			'name': '坪山新区',
			'merger_name': '中国,广东省,深圳市,坪山新区',
			'level': 3
		}, {
			'id': 1997,
			'pid': 1988,
			'name': '大鹏新区',
			'merger_name': '中国,广东省,深圳市,大鹏新区',
			'level': 3
		}, {
			'id': 1998,
			'pid': 1988,
			'name': '龙华新区',
			'merger_name': '中国,广东省,深圳市,龙华新区',
			'level': 3
		}]
	}, {
		'id': 1999,
		'pid': 1964,
		'name': '珠海市',
		'merger_name': '中国,广东省,珠海市',
		'level': 2,
		'areas': [{
			'id': 2000,
			'pid': 1999,
			'name': '香洲区',
			'merger_name': '中国,广东省,珠海市,香洲区',
			'level': 3
		}, {
			'id': 2001,
			'pid': 1999,
			'name': '斗门区',
			'merger_name': '中国,广东省,珠海市,斗门区',
			'level': 3
		}, {
			'id': 2002,
			'pid': 1999,
			'name': '金湾区',
			'merger_name': '中国,广东省,珠海市,金湾区',
			'level': 3
		}]
	}, {
		'id': 2003,
		'pid': 1964,
		'name': '汕头市',
		'merger_name': '中国,广东省,汕头市',
		'level': 2,
		'areas': [{
			'id': 2004,
			'pid': 2003,
			'name': '龙湖区',
			'merger_name': '中国,广东省,汕头市,龙湖区',
			'level': 3
		}, {
			'id': 2005,
			'pid': 2003,
			'name': '金平区',
			'merger_name': '中国,广东省,汕头市,金平区',
			'level': 3
		}, {
			'id': 2006,
			'pid': 2003,
			'name': '濠江区',
			'merger_name': '中国,广东省,汕头市,濠江区',
			'level': 3
		}, {
			'id': 2007,
			'pid': 2003,
			'name': '潮阳区',
			'merger_name': '中国,广东省,汕头市,潮阳区',
			'level': 3
		}, {
			'id': 2008,
			'pid': 2003,
			'name': '潮南区',
			'merger_name': '中国,广东省,汕头市,潮南区',
			'level': 3
		}, {
			'id': 2009,
			'pid': 2003,
			'name': '澄海区',
			'merger_name': '中国,广东省,汕头市,澄海区',
			'level': 3
		}, {
			'id': 2010,
			'pid': 2003,
			'name': '南澳县',
			'merger_name': '中国,广东省,汕头市,南澳县',
			'level': 3
		}]
	}, {
		'id': 2011,
		'pid': 1964,
		'name': '佛山市',
		'merger_name': '中国,广东省,佛山市',
		'level': 2,
		'areas': [{
			'id': 2012,
			'pid': 2011,
			'name': '禅城区',
			'merger_name': '中国,广东省,佛山市,禅城区',
			'level': 3
		}, {
			'id': 2013,
			'pid': 2011,
			'name': '南海区',
			'merger_name': '中国,广东省,佛山市,南海区',
			'level': 3
		}, {
			'id': 2014,
			'pid': 2011,
			'name': '顺德区',
			'merger_name': '中国,广东省,佛山市,顺德区',
			'level': 3
		}, {
			'id': 2015,
			'pid': 2011,
			'name': '三水区',
			'merger_name': '中国,广东省,佛山市,三水区',
			'level': 3
		}, {
			'id': 2016,
			'pid': 2011,
			'name': '高明区',
			'merger_name': '中国,广东省,佛山市,高明区',
			'level': 3
		}]
	}, {
		'id': 2017,
		'pid': 1964,
		'name': '江门市',
		'merger_name': '中国,广东省,江门市',
		'level': 2,
		'areas': [{
			'id': 2018,
			'pid': 2017,
			'name': '蓬江区',
			'merger_name': '中国,广东省,江门市,蓬江区',
			'level': 3
		}, {
			'id': 2019,
			'pid': 2017,
			'name': '江海区',
			'merger_name': '中国,广东省,江门市,江海区',
			'level': 3
		}, {
			'id': 2020,
			'pid': 2017,
			'name': '新会区',
			'merger_name': '中国,广东省,江门市,新会区',
			'level': 3
		}, {
			'id': 2021,
			'pid': 2017,
			'name': '台山市',
			'merger_name': '中国,广东省,江门市,台山市',
			'level': 3
		}, {
			'id': 2022,
			'pid': 2017,
			'name': '开平市',
			'merger_name': '中国,广东省,江门市,开平市',
			'level': 3
		}, {
			'id': 2023,
			'pid': 2017,
			'name': '鹤山市',
			'merger_name': '中国,广东省,江门市,鹤山市',
			'level': 3
		}, {
			'id': 2024,
			'pid': 2017,
			'name': '恩平市',
			'merger_name': '中国,广东省,江门市,恩平市',
			'level': 3
		}]
	}, {
		'id': 2025,
		'pid': 1964,
		'name': '湛江市',
		'merger_name': '中国,广东省,湛江市',
		'level': 2,
		'areas': [{
			'id': 2026,
			'pid': 2025,
			'name': '赤坎区',
			'merger_name': '中国,广东省,湛江市,赤坎区',
			'level': 3
		}, {
			'id': 2027,
			'pid': 2025,
			'name': '霞山区',
			'merger_name': '中国,广东省,湛江市,霞山区',
			'level': 3
		}, {
			'id': 2028,
			'pid': 2025,
			'name': '坡头区',
			'merger_name': '中国,广东省,湛江市,坡头区',
			'level': 3
		}, {
			'id': 2029,
			'pid': 2025,
			'name': '麻章区',
			'merger_name': '中国,广东省,湛江市,麻章区',
			'level': 3
		}, {
			'id': 2030,
			'pid': 2025,
			'name': '遂溪县',
			'merger_name': '中国,广东省,湛江市,遂溪县',
			'level': 3
		}, {
			'id': 2031,
			'pid': 2025,
			'name': '徐闻县',
			'merger_name': '中国,广东省,湛江市,徐闻县',
			'level': 3
		}, {
			'id': 2032,
			'pid': 2025,
			'name': '廉江市',
			'merger_name': '中国,广东省,湛江市,廉江市',
			'level': 3
		}, {
			'id': 2033,
			'pid': 2025,
			'name': '雷州市',
			'merger_name': '中国,广东省,湛江市,雷州市',
			'level': 3
		}, {
			'id': 2034,
			'pid': 2025,
			'name': '吴川市',
			'merger_name': '中国,广东省,湛江市,吴川市',
			'level': 3
		}]
	}, {
		'id': 2035,
		'pid': 1964,
		'name': '茂名市',
		'merger_name': '中国,广东省,茂名市',
		'level': 2,
		'areas': [{
			'id': 2036,
			'pid': 2035,
			'name': '茂南区',
			'merger_name': '中国,广东省,茂名市,茂南区',
			'level': 3
		}, {
			'id': 2037,
			'pid': 2035,
			'name': '电白区',
			'merger_name': '中国,广东省,茂名市,电白区',
			'level': 3
		}, {
			'id': 2038,
			'pid': 2035,
			'name': '高州市',
			'merger_name': '中国,广东省,茂名市,高州市',
			'level': 3
		}, {
			'id': 2039,
			'pid': 2035,
			'name': '化州市',
			'merger_name': '中国,广东省,茂名市,化州市',
			'level': 3
		}, {
			'id': 2040,
			'pid': 2035,
			'name': '信宜市',
			'merger_name': '中国,广东省,茂名市,信宜市',
			'level': 3
		}]
	}, {
		'id': 2041,
		'pid': 1964,
		'name': '肇庆市',
		'merger_name': '中国,广东省,肇庆市',
		'level': 2,
		'areas': [{
			'id': 2042,
			'pid': 2041,
			'name': '端州区',
			'merger_name': '中国,广东省,肇庆市,端州区',
			'level': 3
		}, {
			'id': 2043,
			'pid': 2041,
			'name': '鼎湖区',
			'merger_name': '中国,广东省,肇庆市,鼎湖区',
			'level': 3
		}, {
			'id': 2044,
			'pid': 2041,
			'name': '广宁县',
			'merger_name': '中国,广东省,肇庆市,广宁县',
			'level': 3
		}, {
			'id': 2045,
			'pid': 2041,
			'name': '怀集县',
			'merger_name': '中国,广东省,肇庆市,怀集县',
			'level': 3
		}, {
			'id': 2046,
			'pid': 2041,
			'name': '封开县',
			'merger_name': '中国,广东省,肇庆市,封开县',
			'level': 3
		}, {
			'id': 2047,
			'pid': 2041,
			'name': '德庆县',
			'merger_name': '中国,广东省,肇庆市,德庆县',
			'level': 3
		}, {
			'id': 2048,
			'pid': 2041,
			'name': '高要市',
			'merger_name': '中国,广东省,肇庆市,高要市',
			'level': 3
		}, {
			'id': 2049,
			'pid': 2041,
			'name': '四会市',
			'merger_name': '中国,广东省,肇庆市,四会市',
			'level': 3
		}]
	}, {
		'id': 2050,
		'pid': 1964,
		'name': '惠州市',
		'merger_name': '中国,广东省,惠州市',
		'level': 2,
		'areas': [{
			'id': 2051,
			'pid': 2050,
			'name': '惠城区',
			'merger_name': '中国,广东省,惠州市,惠城区',
			'level': 3
		}, {
			'id': 2052,
			'pid': 2050,
			'name': '惠阳区',
			'merger_name': '中国,广东省,惠州市,惠阳区',
			'level': 3
		}, {
			'id': 2053,
			'pid': 2050,
			'name': '博罗县',
			'merger_name': '中国,广东省,惠州市,博罗县',
			'level': 3
		}, {
			'id': 2054,
			'pid': 2050,
			'name': '惠东县',
			'merger_name': '中国,广东省,惠州市,惠东县',
			'level': 3
		}, {
			'id': 2055,
			'pid': 2050,
			'name': '龙门县',
			'merger_name': '中国,广东省,惠州市,龙门县',
			'level': 3
		}]
	}, {
		'id': 2056,
		'pid': 1964,
		'name': '梅州市',
		'merger_name': '中国,广东省,梅州市',
		'level': 2,
		'areas': [{
			'id': 2057,
			'pid': 2056,
			'name': '梅江区',
			'merger_name': '中国,广东省,梅州市,梅江区',
			'level': 3
		}, {
			'id': 2058,
			'pid': 2056,
			'name': '梅县区',
			'merger_name': '中国,广东省,梅州市,梅县区',
			'level': 3
		}, {
			'id': 2059,
			'pid': 2056,
			'name': '大埔县',
			'merger_name': '中国,广东省,梅州市,大埔县',
			'level': 3
		}, {
			'id': 2060,
			'pid': 2056,
			'name': '丰顺县',
			'merger_name': '中国,广东省,梅州市,丰顺县',
			'level': 3
		}, {
			'id': 2061,
			'pid': 2056,
			'name': '五华县',
			'merger_name': '中国,广东省,梅州市,五华县',
			'level': 3
		}, {
			'id': 2062,
			'pid': 2056,
			'name': '平远县',
			'merger_name': '中国,广东省,梅州市,平远县',
			'level': 3
		}, {
			'id': 2063,
			'pid': 2056,
			'name': '蕉岭县',
			'merger_name': '中国,广东省,梅州市,蕉岭县',
			'level': 3
		}, {
			'id': 2064,
			'pid': 2056,
			'name': '兴宁市',
			'merger_name': '中国,广东省,梅州市,兴宁市',
			'level': 3
		}]
	}, {
		'id': 2065,
		'pid': 1964,
		'name': '汕尾市',
		'merger_name': '中国,广东省,汕尾市',
		'level': 2,
		'areas': [{
			'id': 2066,
			'pid': 2065,
			'name': '城区',
			'merger_name': '中国,广东省,汕尾市,城区',
			'level': 3
		}, {
			'id': 2067,
			'pid': 2065,
			'name': '海丰县',
			'merger_name': '中国,广东省,汕尾市,海丰县',
			'level': 3
		}, {
			'id': 2068,
			'pid': 2065,
			'name': '陆河县',
			'merger_name': '中国,广东省,汕尾市,陆河县',
			'level': 3
		}, {
			'id': 2069,
			'pid': 2065,
			'name': '陆丰市',
			'merger_name': '中国,广东省,汕尾市,陆丰市',
			'level': 3
		}]
	}, {
		'id': 2070,
		'pid': 1964,
		'name': '河源市',
		'merger_name': '中国,广东省,河源市',
		'level': 2,
		'areas': [{
			'id': 2071,
			'pid': 2070,
			'name': '源城区',
			'merger_name': '中国,广东省,河源市,源城区',
			'level': 3
		}, {
			'id': 2072,
			'pid': 2070,
			'name': '紫金县',
			'merger_name': '中国,广东省,河源市,紫金县',
			'level': 3
		}, {
			'id': 2073,
			'pid': 2070,
			'name': '龙川县',
			'merger_name': '中国,广东省,河源市,龙川县',
			'level': 3
		}, {
			'id': 2074,
			'pid': 2070,
			'name': '连平县',
			'merger_name': '中国,广东省,河源市,连平县',
			'level': 3
		}, {
			'id': 2075,
			'pid': 2070,
			'name': '和平县',
			'merger_name': '中国,广东省,河源市,和平县',
			'level': 3
		}, {
			'id': 2076,
			'pid': 2070,
			'name': '东源县',
			'merger_name': '中国,广东省,河源市,东源县',
			'level': 3
		}]
	}, {
		'id': 2077,
		'pid': 1964,
		'name': '阳江市',
		'merger_name': '中国,广东省,阳江市',
		'level': 2,
		'areas': [{
			'id': 2078,
			'pid': 2077,
			'name': '江城区',
			'merger_name': '中国,广东省,阳江市,江城区',
			'level': 3
		}, {
			'id': 2079,
			'pid': 2077,
			'name': '阳东区',
			'merger_name': '中国,广东省,阳江市,阳东区',
			'level': 3
		}, {
			'id': 2080,
			'pid': 2077,
			'name': '阳西县',
			'merger_name': '中国,广东省,阳江市,阳西县',
			'level': 3
		}, {
			'id': 2081,
			'pid': 2077,
			'name': '阳春市',
			'merger_name': '中国,广东省,阳江市,阳春市',
			'level': 3
		}]
	}, {
		'id': 2082,
		'pid': 1964,
		'name': '清远市',
		'merger_name': '中国,广东省,清远市',
		'level': 2,
		'areas': [{
			'id': 2083,
			'pid': 2082,
			'name': '清城区',
			'merger_name': '中国,广东省,清远市,清城区',
			'level': 3
		}, {
			'id': 2084,
			'pid': 2082,
			'name': '清新区',
			'merger_name': '中国,广东省,清远市,清新区',
			'level': 3
		}, {
			'id': 2085,
			'pid': 2082,
			'name': '佛冈县',
			'merger_name': '中国,广东省,清远市,佛冈县',
			'level': 3
		}, {
			'id': 2086,
			'pid': 2082,
			'name': '阳山县',
			'merger_name': '中国,广东省,清远市,阳山县',
			'level': 3
		}, {
			'id': 2087,
			'pid': 2082,
			'name': '连山壮族瑶族自治县',
			'merger_name': '中国,广东省,清远市,连山壮族瑶族自治县',
			'level': 3
		}, {
			'id': 2088,
			'pid': 2082,
			'name': '连南瑶族自治县',
			'merger_name': '中国,广东省,清远市,连南瑶族自治县',
			'level': 3
		}, {
			'id': 2089,
			'pid': 2082,
			'name': '英德市',
			'merger_name': '中国,广东省,清远市,英德市',
			'level': 3
		}, {
			'id': 2090,
			'pid': 2082,
			'name': '连州市',
			'merger_name': '中国,广东省,清远市,连州市',
			'level': 3
		}]
	}, {
		'id': 2091,
		'pid': 1964,
		'name': '东莞市',
		'merger_name': '中国,广东省,东莞市',
		'level': 2,
		'areas': [{
			'id': 2092,
			'pid': 2091,
			'name': '莞城区',
			'merger_name': '中国,广东省,东莞市,莞城区',
			'level': 3
		}, {
			'id': 2093,
			'pid': 2091,
			'name': '南城区',
			'merger_name': '中国,广东省,东莞市,南城区',
			'level': 3
		}, {
			'id': 2094,
			'pid': 2091,
			'name': '万江区',
			'merger_name': '中国,广东省,东莞市,万江区',
			'level': 3
		}, {
			'id': 2095,
			'pid': 2091,
			'name': '石碣镇',
			'merger_name': '中国,广东省,东莞市,石碣镇',
			'level': 3
		}, {
			'id': 2096,
			'pid': 2091,
			'name': '石龙镇',
			'merger_name': '中国,广东省,东莞市,石龙镇',
			'level': 3
		}, {
			'id': 2097,
			'pid': 2091,
			'name': '茶山镇',
			'merger_name': '中国,广东省,东莞市,茶山镇',
			'level': 3
		}, {
			'id': 2098,
			'pid': 2091,
			'name': '石排镇',
			'merger_name': '中国,广东省,东莞市,石排镇',
			'level': 3
		}, {
			'id': 2099,
			'pid': 2091,
			'name': '企石镇',
			'merger_name': '中国,广东省,东莞市,企石镇',
			'level': 3
		}, {
			'id': 2100,
			'pid': 2091,
			'name': '横沥镇',
			'merger_name': '中国,广东省,东莞市,横沥镇',
			'level': 3
		}, {
			'id': 2101,
			'pid': 2091,
			'name': '桥头镇',
			'merger_name': '中国,广东省,东莞市,桥头镇',
			'level': 3
		}, {
			'id': 2102,
			'pid': 2091,
			'name': '谢岗镇',
			'merger_name': '中国,广东省,东莞市,谢岗镇',
			'level': 3
		}, {
			'id': 2103,
			'pid': 2091,
			'name': '东坑镇',
			'merger_name': '中国,广东省,东莞市,东坑镇',
			'level': 3
		}, {
			'id': 2104,
			'pid': 2091,
			'name': '常平镇',
			'merger_name': '中国,广东省,东莞市,常平镇',
			'level': 3
		}, {
			'id': 2105,
			'pid': 2091,
			'name': '寮步镇',
			'merger_name': '中国,广东省,东莞市,寮步镇',
			'level': 3
		}, {
			'id': 2106,
			'pid': 2091,
			'name': '大朗镇',
			'merger_name': '中国,广东省,东莞市,大朗镇',
			'level': 3
		}, {
			'id': 2107,
			'pid': 2091,
			'name': '麻涌镇',
			'merger_name': '中国,广东省,东莞市,麻涌镇',
			'level': 3
		}, {
			'id': 2108,
			'pid': 2091,
			'name': '中堂镇',
			'merger_name': '中国,广东省,东莞市,中堂镇',
			'level': 3
		}, {
			'id': 2109,
			'pid': 2091,
			'name': '高埗镇',
			'merger_name': '中国,广东省,东莞市,高埗镇',
			'level': 3
		}, {
			'id': 2110,
			'pid': 2091,
			'name': "樟木头镇",
			"merger_name": "中国,广东省,东莞市,樟木头镇",
			"level": 3
		}, {
			"id": 2111,
			"pid": 2091,
			"name": "大岭山镇",
			"merger_name": "中国,广东省,东莞市,大岭山镇",
			"level": 3
		}, {
			"id": 2112,
			"pid": 2091,
			"name": "望牛墩镇",
			"merger_name": "中国,广东省,东莞市,望牛墩镇",
			"level": 3
		}, {
			"id": 2113,
			"pid": 2091,
			"name": "黄江镇",
			"merger_name": "中国,广东省,东莞市,黄江镇",
			"level": 3
		}, {
			"id": 2114,
			"pid": 2091,
			"name": "洪梅镇",
			"merger_name": "中国,广东省,东莞市,洪梅镇",
			"level": 3
		}, {
			"id": 2115,
			"pid": 2091,
			"name": "清溪镇",
			"merger_name": "中国,广东省,东莞市,清溪镇",
			"level": 3
		}, {
			"id": 2116,
			"pid": 2091,
			"name": "沙田镇",
			"merger_name": "中国,广东省,东莞市,沙田镇",
			"level": 3
		}, {
			"id": 2117,
			"pid": 2091,
			"name": "道滘镇",
			"merger_name": "中国,广东省,东莞市,道滘镇",
			"level": 3
		}, {
			"id": 2118,
			"pid": 2091,
			"name": "塘厦镇",
			"merger_name": "中国,广东省,东莞市,塘厦镇",
			"level": 3
		}, {
			"id": 2119,
			"pid": 2091,
			"name": "虎门镇",
			"merger_name": "中国,广东省,东莞市,虎门镇",
			"level": 3
		}, {
			"id": 2120,
			"pid": 2091,
			"name": "厚街镇",
			"merger_name": "中国,广东省,东莞市,厚街镇",
			"level": 3
		}, {
			"id": 2121,
			"pid": 2091,
			"name": "凤岗镇",
			"merger_name": "中国,广东省,东莞市,凤岗镇",
			"level": 3
		}, {
			"id": 2122,
			"pid": 2091,
			"name": "长安镇",
			"merger_name": "中国,广东省,东莞市,长安镇",
			"level": 3
		}, {
			"id": 3752,
			"pid": 2091,
			"name": "东城区",
			"merger_name": "中国,广东省,东莞市,东城区",
			"level": 3
		}]
	}, {
		"id": 2123,
		"pid": 1964,
		"name": "中山市",
		"merger_name": "中国,广东省,中山市",
		"level": 2,
		"areas": [{
			"id": 2124,
			"pid": 2123,
			"name": "石岐区",
			"merger_name": "中国,广东省,中山市,石岐区",
			"level": 3
		}, {
			"id": 2125,
			"pid": 2123,
			"name": "南区",
			"merger_name": "中国,广东省,中山市,南区",
			"level": 3
		}, {
			"id": 2126,
			"pid": 2123,
			"name": "五桂山区",
			"merger_name": "中国,广东省,中山市,五桂山区",
			"level": 3
		}, {
			"id": 2127,
			"pid": 2123,
			"name": "火炬开发区",
			"merger_name": "中国,广东省,中山市,火炬开发区",
			"level": 3
		}, {
			"id": 2128,
			"pid": 2123,
			"name": "黄圃镇",
			"merger_name": "中国,广东省,中山市,黄圃镇",
			"level": 3
		}, {
			"id": 2129,
			"pid": 2123,
			"name": "南头镇",
			"merger_name": "中国,广东省,中山市,南头镇",
			"level": 3
		}, {
			"id": 2130,
			"pid": 2123,
			"name": "东凤镇",
			"merger_name": "中国,广东省,中山市,东凤镇",
			"level": 3
		}, {
			"id": 2131,
			"pid": 2123,
			"name": "阜沙镇",
			"merger_name": "中国,广东省,中山市,阜沙镇",
			"level": 3
		}, {
			"id": 2132,
			"pid": 2123,
			"name": "小榄镇",
			"merger_name": "中国,广东省,中山市,小榄镇",
			"level": 3
		}, {
			"id": 2133,
			"pid": 2123,
			"name": "东升镇",
			"merger_name": "中国,广东省,中山市,东升镇",
			"level": 3
		}, {
			"id": 2134,
			"pid": 2123,
			"name": "古镇镇",
			"merger_name": "中国,广东省,中山市,古镇镇",
			"level": 3
		}, {
			"id": 2135,
			"pid": 2123,
			"name": "横栏镇",
			"merger_name": "中国,广东省,中山市,横栏镇",
			"level": 3
		}, {
			"id": 2136,
			"pid": 2123,
			"name": "三角镇",
			"merger_name": "中国,广东省,中山市,三角镇",
			"level": 3
		}, {
			"id": 2137,
			"pid": 2123,
			"name": "民众镇",
			"merger_name": "中国,广东省,中山市,民众镇",
			"level": 3
		}, {
			"id": 2138,
			"pid": 2123,
			"name": "南朗镇",
			"merger_name": "中国,广东省,中山市,南朗镇",
			"level": 3
		}, {
			"id": 2139,
			"pid": 2123,
			"name": "港口镇",
			"merger_name": "中国,广东省,中山市,港口镇",
			"level": 3
		}, {
			"id": 2140,
			"pid": 2123,
			"name": "大涌镇",
			"merger_name": "中国,广东省,中山市,大涌镇",
			"level": 3
		}, {
			"id": 2141,
			"pid": 2123,
			"name": "沙溪镇",
			"merger_name": "中国,广东省,中山市,沙溪镇",
			"level": 3
		}, {
			"id": 2142,
			"pid": 2123,
			"name": "三乡镇",
			"merger_name": "中国,广东省,中山市,三乡镇",
			"level": 3
		}, {
			"id": 2143,
			"pid": 2123,
			"name": "板芙镇",
			"merger_name": "中国,广东省,中山市,板芙镇",
			"level": 3
		}, {
			"id": 2144,
			"pid": 2123,
			"name": "神湾镇",
			"merger_name": "中国,广东省,中山市,神湾镇",
			"level": 3
		}, {
			"id": 2145,
			"pid": 2123,
			"name": "坦洲镇",
			"merger_name": "中国,广东省,中山市,坦洲镇",
			"level": 3
		}]
	}, {
		"id": 2146,
		"pid": 1964,
		"name": "潮州市",
		"merger_name": "中国,广东省,潮州市",
		"level": 2,
		"areas": [{
			"id": 2147,
			"pid": 2146,
			"name": "湘桥区",
			"merger_name": "中国,广东省,潮州市,湘桥区",
			"level": 3
		}, {
			"id": 2148,
			"pid": 2146,
			"name": "潮安区",
			"merger_name": "中国,广东省,潮州市,潮安区",
			"level": 3
		}, {
			"id": 2149,
			"pid": 2146,
			"name": "饶平县",
			"merger_name": "中国,广东省,潮州市,饶平县",
			"level": 3
		}]
	}, {
		"id": 2150,
		"pid": 1964,
		"name": "揭阳市",
		"merger_name": "中国,广东省,揭阳市",
		"level": 2,
		"areas": [{
			"id": 2151,
			"pid": 2150,
			"name": "榕城区",
			"merger_name": "中国,广东省,揭阳市,榕城区",
			"level": 3
		}, {
			"id": 2152,
			"pid": 2150,
			"name": "揭东区",
			"merger_name": "中国,广东省,揭阳市,揭东区",
			"level": 3
		}, {
			"id": 2153,
			"pid": 2150,
			"name": "揭西县",
			"merger_name": "中国,广东省,揭阳市,揭西县",
			"level": 3
		}, {
			"id": 2154,
			"pid": 2150,
			"name": "惠来县",
			"merger_name": "中国,广东省,揭阳市,惠来县",
			"level": 3
		}, {
			"id": 2155,
			"pid": 2150,
			"name": "普宁市",
			"merger_name": "中国,广东省,揭阳市,普宁市",
			"level": 3
		}]
	}, {
		"id": 2156,
		"pid": 1964,
		"name": "云浮市",
		"merger_name": "中国,广东省,云浮市",
		"level": 2,
		"areas": [{
			"id": 2157,
			"pid": 2156,
			"name": "云城区",
			"merger_name": "中国,广东省,云浮市,云城区",
			"level": 3
		}, {
			"id": 2158,
			"pid": 2156,
			"name": "云安区",
			"merger_name": "中国,广东省,云浮市,云安区",
			"level": 3
		}, {
			"id": 2159,
			"pid": 2156,
			"name": "新兴县",
			"merger_name": "中国,广东省,云浮市,新兴县",
			"level": 3
		}, {
			"id": 2160,
			"pid": 2156,
			"name": "郁南县",
			"merger_name": "中国,广东省,云浮市,郁南县",
			"level": 3
		}, {
			"id": 2161,
			"pid": 2156,
			"name": "罗定市",
			"merger_name": "中国,广东省,云浮市,罗定市",
			"level": 3
		}]
	}]
}, {
	"id": 2162,
	"pid": 0,
	"name": "广西壮族自治区",
	"merger_name": "中国,广西壮族自治区",
	"level": 1,
	"cities": [{
		"id": 2163,
		"pid": 2162,
		"name": "南宁市",
		"merger_name": "中国,广西壮族自治区,南宁市",
		"level": 2,
		"areas": [{
			"id": 2164,
			"pid": 2163,
			"name": "兴宁区",
			"merger_name": "中国,广西壮族自治区,南宁市,兴宁区",
			"level": 3
		}, {
			"id": 2165,
			"pid": 2163,
			"name": "青秀区",
			"merger_name": "中国,广西壮族自治区,南宁市,青秀区",
			"level": 3
		}, {
			"id": 2166,
			"pid": 2163,
			"name": "江南区",
			"merger_name": "中国,广西壮族自治区,南宁市,江南区",
			"level": 3
		}, {
			"id": 2167,
			"pid": 2163,
			"name": "西乡塘区",
			"merger_name": "中国,广西壮族自治区,南宁市,西乡塘区",
			"level": 3
		}, {
			"id": 2168,
			"pid": 2163,
			"name": "良庆区",
			"merger_name": "中国,广西壮族自治区,南宁市,良庆区",
			"level": 3
		}, {
			"id": 2169,
			"pid": 2163,
			"name": "邕宁区",
			"merger_name": "中国,广西壮族自治区,南宁市,邕宁区",
			"level": 3
		}, {
			"id": 2170,
			"pid": 2163,
			"name": "武鸣县",
			"merger_name": "中国,广西壮族自治区,南宁市,武鸣县",
			"level": 3
		}, {
			"id": 2171,
			"pid": 2163,
			"name": "隆安县",
			"merger_name": "中国,广西壮族自治区,南宁市,隆安县",
			"level": 3
		}, {
			"id": 2172,
			"pid": 2163,
			"name": "马山县",
			"merger_name": "中国,广西壮族自治区,南宁市,马山县",
			"level": 3
		}, {
			"id": 2173,
			"pid": 2163,
			"name": "上林县",
			"merger_name": "中国,广西壮族自治区,南宁市,上林县",
			"level": 3
		}, {
			"id": 2174,
			"pid": 2163,
			"name": "宾阳县",
			"merger_name": "中国,广西壮族自治区,南宁市,宾阳县",
			"level": 3
		}, {
			"id": 2175,
			"pid": 2163,
			"name": "横县",
			"merger_name": "中国,广西壮族自治区,南宁市,横县",
			"level": 3
		}, {
			"id": 2176,
			"pid": 2163,
			"name": "埌东新区",
			"merger_name": "中国,广西壮族自治区,南宁市,埌东新区",
			"level": 3
		}]
	}, {
		"id": 2177,
		"pid": 2162,
		"name": "柳州市",
		"merger_name": "中国,广西壮族自治区,柳州市",
		"level": 2,
		"areas": [{
			"id": 2178,
			"pid": 2177,
			"name": "城中区",
			"merger_name": "中国,广西壮族自治区,柳州市,城中区",
			"level": 3
		}, {
			"id": 2179,
			"pid": 2177,
			"name": "鱼峰区",
			"merger_name": "中国,广西壮族自治区,柳州市,鱼峰区",
			"level": 3
		}, {
			"id": 2180,
			"pid": 2177,
			"name": "柳南区",
			"merger_name": "中国,广西壮族自治区,柳州市,柳南区",
			"level": 3
		}, {
			"id": 2181,
			"pid": 2177,
			"name": "柳北区",
			"merger_name": "中国,广西壮族自治区,柳州市,柳北区",
			"level": 3
		}, {
			"id": 2182,
			"pid": 2177,
			"name": "柳江县",
			"merger_name": "中国,广西壮族自治区,柳州市,柳江县",
			"level": 3
		}, {
			"id": 2183,
			"pid": 2177,
			"name": "柳城县",
			"merger_name": "中国,广西壮族自治区,柳州市,柳城县",
			"level": 3
		}, {
			"id": 2184,
			"pid": 2177,
			"name": "鹿寨县",
			"merger_name": "中国,广西壮族自治区,柳州市,鹿寨县",
			"level": 3
		}, {
			"id": 2185,
			"pid": 2177,
			"name": "融安县",
			"merger_name": "中国,广西壮族自治区,柳州市,融安县",
			"level": 3
		}, {
			"id": 2186,
			"pid": 2177,
			"name": "融水苗族自治县",
			"merger_name": "中国,广西壮族自治区,柳州市,融水苗族自治县",
			"level": 3
		}, {
			"id": 2187,
			"pid": 2177,
			"name": "三江侗族自治县",
			"merger_name": "中国,广西壮族自治区,柳州市,三江侗族自治县",
			"level": 3
		}, {
			"id": 2188,
			"pid": 2177,
			"name": "柳东新区",
			"merger_name": "中国,广西壮族自治区,柳州市,柳东新区",
			"level": 3
		}]
	}, {
		"id": 2189,
		"pid": 2162,
		"name": "桂林市",
		"merger_name": "中国,广西壮族自治区,桂林市",
		"level": 2,
		"areas": [{
			"id": 2190,
			"pid": 2189,
			"name": "秀峰区",
			"merger_name": "中国,广西壮族自治区,桂林市,秀峰区",
			"level": 3
		}, {
			"id": 2191,
			"pid": 2189,
			"name": "叠彩区",
			"merger_name": "中国,广西壮族自治区,桂林市,叠彩区",
			"level": 3
		}, {
			"id": 2192,
			"pid": 2189,
			"name": "象山区",
			"merger_name": "中国,广西壮族自治区,桂林市,象山区",
			"level": 3
		}, {
			"id": 2193,
			"pid": 2189,
			"name": "七星区",
			"merger_name": "中国,广西壮族自治区,桂林市,七星区",
			"level": 3
		}, {
			"id": 2194,
			"pid": 2189,
			"name": "雁山区",
			"merger_name": "中国,广西壮族自治区,桂林市,雁山区",
			"level": 3
		}, {
			"id": 2195,
			"pid": 2189,
			"name": "临桂区",
			"merger_name": "中国,广西壮族自治区,桂林市,临桂区",
			"level": 3
		}, {
			"id": 2196,
			"pid": 2189,
			"name": "阳朔县",
			"merger_name": "中国,广西壮族自治区,桂林市,阳朔县",
			"level": 3
		}, {
			"id": 2197,
			"pid": 2189,
			"name": "灵川县",
			"merger_name": "中国,广西壮族自治区,桂林市,灵川县",
			"level": 3
		}, {
			"id": 2198,
			"pid": 2189,
			"name": "全州县",
			"merger_name": "中国,广西壮族自治区,桂林市,全州县",
			"level": 3
		}, {
			"id": 2199,
			"pid": 2189,
			"name": "兴安县",
			"merger_name": "中国,广西壮族自治区,桂林市,兴安县",
			"level": 3
		}, {
			"id": 2200,
			"pid": 2189,
			"name": "永福县",
			"merger_name": "中国,广西壮族自治区,桂林市,永福县",
			"level": 3
		}, {
			"id": 2201,
			"pid": 2189,
			"name": "灌阳县",
			"merger_name": "中国,广西壮族自治区,桂林市,灌阳县",
			"level": 3
		}, {
			"id": 2202,
			"pid": 2189,
			"name": "龙胜各族自治县",
			"merger_name": "中国,广西壮族自治区,桂林市,龙胜各族自治县",
			"level": 3
		}, {
			"id": 2203,
			"pid": 2189,
			"name": "资源县",
			"merger_name": "中国,广西壮族自治区,桂林市,资源县",
			"level": 3
		}, {
			"id": 2204,
			"pid": 2189,
			"name": "平乐县",
			"merger_name": "中国,广西壮族自治区,桂林市,平乐县",
			"level": 3
		}, {
			"id": 2205,
			"pid": 2189,
			"name": "荔浦县",
			"merger_name": "中国,广西壮族自治区,桂林市,荔浦县",
			"level": 3
		}, {
			"id": 2206,
			"pid": 2189,
			"name": "恭城瑶族自治县",
			"merger_name": "中国,广西壮族自治区,桂林市,恭城瑶族自治县",
			"level": 3
		}]
	}, {
		"id": 2207,
		"pid": 2162,
		"name": "梧州市",
		"merger_name": "中国,广西壮族自治区,梧州市",
		"level": 2,
		"areas": [{
			"id": 2208,
			"pid": 2207,
			"name": "万秀区",
			"merger_name": "中国,广西壮族自治区,梧州市,万秀区",
			"level": 3
		}, {
			"id": 2209,
			"pid": 2207,
			"name": "长洲区",
			"merger_name": "中国,广西壮族自治区,梧州市,长洲区",
			"level": 3
		}, {
			"id": 2210,
			"pid": 2207,
			"name": "龙圩区",
			"merger_name": "中国,广西壮族自治区,梧州市,龙圩区",
			"level": 3
		}, {
			"id": 2211,
			"pid": 2207,
			"name": "苍梧县",
			"merger_name": "中国,广西壮族自治区,梧州市,苍梧县",
			"level": 3
		}, {
			"id": 2212,
			"pid": 2207,
			"name": "藤县",
			"merger_name": "中国,广西壮族自治区,梧州市,藤县",
			"level": 3
		}, {
			"id": 2213,
			"pid": 2207,
			"name": "蒙山县",
			"merger_name": "中国,广西壮族自治区,梧州市,蒙山县",
			"level": 3
		}, {
			"id": 2214,
			"pid": 2207,
			"name": "岑溪市",
			"merger_name": "中国,广西壮族自治区,梧州市,岑溪市",
			"level": 3
		}]
	}, {
		"id": 2215,
		"pid": 2162,
		"name": "北海市",
		"merger_name": "中国,广西壮族自治区,北海市",
		"level": 2,
		"areas": [{
			"id": 2216,
			"pid": 2215,
			"name": "海城区",
			"merger_name": "中国,广西壮族自治区,北海市,海城区",
			"level": 3
		}, {
			"id": 2217,
			"pid": 2215,
			"name": "银海区",
			"merger_name": "中国,广西壮族自治区,北海市,银海区",
			"level": 3
		}, {
			"id": 2218,
			"pid": 2215,
			"name": "铁山港区",
			"merger_name": "中国,广西壮族自治区,北海市,铁山港区",
			"level": 3
		}, {
			"id": 2219,
			"pid": 2215,
			"name": "合浦县",
			"merger_name": "中国,广西壮族自治区,北海市,合浦县",
			"level": 3
		}]
	}, {
		"id": 2220,
		"pid": 2162,
		"name": "防城港市",
		"merger_name": "中国,广西壮族自治区,防城港市",
		"level": 2,
		"areas": [{
			"id": 2221,
			"pid": 2220,
			"name": "港口区",
			"merger_name": "中国,广西壮族自治区,防城港市,港口区",
			"level": 3
		}, {
			"id": 2222,
			"pid": 2220,
			"name": "防城区",
			"merger_name": "中国,广西壮族自治区,防城港市,防城区",
			"level": 3
		}, {
			"id": 2223,
			"pid": 2220,
			"name": "上思县",
			"merger_name": "中国,广西壮族自治区,防城港市,上思县",
			"level": 3
		}, {
			"id": 2224,
			"pid": 2220,
			"name": "东兴市",
			"merger_name": "中国,广西壮族自治区,防城港市,东兴市",
			"level": 3
		}]
	}, {
		"id": 2225,
		"pid": 2162,
		"name": "钦州市",
		"merger_name": "中国,广西壮族自治区,钦州市",
		"level": 2,
		"areas": [{
			"id": 2226,
			"pid": 2225,
			"name": "钦南区",
			"merger_name": "中国,广西壮族自治区,钦州市,钦南区",
			"level": 3
		}, {
			"id": 2227,
			"pid": 2225,
			"name": "钦北区",
			"merger_name": "中国,广西壮族自治区,钦州市,钦北区",
			"level": 3
		}, {
			"id": 2228,
			"pid": 2225,
			"name": "灵山县",
			"merger_name": "中国,广西壮族自治区,钦州市,灵山县",
			"level": 3
		}, {
			"id": 2229,
			"pid": 2225,
			"name": "浦北县",
			"merger_name": "中国,广西壮族自治区,钦州市,浦北县",
			"level": 3
		}]
	}, {
		"id": 2230,
		"pid": 2162,
		"name": "贵港市",
		"merger_name": "中国,广西壮族自治区,贵港市",
		"level": 2,
		"areas": [{
			"id": 2231,
			"pid": 2230,
			"name": "港北区",
			"merger_name": "中国,广西壮族自治区,贵港市,港北区",
			"level": 3
		}, {
			"id": 2232,
			"pid": 2230,
			"name": "港南区",
			"merger_name": "中国,广西壮族自治区,贵港市,港南区",
			"level": 3
		}, {
			"id": 2233,
			"pid": 2230,
			"name": "覃塘区",
			"merger_name": "中国,广西壮族自治区,贵港市,覃塘区",
			"level": 3
		}, {
			"id": 2234,
			"pid": 2230,
			"name": "平南县",
			"merger_name": "中国,广西壮族自治区,贵港市,平南县",
			"level": 3
		}, {
			"id": 2235,
			"pid": 2230,
			"name": "桂平市",
			"merger_name": "中国,广西壮族自治区,贵港市,桂平市",
			"level": 3
		}]
	}, {
		"id": 2236,
		"pid": 2162,
		"name": "玉林市",
		"merger_name": "中国,广西壮族自治区,玉林市",
		"level": 2,
		"areas": [{
			"id": 2237,
			"pid": 2236,
			"name": "玉州区",
			"merger_name": "中国,广西壮族自治区,玉林市,玉州区",
			"level": 3
		}, {
			"id": 2238,
			"pid": 2236,
			"name": "福绵区",
			"merger_name": "中国,广西壮族自治区,玉林市,福绵区",
			"level": 3
		}, {
			"id": 2239,
			"pid": 2236,
			"name": "玉东新区",
			"merger_name": "中国,广西壮族自治区,玉林市,玉东新区",
			"level": 3
		}, {
			"id": 2240,
			"pid": 2236,
			"name": "容县",
			"merger_name": "中国,广西壮族自治区,玉林市,容县",
			"level": 3
		}, {
			"id": 2241,
			"pid": 2236,
			"name": "陆川县",
			"merger_name": "中国,广西壮族自治区,玉林市,陆川县",
			"level": 3
		}, {
			"id": 2242,
			"pid": 2236,
			"name": "博白县",
			"merger_name": "中国,广西壮族自治区,玉林市,博白县",
			"level": 3
		}, {
			"id": 2243,
			"pid": 2236,
			"name": "兴业县",
			"merger_name": "中国,广西壮族自治区,玉林市,兴业县",
			"level": 3
		}, {
			"id": 2244,
			"pid": 2236,
			"name": "北流市",
			"merger_name": "中国,广西壮族自治区,玉林市,北流市",
			"level": 3
		}]
	}, {
		"id": 2245,
		"pid": 2162,
		"name": "百色市",
		"merger_name": "中国,广西壮族自治区,百色市",
		"level": 2,
		"areas": [{
			"id": 2246,
			"pid": 2245,
			"name": "右江区",
			"merger_name": "中国,广西壮族自治区,百色市,右江区",
			"level": 3
		}, {
			"id": 2247,
			"pid": 2245,
			"name": "田阳县",
			"merger_name": "中国,广西壮族自治区,百色市,田阳县",
			"level": 3
		}, {
			"id": 2248,
			"pid": 2245,
			"name": "田东县",
			"merger_name": "中国,广西壮族自治区,百色市,田东县",
			"level": 3
		}, {
			"id": 2249,
			"pid": 2245,
			"name": "平果县",
			"merger_name": "中国,广西壮族自治区,百色市,平果县",
			"level": 3
		}, {
			"id": 2250,
			"pid": 2245,
			"name": "德保县",
			"merger_name": "中国,广西壮族自治区,百色市,德保县",
			"level": 3
		}, {
			"id": 2251,
			"pid": 2245,
			"name": "靖西县",
			"merger_name": "中国,广西壮族自治区,百色市,靖西县",
			"level": 3
		}, {
			"id": 2252,
			"pid": 2245,
			"name": "那坡县",
			"merger_name": "中国,广西壮族自治区,百色市,那坡县",
			"level": 3
		}, {
			"id": 2253,
			"pid": 2245,
			"name": "凌云县",
			"merger_name": "中国,广西壮族自治区,百色市,凌云县",
			"level": 3
		}, {
			"id": 2254,
			"pid": 2245,
			"name": "乐业县",
			"merger_name": "中国,广西壮族自治区,百色市,乐业县",
			"level": 3
		}, {
			"id": 2255,
			"pid": 2245,
			"name": "田林县",
			"merger_name": "中国,广西壮族自治区,百色市,田林县",
			"level": 3
		}, {
			"id": 2256,
			"pid": 2245,
			"name": "西林县",
			"merger_name": "中国,广西壮族自治区,百色市,西林县",
			"level": 3
		}, {
			"id": 2257,
			"pid": 2245,
			"name": "隆林各族自治县",
			"merger_name": "中国,广西壮族自治区,百色市,隆林各族自治县",
			"level": 3
		}]
	}, {
		"id": 2258,
		"pid": 2162,
		"name": "贺州市",
		"merger_name": "中国,广西壮族自治区,贺州市",
		"level": 2,
		"areas": [{
			"id": 2259,
			"pid": 2258,
			"name": "八步区",
			"merger_name": "中国,广西壮族自治区,贺州市,八步区",
			"level": 3
		}, {
			"id": 2260,
			"pid": 2258,
			"name": "昭平县",
			"merger_name": "中国,广西壮族自治区,贺州市,昭平县",
			"level": 3
		}, {
			"id": 2261,
			"pid": 2258,
			"name": "钟山县",
			"merger_name": "中国,广西壮族自治区,贺州市,钟山县",
			"level": 3
		}, {
			"id": 2262,
			"pid": 2258,
			"name": "富川瑶族自治县",
			"merger_name": "中国,广西壮族自治区,贺州市,富川瑶族自治县",
			"level": 3
		}, {
			"id": 2263,
			"pid": 2258,
			"name": "平桂管理区",
			"merger_name": "中国,广西壮族自治区,贺州市,平桂管理区",
			"level": 3
		}]
	}, {
		"id": 2264,
		"pid": 2162,
		"name": "河池市",
		"merger_name": "中国,广西壮族自治区,河池市",
		"level": 2,
		"areas": [{
			"id": 2265,
			"pid": 2264,
			"name": "金城江区",
			"merger_name": "中国,广西壮族自治区,河池市,金城江区",
			"level": 3
		}, {
			"id": 2266,
			"pid": 2264,
			"name": "南丹县",
			"merger_name": "中国,广西壮族自治区,河池市,南丹县",
			"level": 3
		}, {
			"id": 2267,
			"pid": 2264,
			"name": "天峨县",
			"merger_name": "中国,广西壮族自治区,河池市,天峨县",
			"level": 3
		}, {
			"id": 2268,
			"pid": 2264,
			"name": "凤山县",
			"merger_name": "中国,广西壮族自治区,河池市,凤山县",
			"level": 3
		}, {
			"id": 2269,
			"pid": 2264,
			"name": "东兰县",
			"merger_name": "中国,广西壮族自治区,河池市,东兰县",
			"level": 3
		}, {
			"id": 2270,
			"pid": 2264,
			"name": "罗城仫佬族自治县",
			"merger_name": "中国,广西壮族自治区,河池市,罗城仫佬族自治县",
			"level": 3
		}, {
			"id": 2271,
			"pid": 2264,
			"name": "环江毛南族自治县",
			"merger_name": "中国,广西壮族自治区,河池市,环江毛南族自治县",
			"level": 3
		}, {
			"id": 2272,
			"pid": 2264,
			"name": "巴马瑶族自治县",
			"merger_name": "中国,广西壮族自治区,河池市,巴马瑶族自治县",
			"level": 3
		}, {
			"id": 2273,
			"pid": 2264,
			"name": "都安瑶族自治县",
			"merger_name": "中国,广西壮族自治区,河池市,都安瑶族自治县",
			"level": 3
		}, {
			"id": 2274,
			"pid": 2264,
			"name": "大化瑶族自治县",
			"merger_name": "中国,广西壮族自治区,河池市,大化瑶族自治县",
			"level": 3
		}, {
			"id": 2275,
			"pid": 2264,
			"name": "宜州市",
			"merger_name": "中国,广西壮族自治区,河池市,宜州市",
			"level": 3
		}]
	}, {
		"id": 2276,
		"pid": 2162,
		"name": "来宾市",
		"merger_name": "中国,广西壮族自治区,来宾市",
		"level": 2,
		"areas": [{
			"id": 2277,
			"pid": 2276,
			"name": "兴宾区",
			"merger_name": "中国,广西壮族自治区,来宾市,兴宾区",
			"level": 3
		}, {
			"id": 2278,
			"pid": 2276,
			"name": "忻城县",
			"merger_name": "中国,广西壮族自治区,来宾市,忻城县",
			"level": 3
		}, {
			"id": 2279,
			"pid": 2276,
			"name": "象州县",
			"merger_name": "中国,广西壮族自治区,来宾市,象州县",
			"level": 3
		}, {
			"id": 2280,
			"pid": 2276,
			"name": "武宣县",
			"merger_name": "中国,广西壮族自治区,来宾市,武宣县",
			"level": 3
		}, {
			"id": 2281,
			"pid": 2276,
			"name": "金秀瑶族自治县",
			"merger_name": "中国,广西壮族自治区,来宾市,金秀瑶族自治县",
			"level": 3
		}, {
			"id": 2282,
			"pid": 2276,
			"name": "合山市",
			"merger_name": "中国,广西壮族自治区,来宾市,合山市",
			"level": 3
		}]
	}, {
		"id": 2283,
		"pid": 2162,
		"name": "崇左市",
		"merger_name": "中国,广西壮族自治区,崇左市",
		"level": 2,
		"areas": [{
			"id": 2284,
			"pid": 2283,
			"name": "江州区",
			"merger_name": "中国,广西壮族自治区,崇左市,江州区",
			"level": 3
		}, {
			"id": 2285,
			"pid": 2283,
			"name": "扶绥县",
			"merger_name": "中国,广西壮族自治区,崇左市,扶绥县",
			"level": 3
		}, {
			"id": 2286,
			"pid": 2283,
			"name": "宁明县",
			"merger_name": "中国,广西壮族自治区,崇左市,宁明县",
			"level": 3
		}, {
			"id": 2287,
			"pid": 2283,
			"name": "龙州县",
			"merger_name": "中国,广西壮族自治区,崇左市,龙州县",
			"level": 3
		}, {
			"id": 2288,
			"pid": 2283,
			"name": "大新县",
			"merger_name": "中国,广西壮族自治区,崇左市,大新县",
			"level": 3
		}, {
			"id": 2289,
			"pid": 2283,
			"name": "天等县",
			"merger_name": "中国,广西壮族自治区,崇左市,天等县",
			"level": 3
		}, {
			"id": 2290,
			"pid": 2283,
			"name": "凭祥市",
			"merger_name": "中国,广西壮族自治区,崇左市,凭祥市",
			"level": 3
		}]
	}]
}, {
	"id": 2291,
	"pid": 0,
	"name": "海南省",
	"merger_name": "中国,海南省",
	"level": 1,
	"cities": [{
		"id": 2292,
		"pid": 2291,
		"name": "海口市",
		"merger_name": "中国,海南省,海口市",
		"level": 2,
		"areas": [{
			"id": 2293,
			"pid": 2292,
			"name": "秀英区",
			"merger_name": "中国,海南省,海口市,秀英区",
			"level": 3
		}, {
			"id": 2294,
			"pid": 2292,
			"name": "龙华区",
			"merger_name": "中国,海南省,海口市,龙华区",
			"level": 3
		}, {
			"id": 2295,
			"pid": 2292,
			"name": "琼山区",
			"merger_name": "中国,海南省,海口市,琼山区",
			"level": 3
		}, {
			"id": 2296,
			"pid": 2292,
			"name": "美兰区",
			"merger_name": "中国,海南省,海口市,美兰区",
			"level": 3
		}]
	}, {
		"id": 2297,
		"pid": 2291,
		"name": "三亚市",
		"merger_name": "中国,海南省,三亚市",
		"level": 2,
		"areas": [{
			"id": 2298,
			"pid": 2297,
			"name": "海棠区",
			"merger_name": "中国,海南省,三亚市,海棠区",
			"level": 3
		}, {
			"id": 2299,
			"pid": 2297,
			"name": "吉阳区",
			"merger_name": "中国,海南省,三亚市,吉阳区",
			"level": 3
		}, {
			"id": 2300,
			"pid": 2297,
			"name": "天涯区",
			"merger_name": "中国,海南省,三亚市,天涯区",
			"level": 3
		}, {
			"id": 2301,
			"pid": 2297,
			"name": "崖州区",
			"merger_name": "中国,海南省,三亚市,崖州区",
			"level": 3
		}]
	}, {
		"id": 2302,
		"pid": 2291,
		"name": "三沙市",
		"merger_name": "中国,海南省,三沙市",
		"level": 2,
		"areas": [{
			"id": 2303,
			"pid": 2302,
			"name": "西沙群岛",
			"merger_name": "中国,海南省,三沙市,西沙群岛",
			"level": 3
		}, {
			"id": 2304,
			"pid": 2302,
			"name": "南沙群岛",
			"merger_name": "中国,海南省,三沙市,南沙群岛",
			"level": 3
		}, {
			"id": 2305,
			"pid": 2302,
			"name": "中沙群岛",
			"merger_name": "中国,海南省,三沙市,中沙群岛",
			"level": 3
		}]
	}, {
		"id": 2306,
		"pid": 2291,
		"name": "直辖县级",
		"merger_name": "中国,海南省,直辖县级",
		"level": 2,
		"areas": [{
			"id": 2307,
			"pid": 2306,
			"name": "五指山市",
			"merger_name": "中国,海南省,直辖县级,五指山市",
			"level": 3
		}, {
			"id": 2308,
			"pid": 2306,
			"name": "琼海市",
			"merger_name": "中国,海南省,直辖县级,琼海市",
			"level": 3
		}, {
			"id": 2309,
			"pid": 2306,
			"name": "儋州市",
			"merger_name": "中国,海南省,直辖县级,儋州市",
			"level": 3
		}, {
			"id": 2310,
			"pid": 2306,
			"name": "文昌市",
			"merger_name": "中国,海南省,直辖县级,文昌市",
			"level": 3
		}, {
			"id": 2311,
			"pid": 2306,
			"name": "万宁市",
			"merger_name": "中国,海南省,直辖县级,万宁市",
			"level": 3
		}, {
			"id": 2312,
			"pid": 2306,
			"name": "东方市",
			"merger_name": "中国,海南省,直辖县级,东方市",
			"level": 3
		}, {
			"id": 2313,
			"pid": 2306,
			"name": "定安县",
			"merger_name": "中国,海南省,直辖县级,定安县",
			"level": 3
		}, {
			"id": 2314,
			"pid": 2306,
			"name": "屯昌县",
			"merger_name": "中国,海南省,直辖县级,屯昌县",
			"level": 3
		}, {
			"id": 2315,
			"pid": 2306,
			"name": "澄迈县",
			"merger_name": "中国,海南省,直辖县级,澄迈县",
			"level": 3
		}, {
			"id": 2316,
			"pid": 2306,
			"name": "临高县",
			"merger_name": "中国,海南省,直辖县级,临高县",
			"level": 3
		}, {
			"id": 2317,
			"pid": 2306,
			"name": "白沙黎族自治县",
			"merger_name": "中国,海南省,直辖县级,白沙黎族自治县",
			"level": 3
		}, {
			"id": 2318,
			"pid": 2306,
			"name": "昌江黎族自治县",
			"merger_name": "中国,海南省,直辖县级,昌江黎族自治县",
			"level": 3
		}, {
			"id": 2319,
			"pid": 2306,
			"name": "乐东黎族自治县",
			"merger_name": "中国,海南省,直辖县级,乐东黎族自治县",
			"level": 3
		}, {
			"id": 2320,
			"pid": 2306,
			"name": "陵水黎族自治县",
			"merger_name": "中国,海南省,直辖县级,陵水黎族自治县",
			"level": 3
		}, {
			"id": 2321,
			"pid": 2306,
			"name": "保亭黎族苗族自治县",
			"merger_name": "中国,海南省,直辖县级,保亭黎族苗族自治县",
			"level": 3
		}, {
			"id": 2322,
			"pid": 2306,
			"name": "琼中黎族苗族自治县",
			"merger_name": "中国,海南省,直辖县级,琼中黎族苗族自治县",
			"level": 3
		}]
	}]
}, {
	"id": 2323,
	"pid": 0,
	"name": "重庆市",
	"merger_name": "中国,重庆市",
	"level": 1,
	"cities": [{
		"id": 2324,
		"pid": 2323,
		"name": "重庆市",
		"merger_name": "中国,重庆,重庆市",
		"level": 2,
		"areas": [{
			"id": 2325,
			"pid": 2324,
			"name": "万州区",
			"merger_name": "中国,重庆,重庆市,万州区",
			"level": 3
		}, {
			"id": 2326,
			"pid": 2324,
			"name": "涪陵区",
			"merger_name": "中国,重庆,重庆市,涪陵区",
			"level": 3
		}, {
			"id": 2327,
			"pid": 2324,
			"name": "渝中区",
			"merger_name": "中国,重庆,重庆市,渝中区",
			"level": 3
		}, {
			"id": 2328,
			"pid": 2324,
			"name": "大渡口区",
			"merger_name": "中国,重庆,重庆市,大渡口区",
			"level": 3
		}, {
			"id": 2329,
			"pid": 2324,
			"name": "江北区",
			"merger_name": "中国,重庆,重庆市,江北区",
			"level": 3
		}, {
			"id": 2330,
			"pid": 2324,
			"name": "沙坪坝区",
			"merger_name": "中国,重庆,重庆市,沙坪坝区",
			"level": 3
		}, {
			"id": 2331,
			"pid": 2324,
			"name": "九龙坡区",
			"merger_name": "中国,重庆,重庆市,九龙坡区",
			"level": 3
		}, {
			"id": 2332,
			"pid": 2324,
			"name": "南岸区",
			"merger_name": "中国,重庆,重庆市,南岸区",
			"level": 3
		}, {
			"id": 2333,
			"pid": 2324,
			"name": "北碚区",
			"merger_name": "中国,重庆,重庆市,北碚区",
			"level": 3
		}, {
			"id": 2334,
			"pid": 2324,
			"name": "綦江区",
			"merger_name": "中国,重庆,重庆市,綦江区",
			"level": 3
		}, {
			"id": 2335,
			"pid": 2324,
			"name": "大足区",
			"merger_name": "中国,重庆,重庆市,大足区",
			"level": 3
		}, {
			"id": 2336,
			"pid": 2324,
			"name": "渝北区",
			"merger_name": "中国,重庆,重庆市,渝北区",
			"level": 3
		}, {
			"id": 2337,
			"pid": 2324,
			"name": "巴南区",
			"merger_name": "中国,重庆,重庆市,巴南区",
			"level": 3
		}, {
			"id": 2338,
			"pid": 2324,
			"name": "黔江区",
			"merger_name": "中国,重庆,重庆市,黔江区",
			"level": 3
		}, {
			"id": 2339,
			"pid": 2324,
			"name": "长寿区",
			"merger_name": "中国,重庆,重庆市,长寿区",
			"level": 3
		}, {
			"id": 2340,
			"pid": 2324,
			"name": "江津区",
			"merger_name": "中国,重庆,重庆市,江津区",
			"level": 3
		}, {
			"id": 2341,
			"pid": 2324,
			"name": "合川区",
			"merger_name": "中国,重庆,重庆市,合川区",
			"level": 3
		}, {
			"id": 2342,
			"pid": 2324,
			"name": "永川区",
			"merger_name": "中国,重庆,重庆市,永川区",
			"level": 3
		}, {
			"id": 2343,
			"pid": 2324,
			"name": "南川区",
			"merger_name": "中国,重庆,重庆市,南川区",
			"level": 3
		}, {
			"id": 2344,
			"pid": 2324,
			"name": "璧山区",
			"merger_name": "中国,重庆,重庆市,璧山区",
			"level": 3
		}, {
			"id": 2345,
			"pid": 2324,
			"name": "铜梁区",
			"merger_name": "中国,重庆,重庆市,铜梁区",
			"level": 3
		}, {
			"id": 2346,
			"pid": 2324,
			"name": "潼南县",
			"merger_name": "中国,重庆,重庆市,潼南县",
			"level": 3
		}, {
			"id": 2347,
			"pid": 2324,
			"name": "荣昌县",
			"merger_name": "中国,重庆,重庆市,荣昌县",
			"level": 3
		}, {
			"id": 2348,
			"pid": 2324,
			"name": "梁平县",
			"merger_name": "中国,重庆,重庆市,梁平县",
			"level": 3
		}, {
			"id": 2349,
			"pid": 2324,
			"name": "城口县",
			"merger_name": "中国,重庆,重庆市,城口县",
			"level": 3
		}, {
			"id": 2350,
			"pid": 2324,
			"name": "丰都县",
			"merger_name": "中国,重庆,重庆市,丰都县",
			"level": 3
		}, {
			"id": 2351,
			"pid": 2324,
			"name": "垫江县",
			"merger_name": "中国,重庆,重庆市,垫江县",
			"level": 3
		}, {
			"id": 2352,
			"pid": 2324,
			"name": "武隆县",
			"merger_name": "中国,重庆,重庆市,武隆县",
			"level": 3
		}, {
			"id": 2353,
			"pid": 2324,
			"name": "忠县",
			"merger_name": "中国,重庆,重庆市,忠县",
			"level": 3
		}, {
			"id": 2354,
			"pid": 2324,
			"name": "开县",
			"merger_name": "中国,重庆,重庆市,开县",
			"level": 3
		}, {
			"id": 2355,
			"pid": 2324,
			"name": "云阳县",
			"merger_name": "中国,重庆,重庆市,云阳县",
			"level": 3
		}, {
			"id": 2356,
			"pid": 2324,
			"name": "奉节县",
			"merger_name": "中国,重庆,重庆市,奉节县",
			"level": 3
		}, {
			"id": 2357,
			"pid": 2324,
			"name": "巫山县",
			"merger_name": "中国,重庆,重庆市,巫山县",
			"level": 3
		}, {
			"id": 2358,
			"pid": 2324,
			"name": "巫溪县",
			"merger_name": "中国,重庆,重庆市,巫溪县",
			"level": 3
		}, {
			"id": 2359,
			"pid": 2324,
			"name": "石柱土家族自治县",
			"merger_name": "中国,重庆,重庆市,石柱土家族自治县",
			"level": 3
		}, {
			"id": 2360,
			"pid": 2324,
			"name": "秀山土家族苗族自治县",
			"merger_name": "中国,重庆,重庆市,秀山土家族苗族自治县",
			"level": 3
		}, {
			"id": 2361,
			"pid": 2324,
			"name": "酉阳土家族苗族自治县",
			"merger_name": "中国,重庆,重庆市,酉阳土家族苗族自治县",
			"level": 3
		}, {
			"id": 2362,
			"pid": 2324,
			"name": "彭水苗族土家族自治县",
			"merger_name": "中国,重庆,重庆市,彭水苗族土家族自治县",
			"level": 3
		}]
	}, {
		"id": 2363,
		"pid": 2323,
		"name": "两江新区",
		"merger_name": "中国,重庆,两江新区",
		"level": 2,
		"areas": [{
			"id": 2364,
			"pid": 2363,
			"name": "北部新区",
			"merger_name": "中国,重庆,两江新区,北部新区",
			"level": 3
		}, {
			"id": 2365,
			"pid": 2363,
			"name": "保税港区",
			"merger_name": "中国,重庆,两江新区,保税港区",
			"level": 3
		}, {
			"id": 2366,
			"pid": 2363,
			"name": "工业园区",
			"merger_name": "中国,重庆,两江新区,工业园区",
			"level": 3
		}]
	}]
}, {
	"id": 2367,
	"pid": 0,
	"name": "四川省",
	"merger_name": "中国,四川省",
	"level": 1,
	"cities": [{
		"id": 2368,
		"pid": 2367,
		"name": "成都市",
		"merger_name": "中国,四川省,成都市",
		"level": 2,
		"areas": [{
			"id": 2369,
			"pid": 2368,
			"name": "锦江区",
			"merger_name": "中国,四川省,成都市,锦江区",
			"level": 3
		}, {
			"id": 2370,
			"pid": 2368,
			"name": "青羊区",
			"merger_name": "中国,四川省,成都市,青羊区",
			"level": 3
		}, {
			"id": 2371,
			"pid": 2368,
			"name": "金牛区",
			"merger_name": "中国,四川省,成都市,金牛区",
			"level": 3
		}, {
			"id": 2372,
			"pid": 2368,
			"name": "武侯区",
			"merger_name": "中国,四川省,成都市,武侯区",
			"level": 3
		}, {
			"id": 2373,
			"pid": 2368,
			"name": "成华区",
			"merger_name": "中国,四川省,成都市,成华区",
			"level": 3
		}, {
			"id": 2374,
			"pid": 2368,
			"name": "龙泉驿区",
			"merger_name": "中国,四川省,成都市,龙泉驿区",
			"level": 3
		}, {
			"id": 2375,
			"pid": 2368,
			"name": "青白江区",
			"merger_name": "中国,四川省,成都市,青白江区",
			"level": 3
		}, {
			"id": 2376,
			"pid": 2368,
			"name": "新都区",
			"merger_name": "中国,四川省,成都市,新都区",
			"level": 3
		}, {
			"id": 2377,
			"pid": 2368,
			"name": "温江区",
			"merger_name": "中国,四川省,成都市,温江区",
			"level": 3
		}, {
			"id": 2378,
			"pid": 2368,
			"name": "金堂县",
			"merger_name": "中国,四川省,成都市,金堂县",
			"level": 3
		}, {
			"id": 2379,
			"pid": 2368,
			"name": "双流县",
			"merger_name": "中国,四川省,成都市,双流县",
			"level": 3
		}, {
			"id": 2380,
			"pid": 2368,
			"name": "郫县",
			"merger_name": "中国,四川省,成都市,郫县",
			"level": 3
		}, {
			"id": 2381,
			"pid": 2368,
			"name": "大邑县",
			"merger_name": "中国,四川省,成都市,大邑县",
			"level": 3
		}, {
			"id": 2382,
			"pid": 2368,
			"name": "蒲江县",
			"merger_name": "中国,四川省,成都市,蒲江县",
			"level": 3
		}, {
			"id": 2383,
			"pid": 2368,
			"name": "新津县",
			"merger_name": "中国,四川省,成都市,新津县",
			"level": 3
		}, {
			"id": 2384,
			"pid": 2368,
			"name": "都江堰市",
			"merger_name": "中国,四川省,成都市,都江堰市",
			"level": 3
		}, {
			"id": 2385,
			"pid": 2368,
			"name": "彭州市",
			"merger_name": "中国,四川省,成都市,彭州市",
			"level": 3
		}, {
			"id": 2386,
			"pid": 2368,
			"name": "邛崃市",
			"merger_name": "中国,四川省,成都市,邛崃市",
			"level": 3
		}, {
			"id": 2387,
			"pid": 2368,
			"name": "崇州市",
			"merger_name": "中国,四川省,成都市,崇州市",
			"level": 3
		}]
	}, {
		"id": 2388,
		"pid": 2367,
		"name": "自贡市",
		"merger_name": "中国,四川省,自贡市",
		"level": 2,
		"areas": [{
			"id": 2389,
			"pid": 2388,
			"name": "自流井区",
			"merger_name": "中国,四川省,自贡市,自流井区",
			"level": 3
		}, {
			"id": 2390,
			"pid": 2388,
			"name": "贡井区",
			"merger_name": "中国,四川省,自贡市,贡井区",
			"level": 3
		}, {
			"id": 2391,
			"pid": 2388,
			"name": "大安区",
			"merger_name": "中国,四川省,自贡市,大安区",
			"level": 3
		}, {
			"id": 2392,
			"pid": 2388,
			"name": "沿滩区",
			"merger_name": "中国,四川省,自贡市,沿滩区",
			"level": 3
		}, {
			"id": 2393,
			"pid": 2388,
			"name": "荣县",
			"merger_name": "中国,四川省,自贡市,荣县",
			"level": 3
		}, {
			"id": 2394,
			"pid": 2388,
			"name": "富顺县",
			"merger_name": "中国,四川省,自贡市,富顺县",
			"level": 3
		}]
	}, {
		"id": 2395,
		"pid": 2367,
		"name": "攀枝花市",
		"merger_name": "中国,四川省,攀枝花市",
		"level": 2,
		"areas": [{
			"id": 2396,
			"pid": 2395,
			"name": "东区",
			"merger_name": "中国,四川省,攀枝花市,东区",
			"level": 3
		}, {
			"id": 2397,
			"pid": 2395,
			"name": "西区",
			"merger_name": "中国,四川省,攀枝花市,西区",
			"level": 3
		}, {
			"id": 2398,
			"pid": 2395,
			"name": "仁和区",
			"merger_name": "中国,四川省,攀枝花市,仁和区",
			"level": 3
		}, {
			"id": 2399,
			"pid": 2395,
			"name": "米易县",
			"merger_name": "中国,四川省,攀枝花市,米易县",
			"level": 3
		}, {
			"id": 2400,
			"pid": 2395,
			"name": "盐边县",
			"merger_name": "中国,四川省,攀枝花市,盐边县",
			"level": 3
		}]
	}, {
		"id": 2401,
		"pid": 2367,
		"name": "泸州市",
		"merger_name": "中国,四川省,泸州市",
		"level": 2,
		"areas": [{
			"id": 2402,
			"pid": 2401,
			"name": "江阳区",
			"merger_name": "中国,四川省,泸州市,江阳区",
			"level": 3
		}, {
			"id": 2403,
			"pid": 2401,
			"name": "纳溪区",
			"merger_name": "中国,四川省,泸州市,纳溪区",
			"level": 3
		}, {
			"id": 2404,
			"pid": 2401,
			"name": "龙马潭区",
			"merger_name": "中国,四川省,泸州市,龙马潭区",
			"level": 3
		}, {
			"id": 2405,
			"pid": 2401,
			"name": "泸县",
			"merger_name": "中国,四川省,泸州市,泸县",
			"level": 3
		}, {
			"id": 2406,
			"pid": 2401,
			"name": "合江县",
			"merger_name": "中国,四川省,泸州市,合江县",
			"level": 3
		}, {
			"id": 2407,
			"pid": 2401,
			"name": "叙永县",
			"merger_name": "中国,四川省,泸州市,叙永县",
			"level": 3
		}, {
			"id": 2408,
			"pid": 2401,
			"name": "古蔺县",
			"merger_name": "中国,四川省,泸州市,古蔺县",
			"level": 3
		}]
	}, {
		"id": 2409,
		"pid": 2367,
		"name": "德阳市",
		"merger_name": "中国,四川省,德阳市",
		"level": 2,
		"areas": [{
			"id": 2410,
			"pid": 2409,
			"name": "旌阳区",
			"merger_name": "中国,四川省,德阳市,旌阳区",
			"level": 3
		}, {
			"id": 2411,
			"pid": 2409,
			"name": "中江县",
			"merger_name": "中国,四川省,德阳市,中江县",
			"level": 3
		}, {
			"id": 2412,
			"pid": 2409,
			"name": "罗江县",
			"merger_name": "中国,四川省,德阳市,罗江县",
			"level": 3
		}, {
			"id": 2413,
			"pid": 2409,
			"name": "广汉市",
			"merger_name": "中国,四川省,德阳市,广汉市",
			"level": 3
		}, {
			"id": 2414,
			"pid": 2409,
			"name": "什邡市",
			"merger_name": "中国,四川省,德阳市,什邡市",
			"level": 3
		}, {
			"id": 2415,
			"pid": 2409,
			"name": "绵竹市",
			"merger_name": "中国,四川省,德阳市,绵竹市",
			"level": 3
		}]
	}, {
		"id": 2416,
		"pid": 2367,
		"name": "绵阳市",
		"merger_name": "中国,四川省,绵阳市",
		"level": 2,
		"areas": [{
			"id": 2417,
			"pid": 2416,
			"name": "涪城区",
			"merger_name": "中国,四川省,绵阳市,涪城区",
			"level": 3
		}, {
			"id": 2418,
			"pid": 2416,
			"name": "游仙区",
			"merger_name": "中国,四川省,绵阳市,游仙区",
			"level": 3
		}, {
			"id": 2419,
			"pid": 2416,
			"name": "三台县",
			"merger_name": "中国,四川省,绵阳市,三台县",
			"level": 3
		}, {
			"id": 2420,
			"pid": 2416,
			"name": "盐亭县",
			"merger_name": "中国,四川省,绵阳市,盐亭县",
			"level": 3
		}, {
			"id": 2421,
			"pid": 2416,
			"name": "安县",
			"merger_name": "中国,四川省,绵阳市,安县",
			"level": 3
		}, {
			"id": 2422,
			"pid": 2416,
			"name": "梓潼县",
			"merger_name": "中国,四川省,绵阳市,梓潼县",
			"level": 3
		}, {
			"id": 2423,
			"pid": 2416,
			"name": "北川羌族自治县",
			"merger_name": "中国,四川省,绵阳市,北川羌族自治县",
			"level": 3
		}, {
			"id": 2424,
			"pid": 2416,
			"name": "平武县",
			"merger_name": "中国,四川省,绵阳市,平武县",
			"level": 3
		}, {
			"id": 2425,
			"pid": 2416,
			"name": "江油市",
			"merger_name": "中国,四川省,绵阳市,江油市",
			"level": 3
		}]
	}, {
		"id": 2426,
		"pid": 2367,
		"name": "广元市",
		"merger_name": "中国,四川省,广元市",
		"level": 2,
		"areas": [{
			"id": 2427,
			"pid": 2426,
			"name": "利州区",
			"merger_name": "中国,四川省,广元市,利州区",
			"level": 3
		}, {
			"id": 2428,
			"pid": 2426,
			"name": "昭化区",
			"merger_name": "中国,四川省,广元市,昭化区",
			"level": 3
		}, {
			"id": 2429,
			"pid": 2426,
			"name": "朝天区",
			"merger_name": "中国,四川省,广元市,朝天区",
			"level": 3
		}, {
			"id": 2430,
			"pid": 2426,
			"name": "旺苍县",
			"merger_name": "中国,四川省,广元市,旺苍县",
			"level": 3
		}, {
			"id": 2431,
			"pid": 2426,
			"name": "青川县",
			"merger_name": "中国,四川省,广元市,青川县",
			"level": 3
		}, {
			"id": 2432,
			"pid": 2426,
			"name": "剑阁县",
			"merger_name": "中国,四川省,广元市,剑阁县",
			"level": 3
		}, {
			"id": 2433,
			"pid": 2426,
			"name": "苍溪县",
			"merger_name": "中国,四川省,广元市,苍溪县",
			"level": 3
		}]
	}, {
		"id": 2434,
		"pid": 2367,
		"name": "遂宁市",
		"merger_name": "中国,四川省,遂宁市",
		"level": 2,
		"areas": [{
			"id": 2435,
			"pid": 2434,
			"name": "船山区",
			"merger_name": "中国,四川省,遂宁市,船山区",
			"level": 3
		}, {
			"id": 2436,
			"pid": 2434,
			"name": "安居区",
			"merger_name": "中国,四川省,遂宁市,安居区",
			"level": 3
		}, {
			"id": 2437,
			"pid": 2434,
			"name": "蓬溪县",
			"merger_name": "中国,四川省,遂宁市,蓬溪县",
			"level": 3
		}, {
			"id": 2438,
			"pid": 2434,
			"name": "射洪县",
			"merger_name": "中国,四川省,遂宁市,射洪县",
			"level": 3
		}, {
			"id": 2439,
			"pid": 2434,
			"name": "大英县",
			"merger_name": "中国,四川省,遂宁市,大英县",
			"level": 3
		}]
	}, {
		"id": 2440,
		"pid": 2367,
		"name": "内江市",
		"merger_name": "中国,四川省,内江市",
		"level": 2,
		"areas": [{
			"id": 2441,
			"pid": 2440,
			"name": "市中区",
			"merger_name": "中国,四川省,内江市,市中区",
			"level": 3
		}, {
			"id": 2442,
			"pid": 2440,
			"name": "东兴区",
			"merger_name": "中国,四川省,内江市,东兴区",
			"level": 3
		}, {
			"id": 2443,
			"pid": 2440,
			"name": "威远县",
			"merger_name": "中国,四川省,内江市,威远县",
			"level": 3
		}, {
			"id": 2444,
			"pid": 2440,
			"name": "资中县",
			"merger_name": "中国,四川省,内江市,资中县",
			"level": 3
		}, {
			"id": 2445,
			"pid": 2440,
			"name": "隆昌县",
			"merger_name": "中国,四川省,内江市,隆昌县",
			"level": 3
		}]
	}, {
		"id": 2446,
		"pid": 2367,
		"name": "乐山市",
		"merger_name": "中国,四川省,乐山市",
		"level": 2,
		"areas": [{
			"id": 2447,
			"pid": 2446,
			"name": "市中区",
			"merger_name": "中国,四川省,乐山市,市中区",
			"level": 3
		}, {
			"id": 2448,
			"pid": 2446,
			"name": "沙湾区",
			"merger_name": "中国,四川省,乐山市,沙湾区",
			"level": 3
		}, {
			"id": 2449,
			"pid": 2446,
			"name": "五通桥区",
			"merger_name": "中国,四川省,乐山市,五通桥区",
			"level": 3
		}, {
			"id": 2450,
			"pid": 2446,
			"name": "金口河区",
			"merger_name": "中国,四川省,乐山市,金口河区",
			"level": 3
		}, {
			"id": 2451,
			"pid": 2446,
			"name": "犍为县",
			"merger_name": "中国,四川省,乐山市,犍为县",
			"level": 3
		}, {
			"id": 2452,
			"pid": 2446,
			"name": "井研县",
			"merger_name": "中国,四川省,乐山市,井研县",
			"level": 3
		}, {
			"id": 2453,
			"pid": 2446,
			"name": "夹江县",
			"merger_name": "中国,四川省,乐山市,夹江县",
			"level": 3
		}, {
			"id": 2454,
			"pid": 2446,
			"name": "沐川县",
			"merger_name": "中国,四川省,乐山市,沐川县",
			"level": 3
		}, {
			"id": 2455,
			"pid": 2446,
			"name": "峨边彝族自治县",
			"merger_name": "中国,四川省,乐山市,峨边彝族自治县",
			"level": 3
		}, {
			"id": 2456,
			"pid": 2446,
			"name": "马边彝族自治县",
			"merger_name": "中国,四川省,乐山市,马边彝族自治县",
			"level": 3
		}, {
			"id": 2457,
			"pid": 2446,
			"name": "峨眉山市",
			"merger_name": "中国,四川省,乐山市,峨眉山市",
			"level": 3
		}]
	}, {
		"id": 2458,
		"pid": 2367,
		"name": "南充市",
		"merger_name": "中国,四川省,南充市",
		"level": 2,
		"areas": [{
			"id": 2459,
			"pid": 2458,
			"name": "顺庆区",
			"merger_name": "中国,四川省,南充市,顺庆区",
			"level": 3
		}, {
			"id": 2460,
			"pid": 2458,
			"name": "高坪区",
			"merger_name": "中国,四川省,南充市,高坪区",
			"level": 3
		}, {
			"id": 2461,
			"pid": 2458,
			"name": "嘉陵区",
			"merger_name": "中国,四川省,南充市,嘉陵区",
			"level": 3
		}, {
			"id": 2462,
			"pid": 2458,
			"name": "南部县",
			"merger_name": "中国,四川省,南充市,南部县",
			"level": 3
		}, {
			"id": 2463,
			"pid": 2458,
			"name": "营山县",
			"merger_name": "中国,四川省,南充市,营山县",
			"level": 3
		}, {
			"id": 2464,
			"pid": 2458,
			"name": "蓬安县",
			"merger_name": "中国,四川省,南充市,蓬安县",
			"level": 3
		}, {
			"id": 2465,
			"pid": 2458,
			"name": "仪陇县",
			"merger_name": "中国,四川省,南充市,仪陇县",
			"level": 3
		}, {
			"id": 2466,
			"pid": 2458,
			"name": "西充县",
			"merger_name": "中国,四川省,南充市,西充县",
			"level": 3
		}, {
			"id": 2467,
			"pid": 2458,
			"name": "阆中市",
			"merger_name": "中国,四川省,南充市,阆中市",
			"level": 3
		}]
	}, {
		"id": 2468,
		"pid": 2367,
		"name": "眉山市",
		"merger_name": "中国,四川省,眉山市",
		"level": 2,
		"areas": [{
			"id": 2469,
			"pid": 2468,
			"name": "东坡区",
			"merger_name": "中国,四川省,眉山市,东坡区",
			"level": 3
		}, {
			"id": 2470,
			"pid": 2468,
			"name": "彭山区",
			"merger_name": "中国,四川省,眉山市,彭山区",
			"level": 3
		}, {
			"id": 2471,
			"pid": 2468,
			"name": "仁寿县",
			"merger_name": "中国,四川省,眉山市,仁寿县",
			"level": 3
		}, {
			"id": 2472,
			"pid": 2468,
			"name": "洪雅县",
			"merger_name": "中国,四川省,眉山市,洪雅县",
			"level": 3
		}, {
			"id": 2473,
			"pid": 2468,
			"name": "丹棱县",
			"merger_name": "中国,四川省,眉山市,丹棱县",
			"level": 3
		}, {
			"id": 2474,
			"pid": 2468,
			"name": "青神县",
			"merger_name": "中国,四川省,眉山市,青神县",
			"level": 3
		}]
	}, {
		"id": 2475,
		"pid": 2367,
		"name": "宜宾市",
		"merger_name": "中国,四川省,宜宾市",
		"level": 2,
		"areas": [{
			"id": 2476,
			"pid": 2475,
			"name": "翠屏区",
			"merger_name": "中国,四川省,宜宾市,翠屏区",
			"level": 3
		}, {
			"id": 2477,
			"pid": 2475,
			"name": "南溪区",
			"merger_name": "中国,四川省,宜宾市,南溪区",
			"level": 3
		}, {
			"id": 2478,
			"pid": 2475,
			"name": "宜宾县",
			"merger_name": "中国,四川省,宜宾市,宜宾县",
			"level": 3
		}, {
			"id": 2479,
			"pid": 2475,
			"name": "江安县",
			"merger_name": "中国,四川省,宜宾市,江安县",
			"level": 3
		}, {
			"id": 2480,
			"pid": 2475,
			"name": "长宁县",
			"merger_name": "中国,四川省,宜宾市,长宁县",
			"level": 3
		}, {
			"id": 2481,
			"pid": 2475,
			"name": "高县",
			"merger_name": "中国,四川省,宜宾市,高县",
			"level": 3
		}, {
			"id": 2482,
			"pid": 2475,
			"name": "珙县",
			"merger_name": "中国,四川省,宜宾市,珙县",
			"level": 3
		}, {
			"id": 2483,
			"pid": 2475,
			"name": "筠连县",
			"merger_name": "中国,四川省,宜宾市,筠连县",
			"level": 3
		}, {
			"id": 2484,
			"pid": 2475,
			"name": "兴文县",
			"merger_name": "中国,四川省,宜宾市,兴文县",
			"level": 3
		}, {
			"id": 2485,
			"pid": 2475,
			"name": "屏山县",
			"merger_name": "中国,四川省,宜宾市,屏山县",
			"level": 3
		}]
	}, {
		"id": 2486,
		"pid": 2367,
		"name": "广安市",
		"merger_name": "中国,四川省,广安市",
		"level": 2,
		"areas": [{
			"id": 2487,
			"pid": 2486,
			"name": "广安区",
			"merger_name": "中国,四川省,广安市,广安区",
			"level": 3
		}, {
			"id": 2488,
			"pid": 2486,
			"name": "前锋区",
			"merger_name": "中国,四川省,广安市,前锋区",
			"level": 3
		}, {
			"id": 2489,
			"pid": 2486,
			"name": "岳池县",
			"merger_name": "中国,四川省,广安市,岳池县",
			"level": 3
		}, {
			"id": 2490,
			"pid": 2486,
			"name": "武胜县",
			"merger_name": "中国,四川省,广安市,武胜县",
			"level": 3
		}, {
			"id": 2491,
			"pid": 2486,
			"name": "邻水县",
			"merger_name": "中国,四川省,广安市,邻水县",
			"level": 3
		}, {
			"id": 2492,
			"pid": 2486,
			"name": "华蓥市",
			"merger_name": "中国,四川省,广安市,华蓥市",
			"level": 3
		}]
	}, {
		"id": 2493,
		"pid": 2367,
		"name": "达州市",
		"merger_name": "中国,四川省,达州市",
		"level": 2,
		"areas": [{
			"id": 2494,
			"pid": 2493,
			"name": "通川区",
			"merger_name": "中国,四川省,达州市,通川区",
			"level": 3
		}, {
			"id": 2495,
			"pid": 2493,
			"name": "达川区",
			"merger_name": "中国,四川省,达州市,达川区",
			"level": 3
		}, {
			"id": 2496,
			"pid": 2493,
			"name": "宣汉县",
			"merger_name": "中国,四川省,达州市,宣汉县",
			"level": 3
		}, {
			"id": 2497,
			"pid": 2493,
			"name": "开江县",
			"merger_name": "中国,四川省,达州市,开江县",
			"level": 3
		}, {
			"id": 2498,
			"pid": 2493,
			"name": "大竹县",
			"merger_name": "中国,四川省,达州市,大竹县",
			"level": 3
		}, {
			"id": 2499,
			"pid": 2493,
			"name": "渠县",
			"merger_name": "中国,四川省,达州市,渠县",
			"level": 3
		}, {
			"id": 2500,
			"pid": 2493,
			"name": "万源市",
			"merger_name": "中国,四川省,达州市,万源市",
			"level": 3
		}]
	}, {
		"id": 2501,
		"pid": 2367,
		"name": "雅安市",
		"merger_name": "中国,四川省,雅安市",
		"level": 2,
		"areas": [{
			"id": 2502,
			"pid": 2501,
			"name": "雨城区",
			"merger_name": "中国,四川省,雅安市,雨城区",
			"level": 3
		}, {
			"id": 2503,
			"pid": 2501,
			"name": "名山区",
			"merger_name": "中国,四川省,雅安市,名山区",
			"level": 3
		}, {
			"id": 2504,
			"pid": 2501,
			"name": "荥经县",
			"merger_name": "中国,四川省,雅安市,荥经县",
			"level": 3
		}, {
			"id": 2505,
			"pid": 2501,
			"name": "汉源县",
			"merger_name": "中国,四川省,雅安市,汉源县",
			"level": 3
		}, {
			"id": 2506,
			"pid": 2501,
			"name": "石棉县",
			"merger_name": "中国,四川省,雅安市,石棉县",
			"level": 3
		}, {
			"id": 2507,
			"pid": 2501,
			"name": "天全县",
			"merger_name": "中国,四川省,雅安市,天全县",
			"level": 3
		}, {
			"id": 2508,
			"pid": 2501,
			"name": "芦山县",
			"merger_name": "中国,四川省,雅安市,芦山县",
			"level": 3
		}, {
			"id": 2509,
			"pid": 2501,
			"name": "宝兴县",
			"merger_name": "中国,四川省,雅安市,宝兴县",
			"level": 3
		}]
	}, {
		"id": 2510,
		"pid": 2367,
		"name": "巴中市",
		"merger_name": "中国,四川省,巴中市",
		"level": 2,
		"areas": [{
			"id": 2511,
			"pid": 2510,
			"name": "巴州区",
			"merger_name": "中国,四川省,巴中市,巴州区",
			"level": 3
		}, {
			"id": 2512,
			"pid": 2510,
			"name": "恩阳区",
			"merger_name": "中国,四川省,巴中市,恩阳区",
			"level": 3
		}, {
			"id": 2513,
			"pid": 2510,
			"name": "通江县",
			"merger_name": "中国,四川省,巴中市,通江县",
			"level": 3
		}, {
			"id": 2514,
			"pid": 2510,
			"name": "南江县",
			"merger_name": "中国,四川省,巴中市,南江县",
			"level": 3
		}, {
			"id": 2515,
			"pid": 2510,
			"name": "平昌县",
			"merger_name": "中国,四川省,巴中市,平昌县",
			"level": 3
		}]
	}, {
		"id": 2516,
		"pid": 2367,
		"name": "资阳市",
		"merger_name": "中国,四川省,资阳市",
		"level": 2,
		"areas": [{
			"id": 2517,
			"pid": 2516,
			"name": "雁江区",
			"merger_name": "中国,四川省,资阳市,雁江区",
			"level": 3
		}, {
			"id": 2518,
			"pid": 2516,
			"name": "安岳县",
			"merger_name": "中国,四川省,资阳市,安岳县",
			"level": 3
		}, {
			"id": 2519,
			"pid": 2516,
			"name": "乐至县",
			"merger_name": "中国,四川省,资阳市,乐至县",
			"level": 3
		}, {
			"id": 2520,
			"pid": 2516,
			"name": "简阳市",
			"merger_name": "中国,四川省,资阳市,简阳市",
			"level": 3
		}]
	}, {
		"id": 2521,
		"pid": 2367,
		"name": "阿坝藏族羌族自治州",
		"merger_name": "中国,四川省,阿坝藏族羌族自治州",
		"level": 2,
		"areas": [{
			"id": 2522,
			"pid": 2521,
			"name": "汶川县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,汶川县",
			"level": 3
		}, {
			"id": 2523,
			"pid": 2521,
			"name": "理县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,理县",
			"level": 3
		}, {
			"id": 2524,
			"pid": 2521,
			"name": "茂县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,茂县",
			"level": 3
		}, {
			"id": 2525,
			"pid": 2521,
			"name": "松潘县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,松潘县",
			"level": 3
		}, {
			"id": 2526,
			"pid": 2521,
			"name": "九寨沟县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,九寨沟县",
			"level": 3
		}, {
			"id": 2527,
			"pid": 2521,
			"name": "金川县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,金川县",
			"level": 3
		}, {
			"id": 2528,
			"pid": 2521,
			"name": "小金县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,小金县",
			"level": 3
		}, {
			"id": 2529,
			"pid": 2521,
			"name": "黑水县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,黑水县",
			"level": 3
		}, {
			"id": 2530,
			"pid": 2521,
			"name": "马尔康县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,马尔康县",
			"level": 3
		}, {
			"id": 2531,
			"pid": 2521,
			"name": "壤塘县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,壤塘县",
			"level": 3
		}, {
			"id": 2532,
			"pid": 2521,
			"name": "阿坝县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,阿坝县",
			"level": 3
		}, {
			"id": 2533,
			"pid": 2521,
			"name": "若尔盖县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,若尔盖县",
			"level": 3
		}, {
			"id": 2534,
			"pid": 2521,
			"name": "红原县",
			"merger_name": "中国,四川省,阿坝藏族羌族自治州,红原县",
			"level": 3
		}]
	}, {
		"id": 2535,
		"pid": 2367,
		"name": "甘孜藏族自治州",
		"merger_name": "中国,四川省,甘孜藏族自治州",
		"level": 2,
		"areas": [{
			"id": 2536,
			"pid": 2535,
			"name": "康定县",
			"merger_name": "中国,四川省,甘孜藏族自治州,康定县",
			"level": 3
		}, {
			"id": 2537,
			"pid": 2535,
			"name": "泸定县",
			"merger_name": "中国,四川省,甘孜藏族自治州,泸定县",
			"level": 3
		}, {
			"id": 2538,
			"pid": 2535,
			"name": "丹巴县",
			"merger_name": "中国,四川省,甘孜藏族自治州,丹巴县",
			"level": 3
		}, {
			"id": 2539,
			"pid": 2535,
			"name": "九龙县",
			"merger_name": "中国,四川省,甘孜藏族自治州,九龙县",
			"level": 3
		}, {
			"id": 2540,
			"pid": 2535,
			"name": "雅江县",
			"merger_name": "中国,四川省,甘孜藏族自治州,雅江县",
			"level": 3
		}, {
			"id": 2541,
			"pid": 2535,
			"name": "道孚县",
			"merger_name": "中国,四川省,甘孜藏族自治州,道孚县",
			"level": 3
		}, {
			"id": 2542,
			"pid": 2535,
			"name": "炉霍县",
			"merger_name": "中国,四川省,甘孜藏族自治州,炉霍县",
			"level": 3
		}, {
			"id": 2543,
			"pid": 2535,
			"name": "甘孜县",
			"merger_name": "中国,四川省,甘孜藏族自治州,甘孜县",
			"level": 3
		}, {
			"id": 2544,
			"pid": 2535,
			"name": "新龙县",
			"merger_name": "中国,四川省,甘孜藏族自治州,新龙县",
			"level": 3
		}, {
			"id": 2545,
			"pid": 2535,
			"name": "德格县",
			"merger_name": "中国,四川省,甘孜藏族自治州,德格县",
			"level": 3
		}, {
			"id": 2546,
			"pid": 2535,
			"name": "白玉县",
			"merger_name": "中国,四川省,甘孜藏族自治州,白玉县",
			"level": 3
		}, {
			"id": 2547,
			"pid": 2535,
			"name": "石渠县",
			"merger_name": "中国,四川省,甘孜藏族自治州,石渠县",
			"level": 3
		}, {
			"id": 2548,
			"pid": 2535,
			"name": "色达县",
			"merger_name": "中国,四川省,甘孜藏族自治州,色达县",
			"level": 3
		}, {
			"id": 2549,
			"pid": 2535,
			"name": "理塘县",
			"merger_name": "中国,四川省,甘孜藏族自治州,理塘县",
			"level": 3
		}, {
			"id": 2550,
			"pid": 2535,
			"name": "巴塘县",
			"merger_name": "中国,四川省,甘孜藏族自治州,巴塘县",
			"level": 3
		}, {
			"id": 2551,
			"pid": 2535,
			"name": "乡城县",
			"merger_name": "中国,四川省,甘孜藏族自治州,乡城县",
			"level": 3
		}, {
			"id": 2552,
			"pid": 2535,
			"name": "稻城县",
			"merger_name": "中国,四川省,甘孜藏族自治州,稻城县",
			"level": 3
		}, {
			"id": 2553,
			"pid": 2535,
			"name": "得荣县",
			"merger_name": "中国,四川省,甘孜藏族自治州,得荣县",
			"level": 3
		}]
	}, {
		"id": 2554,
		"pid": 2367,
		"name": "凉山彝族自治州",
		"merger_name": "中国,四川省,凉山彝族自治州",
		"level": 2,
		"areas": [{
			"id": 2555,
			"pid": 2554,
			"name": "西昌市",
			"merger_name": "中国,四川省,凉山彝族自治州,西昌市",
			"level": 3
		}, {
			"id": 2556,
			"pid": 2554,
			"name": "木里藏族自治县",
			"merger_name": "中国,四川省,凉山彝族自治州,木里藏族自治县",
			"level": 3
		}, {
			"id": 2557,
			"pid": 2554,
			"name": "盐源县",
			"merger_name": "中国,四川省,凉山彝族自治州,盐源县",
			"level": 3
		}, {
			"id": 2558,
			"pid": 2554,
			"name": "德昌县",
			"merger_name": "中国,四川省,凉山彝族自治州,德昌县",
			"level": 3
		}, {
			"id": 2559,
			"pid": 2554,
			"name": "会理县",
			"merger_name": "中国,四川省,凉山彝族自治州,会理县",
			"level": 3
		}, {
			"id": 2560,
			"pid": 2554,
			"name": "会东县",
			"merger_name": "中国,四川省,凉山彝族自治州,会东县",
			"level": 3
		}, {
			"id": 2561,
			"pid": 2554,
			"name": "宁南县",
			"merger_name": "中国,四川省,凉山彝族自治州,宁南县",
			"level": 3
		}, {
			"id": 2562,
			"pid": 2554,
			"name": "普格县",
			"merger_name": "中国,四川省,凉山彝族自治州,普格县",
			"level": 3
		}, {
			"id": 2563,
			"pid": 2554,
			"name": "布拖县",
			"merger_name": "中国,四川省,凉山彝族自治州,布拖县",
			"level": 3
		}, {
			"id": 2564,
			"pid": 2554,
			"name": "金阳县",
			"merger_name": "中国,四川省,凉山彝族自治州,金阳县",
			"level": 3
		}, {
			"id": 2565,
			"pid": 2554,
			"name": "昭觉县",
			"merger_name": "中国,四川省,凉山彝族自治州,昭觉县",
			"level": 3
		}, {
			"id": 2566,
			"pid": 2554,
			"name": "喜德县",
			"merger_name": "中国,四川省,凉山彝族自治州,喜德县",
			"level": 3
		}, {
			"id": 2567,
			"pid": 2554,
			"name": "冕宁县",
			"merger_name": "中国,四川省,凉山彝族自治州,冕宁县",
			"level": 3
		}, {
			"id": 2568,
			"pid": 2554,
			"name": "越西县",
			"merger_name": "中国,四川省,凉山彝族自治州,越西县",
			"level": 3
		}, {
			"id": 2569,
			"pid": 2554,
			"name": "甘洛县",
			"merger_name": "中国,四川省,凉山彝族自治州,甘洛县",
			"level": 3
		}, {
			"id": 2570,
			"pid": 2554,
			"name": "美姑县",
			"merger_name": "中国,四川省,凉山彝族自治州,美姑县",
			"level": 3
		}, {
			"id": 2571,
			"pid": 2554,
			"name": "雷波县",
			"merger_name": "中国,四川省,凉山彝族自治州,雷波县",
			"level": 3
		}]
	}]
}, {
	"id": 2572,
	"pid": 0,
	"name": "贵州省",
	"merger_name": "中国,贵州省",
	"level": 1,
	"cities": [{
		"id": 2573,
		"pid": 2572,
		"name": "贵阳市",
		"merger_name": "中国,贵州省,贵阳市",
		"level": 2,
		"areas": [{
			"id": 2574,
			"pid": 2573,
			"name": "南明区",
			"merger_name": "中国,贵州省,贵阳市,南明区",
			"level": 3
		}, {
			"id": 2575,
			"pid": 2573,
			"name": "云岩区",
			"merger_name": "中国,贵州省,贵阳市,云岩区",
			"level": 3
		}, {
			"id": 2576,
			"pid": 2573,
			"name": "花溪区",
			"merger_name": "中国,贵州省,贵阳市,花溪区",
			"level": 3
		}, {
			"id": 2577,
			"pid": 2573,
			"name": "乌当区",
			"merger_name": "中国,贵州省,贵阳市,乌当区",
			"level": 3
		}, {
			"id": 2578,
			"pid": 2573,
			"name": "白云区",
			"merger_name": "中国,贵州省,贵阳市,白云区",
			"level": 3
		}, {
			"id": 2579,
			"pid": 2573,
			"name": "观山湖区",
			"merger_name": "中国,贵州省,贵阳市,观山湖区",
			"level": 3
		}, {
			"id": 2580,
			"pid": 2573,
			"name": "开阳县",
			"merger_name": "中国,贵州省,贵阳市,开阳县",
			"level": 3
		}, {
			"id": 2581,
			"pid": 2573,
			"name": "息烽县",
			"merger_name": "中国,贵州省,贵阳市,息烽县",
			"level": 3
		}, {
			"id": 2582,
			"pid": 2573,
			"name": "修文县",
			"merger_name": "中国,贵州省,贵阳市,修文县",
			"level": 3
		}, {
			"id": 2583,
			"pid": 2573,
			"name": "清镇市",
			"merger_name": "中国,贵州省,贵阳市,清镇市",
			"level": 3
		}]
	}, {
		"id": 2584,
		"pid": 2572,
		"name": "六盘水市",
		"merger_name": "中国,贵州省,六盘水市",
		"level": 2,
		"areas": [{
			"id": 2585,
			"pid": 2584,
			"name": "钟山区",
			"merger_name": "中国,贵州省,六盘水市,钟山区",
			"level": 3
		}, {
			"id": 2586,
			"pid": 2584,
			"name": "六枝特区",
			"merger_name": "中国,贵州省,六盘水市,六枝特区",
			"level": 3
		}, {
			"id": 2587,
			"pid": 2584,
			"name": "水城县",
			"merger_name": "中国,贵州省,六盘水市,水城县",
			"level": 3
		}, {
			"id": 2588,
			"pid": 2584,
			"name": "盘县",
			"merger_name": "中国,贵州省,六盘水市,盘县",
			"level": 3
		}]
	}, {
		"id": 2589,
		"pid": 2572,
		"name": "遵义市",
		"merger_name": "中国,贵州省,遵义市",
		"level": 2,
		"areas": [{
			"id": 2590,
			"pid": 2589,
			"name": "红花岗区",
			"merger_name": "中国,贵州省,遵义市,红花岗区",
			"level": 3
		}, {
			"id": 2591,
			"pid": 2589,
			"name": "汇川区",
			"merger_name": "中国,贵州省,遵义市,汇川区",
			"level": 3
		}, {
			"id": 2592,
			"pid": 2589,
			"name": "遵义县",
			"merger_name": "中国,贵州省,遵义市,遵义县",
			"level": 3
		}, {
			"id": 2593,
			"pid": 2589,
			"name": "桐梓县",
			"merger_name": "中国,贵州省,遵义市,桐梓县",
			"level": 3
		}, {
			"id": 2594,
			"pid": 2589,
			"name": "绥阳县",
			"merger_name": "中国,贵州省,遵义市,绥阳县",
			"level": 3
		}, {
			"id": 2595,
			"pid": 2589,
			"name": "正安县",
			"merger_name": "中国,贵州省,遵义市,正安县",
			"level": 3
		}, {
			"id": 2596,
			"pid": 2589,
			"name": "道真仡佬族苗族自治县",
			"merger_name": "中国,贵州省,遵义市,道真仡佬族苗族自治县",
			"level": 3
		}, {
			"id": 2597,
			"pid": 2589,
			"name": "务川仡佬族苗族自治县",
			"merger_name": "中国,贵州省,遵义市,务川仡佬族苗族自治县",
			"level": 3
		}, {
			"id": 2598,
			"pid": 2589,
			"name": "凤冈县",
			"merger_name": "中国,贵州省,遵义市,凤冈县",
			"level": 3
		}, {
			"id": 2599,
			"pid": 2589,
			"name": "湄潭县",
			"merger_name": "中国,贵州省,遵义市,湄潭县",
			"level": 3
		}, {
			"id": 2600,
			"pid": 2589,
			"name": "余庆县",
			"merger_name": "中国,贵州省,遵义市,余庆县",
			"level": 3
		}, {
			"id": 2601,
			"pid": 2589,
			"name": "习水县",
			"merger_name": "中国,贵州省,遵义市,习水县",
			"level": 3
		}, {
			"id": 2602,
			"pid": 2589,
			"name": "赤水市",
			"merger_name": "中国,贵州省,遵义市,赤水市",
			"level": 3
		}, {
			"id": 2603,
			"pid": 2589,
			"name": "仁怀市",
			"merger_name": "中国,贵州省,遵义市,仁怀市",
			"level": 3
		}]
	}, {
		"id": 2604,
		"pid": 2572,
		"name": "安顺市",
		"merger_name": "中国,贵州省,安顺市",
		"level": 2,
		"areas": [{
			"id": 2605,
			"pid": 2604,
			"name": "西秀区",
			"merger_name": "中国,贵州省,安顺市,西秀区",
			"level": 3
		}, {
			"id": 2606,
			"pid": 2604,
			"name": "平坝区",
			"merger_name": "中国,贵州省,安顺市,平坝区",
			"level": 3
		}, {
			"id": 2607,
			"pid": 2604,
			"name": "普定县",
			"merger_name": "中国,贵州省,安顺市,普定县",
			"level": 3
		}, {
			"id": 2608,
			"pid": 2604,
			"name": "镇宁布依族苗族自治县",
			"merger_name": "中国,贵州省,安顺市,镇宁布依族苗族自治县",
			"level": 3
		}, {
			"id": 2609,
			"pid": 2604,
			"name": "关岭布依族苗族自治县",
			"merger_name": "中国,贵州省,安顺市,关岭布依族苗族自治县",
			"level": 3
		}, {
			"id": 2610,
			"pid": 2604,
			"name": "紫云苗族布依族自治县",
			"merger_name": "中国,贵州省,安顺市,紫云苗族布依族自治县",
			"level": 3
		}]
	}, {
		"id": 2611,
		"pid": 2572,
		"name": "毕节市",
		"merger_name": "中国,贵州省,毕节市",
		"level": 2,
		"areas": [{
			"id": 2612,
			"pid": 2611,
			"name": "七星关区",
			"merger_name": "中国,贵州省,毕节市,七星关区",
			"level": 3
		}, {
			"id": 2613,
			"pid": 2611,
			"name": "大方县",
			"merger_name": "中国,贵州省,毕节市,大方县",
			"level": 3
		}, {
			"id": 2614,
			"pid": 2611,
			"name": "黔西县",
			"merger_name": "中国,贵州省,毕节市,黔西县",
			"level": 3
		}, {
			"id": 2615,
			"pid": 2611,
			"name": "金沙县",
			"merger_name": "中国,贵州省,毕节市,金沙县",
			"level": 3
		}, {
			"id": 2616,
			"pid": 2611,
			"name": "织金县",
			"merger_name": "中国,贵州省,毕节市,织金县",
			"level": 3
		}, {
			"id": 2617,
			"pid": 2611,
			"name": "纳雍县",
			"merger_name": "中国,贵州省,毕节市,纳雍县",
			"level": 3
		}, {
			"id": 2618,
			"pid": 2611,
			"name": "威宁彝族回族苗族自治县",
			"merger_name": "中国,贵州省,毕节市,威宁彝族回族苗族自治县",
			"level": 3
		}, {
			"id": 2619,
			"pid": 2611,
			"name": "赫章县",
			"merger_name": "中国,贵州省,毕节市,赫章县",
			"level": 3
		}]
	}, {
		"id": 2620,
		"pid": 2572,
		"name": "铜仁市",
		"merger_name": "中国,贵州省,铜仁市",
		"level": 2,
		"areas": [{
			"id": 2621,
			"pid": 2620,
			"name": "碧江区",
			"merger_name": "中国,贵州省,铜仁市,碧江区",
			"level": 3
		}, {
			"id": 2622,
			"pid": 2620,
			"name": "万山区",
			"merger_name": "中国,贵州省,铜仁市,万山区",
			"level": 3
		}, {
			"id": 2623,
			"pid": 2620,
			"name": "江口县",
			"merger_name": "中国,贵州省,铜仁市,江口县",
			"level": 3
		}, {
			"id": 2624,
			"pid": 2620,
			"name": "玉屏侗族自治县",
			"merger_name": "中国,贵州省,铜仁市,玉屏侗族自治县",
			"level": 3
		}, {
			"id": 2625,
			"pid": 2620,
			"name": "石阡县",
			"merger_name": "中国,贵州省,铜仁市,石阡县",
			"level": 3
		}, {
			"id": 2626,
			"pid": 2620,
			"name": "思南县",
			"merger_name": "中国,贵州省,铜仁市,思南县",
			"level": 3
		}, {
			"id": 2627,
			"pid": 2620,
			"name": "印江土家族苗族自治县",
			"merger_name": "中国,贵州省,铜仁市,印江土家族苗族自治县",
			"level": 3
		}, {
			"id": 2628,
			"pid": 2620,
			"name": "德江县",
			"merger_name": "中国,贵州省,铜仁市,德江县",
			"level": 3
		}, {
			"id": 2629,
			"pid": 2620,
			"name": "沿河土家族自治县",
			"merger_name": "中国,贵州省,铜仁市,沿河土家族自治县",
			"level": 3
		}, {
			"id": 2630,
			"pid": 2620,
			"name": "松桃苗族自治县",
			"merger_name": "中国,贵州省,铜仁市,松桃苗族自治县",
			"level": 3
		}]
	}, {
		"id": 2631,
		"pid": 2572,
		"name": "黔西南布依族苗族自治州",
		"merger_name": "中国,贵州省,黔西南布依族苗族自治州",
		"level": 2,
		"areas": [{
			"id": 2632,
			"pid": 2631,
			"name": "兴义市 ",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,兴义市 ",
			"level": 3
		}, {
			"id": 2633,
			"pid": 2631,
			"name": "兴仁县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,兴仁县",
			"level": 3
		}, {
			"id": 2634,
			"pid": 2631,
			"name": "普安县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,普安县",
			"level": 3
		}, {
			"id": 2635,
			"pid": 2631,
			"name": "晴隆县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,晴隆县",
			"level": 3
		}, {
			"id": 2636,
			"pid": 2631,
			"name": "贞丰县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,贞丰县",
			"level": 3
		}, {
			"id": 2637,
			"pid": 2631,
			"name": "望谟县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,望谟县",
			"level": 3
		}, {
			"id": 2638,
			"pid": 2631,
			"name": "册亨县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,册亨县",
			"level": 3
		}, {
			"id": 2639,
			"pid": 2631,
			"name": "安龙县",
			"merger_name": "中国,贵州省,黔西南布依族苗族自治州,安龙县",
			"level": 3
		}]
	}, {
		"id": 2640,
		"pid": 2572,
		"name": "黔东南苗族侗族自治州",
		"merger_name": "中国,贵州省,黔东南苗族侗族自治州",
		"level": 2,
		"areas": [{
			"id": 2641,
			"pid": 2640,
			"name": "凯里市",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,凯里市",
			"level": 3
		}, {
			"id": 2642,
			"pid": 2640,
			"name": "黄平县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,黄平县",
			"level": 3
		}, {
			"id": 2643,
			"pid": 2640,
			"name": "施秉县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,施秉县",
			"level": 3
		}, {
			"id": 2644,
			"pid": 2640,
			"name": "三穗县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,三穗县",
			"level": 3
		}, {
			"id": 2645,
			"pid": 2640,
			"name": "镇远县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,镇远县",
			"level": 3
		}, {
			"id": 2646,
			"pid": 2640,
			"name": "岑巩县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,岑巩县",
			"level": 3
		}, {
			"id": 2647,
			"pid": 2640,
			"name": "天柱县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,天柱县",
			"level": 3
		}, {
			"id": 2648,
			"pid": 2640,
			"name": "锦屏县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,锦屏县",
			"level": 3
		}, {
			"id": 2649,
			"pid": 2640,
			"name": "剑河县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,剑河县",
			"level": 3
		}, {
			"id": 2650,
			"pid": 2640,
			"name": "台江县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,台江县",
			"level": 3
		}, {
			"id": 2651,
			"pid": 2640,
			"name": "黎平县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,黎平县",
			"level": 3
		}, {
			"id": 2652,
			"pid": 2640,
			"name": "榕江县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,榕江县",
			"level": 3
		}, {
			"id": 2653,
			"pid": 2640,
			"name": "从江县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,从江县",
			"level": 3
		}, {
			"id": 2654,
			"pid": 2640,
			"name": "雷山县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,雷山县",
			"level": 3
		}, {
			"id": 2655,
			"pid": 2640,
			"name": "麻江县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,麻江县",
			"level": 3
		}, {
			"id": 2656,
			"pid": 2640,
			"name": "丹寨县",
			"merger_name": "中国,贵州省,黔东南苗族侗族自治州,丹寨县",
			"level": 3
		}]
	}, {
		"id": 2657,
		"pid": 2572,
		"name": "黔南布依族苗族自治州",
		"merger_name": "中国,贵州省,黔南布依族苗族自治州",
		"level": 2,
		"areas": [{
			"id": 2658,
			"pid": 2657,
			"name": "都匀市",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,都匀市",
			"level": 3
		}, {
			"id": 2659,
			"pid": 2657,
			"name": "福泉市",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,福泉市",
			"level": 3
		}, {
			"id": 2660,
			"pid": 2657,
			"name": "荔波县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,荔波县",
			"level": 3
		}, {
			"id": 2661,
			"pid": 2657,
			"name": "贵定县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,贵定县",
			"level": 3
		}, {
			"id": 2662,
			"pid": 2657,
			"name": "瓮安县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,瓮安县",
			"level": 3
		}, {
			"id": 2663,
			"pid": 2657,
			"name": "独山县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,独山县",
			"level": 3
		}, {
			"id": 2664,
			"pid": 2657,
			"name": "平塘县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,平塘县",
			"level": 3
		}, {
			"id": 2665,
			"pid": 2657,
			"name": "罗甸县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,罗甸县",
			"level": 3
		}, {
			"id": 2666,
			"pid": 2657,
			"name": "长顺县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,长顺县",
			"level": 3
		}, {
			"id": 2667,
			"pid": 2657,
			"name": "龙里县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,龙里县",
			"level": 3
		}, {
			"id": 2668,
			"pid": 2657,
			"name": "惠水县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,惠水县",
			"level": 3
		}, {
			"id": 2669,
			"pid": 2657,
			"name": "三都水族自治县",
			"merger_name": "中国,贵州省,黔南布依族苗族自治州,三都水族自治县",
			"level": 3
		}]
	}]
}, {
	"id": 2670,
	"pid": 0,
	"name": "云南省",
	"merger_name": "中国,云南省",
	"level": 1,
	"cities": [{
		"id": 2671,
		"pid": 2670,
		"name": "昆明市",
		"merger_name": "中国,云南省,昆明市",
		"level": 2,
		"areas": [{
			"id": 2672,
			"pid": 2671,
			"name": "五华区",
			"merger_name": "中国,云南省,昆明市,五华区",
			"level": 3
		}, {
			"id": 2673,
			"pid": 2671,
			"name": "盘龙区",
			"merger_name": "中国,云南省,昆明市,盘龙区",
			"level": 3
		}, {
			"id": 2674,
			"pid": 2671,
			"name": "官渡区",
			"merger_name": "中国,云南省,昆明市,官渡区",
			"level": 3
		}, {
			"id": 2675,
			"pid": 2671,
			"name": "西山区",
			"merger_name": "中国,云南省,昆明市,西山区",
			"level": 3
		}, {
			"id": 2676,
			"pid": 2671,
			"name": "东川区",
			"merger_name": "中国,云南省,昆明市,东川区",
			"level": 3
		}, {
			"id": 2677,
			"pid": 2671,
			"name": "呈贡区",
			"merger_name": "中国,云南省,昆明市,呈贡区",
			"level": 3
		}, {
			"id": 2678,
			"pid": 2671,
			"name": "晋宁县",
			"merger_name": "中国,云南省,昆明市,晋宁县",
			"level": 3
		}, {
			"id": 2679,
			"pid": 2671,
			"name": "富民县",
			"merger_name": "中国,云南省,昆明市,富民县",
			"level": 3
		}, {
			"id": 2680,
			"pid": 2671,
			"name": "宜良县",
			"merger_name": "中国,云南省,昆明市,宜良县",
			"level": 3
		}, {
			"id": 2681,
			"pid": 2671,
			"name": "石林彝族自治县",
			"merger_name": "中国,云南省,昆明市,石林彝族自治县",
			"level": 3
		}, {
			"id": 2682,
			"pid": 2671,
			"name": "嵩明县",
			"merger_name": "中国,云南省,昆明市,嵩明县",
			"level": 3
		}, {
			"id": 2683,
			"pid": 2671,
			"name": "禄劝彝族苗族自治县",
			"merger_name": "中国,云南省,昆明市,禄劝彝族苗族自治县",
			"level": 3
		}, {
			"id": 2684,
			"pid": 2671,
			"name": "寻甸回族彝族自治县 ",
			"merger_name": "中国,云南省,昆明市,寻甸回族彝族自治县 ",
			"level": 3
		}, {
			"id": 2685,
			"pid": 2671,
			"name": "安宁市",
			"merger_name": "中国,云南省,昆明市,安宁市",
			"level": 3
		}]
	}, {
		"id": 2686,
		"pid": 2670,
		"name": "曲靖市",
		"merger_name": "中国,云南省,曲靖市",
		"level": 2,
		"areas": [{
			"id": 2687,
			"pid": 2686,
			"name": "麒麟区",
			"merger_name": "中国,云南省,曲靖市,麒麟区",
			"level": 3
		}, {
			"id": 2688,
			"pid": 2686,
			"name": "马龙县",
			"merger_name": "中国,云南省,曲靖市,马龙县",
			"level": 3
		}, {
			"id": 2689,
			"pid": 2686,
			"name": "陆良县",
			"merger_name": "中国,云南省,曲靖市,陆良县",
			"level": 3
		}, {
			"id": 2690,
			"pid": 2686,
			"name": "师宗县",
			"merger_name": "中国,云南省,曲靖市,师宗县",
			"level": 3
		}, {
			"id": 2691,
			"pid": 2686,
			"name": "罗平县",
			"merger_name": "中国,云南省,曲靖市,罗平县",
			"level": 3
		}, {
			"id": 2692,
			"pid": 2686,
			"name": "富源县",
			"merger_name": "中国,云南省,曲靖市,富源县",
			"level": 3
		}, {
			"id": 2693,
			"pid": 2686,
			"name": "会泽县",
			"merger_name": "中国,云南省,曲靖市,会泽县",
			"level": 3
		}, {
			"id": 2694,
			"pid": 2686,
			"name": "沾益县",
			"merger_name": "中国,云南省,曲靖市,沾益县",
			"level": 3
		}, {
			"id": 2695,
			"pid": 2686,
			"name": "宣威市",
			"merger_name": "中国,云南省,曲靖市,宣威市",
			"level": 3
		}]
	}, {
		"id": 2696,
		"pid": 2670,
		"name": "玉溪市",
		"merger_name": "中国,云南省,玉溪市",
		"level": 2,
		"areas": [{
			"id": 2697,
			"pid": 2696,
			"name": "红塔区",
			"merger_name": "中国,云南省,玉溪市,红塔区",
			"level": 3
		}, {
			"id": 2698,
			"pid": 2696,
			"name": "江川县",
			"merger_name": "中国,云南省,玉溪市,江川县",
			"level": 3
		}, {
			"id": 2699,
			"pid": 2696,
			"name": "澄江县",
			"merger_name": "中国,云南省,玉溪市,澄江县",
			"level": 3
		}, {
			"id": 2700,
			"pid": 2696,
			"name": "通海县",
			"merger_name": "中国,云南省,玉溪市,通海县",
			"level": 3
		}, {
			"id": 2701,
			"pid": 2696,
			"name": "华宁县",
			"merger_name": "中国,云南省,玉溪市,华宁县",
			"level": 3
		}, {
			"id": 2702,
			"pid": 2696,
			"name": "易门县",
			"merger_name": "中国,云南省,玉溪市,易门县",
			"level": 3
		}, {
			"id": 2703,
			"pid": 2696,
			"name": "峨山彝族自治县",
			"merger_name": "中国,云南省,玉溪市,峨山彝族自治县",
			"level": 3
		}, {
			"id": 2704,
			"pid": 2696,
			"name": "新平彝族傣族自治县",
			"merger_name": "中国,云南省,玉溪市,新平彝族傣族自治县",
			"level": 3
		}, {
			"id": 2705,
			"pid": 2696,
			"name": "元江哈尼族彝族傣族自治县",
			"merger_name": "中国,云南省,玉溪市,元江哈尼族彝族傣族自治县",
			"level": 3
		}]
	}, {
		"id": 2706,
		"pid": 2670,
		"name": "保山市",
		"merger_name": "中国,云南省,保山市",
		"level": 2,
		"areas": [{
			"id": 2707,
			"pid": 2706,
			"name": "隆阳区",
			"merger_name": "中国,云南省,保山市,隆阳区",
			"level": 3
		}, {
			"id": 2708,
			"pid": 2706,
			"name": "施甸县",
			"merger_name": "中国,云南省,保山市,施甸县",
			"level": 3
		}, {
			"id": 2709,
			"pid": 2706,
			"name": "腾冲县",
			"merger_name": "中国,云南省,保山市,腾冲县",
			"level": 3
		}, {
			"id": 2710,
			"pid": 2706,
			"name": "龙陵县",
			"merger_name": "中国,云南省,保山市,龙陵县",
			"level": 3
		}, {
			"id": 2711,
			"pid": 2706,
			"name": "昌宁县",
			"merger_name": "中国,云南省,保山市,昌宁县",
			"level": 3
		}]
	}, {
		"id": 2712,
		"pid": 2670,
		"name": "昭通市",
		"merger_name": "中国,云南省,昭通市",
		"level": 2,
		"areas": [{
			"id": 2713,
			"pid": 2712,
			"name": "昭阳区",
			"merger_name": "中国,云南省,昭通市,昭阳区",
			"level": 3
		}, {
			"id": 2714,
			"pid": 2712,
			"name": "鲁甸县",
			"merger_name": "中国,云南省,昭通市,鲁甸县",
			"level": 3
		}, {
			"id": 2715,
			"pid": 2712,
			"name": "巧家县",
			"merger_name": "中国,云南省,昭通市,巧家县",
			"level": 3
		}, {
			"id": 2716,
			"pid": 2712,
			"name": "盐津县",
			"merger_name": "中国,云南省,昭通市,盐津县",
			"level": 3
		}, {
			"id": 2717,
			"pid": 2712,
			"name": "大关县",
			"merger_name": "中国,云南省,昭通市,大关县",
			"level": 3
		}, {
			"id": 2718,
			"pid": 2712,
			"name": "永善县",
			"merger_name": "中国,云南省,昭通市,永善县",
			"level": 3
		}, {
			"id": 2719,
			"pid": 2712,
			"name": "绥江县",
			"merger_name": "中国,云南省,昭通市,绥江县",
			"level": 3
		}, {
			"id": 2720,
			"pid": 2712,
			"name": "镇雄县",
			"merger_name": "中国,云南省,昭通市,镇雄县",
			"level": 3
		}, {
			"id": 2721,
			"pid": 2712,
			"name": "彝良县",
			"merger_name": "中国,云南省,昭通市,彝良县",
			"level": 3
		}, {
			"id": 2722,
			"pid": 2712,
			"name": "威信县",
			"merger_name": "中国,云南省,昭通市,威信县",
			"level": 3
		}, {
			"id": 2723,
			"pid": 2712,
			"name": "水富县",
			"merger_name": "中国,云南省,昭通市,水富县",
			"level": 3
		}]
	}, {
		"id": 2724,
		"pid": 2670,
		"name": "丽江市",
		"merger_name": "中国,云南省,丽江市",
		"level": 2,
		"areas": [{
			"id": 2725,
			"pid": 2724,
			"name": "古城区",
			"merger_name": "中国,云南省,丽江市,古城区",
			"level": 3
		}, {
			"id": 2726,
			"pid": 2724,
			"name": "玉龙纳西族自治县",
			"merger_name": "中国,云南省,丽江市,玉龙纳西族自治县",
			"level": 3
		}, {
			"id": 2727,
			"pid": 2724,
			"name": "永胜县",
			"merger_name": "中国,云南省,丽江市,永胜县",
			"level": 3
		}, {
			"id": 2728,
			"pid": 2724,
			"name": "华坪县",
			"merger_name": "中国,云南省,丽江市,华坪县",
			"level": 3
		}, {
			"id": 2729,
			"pid": 2724,
			"name": "宁蒗彝族自治县",
			"merger_name": "中国,云南省,丽江市,宁蒗彝族自治县",
			"level": 3
		}]
	}, {
		"id": 2730,
		"pid": 2670,
		"name": "普洱市",
		"merger_name": "中国,云南省,普洱市",
		"level": 2,
		"areas": [{
			"id": 2731,
			"pid": 2730,
			"name": "思茅区",
			"merger_name": "中国,云南省,普洱市,思茅区",
			"level": 3
		}, {
			"id": 2732,
			"pid": 2730,
			"name": "宁洱哈尼族彝族自治县",
			"merger_name": "中国,云南省,普洱市,宁洱哈尼族彝族自治县",
			"level": 3
		}, {
			"id": 2733,
			"pid": 2730,
			"name": "墨江哈尼族自治县",
			"merger_name": "中国,云南省,普洱市,墨江哈尼族自治县",
			"level": 3
		}, {
			"id": 2734,
			"pid": 2730,
			"name": "景东彝族自治县",
			"merger_name": "中国,云南省,普洱市,景东彝族自治县",
			"level": 3
		}, {
			"id": 2735,
			"pid": 2730,
			"name": "景谷傣族彝族自治县",
			"merger_name": "中国,云南省,普洱市,景谷傣族彝族自治县",
			"level": 3
		}, {
			"id": 2736,
			"pid": 2730,
			"name": "镇沅彝族哈尼族拉祜族自治县",
			"merger_name": "中国,云南省,普洱市,镇沅彝族哈尼族拉祜族自治县",
			"level": 3
		}, {
			"id": 2737,
			"pid": 2730,
			"name": "江城哈尼族彝族自治县",
			"merger_name": "中国,云南省,普洱市,江城哈尼族彝族自治县",
			"level": 3
		}, {
			"id": 2738,
			"pid": 2730,
			"name": "孟连傣族拉祜族佤族自治县",
			"merger_name": "中国,云南省,普洱市,孟连傣族拉祜族佤族自治县",
			"level": 3
		}, {
			"id": 2739,
			"pid": 2730,
			"name": "澜沧拉祜族自治县",
			"merger_name": "中国,云南省,普洱市,澜沧拉祜族自治县",
			"level": 3
		}, {
			"id": 2740,
			"pid": 2730,
			"name": "西盟佤族自治县",
			"merger_name": "中国,云南省,普洱市,西盟佤族自治县",
			"level": 3
		}]
	}, {
		"id": 2741,
		"pid": 2670,
		"name": "临沧市",
		"merger_name": "中国,云南省,临沧市",
		"level": 2,
		"areas": [{
			"id": 2742,
			"pid": 2741,
			"name": "临翔区",
			"merger_name": "中国,云南省,临沧市,临翔区",
			"level": 3
		}, {
			"id": 2743,
			"pid": 2741,
			"name": "凤庆县",
			"merger_name": "中国,云南省,临沧市,凤庆县",
			"level": 3
		}, {
			"id": 2744,
			"pid": 2741,
			"name": "云县",
			"merger_name": "中国,云南省,临沧市,云县",
			"level": 3
		}, {
			"id": 2745,
			"pid": 2741,
			"name": "永德县",
			"merger_name": "中国,云南省,临沧市,永德县",
			"level": 3
		}, {
			"id": 2746,
			"pid": 2741,
			"name": "镇康县",
			"merger_name": "中国,云南省,临沧市,镇康县",
			"level": 3
		}, {
			"id": 2747,
			"pid": 2741,
			"name": "双江拉祜族佤族布朗族傣族自治县",
			"merger_name": "中国,云南省,临沧市,双江拉祜族佤族布朗族傣族自治县",
			"level": 3
		}, {
			"id": 2748,
			"pid": 2741,
			"name": "耿马傣族佤族自治县",
			"merger_name": "中国,云南省,临沧市,耿马傣族佤族自治县",
			"level": 3
		}, {
			"id": 2749,
			"pid": 2741,
			"name": "沧源佤族自治县",
			"merger_name": "中国,云南省,临沧市,沧源佤族自治县",
			"level": 3
		}]
	}, {
		"id": 2750,
		"pid": 2670,
		"name": "楚雄彝族自治州",
		"merger_name": "中国,云南省,楚雄彝族自治州",
		"level": 2,
		"areas": [{
			"id": 2751,
			"pid": 2750,
			"name": "楚雄市",
			"merger_name": "中国,云南省,楚雄彝族自治州,楚雄市",
			"level": 3
		}, {
			"id": 2752,
			"pid": 2750,
			"name": "双柏县",
			"merger_name": "中国,云南省,楚雄彝族自治州,双柏县",
			"level": 3
		}, {
			"id": 2753,
			"pid": 2750,
			"name": "牟定县",
			"merger_name": "中国,云南省,楚雄彝族自治州,牟定县",
			"level": 3
		}, {
			"id": 2754,
			"pid": 2750,
			"name": "南华县",
			"merger_name": "中国,云南省,楚雄彝族自治州,南华县",
			"level": 3
		}, {
			"id": 2755,
			"pid": 2750,
			"name": "姚安县",
			"merger_name": "中国,云南省,楚雄彝族自治州,姚安县",
			"level": 3
		}, {
			"id": 2756,
			"pid": 2750,
			"name": "大姚县",
			"merger_name": "中国,云南省,楚雄彝族自治州,大姚县",
			"level": 3
		}, {
			"id": 2757,
			"pid": 2750,
			"name": "永仁县",
			"merger_name": "中国,云南省,楚雄彝族自治州,永仁县",
			"level": 3
		}, {
			"id": 2758,
			"pid": 2750,
			"name": "元谋县",
			"merger_name": "中国,云南省,楚雄彝族自治州,元谋县",
			"level": 3
		}, {
			"id": 2759,
			"pid": 2750,
			"name": "武定县",
			"merger_name": "中国,云南省,楚雄彝族自治州,武定县",
			"level": 3
		}, {
			"id": 2760,
			"pid": 2750,
			"name": "禄丰县",
			"merger_name": "中国,云南省,楚雄彝族自治州,禄丰县",
			"level": 3
		}]
	}, {
		"id": 2761,
		"pid": 2670,
		"name": "红河哈尼族彝族自治州",
		"merger_name": "中国,云南省,红河哈尼族彝族自治州",
		"level": 2,
		"areas": [{
			"id": 2762,
			"pid": 2761,
			"name": "个旧市",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,个旧市",
			"level": 3
		}, {
			"id": 2763,
			"pid": 2761,
			"name": "开远市",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,开远市",
			"level": 3
		}, {
			"id": 2764,
			"pid": 2761,
			"name": "蒙自市",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,蒙自市",
			"level": 3
		}, {
			"id": 2765,
			"pid": 2761,
			"name": "弥勒市",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,弥勒市",
			"level": 3
		}, {
			"id": 2766,
			"pid": 2761,
			"name": "屏边苗族自治县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,屏边苗族自治县",
			"level": 3
		}, {
			"id": 2767,
			"pid": 2761,
			"name": "建水县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,建水县",
			"level": 3
		}, {
			"id": 2768,
			"pid": 2761,
			"name": "石屏县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,石屏县",
			"level": 3
		}, {
			"id": 2769,
			"pid": 2761,
			"name": "泸西县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,泸西县",
			"level": 3
		}, {
			"id": 2770,
			"pid": 2761,
			"name": "元阳县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,元阳县",
			"level": 3
		}, {
			"id": 2771,
			"pid": 2761,
			"name": "红河县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,红河县",
			"level": 3
		}, {
			"id": 2772,
			"pid": 2761,
			"name": "金平苗族瑶族傣族自治县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,金平苗族瑶族傣族自治县",
			"level": 3
		}, {
			"id": 2773,
			"pid": 2761,
			"name": "绿春县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,绿春县",
			"level": 3
		}, {
			"id": 2774,
			"pid": 2761,
			"name": "河口瑶族自治县",
			"merger_name": "中国,云南省,红河哈尼族彝族自治州,河口瑶族自治县",
			"level": 3
		}]
	}, {
		"id": 2775,
		"pid": 2670,
		"name": "文山壮族苗族自治州",
		"merger_name": "中国,云南省,文山壮族苗族自治州",
		"level": 2,
		"areas": [{
			"id": 2776,
			"pid": 2775,
			"name": "文山市",
			"merger_name": "中国,云南省,文山壮族苗族自治州,文山市",
			"level": 3
		}, {
			"id": 2777,
			"pid": 2775,
			"name": "砚山县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,砚山县",
			"level": 3
		}, {
			"id": 2778,
			"pid": 2775,
			"name": "西畴县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,西畴县",
			"level": 3
		}, {
			"id": 2779,
			"pid": 2775,
			"name": "麻栗坡县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,麻栗坡县",
			"level": 3
		}, {
			"id": 2780,
			"pid": 2775,
			"name": "马关县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,马关县",
			"level": 3
		}, {
			"id": 2781,
			"pid": 2775,
			"name": "丘北县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,丘北县",
			"level": 3
		}, {
			"id": 2782,
			"pid": 2775,
			"name": "广南县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,广南县",
			"level": 3
		}, {
			"id": 2783,
			"pid": 2775,
			"name": "富宁县",
			"merger_name": "中国,云南省,文山壮族苗族自治州,富宁县",
			"level": 3
		}]
	}, {
		"id": 2784,
		"pid": 2670,
		"name": "西双版纳傣族自治州",
		"merger_name": "中国,云南省,西双版纳傣族自治州",
		"level": 2,
		"areas": [{
			"id": 2785,
			"pid": 2784,
			"name": "景洪市",
			"merger_name": "中国,云南省,西双版纳傣族自治州,景洪市",
			"level": 3
		}, {
			"id": 2786,
			"pid": 2784,
			"name": "勐海县",
			"merger_name": "中国,云南省,西双版纳傣族自治州,勐海县",
			"level": 3
		}, {
			"id": 2787,
			"pid": 2784,
			"name": "勐腊县",
			"merger_name": "中国,云南省,西双版纳傣族自治州,勐腊县",
			"level": 3
		}]
	}, {
		"id": 2788,
		"pid": 2670,
		"name": "大理白族自治州",
		"merger_name": "中国,云南省,大理白族自治州",
		"level": 2,
		"areas": [{
			"id": 2789,
			"pid": 2788,
			"name": "大理市",
			"merger_name": "中国,云南省,大理白族自治州,大理市",
			"level": 3
		}, {
			"id": 2790,
			"pid": 2788,
			"name": "漾濞彝族自治县",
			"merger_name": "中国,云南省,大理白族自治州,漾濞彝族自治县",
			"level": 3
		}, {
			"id": 2791,
			"pid": 2788,
			"name": "祥云县",
			"merger_name": "中国,云南省,大理白族自治州,祥云县",
			"level": 3
		}, {
			"id": 2792,
			"pid": 2788,
			"name": "宾川县",
			"merger_name": "中国,云南省,大理白族自治州,宾川县",
			"level": 3
		}, {
			"id": 2793,
			"pid": 2788,
			"name": "弥渡县",
			"merger_name": "中国,云南省,大理白族自治州,弥渡县",
			"level": 3
		}, {
			"id": 2794,
			"pid": 2788,
			"name": "南涧彝族自治县",
			"merger_name": "中国,云南省,大理白族自治州,南涧彝族自治县",
			"level": 3
		}, {
			"id": 2795,
			"pid": 2788,
			"name": "巍山彝族回族自治县",
			"merger_name": "中国,云南省,大理白族自治州,巍山彝族回族自治县",
			"level": 3
		}, {
			"id": 2796,
			"pid": 2788,
			"name": "永平县",
			"merger_name": "中国,云南省,大理白族自治州,永平县",
			"level": 3
		}, {
			"id": 2797,
			"pid": 2788,
			"name": "云龙县",
			"merger_name": "中国,云南省,大理白族自治州,云龙县",
			"level": 3
		}, {
			"id": 2798,
			"pid": 2788,
			"name": "洱源县",
			"merger_name": "中国,云南省,大理白族自治州,洱源县",
			"level": 3
		}, {
			"id": 2799,
			"pid": 2788,
			"name": "剑川县",
			"merger_name": "中国,云南省,大理白族自治州,剑川县",
			"level": 3
		}, {
			"id": 2800,
			"pid": 2788,
			"name": "鹤庆县",
			"merger_name": "中国,云南省,大理白族自治州,鹤庆县",
			"level": 3
		}]
	}, {
		"id": 2801,
		"pid": 2670,
		"name": "德宏傣族景颇族自治州",
		"merger_name": "中国,云南省,德宏傣族景颇族自治州",
		"level": 2,
		"areas": [{
			"id": 2802,
			"pid": 2801,
			"name": "瑞丽市",
			"merger_name": "中国,云南省,德宏傣族景颇族自治州,瑞丽市",
			"level": 3
		}, {
			"id": 2803,
			"pid": 2801,
			"name": "芒市",
			"merger_name": "中国,云南省,德宏傣族景颇族自治州,芒市",
			"level": 3
		}, {
			"id": 2804,
			"pid": 2801,
			"name": "梁河县",
			"merger_name": "中国,云南省,德宏傣族景颇族自治州,梁河县",
			"level": 3
		}, {
			"id": 2805,
			"pid": 2801,
			"name": "盈江县",
			"merger_name": "中国,云南省,德宏傣族景颇族自治州,盈江县",
			"level": 3
		}, {
			"id": 2806,
			"pid": 2801,
			"name": "陇川县",
			"merger_name": "中国,云南省,德宏傣族景颇族自治州,陇川县",
			"level": 3
		}]
	}, {
		"id": 2807,
		"pid": 2670,
		"name": "怒江傈僳族自治州",
		"merger_name": "中国,云南省,怒江傈僳族自治州",
		"level": 2,
		"areas": [{
			"id": 2808,
			"pid": 2807,
			"name": "泸水县",
			"merger_name": "中国,云南省,怒江傈僳族自治州,泸水县",
			"level": 3
		}, {
			"id": 2809,
			"pid": 2807,
			"name": "福贡县",
			"merger_name": "中国,云南省,怒江傈僳族自治州,福贡县",
			"level": 3
		}, {
			"id": 2810,
			"pid": 2807,
			"name": "贡山独龙族怒族自治县",
			"merger_name": "中国,云南省,怒江傈僳族自治州,贡山独龙族怒族自治县",
			"level": 3
		}, {
			"id": 2811,
			"pid": 2807,
			"name": "兰坪白族普米族自治县",
			"merger_name": "中国,云南省,怒江傈僳族自治州,兰坪白族普米族自治县",
			"level": 3
		}]
	}, {
		"id": 2812,
		"pid": 2670,
		"name": "迪庆藏族自治州",
		"merger_name": "中国,云南省,迪庆藏族自治州",
		"level": 2,
		"areas": [{
			"id": 2813,
			"pid": 2812,
			"name": "香格里拉市",
			"merger_name": "中国,云南省,迪庆藏族自治州,香格里拉市",
			"level": 3
		}, {
			"id": 2814,
			"pid": 2812,
			"name": "德钦县",
			"merger_name": "中国,云南省,迪庆藏族自治州,德钦县",
			"level": 3
		}, {
			"id": 2815,
			"pid": 2812,
			"name": "维西傈僳族自治县",
			"merger_name": "中国,云南省,迪庆藏族自治州,维西傈僳族自治县",
			"level": 3
		}]
	}]
}, {
	"id": 2816,
	"pid": 0,
	"name": "西藏自治区",
	"merger_name": "中国,西藏自治区",
	"level": 1,
	"cities": [{
		"id": 2817,
		"pid": 2816,
		"name": "拉萨市",
		"merger_name": "中国,西藏自治区,拉萨市",
		"level": 2,
		"areas": [{
			"id": 2818,
			"pid": 2817,
			"name": "城关区",
			"merger_name": "中国,西藏自治区,拉萨市,城关区",
			"level": 3
		}, {
			"id": 2819,
			"pid": 2817,
			"name": "林周县",
			"merger_name": "中国,西藏自治区,拉萨市,林周县",
			"level": 3
		}, {
			"id": 2820,
			"pid": 2817,
			"name": "当雄县",
			"merger_name": "中国,西藏自治区,拉萨市,当雄县",
			"level": 3
		}, {
			"id": 2821,
			"pid": 2817,
			"name": "尼木县",
			"merger_name": "中国,西藏自治区,拉萨市,尼木县",
			"level": 3
		}, {
			"id": 2822,
			"pid": 2817,
			"name": "曲水县",
			"merger_name": "中国,西藏自治区,拉萨市,曲水县",
			"level": 3
		}, {
			"id": 2823,
			"pid": 2817,
			"name": "堆龙德庆县",
			"merger_name": "中国,西藏自治区,拉萨市,堆龙德庆县",
			"level": 3
		}, {
			"id": 2824,
			"pid": 2817,
			"name": "达孜县",
			"merger_name": "中国,西藏自治区,拉萨市,达孜县",
			"level": 3
		}, {
			"id": 2825,
			"pid": 2817,
			"name": "墨竹工卡县",
			"merger_name": "中国,西藏自治区,拉萨市,墨竹工卡县",
			"level": 3
		}]
	}, {
		"id": 2826,
		"pid": 2816,
		"name": "日喀则市",
		"merger_name": "中国,西藏自治区,日喀则市",
		"level": 2,
		"areas": [{
			"id": 2827,
			"pid": 2826,
			"name": "桑珠孜区",
			"merger_name": "中国,西藏自治区,日喀则市,桑珠孜区",
			"level": 3
		}, {
			"id": 2828,
			"pid": 2826,
			"name": "南木林县",
			"merger_name": "中国,西藏自治区,日喀则市,南木林县",
			"level": 3
		}, {
			"id": 2829,
			"pid": 2826,
			"name": "江孜县",
			"merger_name": "中国,西藏自治区,日喀则市,江孜县",
			"level": 3
		}, {
			"id": 2830,
			"pid": 2826,
			"name": "定日县",
			"merger_name": "中国,西藏自治区,日喀则市,定日县",
			"level": 3
		}, {
			"id": 2831,
			"pid": 2826,
			"name": "萨迦县",
			"merger_name": "中国,西藏自治区,日喀则市,萨迦县",
			"level": 3
		}, {
			"id": 2832,
			"pid": 2826,
			"name": "拉孜县",
			"merger_name": "中国,西藏自治区,日喀则市,拉孜县",
			"level": 3
		}, {
			"id": 2833,
			"pid": 2826,
			"name": "昂仁县",
			"merger_name": "中国,西藏自治区,日喀则市,昂仁县",
			"level": 3
		}, {
			"id": 2834,
			"pid": 2826,
			"name": "谢通门县",
			"merger_name": "中国,西藏自治区,日喀则市,谢通门县",
			"level": 3
		}, {
			"id": 2835,
			"pid": 2826,
			"name": "白朗县",
			"merger_name": "中国,西藏自治区,日喀则市,白朗县",
			"level": 3
		}, {
			"id": 2836,
			"pid": 2826,
			"name": "仁布县",
			"merger_name": "中国,西藏自治区,日喀则市,仁布县",
			"level": 3
		}, {
			"id": 2837,
			"pid": 2826,
			"name": "康马县",
			"merger_name": "中国,西藏自治区,日喀则市,康马县",
			"level": 3
		}, {
			"id": 2838,
			"pid": 2826,
			"name": "定结县",
			"merger_name": "中国,西藏自治区,日喀则市,定结县",
			"level": 3
		}, {
			"id": 2839,
			"pid": 2826,
			"name": "仲巴县",
			"merger_name": "中国,西藏自治区,日喀则市,仲巴县",
			"level": 3
		}, {
			"id": 2840,
			"pid": 2826,
			"name": "亚东县",
			"merger_name": "中国,西藏自治区,日喀则市,亚东县",
			"level": 3
		}, {
			"id": 2841,
			"pid": 2826,
			"name": "吉隆县",
			"merger_name": "中国,西藏自治区,日喀则市,吉隆县",
			"level": 3
		}, {
			"id": 2842,
			"pid": 2826,
			"name": "聂拉木县",
			"merger_name": "中国,西藏自治区,日喀则市,聂拉木县",
			"level": 3
		}, {
			"id": 2843,
			"pid": 2826,
			"name": "萨嘎县",
			"merger_name": "中国,西藏自治区,日喀则市,萨嘎县",
			"level": 3
		}, {
			"id": 2844,
			"pid": 2826,
			"name": "岗巴县",
			"merger_name": "中国,西藏自治区,日喀则市,岗巴县",
			"level": 3
		}]
	}, {
		"id": 2845,
		"pid": 2816,
		"name": "昌都市",
		"merger_name": "中国,西藏自治区,昌都市",
		"level": 2,
		"areas": [{
			"id": 2846,
			"pid": 2845,
			"name": "卡若区",
			"merger_name": "中国,西藏自治区,昌都市,卡若区",
			"level": 3
		}, {
			"id": 2847,
			"pid": 2845,
			"name": "江达县",
			"merger_name": "中国,西藏自治区,昌都市,江达县",
			"level": 3
		}, {
			"id": 2848,
			"pid": 2845,
			"name": "贡觉县",
			"merger_name": "中国,西藏自治区,昌都市,贡觉县",
			"level": 3
		}, {
			"id": 2849,
			"pid": 2845,
			"name": "类乌齐县",
			"merger_name": "中国,西藏自治区,昌都市,类乌齐县",
			"level": 3
		}, {
			"id": 2850,
			"pid": 2845,
			"name": "丁青县",
			"merger_name": "中国,西藏自治区,昌都市,丁青县",
			"level": 3
		}, {
			"id": 2851,
			"pid": 2845,
			"name": "察雅县",
			"merger_name": "中国,西藏自治区,昌都市,察雅县",
			"level": 3
		}, {
			"id": 2852,
			"pid": 2845,
			"name": "八宿县",
			"merger_name": "中国,西藏自治区,昌都市,八宿县",
			"level": 3
		}, {
			"id": 2853,
			"pid": 2845,
			"name": "左贡县",
			"merger_name": "中国,西藏自治区,昌都市,左贡县",
			"level": 3
		}, {
			"id": 2854,
			"pid": 2845,
			"name": "芒康县",
			"merger_name": "中国,西藏自治区,昌都市,芒康县",
			"level": 3
		}, {
			"id": 2855,
			"pid": 2845,
			"name": "洛隆县",
			"merger_name": "中国,西藏自治区,昌都市,洛隆县",
			"level": 3
		}, {
			"id": 2856,
			"pid": 2845,
			"name": "边坝县",
			"merger_name": "中国,西藏自治区,昌都市,边坝县",
			"level": 3
		}]
	}, {
		"id": 2857,
		"pid": 2816,
		"name": "山南地区",
		"merger_name": "中国,西藏自治区,山南地区",
		"level": 2,
		"areas": [{
			"id": 2858,
			"pid": 2857,
			"name": "乃东县",
			"merger_name": "中国,西藏自治区,山南地区,乃东县",
			"level": 3
		}, {
			"id": 2859,
			"pid": 2857,
			"name": "扎囊县",
			"merger_name": "中国,西藏自治区,山南地区,扎囊县",
			"level": 3
		}, {
			"id": 2860,
			"pid": 2857,
			"name": "贡嘎县",
			"merger_name": "中国,西藏自治区,山南地区,贡嘎县",
			"level": 3
		}, {
			"id": 2861,
			"pid": 2857,
			"name": "桑日县",
			"merger_name": "中国,西藏自治区,山南地区,桑日县",
			"level": 3
		}, {
			"id": 2862,
			"pid": 2857,
			"name": "琼结县",
			"merger_name": "中国,西藏自治区,山南地区,琼结县",
			"level": 3
		}, {
			"id": 2863,
			"pid": 2857,
			"name": "曲松县",
			"merger_name": "中国,西藏自治区,山南地区,曲松县",
			"level": 3
		}, {
			"id": 2864,
			"pid": 2857,
			"name": "措美县",
			"merger_name": "中国,西藏自治区,山南地区,措美县",
			"level": 3
		}, {
			"id": 2865,
			"pid": 2857,
			"name": "洛扎县",
			"merger_name": "中国,西藏自治区,山南地区,洛扎县",
			"level": 3
		}, {
			"id": 2866,
			"pid": 2857,
			"name": "加查县",
			"merger_name": "中国,西藏自治区,山南地区,加查县",
			"level": 3
		}, {
			"id": 2867,
			"pid": 2857,
			"name": "隆子县",
			"merger_name": "中国,西藏自治区,山南地区,隆子县",
			"level": 3
		}, {
			"id": 2868,
			"pid": 2857,
			"name": "错那县",
			"merger_name": "中国,西藏自治区,山南地区,错那县",
			"level": 3
		}, {
			"id": 2869,
			"pid": 2857,
			"name": "浪卡子县",
			"merger_name": "中国,西藏自治区,山南地区,浪卡子县",
			"level": 3
		}]
	}, {
		"id": 2870,
		"pid": 2816,
		"name": "那曲地区",
		"merger_name": "中国,西藏自治区,那曲地区",
		"level": 2,
		"areas": [{
			"id": 2871,
			"pid": 2870,
			"name": "那曲县",
			"merger_name": "中国,西藏自治区,那曲地区,那曲县",
			"level": 3
		}, {
			"id": 2872,
			"pid": 2870,
			"name": "嘉黎县",
			"merger_name": "中国,西藏自治区,那曲地区,嘉黎县",
			"level": 3
		}, {
			"id": 2873,
			"pid": 2870,
			"name": "比如县",
			"merger_name": "中国,西藏自治区,那曲地区,比如县",
			"level": 3
		}, {
			"id": 2874,
			"pid": 2870,
			"name": "聂荣县",
			"merger_name": "中国,西藏自治区,那曲地区,聂荣县",
			"level": 3
		}, {
			"id": 2875,
			"pid": 2870,
			"name": "安多县",
			"merger_name": "中国,西藏自治区,那曲地区,安多县",
			"level": 3
		}, {
			"id": 2876,
			"pid": 2870,
			"name": "申扎县",
			"merger_name": "中国,西藏自治区,那曲地区,申扎县",
			"level": 3
		}, {
			"id": 2877,
			"pid": 2870,
			"name": "索县",
			"merger_name": "中国,西藏自治区,那曲地区,索县",
			"level": 3
		}, {
			"id": 2878,
			"pid": 2870,
			"name": "班戈县",
			"merger_name": "中国,西藏自治区,那曲地区,班戈县",
			"level": 3
		}, {
			"id": 2879,
			"pid": 2870,
			"name": "巴青县",
			"merger_name": "中国,西藏自治区,那曲地区,巴青县",
			"level": 3
		}, {
			"id": 2880,
			"pid": 2870,
			"name": "尼玛县",
			"merger_name": "中国,西藏自治区,那曲地区,尼玛县",
			"level": 3
		}, {
			"id": 2881,
			"pid": 2870,
			"name": "双湖县",
			"merger_name": "中国,西藏自治区,那曲地区,双湖县",
			"level": 3
		}]
	}, {
		"id": 2882,
		"pid": 2816,
		"name": "阿里地区",
		"merger_name": "中国,西藏自治区,阿里地区",
		"level": 2,
		"areas": [{
			"id": 2883,
			"pid": 2882,
			"name": "普兰县",
			"merger_name": "中国,西藏自治区,阿里地区,普兰县",
			"level": 3
		}, {
			"id": 2884,
			"pid": 2882,
			"name": "札达县",
			"merger_name": "中国,西藏自治区,阿里地区,札达县",
			"level": 3
		}, {
			"id": 2885,
			"pid": 2882,
			"name": "噶尔县",
			"merger_name": "中国,西藏自治区,阿里地区,噶尔县",
			"level": 3
		}, {
			"id": 2886,
			"pid": 2882,
			"name": "日土县",
			"merger_name": "中国,西藏自治区,阿里地区,日土县",
			"level": 3
		}, {
			"id": 2887,
			"pid": 2882,
			"name": "革吉县",
			"merger_name": "中国,西藏自治区,阿里地区,革吉县",
			"level": 3
		}, {
			"id": 2888,
			"pid": 2882,
			"name": "改则县",
			"merger_name": "中国,西藏自治区,阿里地区,改则县",
			"level": 3
		}, {
			"id": 2889,
			"pid": 2882,
			"name": "措勤县",
			"merger_name": "中国,西藏自治区,阿里地区,措勤县",
			"level": 3
		}]
	}, {
		"id": 2890,
		"pid": 2816,
		"name": "林芝地区",
		"merger_name": "中国,西藏自治区,林芝地区",
		"level": 2,
		"areas": [{
			"id": 2891,
			"pid": 2890,
			"name": "林芝县",
			"merger_name": "中国,西藏自治区,林芝地区,林芝县",
			"level": 3
		}, {
			"id": 2892,
			"pid": 2890,
			"name": "工布江达县",
			"merger_name": "中国,西藏自治区,林芝地区,工布江达县",
			"level": 3
		}, {
			"id": 2893,
			"pid": 2890,
			"name": "米林县",
			"merger_name": "中国,西藏自治区,林芝地区,米林县",
			"level": 3
		}, {
			"id": 2894,
			"pid": 2890,
			"name": "墨脱县",
			"merger_name": "中国,西藏自治区,林芝地区,墨脱县",
			"level": 3
		}, {
			"id": 2895,
			"pid": 2890,
			"name": "波密县",
			"merger_name": "中国,西藏自治区,林芝地区,波密县",
			"level": 3
		}, {
			"id": 2896,
			"pid": 2890,
			"name": "察隅县",
			"merger_name": "中国,西藏自治区,林芝地区,察隅县",
			"level": 3
		}, {
			"id": 2897,
			"pid": 2890,
			"name": "朗县",
			"merger_name": "中国,西藏自治区,林芝地区,朗县",
			"level": 3
		}]
	}]
}, {
	"id": 2898,
	"pid": 0,
	"name": "陕西省",
	"merger_name": "中国,陕西省",
	"level": 1,
	"cities": [{
		"id": 2899,
		"pid": 2898,
		"name": "西安市",
		"merger_name": "中国,陕西省,西安市",
		"level": 2,
		"areas": [{
			"id": 2900,
			"pid": 2899,
			"name": "新城区",
			"merger_name": "中国,陕西省,西安市,新城区",
			"level": 3
		}, {
			"id": 2901,
			"pid": 2899,
			"name": "碑林区",
			"merger_name": "中国,陕西省,西安市,碑林区",
			"level": 3
		}, {
			"id": 2902,
			"pid": 2899,
			"name": "莲湖区",
			"merger_name": "中国,陕西省,西安市,莲湖区",
			"level": 3
		}, {
			"id": 2903,
			"pid": 2899,
			"name": "灞桥区",
			"merger_name": "中国,陕西省,西安市,灞桥区",
			"level": 3
		}, {
			"id": 2904,
			"pid": 2899,
			"name": "未央区",
			"merger_name": "中国,陕西省,西安市,未央区",
			"level": 3
		}, {
			"id": 2905,
			"pid": 2899,
			"name": "雁塔区",
			"merger_name": "中国,陕西省,西安市,雁塔区",
			"level": 3
		}, {
			"id": 2906,
			"pid": 2899,
			"name": "阎良区",
			"merger_name": "中国,陕西省,西安市,阎良区",
			"level": 3
		}, {
			"id": 2907,
			"pid": 2899,
			"name": "临潼区",
			"merger_name": "中国,陕西省,西安市,临潼区",
			"level": 3
		}, {
			"id": 2908,
			"pid": 2899,
			"name": "长安区",
			"merger_name": "中国,陕西省,西安市,长安区",
			"level": 3
		}, {
			"id": 2909,
			"pid": 2899,
			"name": "蓝田县",
			"merger_name": "中国,陕西省,西安市,蓝田县",
			"level": 3
		}, {
			"id": 2910,
			"pid": 2899,
			"name": "周至县",
			"merger_name": "中国,陕西省,西安市,周至县",
			"level": 3
		}, {
			"id": 2911,
			"pid": 2899,
			"name": "户县",
			"merger_name": "中国,陕西省,西安市,户县",
			"level": 3
		}, {
			"id": 2912,
			"pid": 2899,
			"name": "高陵区",
			"merger_name": "中国,陕西省,西安市,高陵区",
			"level": 3
		}]
	}, {
		"id": 2913,
		"pid": 2898,
		"name": "铜川市",
		"merger_name": "中国,陕西省,铜川市",
		"level": 2,
		"areas": [{
			"id": 2914,
			"pid": 2913,
			"name": "王益区",
			"merger_name": "中国,陕西省,铜川市,王益区",
			"level": 3
		}, {
			"id": 2915,
			"pid": 2913,
			"name": "印台区",
			"merger_name": "中国,陕西省,铜川市,印台区",
			"level": 3
		}, {
			"id": 2916,
			"pid": 2913,
			"name": "耀州区",
			"merger_name": "中国,陕西省,铜川市,耀州区",
			"level": 3
		}, {
			"id": 2917,
			"pid": 2913,
			"name": "宜君县",
			"merger_name": "中国,陕西省,铜川市,宜君县",
			"level": 3
		}]
	}, {
		"id": 2918,
		"pid": 2898,
		"name": "宝鸡市",
		"merger_name": "中国,陕西省,宝鸡市",
		"level": 2,
		"areas": [{
			"id": 2919,
			"pid": 2918,
			"name": "渭滨区",
			"merger_name": "中国,陕西省,宝鸡市,渭滨区",
			"level": 3
		}, {
			"id": 2920,
			"pid": 2918,
			"name": "金台区",
			"merger_name": "中国,陕西省,宝鸡市,金台区",
			"level": 3
		}, {
			"id": 2921,
			"pid": 2918,
			"name": "陈仓区",
			"merger_name": "中国,陕西省,宝鸡市,陈仓区",
			"level": 3
		}, {
			"id": 2922,
			"pid": 2918,
			"name": "凤翔县",
			"merger_name": "中国,陕西省,宝鸡市,凤翔县",
			"level": 3
		}, {
			"id": 2923,
			"pid": 2918,
			"name": "岐山县",
			"merger_name": "中国,陕西省,宝鸡市,岐山县",
			"level": 3
		}, {
			"id": 2924,
			"pid": 2918,
			"name": "扶风县",
			"merger_name": "中国,陕西省,宝鸡市,扶风县",
			"level": 3
		}, {
			"id": 2925,
			"pid": 2918,
			"name": "眉县",
			"merger_name": "中国,陕西省,宝鸡市,眉县",
			"level": 3
		}, {
			"id": 2926,
			"pid": 2918,
			"name": "陇县",
			"merger_name": "中国,陕西省,宝鸡市,陇县",
			"level": 3
		}, {
			"id": 2927,
			"pid": 2918,
			"name": "千阳县",
			"merger_name": "中国,陕西省,宝鸡市,千阳县",
			"level": 3
		}, {
			"id": 2928,
			"pid": 2918,
			"name": "麟游县",
			"merger_name": "中国,陕西省,宝鸡市,麟游县",
			"level": 3
		}, {
			"id": 2929,
			"pid": 2918,
			"name": "凤县",
			"merger_name": "中国,陕西省,宝鸡市,凤县",
			"level": 3
		}, {
			"id": 2930,
			"pid": 2918,
			"name": "太白县",
			"merger_name": "中国,陕西省,宝鸡市,太白县",
			"level": 3
		}]
	}, {
		"id": 2931,
		"pid": 2898,
		"name": "咸阳市",
		"merger_name": "中国,陕西省,咸阳市",
		"level": 2,
		"areas": [{
			"id": 2932,
			"pid": 2931,
			"name": "秦都区",
			"merger_name": "中国,陕西省,咸阳市,秦都区",
			"level": 3
		}, {
			"id": 2933,
			"pid": 2931,
			"name": "杨陵区",
			"merger_name": "中国,陕西省,咸阳市,杨陵区",
			"level": 3
		}, {
			"id": 2934,
			"pid": 2931,
			"name": "渭城区",
			"merger_name": "中国,陕西省,咸阳市,渭城区",
			"level": 3
		}, {
			"id": 2935,
			"pid": 2931,
			"name": "三原县",
			"merger_name": "中国,陕西省,咸阳市,三原县",
			"level": 3
		}, {
			"id": 2936,
			"pid": 2931,
			"name": "泾阳县",
			"merger_name": "中国,陕西省,咸阳市,泾阳县",
			"level": 3
		}, {
			"id": 2937,
			"pid": 2931,
			"name": "乾县",
			"merger_name": "中国,陕西省,咸阳市,乾县",
			"level": 3
		}, {
			"id": 2938,
			"pid": 2931,
			"name": "礼泉县",
			"merger_name": "中国,陕西省,咸阳市,礼泉县",
			"level": 3
		}, {
			"id": 2939,
			"pid": 2931,
			"name": "永寿县",
			"merger_name": "中国,陕西省,咸阳市,永寿县",
			"level": 3
		}, {
			"id": 2940,
			"pid": 2931,
			"name": "彬县",
			"merger_name": "中国,陕西省,咸阳市,彬县",
			"level": 3
		}, {
			"id": 2941,
			"pid": 2931,
			"name": "长武县",
			"merger_name": "中国,陕西省,咸阳市,长武县",
			"level": 3
		}, {
			"id": 2942,
			"pid": 2931,
			"name": "旬邑县",
			"merger_name": "中国,陕西省,咸阳市,旬邑县",
			"level": 3
		}, {
			"id": 2943,
			"pid": 2931,
			"name": "淳化县",
			"merger_name": "中国,陕西省,咸阳市,淳化县",
			"level": 3
		}, {
			"id": 2944,
			"pid": 2931,
			"name": "武功县",
			"merger_name": "中国,陕西省,咸阳市,武功县",
			"level": 3
		}, {
			"id": 2945,
			"pid": 2931,
			"name": "兴平市",
			"merger_name": "中国,陕西省,咸阳市,兴平市",
			"level": 3
		}]
	}, {
		"id": 2946,
		"pid": 2898,
		"name": "渭南市",
		"merger_name": "中国,陕西省,渭南市",
		"level": 2,
		"areas": [{
			"id": 2947,
			"pid": 2946,
			"name": "临渭区",
			"merger_name": "中国,陕西省,渭南市,临渭区",
			"level": 3
		}, {
			"id": 2948,
			"pid": 2946,
			"name": "华县",
			"merger_name": "中国,陕西省,渭南市,华县",
			"level": 3
		}, {
			"id": 2949,
			"pid": 2946,
			"name": "潼关县",
			"merger_name": "中国,陕西省,渭南市,潼关县",
			"level": 3
		}, {
			"id": 2950,
			"pid": 2946,
			"name": "大荔县",
			"merger_name": "中国,陕西省,渭南市,大荔县",
			"level": 3
		}, {
			"id": 2951,
			"pid": 2946,
			"name": "合阳县",
			"merger_name": "中国,陕西省,渭南市,合阳县",
			"level": 3
		}, {
			"id": 2952,
			"pid": 2946,
			"name": "澄城县",
			"merger_name": "中国,陕西省,渭南市,澄城县",
			"level": 3
		}, {
			"id": 2953,
			"pid": 2946,
			"name": "蒲城县",
			"merger_name": "中国,陕西省,渭南市,蒲城县",
			"level": 3
		}, {
			"id": 2954,
			"pid": 2946,
			"name": "白水县",
			"merger_name": "中国,陕西省,渭南市,白水县",
			"level": 3
		}, {
			"id": 2955,
			"pid": 2946,
			"name": "富平县",
			"merger_name": "中国,陕西省,渭南市,富平县",
			"level": 3
		}, {
			"id": 2956,
			"pid": 2946,
			"name": "韩城市",
			"merger_name": "中国,陕西省,渭南市,韩城市",
			"level": 3
		}, {
			"id": 2957,
			"pid": 2946,
			"name": "华阴市",
			"merger_name": "中国,陕西省,渭南市,华阴市",
			"level": 3
		}]
	}, {
		"id": 2958,
		"pid": 2898,
		"name": "延安市",
		"merger_name": "中国,陕西省,延安市",
		"level": 2,
		"areas": [{
			"id": 2959,
			"pid": 2958,
			"name": "宝塔区",
			"merger_name": "中国,陕西省,延安市,宝塔区",
			"level": 3
		}, {
			"id": 2960,
			"pid": 2958,
			"name": "延长县",
			"merger_name": "中国,陕西省,延安市,延长县",
			"level": 3
		}, {
			"id": 2961,
			"pid": 2958,
			"name": "延川县",
			"merger_name": "中国,陕西省,延安市,延川县",
			"level": 3
		}, {
			"id": 2962,
			"pid": 2958,
			"name": "子长县",
			"merger_name": "中国,陕西省,延安市,子长县",
			"level": 3
		}, {
			"id": 2963,
			"pid": 2958,
			"name": "安塞县",
			"merger_name": "中国,陕西省,延安市,安塞县",
			"level": 3
		}, {
			"id": 2964,
			"pid": 2958,
			"name": "志丹县",
			"merger_name": "中国,陕西省,延安市,志丹县",
			"level": 3
		}, {
			"id": 2965,
			"pid": 2958,
			"name": "吴起县",
			"merger_name": "中国,陕西省,延安市,吴起县",
			"level": 3
		}, {
			"id": 2966,
			"pid": 2958,
			"name": "甘泉县",
			"merger_name": "中国,陕西省,延安市,甘泉县",
			"level": 3
		}, {
			"id": 2967,
			"pid": 2958,
			"name": "富县",
			"merger_name": "中国,陕西省,延安市,富县",
			"level": 3
		}, {
			"id": 2968,
			"pid": 2958,
			"name": "洛川县",
			"merger_name": "中国,陕西省,延安市,洛川县",
			"level": 3
		}, {
			"id": 2969,
			"pid": 2958,
			"name": "宜川县",
			"merger_name": "中国,陕西省,延安市,宜川县",
			"level": 3
		}, {
			"id": 2970,
			"pid": 2958,
			"name": "黄龙县",
			"merger_name": "中国,陕西省,延安市,黄龙县",
			"level": 3
		}, {
			"id": 2971,
			"pid": 2958,
			"name": "黄陵县",
			"merger_name": "中国,陕西省,延安市,黄陵县",
			"level": 3
		}]
	}, {
		"id": 2972,
		"pid": 2898,
		"name": "汉中市",
		"merger_name": "中国,陕西省,汉中市",
		"level": 2,
		"areas": [{
			"id": 2973,
			"pid": 2972,
			"name": "汉台区",
			"merger_name": "中国,陕西省,汉中市,汉台区",
			"level": 3
		}, {
			"id": 2974,
			"pid": 2972,
			"name": "南郑县",
			"merger_name": "中国,陕西省,汉中市,南郑县",
			"level": 3
		}, {
			"id": 2975,
			"pid": 2972,
			"name": "城固县",
			"merger_name": "中国,陕西省,汉中市,城固县",
			"level": 3
		}, {
			"id": 2976,
			"pid": 2972,
			"name": "洋县",
			"merger_name": "中国,陕西省,汉中市,洋县",
			"level": 3
		}, {
			"id": 2977,
			"pid": 2972,
			"name": "西乡县",
			"merger_name": "中国,陕西省,汉中市,西乡县",
			"level": 3
		}, {
			"id": 2978,
			"pid": 2972,
			"name": "勉县",
			"merger_name": "中国,陕西省,汉中市,勉县",
			"level": 3
		}, {
			"id": 2979,
			"pid": 2972,
			"name": "宁强县",
			"merger_name": "中国,陕西省,汉中市,宁强县",
			"level": 3
		}, {
			"id": 2980,
			"pid": 2972,
			"name": "略阳县",
			"merger_name": "中国,陕西省,汉中市,略阳县",
			"level": 3
		}, {
			"id": 2981,
			"pid": 2972,
			"name": "镇巴县",
			"merger_name": "中国,陕西省,汉中市,镇巴县",
			"level": 3
		}, {
			"id": 2982,
			"pid": 2972,
			"name": "留坝县",
			"merger_name": "中国,陕西省,汉中市,留坝县",
			"level": 3
		}, {
			"id": 2983,
			"pid": 2972,
			"name": "佛坪县",
			"merger_name": "中国,陕西省,汉中市,佛坪县",
			"level": 3
		}]
	}, {
		"id": 2984,
		"pid": 2898,
		"name": "榆林市",
		"merger_name": "中国,陕西省,榆林市",
		"level": 2,
		"areas": [{
			"id": 2985,
			"pid": 2984,
			"name": "榆阳区",
			"merger_name": "中国,陕西省,榆林市,榆阳区",
			"level": 3
		}, {
			"id": 2986,
			"pid": 2984,
			"name": "神木县",
			"merger_name": "中国,陕西省,榆林市,神木县",
			"level": 3
		}, {
			"id": 2987,
			"pid": 2984,
			"name": "府谷县",
			"merger_name": "中国,陕西省,榆林市,府谷县",
			"level": 3
		}, {
			"id": 2988,
			"pid": 2984,
			"name": "横山县",
			"merger_name": "中国,陕西省,榆林市,横山县",
			"level": 3
		}, {
			"id": 2989,
			"pid": 2984,
			"name": "靖边县",
			"merger_name": "中国,陕西省,榆林市,靖边县",
			"level": 3
		}, {
			"id": 2990,
			"pid": 2984,
			"name": "定边县",
			"merger_name": "中国,陕西省,榆林市,定边县",
			"level": 3
		}, {
			"id": 2991,
			"pid": 2984,
			"name": "绥德县",
			"merger_name": "中国,陕西省,榆林市,绥德县",
			"level": 3
		}, {
			"id": 2992,
			"pid": 2984,
			"name": "米脂县",
			"merger_name": "中国,陕西省,榆林市,米脂县",
			"level": 3
		}, {
			"id": 2993,
			"pid": 2984,
			"name": "佳县",
			"merger_name": "中国,陕西省,榆林市,佳县",
			"level": 3
		}, {
			"id": 2994,
			"pid": 2984,
			"name": "吴堡县",
			"merger_name": "中国,陕西省,榆林市,吴堡县",
			"level": 3
		}, {
			"id": 2995,
			"pid": 2984,
			"name": "清涧县",
			"merger_name": "中国,陕西省,榆林市,清涧县",
			"level": 3
		}, {
			"id": 2996,
			"pid": 2984,
			"name": "子洲县",
			"merger_name": "中国,陕西省,榆林市,子洲县",
			"level": 3
		}]
	}, {
		"id": 2997,
		"pid": 2898,
		"name": "安康市",
		"merger_name": "中国,陕西省,安康市",
		"level": 2,
		"areas": [{
			"id": 2998,
			"pid": 2997,
			"name": "汉滨区",
			"merger_name": "中国,陕西省,安康市,汉滨区",
			"level": 3
		}, {
			"id": 2999,
			"pid": 2997,
			"name": "汉阴县",
			"merger_name": "中国,陕西省,安康市,汉阴县",
			"level": 3
		}, {
			"id": 3000,
			"pid": 2997,
			"name": "石泉县",
			"merger_name": "中国,陕西省,安康市,石泉县",
			"level": 3
		}, {
			"id": 3001,
			"pid": 2997,
			"name": "宁陕县",
			"merger_name": "中国,陕西省,安康市,宁陕县",
			"level": 3
		}, {
			"id": 3002,
			"pid": 2997,
			"name": "紫阳县",
			"merger_name": "中国,陕西省,安康市,紫阳县",
			"level": 3
		}, {
			"id": 3003,
			"pid": 2997,
			"name": "岚皋县",
			"merger_name": "中国,陕西省,安康市,岚皋县",
			"level": 3
		}, {
			"id": 3004,
			"pid": 2997,
			"name": "平利县",
			"merger_name": "中国,陕西省,安康市,平利县",
			"level": 3
		}, {
			"id": 3005,
			"pid": 2997,
			"name": "镇坪县",
			"merger_name": "中国,陕西省,安康市,镇坪县",
			"level": 3
		}, {
			"id": 3006,
			"pid": 2997,
			"name": "旬阳县",
			"merger_name": "中国,陕西省,安康市,旬阳县",
			"level": 3
		}, {
			"id": 3007,
			"pid": 2997,
			"name": "白河县",
			"merger_name": "中国,陕西省,安康市,白河县",
			"level": 3
		}]
	}, {
		"id": 3008,
		"pid": 2898,
		"name": "商洛市",
		"merger_name": "中国,陕西省,商洛市",
		"level": 2,
		"areas": [{
			"id": 3009,
			"pid": 3008,
			"name": "商州区",
			"merger_name": "中国,陕西省,商洛市,商州区",
			"level": 3
		}, {
			"id": 3010,
			"pid": 3008,
			"name": "洛南县",
			"merger_name": "中国,陕西省,商洛市,洛南县",
			"level": 3
		}, {
			"id": 3011,
			"pid": 3008,
			"name": "丹凤县",
			"merger_name": "中国,陕西省,商洛市,丹凤县",
			"level": 3
		}, {
			"id": 3012,
			"pid": 3008,
			"name": "商南县",
			"merger_name": "中国,陕西省,商洛市,商南县",
			"level": 3
		}, {
			"id": 3013,
			"pid": 3008,
			"name": "山阳县",
			"merger_name": "中国,陕西省,商洛市,山阳县",
			"level": 3
		}, {
			"id": 3014,
			"pid": 3008,
			"name": "镇安县",
			"merger_name": "中国,陕西省,商洛市,镇安县",
			"level": 3
		}, {
			"id": 3015,
			"pid": 3008,
			"name": "柞水县",
			"merger_name": "中国,陕西省,商洛市,柞水县",
			"level": 3
		}]
	}, {
		"id": 3016,
		"pid": 2898,
		"name": "西咸新区",
		"merger_name": "中国,陕西省,西咸新区",
		"level": 2,
		"areas": [{
			"id": 3017,
			"pid": 3016,
			"name": "空港新城",
			"merger_name": "中国,陕西省,西咸新区,空港新城",
			"level": 3
		}, {
			"id": 3018,
			"pid": 3016,
			"name": "沣东新城",
			"merger_name": "中国,陕西省,西咸新区,沣东新城",
			"level": 3
		}, {
			"id": 3019,
			"pid": 3016,
			"name": "秦汉新城",
			"merger_name": "中国,陕西省,西咸新区,秦汉新城",
			"level": 3
		}, {
			"id": 3020,
			"pid": 3016,
			"name": "沣西新城",
			"merger_name": "中国,陕西省,西咸新区,沣西新城",
			"level": 3
		}, {
			"id": 3021,
			"pid": 3016,
			"name": "泾河新城",
			"merger_name": "中国,陕西省,西咸新区,泾河新城",
			"level": 3
		}]
	}]
}, {
	"id": 3022,
	"pid": 0,
	"name": "甘肃省",
	"merger_name": "中国,甘肃省",
	"level": 1,
	"cities": [{
		"id": 3023,
		"pid": 3022,
		"name": "兰州市",
		"merger_name": "中国,甘肃省,兰州市",
		"level": 2,
		"areas": [{
			"id": 3024,
			"pid": 3023,
			"name": "城关区",
			"merger_name": "中国,甘肃省,兰州市,城关区",
			"level": 3
		}, {
			"id": 3025,
			"pid": 3023,
			"name": "七里河区",
			"merger_name": "中国,甘肃省,兰州市,七里河区",
			"level": 3
		}, {
			"id": 3026,
			"pid": 3023,
			"name": "西固区",
			"merger_name": "中国,甘肃省,兰州市,西固区",
			"level": 3
		}, {
			"id": 3027,
			"pid": 3023,
			"name": "安宁区",
			"merger_name": "中国,甘肃省,兰州市,安宁区",
			"level": 3
		}, {
			"id": 3028,
			"pid": 3023,
			"name": "红古区",
			"merger_name": "中国,甘肃省,兰州市,红古区",
			"level": 3
		}, {
			"id": 3029,
			"pid": 3023,
			"name": "永登县",
			"merger_name": "中国,甘肃省,兰州市,永登县",
			"level": 3
		}, {
			"id": 3030,
			"pid": 3023,
			"name": "皋兰县",
			"merger_name": "中国,甘肃省,兰州市,皋兰县",
			"level": 3
		}, {
			"id": 3031,
			"pid": 3023,
			"name": "榆中县",
			"merger_name": "中国,甘肃省,兰州市,榆中县",
			"level": 3
		}]
	}, {
		"id": 3032,
		"pid": 3022,
		"name": "嘉峪关市",
		"merger_name": "中国,甘肃省,嘉峪关市",
		"level": 2,
		"areas": [{
			"id": 3033,
			"pid": 3032,
			"name": "雄关区",
			"merger_name": "中国,甘肃省,嘉峪关市,雄关区",
			"level": 3
		}, {
			"id": 3034,
			"pid": 3032,
			"name": "长城区",
			"merger_name": "中国,甘肃省,嘉峪关市,长城区",
			"level": 3
		}, {
			"id": 3035,
			"pid": 3032,
			"name": "镜铁区",
			"merger_name": "中国,甘肃省,嘉峪关市,镜铁区",
			"level": 3
		}]
	}, {
		"id": 3036,
		"pid": 3022,
		"name": "金昌市",
		"merger_name": "中国,甘肃省,金昌市",
		"level": 2,
		"areas": [{
			"id": 3037,
			"pid": 3036,
			"name": "金川区",
			"merger_name": "中国,甘肃省,金昌市,金川区",
			"level": 3
		}, {
			"id": 3038,
			"pid": 3036,
			"name": "永昌县",
			"merger_name": "中国,甘肃省,金昌市,永昌县",
			"level": 3
		}]
	}, {
		"id": 3039,
		"pid": 3022,
		"name": "白银市",
		"merger_name": "中国,甘肃省,白银市",
		"level": 2,
		"areas": [{
			"id": 3040,
			"pid": 3039,
			"name": "白银区",
			"merger_name": "中国,甘肃省,白银市,白银区",
			"level": 3
		}, {
			"id": 3041,
			"pid": 3039,
			"name": "平川区",
			"merger_name": "中国,甘肃省,白银市,平川区",
			"level": 3
		}, {
			"id": 3042,
			"pid": 3039,
			"name": "靖远县",
			"merger_name": "中国,甘肃省,白银市,靖远县",
			"level": 3
		}, {
			"id": 3043,
			"pid": 3039,
			"name": "会宁县",
			"merger_name": "中国,甘肃省,白银市,会宁县",
			"level": 3
		}, {
			"id": 3044,
			"pid": 3039,
			"name": "景泰县",
			"merger_name": "中国,甘肃省,白银市,景泰县",
			"level": 3
		}]
	}, {
		"id": 3045,
		"pid": 3022,
		"name": "天水市",
		"merger_name": "中国,甘肃省,天水市",
		"level": 2,
		"areas": [{
			"id": 3046,
			"pid": 3045,
			"name": "秦州区",
			"merger_name": "中国,甘肃省,天水市,秦州区",
			"level": 3
		}, {
			"id": 3047,
			"pid": 3045,
			"name": "麦积区",
			"merger_name": "中国,甘肃省,天水市,麦积区",
			"level": 3
		}, {
			"id": 3048,
			"pid": 3045,
			"name": "清水县",
			"merger_name": "中国,甘肃省,天水市,清水县",
			"level": 3
		}, {
			"id": 3049,
			"pid": 3045,
			"name": "秦安县",
			"merger_name": "中国,甘肃省,天水市,秦安县",
			"level": 3
		}, {
			"id": 3050,
			"pid": 3045,
			"name": "甘谷县",
			"merger_name": "中国,甘肃省,天水市,甘谷县",
			"level": 3
		}, {
			"id": 3051,
			"pid": 3045,
			"name": "武山县",
			"merger_name": "中国,甘肃省,天水市,武山县",
			"level": 3
		}, {
			"id": 3052,
			"pid": 3045,
			"name": "张家川回族自治县",
			"merger_name": "中国,甘肃省,天水市,张家川回族自治县",
			"level": 3
		}]
	}, {
		"id": 3053,
		"pid": 3022,
		"name": "武威市",
		"merger_name": "中国,甘肃省,武威市",
		"level": 2,
		"areas": [{
			"id": 3054,
			"pid": 3053,
			"name": "凉州区",
			"merger_name": "中国,甘肃省,武威市,凉州区",
			"level": 3
		}, {
			"id": 3055,
			"pid": 3053,
			"name": "民勤县",
			"merger_name": "中国,甘肃省,武威市,民勤县",
			"level": 3
		}, {
			"id": 3056,
			"pid": 3053,
			"name": "古浪县",
			"merger_name": "中国,甘肃省,武威市,古浪县",
			"level": 3
		}, {
			"id": 3057,
			"pid": 3053,
			"name": "天祝藏族自治县",
			"merger_name": "中国,甘肃省,武威市,天祝藏族自治县",
			"level": 3
		}]
	}, {
		"id": 3058,
		"pid": 3022,
		"name": "张掖市",
		"merger_name": "中国,甘肃省,张掖市",
		"level": 2,
		"areas": [{
			"id": 3059,
			"pid": 3058,
			"name": "甘州区",
			"merger_name": "中国,甘肃省,张掖市,甘州区",
			"level": 3
		}, {
			"id": 3060,
			"pid": 3058,
			"name": "肃南裕固族自治县",
			"merger_name": "中国,甘肃省,张掖市,肃南裕固族自治县",
			"level": 3
		}, {
			"id": 3061,
			"pid": 3058,
			"name": "民乐县",
			"merger_name": "中国,甘肃省,张掖市,民乐县",
			"level": 3
		}, {
			"id": 3062,
			"pid": 3058,
			"name": "临泽县",
			"merger_name": "中国,甘肃省,张掖市,临泽县",
			"level": 3
		}, {
			"id": 3063,
			"pid": 3058,
			"name": "高台县",
			"merger_name": "中国,甘肃省,张掖市,高台县",
			"level": 3
		}, {
			"id": 3064,
			"pid": 3058,
			"name": "山丹县",
			"merger_name": "中国,甘肃省,张掖市,山丹县",
			"level": 3
		}]
	}, {
		"id": 3065,
		"pid": 3022,
		"name": "平凉市",
		"merger_name": "中国,甘肃省,平凉市",
		"level": 2,
		"areas": [{
			"id": 3066,
			"pid": 3065,
			"name": "崆峒区",
			"merger_name": "中国,甘肃省,平凉市,崆峒区",
			"level": 3
		}, {
			"id": 3067,
			"pid": 3065,
			"name": "泾川县",
			"merger_name": "中国,甘肃省,平凉市,泾川县",
			"level": 3
		}, {
			"id": 3068,
			"pid": 3065,
			"name": "灵台县",
			"merger_name": "中国,甘肃省,平凉市,灵台县",
			"level": 3
		}, {
			"id": 3069,
			"pid": 3065,
			"name": "崇信县",
			"merger_name": "中国,甘肃省,平凉市,崇信县",
			"level": 3
		}, {
			"id": 3070,
			"pid": 3065,
			"name": "华亭县",
			"merger_name": "中国,甘肃省,平凉市,华亭县",
			"level": 3
		}, {
			"id": 3071,
			"pid": 3065,
			"name": "庄浪县",
			"merger_name": "中国,甘肃省,平凉市,庄浪县",
			"level": 3
		}, {
			"id": 3072,
			"pid": 3065,
			"name": "静宁县",
			"merger_name": "中国,甘肃省,平凉市,静宁县",
			"level": 3
		}]
	}, {
		"id": 3073,
		"pid": 3022,
		"name": "酒泉市",
		"merger_name": "中国,甘肃省,酒泉市",
		"level": 2,
		"areas": [{
			"id": 3074,
			"pid": 3073,
			"name": "肃州区",
			"merger_name": "中国,甘肃省,酒泉市,肃州区",
			"level": 3
		}, {
			"id": 3075,
			"pid": 3073,
			"name": "金塔县",
			"merger_name": "中国,甘肃省,酒泉市,金塔县",
			"level": 3
		}, {
			"id": 3076,
			"pid": 3073,
			"name": "瓜州县",
			"merger_name": "中国,甘肃省,酒泉市,瓜州县",
			"level": 3
		}, {
			"id": 3077,
			"pid": 3073,
			"name": "肃北蒙古族自治县",
			"merger_name": "中国,甘肃省,酒泉市,肃北蒙古族自治县",
			"level": 3
		}, {
			"id": 3078,
			"pid": 3073,
			"name": "阿克塞哈萨克族自治县",
			"merger_name": "中国,甘肃省,酒泉市,阿克塞哈萨克族自治县",
			"level": 3
		}, {
			"id": 3079,
			"pid": 3073,
			"name": "玉门市",
			"merger_name": "中国,甘肃省,酒泉市,玉门市",
			"level": 3
		}, {
			"id": 3080,
			"pid": 3073,
			"name": "敦煌市",
			"merger_name": "中国,甘肃省,酒泉市,敦煌市",
			"level": 3
		}]
	}, {
		"id": 3081,
		"pid": 3022,
		"name": "庆阳市",
		"merger_name": "中国,甘肃省,庆阳市",
		"level": 2,
		"areas": [{
			"id": 3082,
			"pid": 3081,
			"name": "西峰区",
			"merger_name": "中国,甘肃省,庆阳市,西峰区",
			"level": 3
		}, {
			"id": 3083,
			"pid": 3081,
			"name": "庆城县",
			"merger_name": "中国,甘肃省,庆阳市,庆城县",
			"level": 3
		}, {
			"id": 3084,
			"pid": 3081,
			"name": "环县",
			"merger_name": "中国,甘肃省,庆阳市,环县",
			"level": 3
		}, {
			"id": 3085,
			"pid": 3081,
			"name": "华池县",
			"merger_name": "中国,甘肃省,庆阳市,华池县",
			"level": 3
		}, {
			"id": 3086,
			"pid": 3081,
			"name": "合水县",
			"merger_name": "中国,甘肃省,庆阳市,合水县",
			"level": 3
		}, {
			"id": 3087,
			"pid": 3081,
			"name": "正宁县",
			"merger_name": "中国,甘肃省,庆阳市,正宁县",
			"level": 3
		}, {
			"id": 3088,
			"pid": 3081,
			"name": "宁县",
			"merger_name": "中国,甘肃省,庆阳市,宁县",
			"level": 3
		}, {
			"id": 3089,
			"pid": 3081,
			"name": "镇原县",
			"merger_name": "中国,甘肃省,庆阳市,镇原县",
			"level": 3
		}]
	}, {
		"id": 3090,
		"pid": 3022,
		"name": "定西市",
		"merger_name": "中国,甘肃省,定西市",
		"level": 2,
		"areas": [{
			"id": 3091,
			"pid": 3090,
			"name": "安定区",
			"merger_name": "中国,甘肃省,定西市,安定区",
			"level": 3
		}, {
			"id": 3092,
			"pid": 3090,
			"name": "通渭县",
			"merger_name": "中国,甘肃省,定西市,通渭县",
			"level": 3
		}, {
			"id": 3093,
			"pid": 3090,
			"name": "陇西县",
			"merger_name": "中国,甘肃省,定西市,陇西县",
			"level": 3
		}, {
			"id": 3094,
			"pid": 3090,
			"name": "渭源县",
			"merger_name": "中国,甘肃省,定西市,渭源县",
			"level": 3
		}, {
			"id": 3095,
			"pid": 3090,
			"name": "临洮县",
			"merger_name": "中国,甘肃省,定西市,临洮县",
			"level": 3
		}, {
			"id": 3096,
			"pid": 3090,
			"name": "漳县",
			"merger_name": "中国,甘肃省,定西市,漳县",
			"level": 3
		}, {
			"id": 3097,
			"pid": 3090,
			"name": "岷县",
			"merger_name": "中国,甘肃省,定西市,岷县",
			"level": 3
		}]
	}, {
		"id": 3098,
		"pid": 3022,
		"name": "陇南市",
		"merger_name": "中国,甘肃省,陇南市",
		"level": 2,
		"areas": [{
			"id": 3099,
			"pid": 3098,
			"name": "武都区",
			"merger_name": "中国,甘肃省,陇南市,武都区",
			"level": 3
		}, {
			"id": 3100,
			"pid": 3098,
			"name": "成县",
			"merger_name": "中国,甘肃省,陇南市,成县",
			"level": 3
		}, {
			"id": 3101,
			"pid": 3098,
			"name": "文县",
			"merger_name": "中国,甘肃省,陇南市,文县",
			"level": 3
		}, {
			"id": 3102,
			"pid": 3098,
			"name": "宕昌县",
			"merger_name": "中国,甘肃省,陇南市,宕昌县",
			"level": 3
		}, {
			"id": 3103,
			"pid": 3098,
			"name": "康县",
			"merger_name": "中国,甘肃省,陇南市,康县",
			"level": 3
		}, {
			"id": 3104,
			"pid": 3098,
			"name": "西和县",
			"merger_name": "中国,甘肃省,陇南市,西和县",
			"level": 3
		}, {
			"id": 3105,
			"pid": 3098,
			"name": "礼县",
			"merger_name": "中国,甘肃省,陇南市,礼县",
			"level": 3
		}, {
			"id": 3106,
			"pid": 3098,
			"name": "徽县",
			"merger_name": "中国,甘肃省,陇南市,徽县",
			"level": 3
		}, {
			"id": 3107,
			"pid": 3098,
			"name": "两当县",
			"merger_name": "中国,甘肃省,陇南市,两当县",
			"level": 3
		}]
	}, {
		"id": 3108,
		"pid": 3022,
		"name": "临夏回族自治州",
		"merger_name": "中国,甘肃省,临夏回族自治州",
		"level": 2,
		"areas": [{
			"id": 3109,
			"pid": 3108,
			"name": "临夏市",
			"merger_name": "中国,甘肃省,临夏回族自治州,临夏市",
			"level": 3
		}, {
			"id": 3110,
			"pid": 3108,
			"name": "临夏县",
			"merger_name": "中国,甘肃省,临夏回族自治州,临夏县",
			"level": 3
		}, {
			"id": 3111,
			"pid": 3108,
			"name": "康乐县",
			"merger_name": "中国,甘肃省,临夏回族自治州,康乐县",
			"level": 3
		}, {
			"id": 3112,
			"pid": 3108,
			"name": "永靖县",
			"merger_name": "中国,甘肃省,临夏回族自治州,永靖县",
			"level": 3
		}, {
			"id": 3113,
			"pid": 3108,
			"name": "广河县",
			"merger_name": "中国,甘肃省,临夏回族自治州,广河县",
			"level": 3
		}, {
			"id": 3114,
			"pid": 3108,
			"name": "和政县",
			"merger_name": "中国,甘肃省,临夏回族自治州,和政县",
			"level": 3
		}, {
			"id": 3115,
			"pid": 3108,
			"name": "东乡族自治县",
			"merger_name": "中国,甘肃省,临夏回族自治州,东乡族自治县",
			"level": 3
		}, {
			"id": 3116,
			"pid": 3108,
			"name": "积石山保安族东乡族撒拉族自治县",
			"merger_name": "中国,甘肃省,临夏回族自治州,积石山保安族东乡族撒拉族自治县",
			"level": 3
		}]
	}, {
		"id": 3117,
		"pid": 3022,
		"name": "甘南藏族自治州",
		"merger_name": "中国,甘肃省,甘南藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3118,
			"pid": 3117,
			"name": "合作市",
			"merger_name": "中国,甘肃省,甘南藏族自治州,合作市",
			"level": 3
		}, {
			"id": 3119,
			"pid": 3117,
			"name": "临潭县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,临潭县",
			"level": 3
		}, {
			"id": 3120,
			"pid": 3117,
			"name": "卓尼县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,卓尼县",
			"level": 3
		}, {
			"id": 3121,
			"pid": 3117,
			"name": "舟曲县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,舟曲县",
			"level": 3
		}, {
			"id": 3122,
			"pid": 3117,
			"name": "迭部县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,迭部县",
			"level": 3
		}, {
			"id": 3123,
			"pid": 3117,
			"name": "玛曲县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,玛曲县",
			"level": 3
		}, {
			"id": 3124,
			"pid": 3117,
			"name": "碌曲县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,碌曲县",
			"level": 3
		}, {
			"id": 3125,
			"pid": 3117,
			"name": "夏河县",
			"merger_name": "中国,甘肃省,甘南藏族自治州,夏河县",
			"level": 3
		}]
	}]
}, {
	"id": 3126,
	"pid": 0,
	"name": "青海省",
	"merger_name": "中国,青海省",
	"level": 1,
	"cities": [{
		"id": 3127,
		"pid": 3126,
		"name": "西宁市",
		"merger_name": "中国,青海省,西宁市",
		"level": 2,
		"areas": [{
			"id": 3128,
			"pid": 3127,
			"name": "城东区",
			"merger_name": "中国,青海省,西宁市,城东区",
			"level": 3
		}, {
			"id": 3129,
			"pid": 3127,
			"name": "城中区",
			"merger_name": "中国,青海省,西宁市,城中区",
			"level": 3
		}, {
			"id": 3130,
			"pid": 3127,
			"name": "城西区",
			"merger_name": "中国,青海省,西宁市,城西区",
			"level": 3
		}, {
			"id": 3131,
			"pid": 3127,
			"name": "城北区",
			"merger_name": "中国,青海省,西宁市,城北区",
			"level": 3
		}, {
			"id": 3132,
			"pid": 3127,
			"name": "大通回族土族自治县",
			"merger_name": "中国,青海省,西宁市,大通回族土族自治县",
			"level": 3
		}, {
			"id": 3133,
			"pid": 3127,
			"name": "湟中县",
			"merger_name": "中国,青海省,西宁市,湟中县",
			"level": 3
		}, {
			"id": 3134,
			"pid": 3127,
			"name": "湟源县",
			"merger_name": "中国,青海省,西宁市,湟源县",
			"level": 3
		}]
	}, {
		"id": 3135,
		"pid": 3126,
		"name": "海东市",
		"merger_name": "中国,青海省,海东市",
		"level": 2,
		"areas": [{
			"id": 3136,
			"pid": 3135,
			"name": "乐都区",
			"merger_name": "中国,青海省,海东市,乐都区",
			"level": 3
		}, {
			"id": 3137,
			"pid": 3135,
			"name": "平安县",
			"merger_name": "中国,青海省,海东市,平安县",
			"level": 3
		}, {
			"id": 3138,
			"pid": 3135,
			"name": "民和回族土族自治县",
			"merger_name": "中国,青海省,海东市,民和回族土族自治县",
			"level": 3
		}, {
			"id": 3139,
			"pid": 3135,
			"name": "互助土族自治县",
			"merger_name": "中国,青海省,海东市,互助土族自治县",
			"level": 3
		}, {
			"id": 3140,
			"pid": 3135,
			"name": "化隆回族自治县",
			"merger_name": "中国,青海省,海东市,化隆回族自治县",
			"level": 3
		}, {
			"id": 3141,
			"pid": 3135,
			"name": "循化撒拉族自治县",
			"merger_name": "中国,青海省,海东市,循化撒拉族自治县",
			"level": 3
		}]
	}, {
		"id": 3142,
		"pid": 3126,
		"name": "海北藏族自治州",
		"merger_name": "中国,青海省,海北藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3143,
			"pid": 3142,
			"name": "门源回族自治县",
			"merger_name": "中国,青海省,海北藏族自治州,门源回族自治县",
			"level": 3
		}, {
			"id": 3144,
			"pid": 3142,
			"name": "祁连县",
			"merger_name": "中国,青海省,海北藏族自治州,祁连县",
			"level": 3
		}, {
			"id": 3145,
			"pid": 3142,
			"name": "海晏县",
			"merger_name": "中国,青海省,海北藏族自治州,海晏县",
			"level": 3
		}, {
			"id": 3146,
			"pid": 3142,
			"name": "刚察县",
			"merger_name": "中国,青海省,海北藏族自治州,刚察县",
			"level": 3
		}]
	}, {
		"id": 3147,
		"pid": 3126,
		"name": "黄南藏族自治州",
		"merger_name": "中国,青海省,黄南藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3148,
			"pid": 3147,
			"name": "同仁县",
			"merger_name": "中国,青海省,黄南藏族自治州,同仁县",
			"level": 3
		}, {
			"id": 3149,
			"pid": 3147,
			"name": "尖扎县",
			"merger_name": "中国,青海省,黄南藏族自治州,尖扎县",
			"level": 3
		}, {
			"id": 3150,
			"pid": 3147,
			"name": "泽库县",
			"merger_name": "中国,青海省,黄南藏族自治州,泽库县",
			"level": 3
		}, {
			"id": 3151,
			"pid": 3147,
			"name": "河南蒙古族自治县",
			"merger_name": "中国,青海省,黄南藏族自治州,河南蒙古族自治县",
			"level": 3
		}]
	}, {
		"id": 3152,
		"pid": 3126,
		"name": "海南藏族自治州",
		"merger_name": "中国,青海省,海南藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3153,
			"pid": 3152,
			"name": "共和县",
			"merger_name": "中国,青海省,海南藏族自治州,共和县",
			"level": 3
		}, {
			"id": 3154,
			"pid": 3152,
			"name": "同德县",
			"merger_name": "中国,青海省,海南藏族自治州,同德县",
			"level": 3
		}, {
			"id": 3155,
			"pid": 3152,
			"name": "贵德县",
			"merger_name": "中国,青海省,海南藏族自治州,贵德县",
			"level": 3
		}, {
			"id": 3156,
			"pid": 3152,
			"name": "兴海县",
			"merger_name": "中国,青海省,海南藏族自治州,兴海县",
			"level": 3
		}, {
			"id": 3157,
			"pid": 3152,
			"name": "贵南县",
			"merger_name": "中国,青海省,海南藏族自治州,贵南县",
			"level": 3
		}]
	}, {
		"id": 3158,
		"pid": 3126,
		"name": "果洛藏族自治州",
		"merger_name": "中国,青海省,果洛藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3159,
			"pid": 3158,
			"name": "玛沁县",
			"merger_name": "中国,青海省,果洛藏族自治州,玛沁县",
			"level": 3
		}, {
			"id": 3160,
			"pid": 3158,
			"name": "班玛县",
			"merger_name": "中国,青海省,果洛藏族自治州,班玛县",
			"level": 3
		}, {
			"id": 3161,
			"pid": 3158,
			"name": "甘德县",
			"merger_name": "中国,青海省,果洛藏族自治州,甘德县",
			"level": 3
		}, {
			"id": 3162,
			"pid": 3158,
			"name": "达日县",
			"merger_name": "中国,青海省,果洛藏族自治州,达日县",
			"level": 3
		}, {
			"id": 3163,
			"pid": 3158,
			"name": "久治县",
			"merger_name": "中国,青海省,果洛藏族自治州,久治县",
			"level": 3
		}, {
			"id": 3164,
			"pid": 3158,
			"name": "玛多县",
			"merger_name": "中国,青海省,果洛藏族自治州,玛多县",
			"level": 3
		}]
	}, {
		"id": 3165,
		"pid": 3126,
		"name": "玉树藏族自治州",
		"merger_name": "中国,青海省,玉树藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3166,
			"pid": 3165,
			"name": "玉树市",
			"merger_name": "中国,青海省,玉树藏族自治州,玉树市",
			"level": 3
		}, {
			"id": 3167,
			"pid": 3165,
			"name": "杂多县",
			"merger_name": "中国,青海省,玉树藏族自治州,杂多县",
			"level": 3
		}, {
			"id": 3168,
			"pid": 3165,
			"name": "称多县",
			"merger_name": "中国,青海省,玉树藏族自治州,称多县",
			"level": 3
		}, {
			"id": 3169,
			"pid": 3165,
			"name": "治多县",
			"merger_name": "中国,青海省,玉树藏族自治州,治多县",
			"level": 3
		}, {
			"id": 3170,
			"pid": 3165,
			"name": "囊谦县",
			"merger_name": "中国,青海省,玉树藏族自治州,囊谦县",
			"level": 3
		}, {
			"id": 3171,
			"pid": 3165,
			"name": "曲麻莱县",
			"merger_name": "中国,青海省,玉树藏族自治州,曲麻莱县",
			"level": 3
		}]
	}, {
		"id": 3172,
		"pid": 3126,
		"name": "海西蒙古族藏族自治州",
		"merger_name": "中国,青海省,海西蒙古族藏族自治州",
		"level": 2,
		"areas": [{
			"id": 3173,
			"pid": 3172,
			"name": "格尔木市",
			"merger_name": "中国,青海省,海西蒙古族藏族自治州,格尔木市",
			"level": 3
		}, {
			"id": 3174,
			"pid": 3172,
			"name": "德令哈市",
			"merger_name": "中国,青海省,海西蒙古族藏族自治州,德令哈市",
			"level": 3
		}, {
			"id": 3175,
			"pid": 3172,
			"name": "乌兰县",
			"merger_name": "中国,青海省,海西蒙古族藏族自治州,乌兰县",
			"level": 3
		}, {
			"id": 3176,
			"pid": 3172,
			"name": "都兰县",
			"merger_name": "中国,青海省,海西蒙古族藏族自治州,都兰县",
			"level": 3
		}, {
			"id": 3177,
			"pid": 3172,
			"name": "天峻县",
			"merger_name": "中国,青海省,海西蒙古族藏族自治州,天峻县",
			"level": 3
		}]
	}]
}, {
	"id": 3178,
	"pid": 0,
	"name": "宁夏回族自治区",
	"merger_name": "中国,宁夏回族自治区",
	"level": 1,
	"cities": [{
		"id": 3179,
		"pid": 3178,
		"name": "银川市",
		"merger_name": "中国,宁夏回族自治区,银川市",
		"level": 2,
		"areas": [{
			"id": 3180,
			"pid": 3179,
			"name": "兴庆区",
			"merger_name": "中国,宁夏回族自治区,银川市,兴庆区",
			"level": 3
		}, {
			"id": 3181,
			"pid": 3179,
			"name": "西夏区",
			"merger_name": "中国,宁夏回族自治区,银川市,西夏区",
			"level": 3
		}, {
			"id": 3182,
			"pid": 3179,
			"name": "金凤区",
			"merger_name": "中国,宁夏回族自治区,银川市,金凤区",
			"level": 3
		}, {
			"id": 3183,
			"pid": 3179,
			"name": "永宁县",
			"merger_name": "中国,宁夏回族自治区,银川市,永宁县",
			"level": 3
		}, {
			"id": 3184,
			"pid": 3179,
			"name": "贺兰县",
			"merger_name": "中国,宁夏回族自治区,银川市,贺兰县",
			"level": 3
		}, {
			"id": 3185,
			"pid": 3179,
			"name": "灵武市",
			"merger_name": "中国,宁夏回族自治区,银川市,灵武市",
			"level": 3
		}]
	}, {
		"id": 3186,
		"pid": 3178,
		"name": "石嘴山市",
		"merger_name": "中国,宁夏回族自治区,石嘴山市",
		"level": 2,
		"areas": [{
			"id": 3187,
			"pid": 3186,
			"name": "大武口区",
			"merger_name": "中国,宁夏回族自治区,石嘴山市,大武口区",
			"level": 3
		}, {
			"id": 3188,
			"pid": 3186,
			"name": "惠农区",
			"merger_name": "中国,宁夏回族自治区,石嘴山市,惠农区",
			"level": 3
		}, {
			"id": 3189,
			"pid": 3186,
			"name": "平罗县",
			"merger_name": "中国,宁夏回族自治区,石嘴山市,平罗县",
			"level": 3
		}]
	}, {
		"id": 3190,
		"pid": 3178,
		"name": "吴忠市",
		"merger_name": "中国,宁夏回族自治区,吴忠市",
		"level": 2,
		"areas": [{
			"id": 3191,
			"pid": 3190,
			"name": "利通区",
			"merger_name": "中国,宁夏回族自治区,吴忠市,利通区",
			"level": 3
		}, {
			"id": 3192,
			"pid": 3190,
			"name": "红寺堡区",
			"merger_name": "中国,宁夏回族自治区,吴忠市,红寺堡区",
			"level": 3
		}, {
			"id": 3193,
			"pid": 3190,
			"name": "盐池县",
			"merger_name": "中国,宁夏回族自治区,吴忠市,盐池县",
			"level": 3
		}, {
			"id": 3194,
			"pid": 3190,
			"name": "同心县",
			"merger_name": "中国,宁夏回族自治区,吴忠市,同心县",
			"level": 3
		}, {
			"id": 3195,
			"pid": 3190,
			"name": "青铜峡市",
			"merger_name": "中国,宁夏回族自治区,吴忠市,青铜峡市",
			"level": 3
		}]
	}, {
		"id": 3196,
		"pid": 3178,
		"name": "固原市",
		"merger_name": "中国,宁夏回族自治区,固原市",
		"level": 2,
		"areas": [{
			"id": 3197,
			"pid": 3196,
			"name": "原州区",
			"merger_name": "中国,宁夏回族自治区,固原市,原州区",
			"level": 3
		}, {
			"id": 3198,
			"pid": 3196,
			"name": "西吉县",
			"merger_name": "中国,宁夏回族自治区,固原市,西吉县",
			"level": 3
		}, {
			"id": 3199,
			"pid": 3196,
			"name": "隆德县",
			"merger_name": "中国,宁夏回族自治区,固原市,隆德县",
			"level": 3
		}, {
			"id": 3200,
			"pid": 3196,
			"name": "泾源县",
			"merger_name": "中国,宁夏回族自治区,固原市,泾源县",
			"level": 3
		}, {
			"id": 3201,
			"pid": 3196,
			"name": "彭阳县",
			"merger_name": "中国,宁夏回族自治区,固原市,彭阳县",
			"level": 3
		}]
	}, {
		"id": 3202,
		"pid": 3178,
		"name": "中卫市",
		"merger_name": "中国,宁夏回族自治区,中卫市",
		"level": 2,
		"areas": [{
			"id": 3203,
			"pid": 3202,
			"name": "沙坡头区",
			"merger_name": "中国,宁夏回族自治区,中卫市,沙坡头区",
			"level": 3
		}, {
			"id": 3204,
			"pid": 3202,
			"name": "中宁县",
			"merger_name": "中国,宁夏回族自治区,中卫市,中宁县",
			"level": 3
		}, {
			"id": 3205,
			"pid": 3202,
			"name": "海原县",
			"merger_name": "中国,宁夏回族自治区,中卫市,海原县",
			"level": 3
		}]
	}]
}, {
	"id": 3206,
	"pid": 0,
	"name": "新疆维吾尔自治区",
	"merger_name": "中国,新疆维吾尔自治区",
	"level": 1,
	"cities": [{
		"id": 3207,
		"pid": 3206,
		"name": "乌鲁木齐市",
		"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市",
		"level": 2,
		"areas": [{
			"id": 3208,
			"pid": 3207,
			"name": "天山区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,天山区",
			"level": 3
		}, {
			"id": 3209,
			"pid": 3207,
			"name": "沙依巴克区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,沙依巴克区",
			"level": 3
		}, {
			"id": 3210,
			"pid": 3207,
			"name": "新市区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,新市区",
			"level": 3
		}, {
			"id": 3211,
			"pid": 3207,
			"name": "水磨沟区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,水磨沟区",
			"level": 3
		}, {
			"id": 3212,
			"pid": 3207,
			"name": "头屯河区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,头屯河区",
			"level": 3
		}, {
			"id": 3213,
			"pid": 3207,
			"name": "达坂城区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,达坂城区",
			"level": 3
		}, {
			"id": 3214,
			"pid": 3207,
			"name": "米东区",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,米东区",
			"level": 3
		}, {
			"id": 3215,
			"pid": 3207,
			"name": "乌鲁木齐县",
			"merger_name": "中国,新疆维吾尔自治区,乌鲁木齐市,乌鲁木齐县",
			"level": 3
		}]
	}, {
		"id": 3216,
		"pid": 3206,
		"name": "克拉玛依市",
		"merger_name": "中国,新疆维吾尔自治区,克拉玛依市",
		"level": 2,
		"areas": [{
			"id": 3217,
			"pid": 3216,
			"name": "独山子区",
			"merger_name": "中国,新疆维吾尔自治区,克拉玛依市,独山子区",
			"level": 3
		}, {
			"id": 3218,
			"pid": 3216,
			"name": "克拉玛依区",
			"merger_name": "中国,新疆维吾尔自治区,克拉玛依市,克拉玛依区",
			"level": 3
		}, {
			"id": 3219,
			"pid": 3216,
			"name": "白碱滩区",
			"merger_name": "中国,新疆维吾尔自治区,克拉玛依市,白碱滩区",
			"level": 3
		}, {
			"id": 3220,
			"pid": 3216,
			"name": "乌尔禾区",
			"merger_name": "中国,新疆维吾尔自治区,克拉玛依市,乌尔禾区",
			"level": 3
		}]
	}, {
		"id": 3221,
		"pid": 3206,
		"name": "吐鲁番地区",
		"merger_name": "中国,新疆维吾尔自治区,吐鲁番地区",
		"level": 2,
		"areas": [{
			"id": 3222,
			"pid": 3221,
			"name": "吐鲁番市",
			"merger_name": "中国,新疆维吾尔自治区,吐鲁番地区,吐鲁番市",
			"level": 3
		}, {
			"id": 3223,
			"pid": 3221,
			"name": "鄯善县",
			"merger_name": "中国,新疆维吾尔自治区,吐鲁番地区,鄯善县",
			"level": 3
		}, {
			"id": 3224,
			"pid": 3221,
			"name": "托克逊县",
			"merger_name": "中国,新疆维吾尔自治区,吐鲁番地区,托克逊县",
			"level": 3
		}]
	}, {
		"id": 3225,
		"pid": 3206,
		"name": "哈密地区",
		"merger_name": "中国,新疆维吾尔自治区,哈密地区",
		"level": 2,
		"areas": [{
			"id": 3226,
			"pid": 3225,
			"name": "哈密市",
			"merger_name": "中国,新疆维吾尔自治区,哈密地区,哈密市",
			"level": 3
		}, {
			"id": 3227,
			"pid": 3225,
			"name": "巴里坤哈萨克自治县",
			"merger_name": "中国,新疆维吾尔自治区,哈密地区,巴里坤哈萨克自治县",
			"level": 3
		}, {
			"id": 3228,
			"pid": 3225,
			"name": "伊吾县",
			"merger_name": "中国,新疆维吾尔自治区,哈密地区,伊吾县",
			"level": 3
		}]
	}, {
		"id": 3229,
		"pid": 3206,
		"name": "昌吉回族自治州",
		"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州",
		"level": 2,
		"areas": [{
			"id": 3230,
			"pid": 3229,
			"name": "昌吉市",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,昌吉市",
			"level": 3
		}, {
			"id": 3231,
			"pid": 3229,
			"name": "阜康市",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,阜康市",
			"level": 3
		}, {
			"id": 3232,
			"pid": 3229,
			"name": "呼图壁县",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,呼图壁县",
			"level": 3
		}, {
			"id": 3233,
			"pid": 3229,
			"name": "玛纳斯县",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,玛纳斯县",
			"level": 3
		}, {
			"id": 3234,
			"pid": 3229,
			"name": "奇台县",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,奇台县",
			"level": 3
		}, {
			"id": 3235,
			"pid": 3229,
			"name": "吉木萨尔县",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,吉木萨尔县",
			"level": 3
		}, {
			"id": 3236,
			"pid": 3229,
			"name": "木垒哈萨克自治县",
			"merger_name": "中国,新疆维吾尔自治区,昌吉回族自治州,木垒哈萨克自治县",
			"level": 3
		}]
	}, {
		"id": 3237,
		"pid": 3206,
		"name": "博尔塔拉蒙古自治州",
		"merger_name": "中国,新疆维吾尔自治区,博尔塔拉蒙古自治州",
		"level": 2,
		"areas": [{
			"id": 3238,
			"pid": 3237,
			"name": "博乐市",
			"merger_name": "中国,新疆维吾尔自治区,博尔塔拉蒙古自治州,博乐市",
			"level": 3
		}, {
			"id": 3239,
			"pid": 3237,
			"name": "阿拉山口市",
			"merger_name": "中国,新疆维吾尔自治区,博尔塔拉蒙古自治州,阿拉山口市",
			"level": 3
		}, {
			"id": 3240,
			"pid": 3237,
			"name": "精河县",
			"merger_name": "中国,新疆维吾尔自治区,博尔塔拉蒙古自治州,精河县",
			"level": 3
		}, {
			"id": 3241,
			"pid": 3237,
			"name": "温泉县",
			"merger_name": "中国,新疆维吾尔自治区,博尔塔拉蒙古自治州,温泉县",
			"level": 3
		}]
	}, {
		"id": 3242,
		"pid": 3206,
		"name": "巴音郭楞蒙古自治州",
		"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州",
		"level": 2,
		"areas": [{
			"id": 3243,
			"pid": 3242,
			"name": "库尔勒市",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,库尔勒市",
			"level": 3
		}, {
			"id": 3244,
			"pid": 3242,
			"name": "轮台县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,轮台县",
			"level": 3
		}, {
			"id": 3245,
			"pid": 3242,
			"name": "尉犁县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,尉犁县",
			"level": 3
		}, {
			"id": 3246,
			"pid": 3242,
			"name": "若羌县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,若羌县",
			"level": 3
		}, {
			"id": 3247,
			"pid": 3242,
			"name": "且末县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,且末县",
			"level": 3
		}, {
			"id": 3248,
			"pid": 3242,
			"name": "焉耆回族自治县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,焉耆回族自治县",
			"level": 3
		}, {
			"id": 3249,
			"pid": 3242,
			"name": "和静县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,和静县",
			"level": 3
		}, {
			"id": 3250,
			"pid": 3242,
			"name": "和硕县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,和硕县",
			"level": 3
		}, {
			"id": 3251,
			"pid": 3242,
			"name": "博湖县",
			"merger_name": "中国,新疆维吾尔自治区,巴音郭楞蒙古自治州,博湖县",
			"level": 3
		}]
	}, {
		"id": 3252,
		"pid": 3206,
		"name": "阿克苏地区",
		"merger_name": "中国,新疆维吾尔自治区,阿克苏地区",
		"level": 2,
		"areas": [{
			"id": 3253,
			"pid": 3252,
			"name": "阿克苏市",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,阿克苏市",
			"level": 3
		}, {
			"id": 3254,
			"pid": 3252,
			"name": "温宿县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,温宿县",
			"level": 3
		}, {
			"id": 3255,
			"pid": 3252,
			"name": "库车县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,库车县",
			"level": 3
		}, {
			"id": 3256,
			"pid": 3252,
			"name": "沙雅县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,沙雅县",
			"level": 3
		}, {
			"id": 3257,
			"pid": 3252,
			"name": "新和县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,新和县",
			"level": 3
		}, {
			"id": 3258,
			"pid": 3252,
			"name": "拜城县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,拜城县",
			"level": 3
		}, {
			"id": 3259,
			"pid": 3252,
			"name": "乌什县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,乌什县",
			"level": 3
		}, {
			"id": 3260,
			"pid": 3252,
			"name": "阿瓦提县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,阿瓦提县",
			"level": 3
		}, {
			"id": 3261,
			"pid": 3252,
			"name": "柯坪县",
			"merger_name": "中国,新疆维吾尔自治区,阿克苏地区,柯坪县",
			"level": 3
		}]
	}, {
		"id": 3262,
		"pid": 3206,
		"name": "克孜勒苏柯尔克孜自治州",
		"merger_name": "中国,新疆维吾尔自治区,克孜勒苏柯尔克孜自治州",
		"level": 2,
		"areas": [{
			"id": 3263,
			"pid": 3262,
			"name": "阿图什市",
			"merger_name": "中国,新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿图什市",
			"level": 3
		}, {
			"id": 3264,
			"pid": 3262,
			"name": "阿克陶县",
			"merger_name": "中国,新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿克陶县",
			"level": 3
		}, {
			"id": 3265,
			"pid": 3262,
			"name": "阿合奇县",
			"merger_name": "中国,新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿合奇县",
			"level": 3
		}, {
			"id": 3266,
			"pid": 3262,
			"name": "乌恰县",
			"merger_name": "中国,新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,乌恰县",
			"level": 3
		}]
	}, {
		"id": 3267,
		"pid": 3206,
		"name": "喀什地区",
		"merger_name": "中国,新疆维吾尔自治区,喀什地区",
		"level": 2,
		"areas": [{
			"id": 3268,
			"pid": 3267,
			"name": "喀什市",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,喀什市",
			"level": 3
		}, {
			"id": 3269,
			"pid": 3267,
			"name": "疏附县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,疏附县",
			"level": 3
		}, {
			"id": 3270,
			"pid": 3267,
			"name": "疏勒县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,疏勒县",
			"level": 3
		}, {
			"id": 3271,
			"pid": 3267,
			"name": "英吉沙县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,英吉沙县",
			"level": 3
		}, {
			"id": 3272,
			"pid": 3267,
			"name": "泽普县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,泽普县",
			"level": 3
		}, {
			"id": 3273,
			"pid": 3267,
			"name": "莎车县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,莎车县",
			"level": 3
		}, {
			"id": 3274,
			"pid": 3267,
			"name": "叶城县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,叶城县",
			"level": 3
		}, {
			"id": 3275,
			"pid": 3267,
			"name": "麦盖提县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,麦盖提县",
			"level": 3
		}, {
			"id": 3276,
			"pid": 3267,
			"name": "岳普湖县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,岳普湖县",
			"level": 3
		}, {
			"id": 3277,
			"pid": 3267,
			"name": "伽师县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,伽师县",
			"level": 3
		}, {
			"id": 3278,
			"pid": 3267,
			"name": "巴楚县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,巴楚县",
			"level": 3
		}, {
			"id": 3279,
			"pid": 3267,
			"name": "塔什库尔干塔吉克自治县",
			"merger_name": "中国,新疆维吾尔自治区,喀什地区,塔什库尔干塔吉克自治县",
			"level": 3
		}]
	}, {
		"id": 3280,
		"pid": 3206,
		"name": "和田地区",
		"merger_name": "中国,新疆维吾尔自治区,和田地区",
		"level": 2,
		"areas": [{
			"id": 3281,
			"pid": 3280,
			"name": "和田市",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,和田市",
			"level": 3
		}, {
			"id": 3282,
			"pid": 3280,
			"name": "和田县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,和田县",
			"level": 3
		}, {
			"id": 3283,
			"pid": 3280,
			"name": "墨玉县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,墨玉县",
			"level": 3
		}, {
			"id": 3284,
			"pid": 3280,
			"name": "皮山县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,皮山县",
			"level": 3
		}, {
			"id": 3285,
			"pid": 3280,
			"name": "洛浦县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,洛浦县",
			"level": 3
		}, {
			"id": 3286,
			"pid": 3280,
			"name": "策勒县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,策勒县",
			"level": 3
		}, {
			"id": 3287,
			"pid": 3280,
			"name": "于田县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,于田县",
			"level": 3
		}, {
			"id": 3288,
			"pid": 3280,
			"name": "民丰县",
			"merger_name": "中国,新疆维吾尔自治区,和田地区,民丰县",
			"level": 3
		}]
	}, {
		"id": 3289,
		"pid": 3206,
		"name": "伊犁哈萨克自治州",
		"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州",
		"level": 2,
		"areas": [{
			"id": 3290,
			"pid": 3289,
			"name": "伊宁市",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,伊宁市",
			"level": 3
		}, {
			"id": 3291,
			"pid": 3289,
			"name": "奎屯市",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,奎屯市",
			"level": 3
		}, {
			"id": 3292,
			"pid": 3289,
			"name": "霍尔果斯市",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,霍尔果斯市",
			"level": 3
		}, {
			"id": 3293,
			"pid": 3289,
			"name": "伊宁县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,伊宁县",
			"level": 3
		}, {
			"id": 3294,
			"pid": 3289,
			"name": "察布查尔锡伯自治县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,察布查尔锡伯自治县",
			"level": 3
		}, {
			"id": 3295,
			"pid": 3289,
			"name": "霍城县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,霍城县",
			"level": 3
		}, {
			"id": 3296,
			"pid": 3289,
			"name": "巩留县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,巩留县",
			"level": 3
		}, {
			"id": 3297,
			"pid": 3289,
			"name": "新源县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,新源县",
			"level": 3
		}, {
			"id": 3298,
			"pid": 3289,
			"name": "昭苏县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,昭苏县",
			"level": 3
		}, {
			"id": 3299,
			"pid": 3289,
			"name": "特克斯县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,特克斯县",
			"level": 3
		}, {
			"id": 3300,
			"pid": 3289,
			"name": "尼勒克县",
			"merger_name": "中国,新疆维吾尔自治区,伊犁哈萨克自治州,尼勒克县",
			"level": 3
		}]
	}, {
		"id": 3301,
		"pid": 3206,
		"name": "塔城地区",
		"merger_name": "中国,新疆维吾尔自治区,塔城地区",
		"level": 2,
		"areas": [{
			"id": 3302,
			"pid": 3301,
			"name": "塔城市",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,塔城市",
			"level": 3
		}, {
			"id": 3303,
			"pid": 3301,
			"name": "乌苏市",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,乌苏市",
			"level": 3
		}, {
			"id": 3304,
			"pid": 3301,
			"name": "额敏县",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,额敏县",
			"level": 3
		}, {
			"id": 3305,
			"pid": 3301,
			"name": "沙湾县",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,沙湾县",
			"level": 3
		}, {
			"id": 3306,
			"pid": 3301,
			"name": "托里县",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,托里县",
			"level": 3
		}, {
			"id": 3307,
			"pid": 3301,
			"name": "裕民县",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,裕民县",
			"level": 3
		}, {
			"id": 3308,
			"pid": 3301,
			"name": "和布克赛尔蒙古自治县",
			"merger_name": "中国,新疆维吾尔自治区,塔城地区,和布克赛尔蒙古自治县",
			"level": 3
		}]
	}, {
		"id": 3309,
		"pid": 3206,
		"name": "阿勒泰地区",
		"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区",
		"level": 2,
		"areas": [{
			"id": 3310,
			"pid": 3309,
			"name": "阿勒泰市",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,阿勒泰市",
			"level": 3
		}, {
			"id": 3311,
			"pid": 3309,
			"name": "布尔津县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,布尔津县",
			"level": 3
		}, {
			"id": 3312,
			"pid": 3309,
			"name": "富蕴县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,富蕴县",
			"level": 3
		}, {
			"id": 3313,
			"pid": 3309,
			"name": "福海县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,福海县",
			"level": 3
		}, {
			"id": 3314,
			"pid": 3309,
			"name": "哈巴河县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,哈巴河县",
			"level": 3
		}, {
			"id": 3315,
			"pid": 3309,
			"name": "青河县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,青河县",
			"level": 3
		}, {
			"id": 3316,
			"pid": 3309,
			"name": "吉木乃县",
			"merger_name": "中国,新疆维吾尔自治区,阿勒泰地区,吉木乃县",
			"level": 3
		}]
	}, {
		"id": 3317,
		"pid": 3206,
		"name": "直辖县级",
		"merger_name": "中国,新疆维吾尔自治区,直辖县级",
		"level": 2,
		"areas": [{
			"id": 3318,
			"pid": 3317,
			"name": "石河子市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,石河子市",
			"level": 3
		}, {
			"id": 3319,
			"pid": 3317,
			"name": "阿拉尔市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,阿拉尔市",
			"level": 3
		}, {
			"id": 3320,
			"pid": 3317,
			"name": "图木舒克市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,图木舒克市",
			"level": 3
		}, {
			"id": 3321,
			"pid": 3317,
			"name": "五家渠市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,五家渠市",
			"level": 3
		}, {
			"id": 3322,
			"pid": 3317,
			"name": "北屯市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,北屯市",
			"level": 3
		}, {
			"id": 3323,
			"pid": 3317,
			"name": "铁门关市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,铁门关市",
			"level": 3
		}, {
			"id": 3324,
			"pid": 3317,
			"name": "双河市",
			"merger_name": "中国,新疆维吾尔自治区,直辖县级,双河市",
			"level": 3
		}]
	}]
}, {
	"id": 3325,
	"pid": 0,
	"name": "台湾省",
	"merger_name": "中国,台湾省",
	"level": 1,
	"cities": [{
		"id": 3326,
		"pid": 3325,
		"name": "台北市",
		"merger_name": "中国,台湾,台北市",
		"level": 2,
		"areas": [{
			"id": 3327,
			"pid": 3326,
			"name": "松山区",
			"merger_name": "中国,台湾,台北市,松山区",
			"level": 3
		}, {
			"id": 3328,
			"pid": 3326,
			"name": "信义区",
			"merger_name": "中国,台湾,台北市,信义区",
			"level": 3
		}, {
			"id": 3329,
			"pid": 3326,
			"name": "大安区",
			"merger_name": "中国,台湾,台北市,大安区",
			"level": 3
		}, {
			"id": 3330,
			"pid": 3326,
			"name": "中山区",
			"merger_name": "中国,台湾,台北市,中山区",
			"level": 3
		}, {
			"id": 3331,
			"pid": 3326,
			"name": "中正区",
			"merger_name": "中国,台湾,台北市,中正区",
			"level": 3
		}, {
			"id": 3332,
			"pid": 3326,
			"name": "大同区",
			"merger_name": "中国,台湾,台北市,大同区",
			"level": 3
		}, {
			"id": 3333,
			"pid": 3326,
			"name": "万华区",
			"merger_name": "中国,台湾,台北市,万华区",
			"level": 3
		}, {
			"id": 3334,
			"pid": 3326,
			"name": "文山区",
			"merger_name": "中国,台湾,台北市,文山区",
			"level": 3
		}, {
			"id": 3335,
			"pid": 3326,
			"name": "南港区",
			"merger_name": "中国,台湾,台北市,南港区",
			"level": 3
		}, {
			"id": 3336,
			"pid": 3326,
			"name": "内湖区",
			"merger_name": "中国,台湾,台北市,内湖区",
			"level": 3
		}, {
			"id": 3337,
			"pid": 3326,
			"name": "士林区",
			"merger_name": "中国,台湾,台北市,士林区",
			"level": 3
		}, {
			"id": 3338,
			"pid": 3326,
			"name": "北投区",
			"merger_name": "中国,台湾,台北市,北投区",
			"level": 3
		}]
	}, {
		"id": 3339,
		"pid": 3325,
		"name": "高雄市",
		"merger_name": "中国,台湾,高雄市",
		"level": 2,
		"areas": [{
			"id": 3340,
			"pid": 3339,
			"name": "盐埕区",
			"merger_name": "中国,台湾,高雄市,盐埕区",
			"level": 3
		}, {
			"id": 3341,
			"pid": 3339,
			"name": "鼓山区",
			"merger_name": "中国,台湾,高雄市,鼓山区",
			"level": 3
		}, {
			"id": 3342,
			"pid": 3339,
			"name": "左营区",
			"merger_name": "中国,台湾,高雄市,左营区",
			"level": 3
		}, {
			"id": 3343,
			"pid": 3339,
			"name": "楠梓区",
			"merger_name": "中国,台湾,高雄市,楠梓区",
			"level": 3
		}, {
			"id": 3344,
			"pid": 3339,
			"name": "三民区",
			"merger_name": "中国,台湾,高雄市,三民区",
			"level": 3
		}, {
			"id": 3345,
			"pid": 3339,
			"name": "新兴区",
			"merger_name": "中国,台湾,高雄市,新兴区",
			"level": 3
		}, {
			"id": 3346,
			"pid": 3339,
			"name": "前金区",
			"merger_name": "中国,台湾,高雄市,前金区",
			"level": 3
		}, {
			"id": 3347,
			"pid": 3339,
			"name": "苓雅区",
			"merger_name": "中国,台湾,高雄市,苓雅区",
			"level": 3
		}, {
			"id": 3348,
			"pid": 3339,
			"name": "前镇区",
			"merger_name": "中国,台湾,高雄市,前镇区",
			"level": 3
		}, {
			"id": 3349,
			"pid": 3339,
			"name": "旗津区",
			"merger_name": "中国,台湾,高雄市,旗津区",
			"level": 3
		}, {
			"id": 3350,
			"pid": 3339,
			"name": "小港区",
			"merger_name": "中国,台湾,高雄市,小港区",
			"level": 3
		}, {
			"id": 3351,
			"pid": 3339,
			"name": "凤山区",
			"merger_name": "中国,台湾,高雄市,凤山区",
			"level": 3
		}, {
			"id": 3352,
			"pid": 3339,
			"name": "林园区",
			"merger_name": "中国,台湾,高雄市,林园区",
			"level": 3
		}, {
			"id": 3353,
			"pid": 3339,
			"name": "大寮区",
			"merger_name": "中国,台湾,高雄市,大寮区",
			"level": 3
		}, {
			"id": 3354,
			"pid": 3339,
			"name": "大树区",
			"merger_name": "中国,台湾,高雄市,大树区",
			"level": 3
		}, {
			"id": 3355,
			"pid": 3339,
			"name": "大社区",
			"merger_name": "中国,台湾,高雄市,大社区",
			"level": 3
		}, {
			"id": 3356,
			"pid": 3339,
			"name": "仁武区",
			"merger_name": "中国,台湾,高雄市,仁武区",
			"level": 3
		}, {
			"id": 3357,
			"pid": 3339,
			"name": "鸟松区",
			"merger_name": "中国,台湾,高雄市,鸟松区",
			"level": 3
		}, {
			"id": 3358,
			"pid": 3339,
			"name": "冈山区",
			"merger_name": "中国,台湾,高雄市,冈山区",
			"level": 3
		}, {
			"id": 3359,
			"pid": 3339,
			"name": "桥头区",
			"merger_name": "中国,台湾,高雄市,桥头区",
			"level": 3
		}, {
			"id": 3360,
			"pid": 3339,
			"name": "燕巢区",
			"merger_name": "中国,台湾,高雄市,燕巢区",
			"level": 3
		}, {
			"id": 3361,
			"pid": 3339,
			"name": "田寮区",
			"merger_name": "中国,台湾,高雄市,田寮区",
			"level": 3
		}, {
			"id": 3362,
			"pid": 3339,
			"name": "阿莲区",
			"merger_name": "中国,台湾,高雄市,阿莲区",
			"level": 3
		}, {
			"id": 3363,
			"pid": 3339,
			"name": "路竹区",
			"merger_name": "中国,台湾,高雄市,路竹区",
			"level": 3
		}, {
			"id": 3364,
			"pid": 3339,
			"name": "湖内区",
			"merger_name": "中国,台湾,高雄市,湖内区",
			"level": 3
		}, {
			"id": 3365,
			"pid": 3339,
			"name": "茄萣区",
			"merger_name": "中国,台湾,高雄市,茄萣区",
			"level": 3
		}, {
			"id": 3366,
			"pid": 3339,
			"name": "永安区",
			"merger_name": "中国,台湾,高雄市,永安区",
			"level": 3
		}, {
			"id": 3367,
			"pid": 3339,
			"name": "弥陀区",
			"merger_name": "中国,台湾,高雄市,弥陀区",
			"level": 3
		}, {
			"id": 3368,
			"pid": 3339,
			"name": "梓官区",
			"merger_name": "中国,台湾,高雄市,梓官区",
			"level": 3
		}, {
			"id": 3369,
			"pid": 3339,
			"name": "旗山区",
			"merger_name": "中国,台湾,高雄市,旗山区",
			"level": 3
		}, {
			"id": 3370,
			"pid": 3339,
			"name": "美浓区",
			"merger_name": "中国,台湾,高雄市,美浓区",
			"level": 3
		}, {
			"id": 3371,
			"pid": 3339,
			"name": "六龟区",
			"merger_name": "中国,台湾,高雄市,六龟区",
			"level": 3
		}, {
			"id": 3372,
			"pid": 3339,
			"name": "甲仙区",
			"merger_name": "中国,台湾,高雄市,甲仙区",
			"level": 3
		}, {
			"id": 3373,
			"pid": 3339,
			"name": "杉林区",
			"merger_name": "中国,台湾,高雄市,杉林区",
			"level": 3
		}, {
			"id": 3374,
			"pid": 3339,
			"name": "内门区",
			"merger_name": "中国,台湾,高雄市,内门区",
			"level": 3
		}, {
			"id": 3375,
			"pid": 3339,
			"name": "茂林区",
			"merger_name": "中国,台湾,高雄市,茂林区",
			"level": 3
		}, {
			"id": 3376,
			"pid": 3339,
			"name": "桃源区",
			"merger_name": "中国,台湾,高雄市,桃源区",
			"level": 3
		}, {
			"id": 3377,
			"pid": 3339,
			"name": "那玛夏区",
			"merger_name": "中国,台湾,高雄市,那玛夏区",
			"level": 3
		}]
	}, {
		"id": 3378,
		"pid": 3325,
		"name": "基隆市",
		"merger_name": "中国,台湾,基隆市",
		"level": 2,
		"areas": [{
			"id": 3379,
			"pid": 3378,
			"name": "中正区",
			"merger_name": "中国,台湾,基隆市,中正区",
			"level": 3
		}, {
			"id": 3380,
			"pid": 3378,
			"name": "七堵区",
			"merger_name": "中国,台湾,基隆市,七堵区",
			"level": 3
		}, {
			"id": 3381,
			"pid": 3378,
			"name": "暖暖区",
			"merger_name": "中国,台湾,基隆市,暖暖区",
			"level": 3
		}, {
			"id": 3382,
			"pid": 3378,
			"name": "仁爱区",
			"merger_name": "中国,台湾,基隆市,仁爱区",
			"level": 3
		}, {
			"id": 3383,
			"pid": 3378,
			"name": "中山区",
			"merger_name": "中国,台湾,基隆市,中山区",
			"level": 3
		}, {
			"id": 3384,
			"pid": 3378,
			"name": "安乐区",
			"merger_name": "中国,台湾,基隆市,安乐区",
			"level": 3
		}, {
			"id": 3385,
			"pid": 3378,
			"name": "信义区",
			"merger_name": "中国,台湾,基隆市,信义区",
			"level": 3
		}]
	}, {
		"id": 3386,
		"pid": 3325,
		"name": "台中市",
		"merger_name": "中国,台湾,台中市",
		"level": 2,
		"areas": [{
			"id": 3387,
			"pid": 3386,
			"name": "中区",
			"merger_name": "中国,台湾,台中市,中区",
			"level": 3
		}, {
			"id": 3388,
			"pid": 3386,
			"name": "东区",
			"merger_name": "中国,台湾,台中市,东区",
			"level": 3
		}, {
			"id": 3389,
			"pid": 3386,
			"name": "南区",
			"merger_name": "中国,台湾,台中市,南区",
			"level": 3
		}, {
			"id": 3390,
			"pid": 3386,
			"name": "西区",
			"merger_name": "中国,台湾,台中市,西区",
			"level": 3
		}, {
			"id": 3391,
			"pid": 3386,
			"name": "北区",
			"merger_name": "中国,台湾,台中市,北区",
			"level": 3
		}, {
			"id": 3392,
			"pid": 3386,
			"name": "西屯区",
			"merger_name": "中国,台湾,台中市,西屯区",
			"level": 3
		}, {
			"id": 3393,
			"pid": 3386,
			"name": "南屯区",
			"merger_name": "中国,台湾,台中市,南屯区",
			"level": 3
		}, {
			"id": 3394,
			"pid": 3386,
			"name": "北屯区",
			"merger_name": "中国,台湾,台中市,北屯区",
			"level": 3
		}, {
			"id": 3395,
			"pid": 3386,
			"name": "丰原区",
			"merger_name": "中国,台湾,台中市,丰原区",
			"level": 3
		}, {
			"id": 3396,
			"pid": 3386,
			"name": "东势区",
			"merger_name": "中国,台湾,台中市,东势区",
			"level": 3
		}, {
			"id": 3397,
			"pid": 3386,
			"name": "大甲区",
			"merger_name": "中国,台湾,台中市,大甲区",
			"level": 3
		}, {
			"id": 3398,
			"pid": 3386,
			"name": "清水区",
			"merger_name": "中国,台湾,台中市,清水区",
			"level": 3
		}, {
			"id": 3399,
			"pid": 3386,
			"name": "沙鹿区",
			"merger_name": "中国,台湾,台中市,沙鹿区",
			"level": 3
		}, {
			"id": 3400,
			"pid": 3386,
			"name": "梧栖区",
			"merger_name": "中国,台湾,台中市,梧栖区",
			"level": 3
		}, {
			"id": 3401,
			"pid": 3386,
			"name": "后里区",
			"merger_name": "中国,台湾,台中市,后里区",
			"level": 3
		}, {
			"id": 3402,
			"pid": 3386,
			"name": "神冈区",
			"merger_name": "中国,台湾,台中市,神冈区",
			"level": 3
		}, {
			"id": 3403,
			"pid": 3386,
			"name": "潭子区",
			"merger_name": "中国,台湾,台中市,潭子区",
			"level": 3
		}, {
			"id": 3404,
			"pid": 3386,
			"name": "大雅区",
			"merger_name": "中国,台湾,台中市,大雅区",
			"level": 3
		}, {
			"id": 3405,
			"pid": 3386,
			"name": "新社区",
			"merger_name": "中国,台湾,台中市,新社区",
			"level": 3
		}, {
			"id": 3406,
			"pid": 3386,
			"name": "石冈区",
			"merger_name": "中国,台湾,台中市,石冈区",
			"level": 3
		}, {
			"id": 3407,
			"pid": 3386,
			"name": "外埔区",
			"merger_name": "中国,台湾,台中市,外埔区",
			"level": 3
		}, {
			"id": 3408,
			"pid": 3386,
			"name": "大安区",
			"merger_name": "中国,台湾,台中市,大安区",
			"level": 3
		}, {
			"id": 3409,
			"pid": 3386,
			"name": "乌日区",
			"merger_name": "中国,台湾,台中市,乌日区",
			"level": 3
		}, {
			"id": 3410,
			"pid": 3386,
			"name": "大肚区",
			"merger_name": "中国,台湾,台中市,大肚区",
			"level": 3
		}, {
			"id": 3411,
			"pid": 3386,
			"name": "龙井区",
			"merger_name": "中国,台湾,台中市,龙井区",
			"level": 3
		}, {
			"id": 3412,
			"pid": 3386,
			"name": "雾峰区",
			"merger_name": "中国,台湾,台中市,雾峰区",
			"level": 3
		}, {
			"id": 3413,
			"pid": 3386,
			"name": "太平区",
			"merger_name": "中国,台湾,台中市,太平区",
			"level": 3
		}, {
			"id": 3414,
			"pid": 3386,
			"name": "大里区",
			"merger_name": "中国,台湾,台中市,大里区",
			"level": 3
		}, {
			"id": 3415,
			"pid": 3386,
			"name": "和平区",
			"merger_name": "中国,台湾,台中市,和平区",
			"level": 3
		}]
	}, {
		"id": 3416,
		"pid": 3325,
		"name": "台南市",
		"merger_name": "中国,台湾,台南市",
		"level": 2,
		"areas": [{
			"id": 3417,
			"pid": 3416,
			"name": "东区",
			"merger_name": "中国,台湾,台南市,东区",
			"level": 3
		}, {
			"id": 3418,
			"pid": 3416,
			"name": "南区",
			"merger_name": "中国,台湾,台南市,南区",
			"level": 3
		}, {
			"id": 3419,
			"pid": 3416,
			"name": "北区",
			"merger_name": "中国,台湾,台南市,北区",
			"level": 3
		}, {
			"id": 3420,
			"pid": 3416,
			"name": "安南区",
			"merger_name": "中国,台湾,台南市,安南区",
			"level": 3
		}, {
			"id": 3421,
			"pid": 3416,
			"name": "安平区",
			"merger_name": "中国,台湾,台南市,安平区",
			"level": 3
		}, {
			"id": 3422,
			"pid": 3416,
			"name": "中西区",
			"merger_name": "中国,台湾,台南市,中西区",
			"level": 3
		}, {
			"id": 3423,
			"pid": 3416,
			"name": "新营区",
			"merger_name": "中国,台湾,台南市,新营区",
			"level": 3
		}, {
			"id": 3424,
			"pid": 3416,
			"name": "盐水区",
			"merger_name": "中国,台湾,台南市,盐水区",
			"level": 3
		}, {
			"id": 3425,
			"pid": 3416,
			"name": "白河区",
			"merger_name": "中国,台湾,台南市,白河区",
			"level": 3
		}, {
			"id": 3426,
			"pid": 3416,
			"name": "柳营区",
			"merger_name": "中国,台湾,台南市,柳营区",
			"level": 3
		}, {
			"id": 3427,
			"pid": 3416,
			"name": "后壁区",
			"merger_name": "中国,台湾,台南市,后壁区",
			"level": 3
		}, {
			"id": 3428,
			"pid": 3416,
			"name": "东山区",
			"merger_name": "中国,台湾,台南市,东山区",
			"level": 3
		}, {
			"id": 3429,
			"pid": 3416,
			"name": "麻豆区",
			"merger_name": "中国,台湾,台南市,麻豆区",
			"level": 3
		}, {
			"id": 3430,
			"pid": 3416,
			"name": "下营区",
			"merger_name": "中国,台湾,台南市,下营区",
			"level": 3
		}, {
			"id": 3431,
			"pid": 3416,
			"name": "六甲区",
			"merger_name": "中国,台湾,台南市,六甲区",
			"level": 3
		}, {
			"id": 3432,
			"pid": 3416,
			"name": "官田区",
			"merger_name": "中国,台湾,台南市,官田区",
			"level": 3
		}, {
			"id": 3433,
			"pid": 3416,
			"name": "大内区",
			"merger_name": "中国,台湾,台南市,大内区",
			"level": 3
		}, {
			"id": 3434,
			"pid": 3416,
			"name": "佳里区",
			"merger_name": "中国,台湾,台南市,佳里区",
			"level": 3
		}, {
			"id": 3435,
			"pid": 3416,
			"name": "学甲区",
			"merger_name": "中国,台湾,台南市,学甲区",
			"level": 3
		}, {
			"id": 3436,
			"pid": 3416,
			"name": "西港区",
			"merger_name": "中国,台湾,台南市,西港区",
			"level": 3
		}, {
			"id": 3437,
			"pid": 3416,
			"name": "七股区",
			"merger_name": "中国,台湾,台南市,七股区",
			"level": 3
		}, {
			"id": 3438,
			"pid": 3416,
			"name": "将军区",
			"merger_name": "中国,台湾,台南市,将军区",
			"level": 3
		}, {
			"id": 3439,
			"pid": 3416,
			"name": "北门区",
			"merger_name": "中国,台湾,台南市,北门区",
			"level": 3
		}, {
			"id": 3440,
			"pid": 3416,
			"name": "新化区",
			"merger_name": "中国,台湾,台南市,新化区",
			"level": 3
		}, {
			"id": 3441,
			"pid": 3416,
			"name": "善化区",
			"merger_name": "中国,台湾,台南市,善化区",
			"level": 3
		}, {
			"id": 3442,
			"pid": 3416,
			"name": "新市区",
			"merger_name": "中国,台湾,台南市,新市区",
			"level": 3
		}, {
			"id": 3443,
			"pid": 3416,
			"name": "安定区",
			"merger_name": "中国,台湾,台南市,安定区",
			"level": 3
		}, {
			"id": 3444,
			"pid": 3416,
			"name": "山上区",
			"merger_name": "中国,台湾,台南市,山上区",
			"level": 3
		}, {
			"id": 3445,
			"pid": 3416,
			"name": "玉井区",
			"merger_name": "中国,台湾,台南市,玉井区",
			"level": 3
		}, {
			"id": 3446,
			"pid": 3416,
			"name": "楠西区",
			"merger_name": "中国,台湾,台南市,楠西区",
			"level": 3
		}, {
			"id": 3447,
			"pid": 3416,
			"name": "南化区",
			"merger_name": "中国,台湾,台南市,南化区",
			"level": 3
		}, {
			"id": 3448,
			"pid": 3416,
			"name": "左镇区",
			"merger_name": "中国,台湾,台南市,左镇区",
			"level": 3
		}, {
			"id": 3449,
			"pid": 3416,
			"name": "仁德区",
			"merger_name": "中国,台湾,台南市,仁德区",
			"level": 3
		}, {
			"id": 3450,
			"pid": 3416,
			"name": "归仁区",
			"merger_name": "中国,台湾,台南市,归仁区",
			"level": 3
		}, {
			"id": 3451,
			"pid": 3416,
			"name": "关庙区",
			"merger_name": "中国,台湾,台南市,关庙区",
			"level": 3
		}, {
			"id": 3452,
			"pid": 3416,
			"name": "龙崎区",
			"merger_name": "中国,台湾,台南市,龙崎区",
			"level": 3
		}, {
			"id": 3453,
			"pid": 3416,
			"name": "永康区",
			"merger_name": "中国,台湾,台南市,永康区",
			"level": 3
		}]
	}, {
		"id": 3454,
		"pid": 3325,
		"name": "新竹市",
		"merger_name": "中国,台湾,新竹市",
		"level": 2,
		"areas": [{
			"id": 3455,
			"pid": 3454,
			"name": "东区",
			"merger_name": "中国,台湾,新竹市,东区",
			"level": 3
		}, {
			"id": 3456,
			"pid": 3454,
			"name": "北区",
			"merger_name": "中国,台湾,新竹市,北区",
			"level": 3
		}, {
			"id": 3457,
			"pid": 3454,
			"name": "香山区",
			"merger_name": "中国,台湾,新竹市,香山区",
			"level": 3
		}]
	}, {
		"id": 3458,
		"pid": 3325,
		"name": "嘉义市",
		"merger_name": "中国,台湾,嘉义市",
		"level": 2,
		"areas": [{
			"id": 3459,
			"pid": 3458,
			"name": "东区",
			"merger_name": "中国,台湾,嘉义市,东区",
			"level": 3
		}, {
			"id": 3460,
			"pid": 3458,
			"name": "西区",
			"merger_name": "中国,台湾,嘉义市,西区",
			"level": 3
		}]
	}, {
		"id": 3461,
		"pid": 3325,
		"name": "新北市",
		"merger_name": "中国,台湾,新北市",
		"level": 2,
		"areas": [{
			"id": 3462,
			"pid": 3461,
			"name": "板桥区",
			"merger_name": "中国,台湾,新北市,板桥区",
			"level": 3
		}, {
			"id": 3463,
			"pid": 3461,
			"name": "三重区",
			"merger_name": "中国,台湾,新北市,三重区",
			"level": 3
		}, {
			"id": 3464,
			"pid": 3461,
			"name": "中和区",
			"merger_name": "中国,台湾,新北市,中和区",
			"level": 3
		}, {
			"id": 3465,
			"pid": 3461,
			"name": "永和区",
			"merger_name": "中国,台湾,新北市,永和区",
			"level": 3
		}, {
			"id": 3466,
			"pid": 3461,
			"name": "新庄区",
			"merger_name": "中国,台湾,新北市,新庄区",
			"level": 3
		}, {
			"id": 3467,
			"pid": 3461,
			"name": "新店区",
			"merger_name": "中国,台湾,新北市,新店区",
			"level": 3
		}, {
			"id": 3468,
			"pid": 3461,
			"name": "树林区",
			"merger_name": "中国,台湾,新北市,树林区",
			"level": 3
		}, {
			"id": 3469,
			"pid": 3461,
			"name": "莺歌区",
			"merger_name": "中国,台湾,新北市,莺歌区",
			"level": 3
		}, {
			"id": 3470,
			"pid": 3461,
			"name": "三峡区",
			"merger_name": "中国,台湾,新北市,三峡区",
			"level": 3
		}, {
			"id": 3471,
			"pid": 3461,
			"name": "淡水区",
			"merger_name": "中国,台湾,新北市,淡水区",
			"level": 3
		}, {
			"id": 3472,
			"pid": 3461,
			"name": "汐止区",
			"merger_name": "中国,台湾,新北市,汐止区",
			"level": 3
		}, {
			"id": 3473,
			"pid": 3461,
			"name": "瑞芳区",
			"merger_name": "中国,台湾,新北市,瑞芳区",
			"level": 3
		}, {
			"id": 3474,
			"pid": 3461,
			"name": "土城区",
			"merger_name": "中国,台湾,新北市,土城区",
			"level": 3
		}, {
			"id": 3475,
			"pid": 3461,
			"name": "芦洲区",
			"merger_name": "中国,台湾,新北市,芦洲区",
			"level": 3
		}, {
			"id": 3476,
			"pid": 3461,
			"name": "五股区",
			"merger_name": "中国,台湾,新北市,五股区",
			"level": 3
		}, {
			"id": 3477,
			"pid": 3461,
			"name": "泰山区",
			"merger_name": "中国,台湾,新北市,泰山区",
			"level": 3
		}, {
			"id": 3478,
			"pid": 3461,
			"name": "林口区",
			"merger_name": "中国,台湾,新北市,林口区",
			"level": 3
		}, {
			"id": 3479,
			"pid": 3461,
			"name": "深坑区",
			"merger_name": "中国,台湾,新北市,深坑区",
			"level": 3
		}, {
			"id": 3480,
			"pid": 3461,
			"name": "石碇区",
			"merger_name": "中国,台湾,新北市,石碇区",
			"level": 3
		}, {
			"id": 3481,
			"pid": 3461,
			"name": "坪林区",
			"merger_name": "中国,台湾,新北市,坪林区",
			"level": 3
		}, {
			"id": 3482,
			"pid": 3461,
			"name": "三芝区",
			"merger_name": "中国,台湾,新北市,三芝区",
			"level": 3
		}, {
			"id": 3483,
			"pid": 3461,
			"name": "石门区",
			"merger_name": "中国,台湾,新北市,石门区",
			"level": 3
		}, {
			"id": 3484,
			"pid": 3461,
			"name": "八里区",
			"merger_name": "中国,台湾,新北市,八里区",
			"level": 3
		}, {
			"id": 3485,
			"pid": 3461,
			"name": "平溪区",
			"merger_name": "中国,台湾,新北市,平溪区",
			"level": 3
		}, {
			"id": 3486,
			"pid": 3461,
			"name": "双溪区",
			"merger_name": "中国,台湾,新北市,双溪区",
			"level": 3
		}, {
			"id": 3487,
			"pid": 3461,
			"name": "贡寮区",
			"merger_name": "中国,台湾,新北市,贡寮区",
			"level": 3
		}, {
			"id": 3488,
			"pid": 3461,
			"name": "金山区",
			"merger_name": "中国,台湾,新北市,金山区",
			"level": 3
		}, {
			"id": 3489,
			"pid": 3461,
			"name": "万里区",
			"merger_name": "中国,台湾,新北市,万里区",
			"level": 3
		}, {
			"id": 3490,
			"pid": 3461,
			"name": "乌来区",
			"merger_name": "中国,台湾,新北市,乌来区",
			"level": 3
		}]
	}, {
		"id": 3491,
		"pid": 3325,
		"name": "宜兰县",
		"merger_name": "中国,台湾,宜兰县",
		"level": 2,
		"areas": [{
			"id": 3492,
			"pid": 3491,
			"name": "宜兰市",
			"merger_name": "中国,台湾,宜兰县,宜兰市",
			"level": 3
		}, {
			"id": 3493,
			"pid": 3491,
			"name": "罗东镇",
			"merger_name": "中国,台湾,宜兰县,罗东镇",
			"level": 3
		}, {
			"id": 3494,
			"pid": 3491,
			"name": "苏澳镇",
			"merger_name": "中国,台湾,宜兰县,苏澳镇",
			"level": 3
		}, {
			"id": 3495,
			"pid": 3491,
			"name": "头城镇",
			"merger_name": "中国,台湾,宜兰县,头城镇",
			"level": 3
		}, {
			"id": 3496,
			"pid": 3491,
			"name": "礁溪乡",
			"merger_name": "中国,台湾,宜兰县,礁溪乡",
			"level": 3
		}, {
			"id": 3497,
			"pid": 3491,
			"name": "壮围乡",
			"merger_name": "中国,台湾,宜兰县,壮围乡",
			"level": 3
		}, {
			"id": 3498,
			"pid": 3491,
			"name": "员山乡",
			"merger_name": "中国,台湾,宜兰县,员山乡",
			"level": 3
		}, {
			"id": 3499,
			"pid": 3491,
			"name": "冬山乡",
			"merger_name": "中国,台湾,宜兰县,冬山乡",
			"level": 3
		}, {
			"id": 3500,
			"pid": 3491,
			"name": "五结乡",
			"merger_name": "中国,台湾,宜兰县,五结乡",
			"level": 3
		}, {
			"id": 3501,
			"pid": 3491,
			"name": "三星乡",
			"merger_name": "中国,台湾,宜兰县,三星乡",
			"level": 3
		}, {
			"id": 3502,
			"pid": 3491,
			"name": "大同乡",
			"merger_name": "中国,台湾,宜兰县,大同乡",
			"level": 3
		}, {
			"id": 3503,
			"pid": 3491,
			"name": "南澳乡",
			"merger_name": "中国,台湾,宜兰县,南澳乡",
			"level": 3
		}]
	}, {
		"id": 3504,
		"pid": 3325,
		"name": "桃园县",
		"merger_name": "中国,台湾,桃园县",
		"level": 2,
		"areas": [{
			"id": 3505,
			"pid": 3504,
			"name": "桃园市",
			"merger_name": "中国,台湾,桃园县,桃园市",
			"level": 3
		}, {
			"id": 3506,
			"pid": 3504,
			"name": "中坜市",
			"merger_name": "中国,台湾,桃园县,中坜市",
			"level": 3
		}, {
			"id": 3507,
			"pid": 3504,
			"name": "平镇市",
			"merger_name": "中国,台湾,桃园县,平镇市",
			"level": 3
		}, {
			"id": 3508,
			"pid": 3504,
			"name": "八德市",
			"merger_name": "中国,台湾,桃园县,八德市",
			"level": 3
		}, {
			"id": 3509,
			"pid": 3504,
			"name": "杨梅市",
			"merger_name": "中国,台湾,桃园县,杨梅市",
			"level": 3
		}, {
			"id": 3510,
			"pid": 3504,
			"name": "芦竹市",
			"merger_name": "中国,台湾,桃园县,芦竹市",
			"level": 3
		}, {
			"id": 3511,
			"pid": 3504,
			"name": "大溪镇",
			"merger_name": "中国,台湾,桃园县,大溪镇",
			"level": 3
		}, {
			"id": 3512,
			"pid": 3504,
			"name": "大园乡",
			"merger_name": "中国,台湾,桃园县,大园乡",
			"level": 3
		}, {
			"id": 3513,
			"pid": 3504,
			"name": "龟山乡",
			"merger_name": "中国,台湾,桃园县,龟山乡",
			"level": 3
		}, {
			"id": 3514,
			"pid": 3504,
			"name": "龙潭乡",
			"merger_name": "中国,台湾,桃园县,龙潭乡",
			"level": 3
		}, {
			"id": 3515,
			"pid": 3504,
			"name": "新屋乡",
			"merger_name": "中国,台湾,桃园县,新屋乡",
			"level": 3
		}, {
			"id": 3516,
			"pid": 3504,
			"name": "观音乡",
			"merger_name": "中国,台湾,桃园县,观音乡",
			"level": 3
		}, {
			"id": 3517,
			"pid": 3504,
			"name": "复兴乡",
			"merger_name": "中国,台湾,桃园县,复兴乡",
			"level": 3
		}]
	}, {
		"id": 3518,
		"pid": 3325,
		"name": "新竹县",
		"merger_name": "中国,台湾,新竹县",
		"level": 2,
		"areas": [{
			"id": 3519,
			"pid": 3518,
			"name": "竹北市",
			"merger_name": "中国,台湾,新竹县,竹北市",
			"level": 3
		}, {
			"id": 3520,
			"pid": 3518,
			"name": "竹东镇",
			"merger_name": "中国,台湾,新竹县,竹东镇",
			"level": 3
		}, {
			"id": 3521,
			"pid": 3518,
			"name": "新埔镇",
			"merger_name": "中国,台湾,新竹县,新埔镇",
			"level": 3
		}, {
			"id": 3522,
			"pid": 3518,
			"name": "关西镇",
			"merger_name": "中国,台湾,新竹县,关西镇",
			"level": 3
		}, {
			"id": 3523,
			"pid": 3518,
			"name": "湖口乡",
			"merger_name": "中国,台湾,新竹县,湖口乡",
			"level": 3
		}, {
			"id": 3524,
			"pid": 3518,
			"name": "新丰乡",
			"merger_name": "中国,台湾,新竹县,新丰乡",
			"level": 3
		}, {
			"id": 3525,
			"pid": 3518,
			"name": "芎林乡",
			"merger_name": "中国,台湾,新竹县,芎林乡",
			"level": 3
		}, {
			"id": 3526,
			"pid": 3518,
			"name": "横山乡",
			"merger_name": "中国,台湾,新竹县,横山乡",
			"level": 3
		}, {
			"id": 3527,
			"pid": 3518,
			"name": "北埔乡",
			"merger_name": "中国,台湾,新竹县,北埔乡",
			"level": 3
		}, {
			"id": 3528,
			"pid": 3518,
			"name": "宝山乡",
			"merger_name": "中国,台湾,新竹县,宝山乡",
			"level": 3
		}, {
			"id": 3529,
			"pid": 3518,
			"name": "峨眉乡",
			"merger_name": "中国,台湾,新竹县,峨眉乡",
			"level": 3
		}, {
			"id": 3530,
			"pid": 3518,
			"name": "尖石乡",
			"merger_name": "中国,台湾,新竹县,尖石乡",
			"level": 3
		}, {
			"id": 3531,
			"pid": 3518,
			"name": "五峰乡",
			"merger_name": "中国,台湾,新竹县,五峰乡",
			"level": 3
		}]
	}, {
		"id": 3532,
		"pid": 3325,
		"name": "苗栗县",
		"merger_name": "中国,台湾,苗栗县",
		"level": 2,
		"areas": [{
			"id": 3533,
			"pid": 3532,
			"name": "苗栗市",
			"merger_name": "中国,台湾,苗栗县,苗栗市",
			"level": 3
		}, {
			"id": 3534,
			"pid": 3532,
			"name": "苑里镇",
			"merger_name": "中国,台湾,苗栗县,苑里镇",
			"level": 3
		}, {
			"id": 3535,
			"pid": 3532,
			"name": "通霄镇",
			"merger_name": "中国,台湾,苗栗县,通霄镇",
			"level": 3
		}, {
			"id": 3536,
			"pid": 3532,
			"name": "竹南镇",
			"merger_name": "中国,台湾,苗栗县,竹南镇",
			"level": 3
		}, {
			"id": 3537,
			"pid": 3532,
			"name": "头份镇",
			"merger_name": "中国,台湾,苗栗县,头份镇",
			"level": 3
		}, {
			"id": 3538,
			"pid": 3532,
			"name": "后龙镇",
			"merger_name": "中国,台湾,苗栗县,后龙镇",
			"level": 3
		}, {
			"id": 3539,
			"pid": 3532,
			"name": "卓兰镇",
			"merger_name": "中国,台湾,苗栗县,卓兰镇",
			"level": 3
		}, {
			"id": 3540,
			"pid": 3532,
			"name": "大湖乡",
			"merger_name": "中国,台湾,苗栗县,大湖乡",
			"level": 3
		}, {
			"id": 3541,
			"pid": 3532,
			"name": "公馆乡",
			"merger_name": "中国,台湾,苗栗县,公馆乡",
			"level": 3
		}, {
			"id": 3542,
			"pid": 3532,
			"name": "铜锣乡",
			"merger_name": "中国,台湾,苗栗县,铜锣乡",
			"level": 3
		}, {
			"id": 3543,
			"pid": 3532,
			"name": "南庄乡",
			"merger_name": "中国,台湾,苗栗县,南庄乡",
			"level": 3
		}, {
			"id": 3544,
			"pid": 3532,
			"name": "头屋乡",
			"merger_name": "中国,台湾,苗栗县,头屋乡",
			"level": 3
		}, {
			"id": 3545,
			"pid": 3532,
			"name": "三义乡",
			"merger_name": "中国,台湾,苗栗县,三义乡",
			"level": 3
		}, {
			"id": 3546,
			"pid": 3532,
			"name": "西湖乡",
			"merger_name": "中国,台湾,苗栗县,西湖乡",
			"level": 3
		}, {
			"id": 3547,
			"pid": 3532,
			"name": "造桥乡",
			"merger_name": "中国,台湾,苗栗县,造桥乡",
			"level": 3
		}, {
			"id": 3548,
			"pid": 3532,
			"name": "三湾乡",
			"merger_name": "中国,台湾,苗栗县,三湾乡",
			"level": 3
		}, {
			"id": 3549,
			"pid": 3532,
			"name": "狮潭乡",
			"merger_name": "中国,台湾,苗栗县,狮潭乡",
			"level": 3
		}, {
			"id": 3550,
			"pid": 3532,
			"name": "泰安乡",
			"merger_name": "中国,台湾,苗栗县,泰安乡",
			"level": 3
		}]
	}, {
		"id": 3551,
		"pid": 3325,
		"name": "彰化县",
		"merger_name": "中国,台湾,彰化县",
		"level": 2,
		"areas": [{
			"id": 3552,
			"pid": 3551,
			"name": "彰化市",
			"merger_name": "中国,台湾,彰化县,彰化市",
			"level": 3
		}, {
			"id": 3553,
			"pid": 3551,
			"name": "鹿港镇",
			"merger_name": "中国,台湾,彰化县,鹿港镇",
			"level": 3
		}, {
			"id": 3554,
			"pid": 3551,
			"name": "和美镇",
			"merger_name": "中国,台湾,彰化县,和美镇",
			"level": 3
		}, {
			"id": 3555,
			"pid": 3551,
			"name": "线西乡",
			"merger_name": "中国,台湾,彰化县,线西乡",
			"level": 3
		}, {
			"id": 3556,
			"pid": 3551,
			"name": "伸港乡",
			"merger_name": "中国,台湾,彰化县,伸港乡",
			"level": 3
		}, {
			"id": 3557,
			"pid": 3551,
			"name": "福兴乡",
			"merger_name": "中国,台湾,彰化县,福兴乡",
			"level": 3
		}, {
			"id": 3558,
			"pid": 3551,
			"name": "秀水乡",
			"merger_name": "中国,台湾,彰化县,秀水乡",
			"level": 3
		}, {
			"id": 3559,
			"pid": 3551,
			"name": "花坛乡",
			"merger_name": "中国,台湾,彰化县,花坛乡",
			"level": 3
		}, {
			"id": 3560,
			"pid": 3551,
			"name": "芬园乡",
			"merger_name": "中国,台湾,彰化县,芬园乡",
			"level": 3
		}, {
			"id": 3561,
			"pid": 3551,
			"name": "员林镇",
			"merger_name": "中国,台湾,彰化县,员林镇",
			"level": 3
		}, {
			"id": 3562,
			"pid": 3551,
			"name": "溪湖镇",
			"merger_name": "中国,台湾,彰化县,溪湖镇",
			"level": 3
		}, {
			"id": 3563,
			"pid": 3551,
			"name": "田中镇",
			"merger_name": "中国,台湾,彰化县,田中镇",
			"level": 3
		}, {
			"id": 3564,
			"pid": 3551,
			"name": "大村乡",
			"merger_name": "中国,台湾,彰化县,大村乡",
			"level": 3
		}, {
			"id": 3565,
			"pid": 3551,
			"name": "埔盐乡",
			"merger_name": "中国,台湾,彰化县,埔盐乡",
			"level": 3
		}, {
			"id": 3566,
			"pid": 3551,
			"name": "埔心乡",
			"merger_name": "中国,台湾,彰化县,埔心乡",
			"level": 3
		}, {
			"id": 3567,
			"pid": 3551,
			"name": "永靖乡",
			"merger_name": "中国,台湾,彰化县,永靖乡",
			"level": 3
		}, {
			"id": 3568,
			"pid": 3551,
			"name": "社头乡",
			"merger_name": "中国,台湾,彰化县,社头乡",
			"level": 3
		}, {
			"id": 3569,
			"pid": 3551,
			"name": "二水乡",
			"merger_name": "中国,台湾,彰化县,二水乡",
			"level": 3
		}, {
			"id": 3570,
			"pid": 3551,
			"name": "北斗镇",
			"merger_name": "中国,台湾,彰化县,北斗镇",
			"level": 3
		}, {
			"id": 3571,
			"pid": 3551,
			"name": "二林镇",
			"merger_name": "中国,台湾,彰化县,二林镇",
			"level": 3
		}, {
			"id": 3572,
			"pid": 3551,
			"name": "田尾乡",
			"merger_name": "中国,台湾,彰化县,田尾乡",
			"level": 3
		}, {
			"id": 3573,
			"pid": 3551,
			"name": "埤头乡",
			"merger_name": "中国,台湾,彰化县,埤头乡",
			"level": 3
		}, {
			"id": 3574,
			"pid": 3551,
			"name": "芳苑乡",
			"merger_name": "中国,台湾,彰化县,芳苑乡",
			"level": 3
		}, {
			"id": 3575,
			"pid": 3551,
			"name": "大城乡",
			"merger_name": "中国,台湾,彰化县,大城乡",
			"level": 3
		}, {
			"id": 3576,
			"pid": 3551,
			"name": "竹塘乡",
			"merger_name": "中国,台湾,彰化县,竹塘乡",
			"level": 3
		}, {
			"id": 3577,
			"pid": 3551,
			"name": "溪州乡",
			"merger_name": "中国,台湾,彰化县,溪州乡",
			"level": 3
		}]
	}, {
		"id": 3578,
		"pid": 3325,
		"name": "南投县",
		"merger_name": "中国,台湾,南投县",
		"level": 2,
		"areas": [{
			"id": 3579,
			"pid": 3578,
			"name": "南投市",
			"merger_name": "中国,台湾,南投县,南投市",
			"level": 3
		}, {
			"id": 3580,
			"pid": 3578,
			"name": "埔里镇",
			"merger_name": "中国,台湾,南投县,埔里镇",
			"level": 3
		}, {
			"id": 3581,
			"pid": 3578,
			"name": "草屯镇",
			"merger_name": "中国,台湾,南投县,草屯镇",
			"level": 3
		}, {
			"id": 3582,
			"pid": 3578,
			"name": "竹山镇",
			"merger_name": "中国,台湾,南投县,竹山镇",
			"level": 3
		}, {
			"id": 3583,
			"pid": 3578,
			"name": "集集镇",
			"merger_name": "中国,台湾,南投县,集集镇",
			"level": 3
		}, {
			"id": 3584,
			"pid": 3578,
			"name": "名间乡",
			"merger_name": "中国,台湾,南投县,名间乡",
			"level": 3
		}, {
			"id": 3585,
			"pid": 3578,
			"name": "鹿谷乡",
			"merger_name": "中国,台湾,南投县,鹿谷乡",
			"level": 3
		}, {
			"id": 3586,
			"pid": 3578,
			"name": "中寮乡",
			"merger_name": "中国,台湾,南投县,中寮乡",
			"level": 3
		}, {
			"id": 3587,
			"pid": 3578,
			"name": "鱼池乡",
			"merger_name": "中国,台湾,南投县,鱼池乡",
			"level": 3
		}, {
			"id": 3588,
			"pid": 3578,
			"name": "国姓乡",
			"merger_name": "中国,台湾,南投县,国姓乡",
			"level": 3
		}, {
			"id": 3589,
			"pid": 3578,
			"name": "水里乡",
			"merger_name": "中国,台湾,南投县,水里乡",
			"level": 3
		}, {
			"id": 3590,
			"pid": 3578,
			"name": "信义乡",
			"merger_name": "中国,台湾,南投县,信义乡",
			"level": 3
		}, {
			"id": 3591,
			"pid": 3578,
			"name": "仁爱乡",
			"merger_name": "中国,台湾,南投县,仁爱乡",
			"level": 3
		}]
	}, {
		"id": 3592,
		"pid": 3325,
		"name": "云林县",
		"merger_name": "中国,台湾,云林县",
		"level": 2,
		"areas": [{
			"id": 3593,
			"pid": 3592,
			"name": "斗六市",
			"merger_name": "中国,台湾,云林县,斗六市",
			"level": 3
		}, {
			"id": 3594,
			"pid": 3592,
			"name": "斗南镇",
			"merger_name": "中国,台湾,云林县,斗南镇",
			"level": 3
		}, {
			"id": 3595,
			"pid": 3592,
			"name": "虎尾镇",
			"merger_name": "中国,台湾,云林县,虎尾镇",
			"level": 3
		}, {
			"id": 3596,
			"pid": 3592,
			"name": "西螺镇",
			"merger_name": "中国,台湾,云林县,西螺镇",
			"level": 3
		}, {
			"id": 3597,
			"pid": 3592,
			"name": "土库镇",
			"merger_name": "中国,台湾,云林县,土库镇",
			"level": 3
		}, {
			"id": 3598,
			"pid": 3592,
			"name": "北港镇",
			"merger_name": "中国,台湾,云林县,北港镇",
			"level": 3
		}, {
			"id": 3599,
			"pid": 3592,
			"name": "古坑乡",
			"merger_name": "中国,台湾,云林县,古坑乡",
			"level": 3
		}, {
			"id": 3600,
			"pid": 3592,
			"name": "大埤乡",
			"merger_name": "中国,台湾,云林县,大埤乡",
			"level": 3
		}, {
			"id": 3601,
			"pid": 3592,
			"name": "莿桐乡",
			"merger_name": "中国,台湾,云林县,莿桐乡",
			"level": 3
		}, {
			"id": 3602,
			"pid": 3592,
			"name": "林内乡",
			"merger_name": "中国,台湾,云林县,林内乡",
			"level": 3
		}, {
			"id": 3603,
			"pid": 3592,
			"name": "二仑乡",
			"merger_name": "中国,台湾,云林县,二仑乡",
			"level": 3
		}, {
			"id": 3604,
			"pid": 3592,
			"name": "仑背乡",
			"merger_name": "中国,台湾,云林县,仑背乡",
			"level": 3
		}, {
			"id": 3605,
			"pid": 3592,
			"name": "麦寮乡",
			"merger_name": "中国,台湾,云林县,麦寮乡",
			"level": 3
		}, {
			"id": 3606,
			"pid": 3592,
			"name": "东势乡",
			"merger_name": "中国,台湾,云林县,东势乡",
			"level": 3
		}, {
			"id": 3607,
			"pid": 3592,
			"name": "褒忠乡",
			"merger_name": "中国,台湾,云林县,褒忠乡",
			"level": 3
		}, {
			"id": 3608,
			"pid": 3592,
			"name": "台西乡",
			"merger_name": "中国,台湾,云林县,台西乡",
			"level": 3
		}, {
			"id": 3609,
			"pid": 3592,
			"name": "元长乡",
			"merger_name": "中国,台湾,云林县,元长乡",
			"level": 3
		}, {
			"id": 3610,
			"pid": 3592,
			"name": "四湖乡",
			"merger_name": "中国,台湾,云林县,四湖乡",
			"level": 3
		}, {
			"id": 3611,
			"pid": 3592,
			"name": "口湖乡",
			"merger_name": "中国,台湾,云林县,口湖乡",
			"level": 3
		}, {
			"id": 3612,
			"pid": 3592,
			"name": "水林乡",
			"merger_name": "中国,台湾,云林县,水林乡",
			"level": 3
		}]
	}, {
		"id": 3613,
		"pid": 3325,
		"name": "嘉义县",
		"merger_name": "中国,台湾,嘉义县",
		"level": 2,
		"areas": [{
			"id": 3614,
			"pid": 3613,
			"name": "太保市",
			"merger_name": "中国,台湾,嘉义县,太保市",
			"level": 3
		}, {
			"id": 3615,
			"pid": 3613,
			"name": "朴子市",
			"merger_name": "中国,台湾,嘉义县,朴子市",
			"level": 3
		}, {
			"id": 3616,
			"pid": 3613,
			"name": "布袋镇",
			"merger_name": "中国,台湾,嘉义县,布袋镇",
			"level": 3
		}, {
			"id": 3617,
			"pid": 3613,
			"name": "大林镇",
			"merger_name": "中国,台湾,嘉义县,大林镇",
			"level": 3
		}, {
			"id": 3618,
			"pid": 3613,
			"name": "民雄乡",
			"merger_name": "中国,台湾,嘉义县,民雄乡",
			"level": 3
		}, {
			"id": 3619,
			"pid": 3613,
			"name": "溪口乡",
			"merger_name": "中国,台湾,嘉义县,溪口乡",
			"level": 3
		}, {
			"id": 3620,
			"pid": 3613,
			"name": "新港乡",
			"merger_name": "中国,台湾,嘉义县,新港乡",
			"level": 3
		}, {
			"id": 3621,
			"pid": 3613,
			"name": "六脚乡",
			"merger_name": "中国,台湾,嘉义县,六脚乡",
			"level": 3
		}, {
			"id": 3622,
			"pid": 3613,
			"name": "东石乡",
			"merger_name": "中国,台湾,嘉义县,东石乡",
			"level": 3
		}, {
			"id": 3623,
			"pid": 3613,
			"name": "义竹乡",
			"merger_name": "中国,台湾,嘉义县,义竹乡",
			"level": 3
		}, {
			"id": 3624,
			"pid": 3613,
			"name": "鹿草乡",
			"merger_name": "中国,台湾,嘉义县,鹿草乡",
			"level": 3
		}, {
			"id": 3625,
			"pid": 3613,
			"name": "水上乡",
			"merger_name": "中国,台湾,嘉义县,水上乡",
			"level": 3
		}, {
			"id": 3626,
			"pid": 3613,
			"name": "中埔乡",
			"merger_name": "中国,台湾,嘉义县,中埔乡",
			"level": 3
		}, {
			"id": 3627,
			"pid": 3613,
			"name": "竹崎乡",
			"merger_name": "中国,台湾,嘉义县,竹崎乡",
			"level": 3
		}, {
			"id": 3628,
			"pid": 3613,
			"name": "梅山乡",
			"merger_name": "中国,台湾,嘉义县,梅山乡",
			"level": 3
		}, {
			"id": 3629,
			"pid": 3613,
			"name": "番路乡",
			"merger_name": "中国,台湾,嘉义县,番路乡",
			"level": 3
		}, {
			"id": 3630,
			"pid": 3613,
			"name": "大埔乡",
			"merger_name": "中国,台湾,嘉义县,大埔乡",
			"level": 3
		}, {
			"id": 3631,
			"pid": 3613,
			"name": "阿里山乡",
			"merger_name": "中国,台湾,嘉义县,阿里山乡",
			"level": 3
		}]
	}, {
		"id": 3632,
		"pid": 3325,
		"name": "屏东县",
		"merger_name": "中国,台湾,屏东县",
		"level": 2,
		"areas": [{
			"id": 3633,
			"pid": 3632,
			"name": "屏东市",
			"merger_name": "中国,台湾,屏东县,屏东市",
			"level": 3
		}, {
			"id": 3634,
			"pid": 3632,
			"name": "潮州镇",
			"merger_name": "中国,台湾,屏东县,潮州镇",
			"level": 3
		}, {
			"id": 3635,
			"pid": 3632,
			"name": "东港镇",
			"merger_name": "中国,台湾,屏东县,东港镇",
			"level": 3
		}, {
			"id": 3636,
			"pid": 3632,
			"name": "恒春镇",
			"merger_name": "中国,台湾,屏东县,恒春镇",
			"level": 3
		}, {
			"id": 3637,
			"pid": 3632,
			"name": "万丹乡",
			"merger_name": "中国,台湾,屏东县,万丹乡",
			"level": 3
		}, {
			"id": 3638,
			"pid": 3632,
			"name": "长治乡",
			"merger_name": "中国,台湾,屏东县,长治乡",
			"level": 3
		}, {
			"id": 3639,
			"pid": 3632,
			"name": "麟洛乡",
			"merger_name": "中国,台湾,屏东县,麟洛乡",
			"level": 3
		}, {
			"id": 3640,
			"pid": 3632,
			"name": "九如乡",
			"merger_name": "中国,台湾,屏东县,九如乡",
			"level": 3
		}, {
			"id": 3641,
			"pid": 3632,
			"name": "里港乡",
			"merger_name": "中国,台湾,屏东县,里港乡",
			"level": 3
		}, {
			"id": 3642,
			"pid": 3632,
			"name": "盐埔乡",
			"merger_name": "中国,台湾,屏东县,盐埔乡",
			"level": 3
		}, {
			"id": 3643,
			"pid": 3632,
			"name": "高树乡",
			"merger_name": "中国,台湾,屏东县,高树乡",
			"level": 3
		}, {
			"id": 3644,
			"pid": 3632,
			"name": "万峦乡",
			"merger_name": "中国,台湾,屏东县,万峦乡",
			"level": 3
		}, {
			"id": 3645,
			"pid": 3632,
			"name": "内埔乡",
			"merger_name": "中国,台湾,屏东县,内埔乡",
			"level": 3
		}, {
			"id": 3646,
			"pid": 3632,
			"name": "竹田乡",
			"merger_name": "中国,台湾,屏东县,竹田乡",
			"level": 3
		}, {
			"id": 3647,
			"pid": 3632,
			"name": "新埤乡",
			"merger_name": "中国,台湾,屏东县,新埤乡",
			"level": 3
		}, {
			"id": 3648,
			"pid": 3632,
			"name": "枋寮乡",
			"merger_name": "中国,台湾,屏东县,枋寮乡",
			"level": 3
		}, {
			"id": 3649,
			"pid": 3632,
			"name": "新园乡",
			"merger_name": "中国,台湾,屏东县,新园乡",
			"level": 3
		}, {
			"id": 3650,
			"pid": 3632,
			"name": "崁顶乡",
			"merger_name": "中国,台湾,屏东县,崁顶乡",
			"level": 3
		}, {
			"id": 3651,
			"pid": 3632,
			"name": "林边乡",
			"merger_name": "中国,台湾,屏东县,林边乡",
			"level": 3
		}, {
			"id": 3652,
			"pid": 3632,
			"name": "南州乡",
			"merger_name": "中国,台湾,屏东县,南州乡",
			"level": 3
		}, {
			"id": 3653,
			"pid": 3632,
			"name": "佳冬乡",
			"merger_name": "中国,台湾,屏东县,佳冬乡",
			"level": 3
		}, {
			"id": 3654,
			"pid": 3632,
			"name": "琉球乡",
			"merger_name": "中国,台湾,屏东县,琉球乡",
			"level": 3
		}, {
			"id": 3655,
			"pid": 3632,
			"name": "车城乡",
			"merger_name": "中国,台湾,屏东县,车城乡",
			"level": 3
		}, {
			"id": 3656,
			"pid": 3632,
			"name": "满州乡",
			"merger_name": "中国,台湾,屏东县,满州乡",
			"level": 3
		}, {
			"id": 3657,
			"pid": 3632,
			"name": "枋山乡",
			"merger_name": "中国,台湾,屏东县,枋山乡",
			"level": 3
		}, {
			"id": 3658,
			"pid": 3632,
			"name": "三地门乡",
			"merger_name": "中国,台湾,屏东县,三地门乡",
			"level": 3
		}, {
			"id": 3659,
			"pid": 3632,
			"name": "雾台乡",
			"merger_name": "中国,台湾,屏东县,雾台乡",
			"level": 3
		}, {
			"id": 3660,
			"pid": 3632,
			"name": "玛家乡",
			"merger_name": "中国,台湾,屏东县,玛家乡",
			"level": 3
		}, {
			"id": 3661,
			"pid": 3632,
			"name": "泰武乡",
			"merger_name": "中国,台湾,屏东县,泰武乡",
			"level": 3
		}, {
			"id": 3662,
			"pid": 3632,
			"name": "来义乡",
			"merger_name": "中国,台湾,屏东县,来义乡",
			"level": 3
		}, {
			"id": 3663,
			"pid": 3632,
			"name": "春日乡",
			"merger_name": "中国,台湾,屏东县,春日乡",
			"level": 3
		}, {
			"id": 3664,
			"pid": 3632,
			"name": "狮子乡",
			"merger_name": "中国,台湾,屏东县,狮子乡",
			"level": 3
		}, {
			"id": 3665,
			"pid": 3632,
			"name": "牡丹乡",
			"merger_name": "中国,台湾,屏东县,牡丹乡",
			"level": 3
		}]
	}, {
		"id": 3666,
		"pid": 3325,
		"name": "台东县",
		"merger_name": "中国,台湾,台东县",
		"level": 2,
		"areas": [{
			"id": 3667,
			"pid": 3666,
			"name": "台东市",
			"merger_name": "中国,台湾,台东县,台东市",
			"level": 3
		}, {
			"id": 3668,
			"pid": 3666,
			"name": "成功镇",
			"merger_name": "中国,台湾,台东县,成功镇",
			"level": 3
		}, {
			"id": 3669,
			"pid": 3666,
			"name": "关山镇",
			"merger_name": "中国,台湾,台东县,关山镇",
			"level": 3
		}, {
			"id": 3670,
			"pid": 3666,
			"name": "卑南乡",
			"merger_name": "中国,台湾,台东县,卑南乡",
			"level": 3
		}, {
			"id": 3671,
			"pid": 3666,
			"name": "鹿野乡",
			"merger_name": "中国,台湾,台东县,鹿野乡",
			"level": 3
		}, {
			"id": 3672,
			"pid": 3666,
			"name": "池上乡",
			"merger_name": "中国,台湾,台东县,池上乡",
			"level": 3
		}, {
			"id": 3673,
			"pid": 3666,
			"name": "东河乡",
			"merger_name": "中国,台湾,台东县,东河乡",
			"level": 3
		}, {
			"id": 3674,
			"pid": 3666,
			"name": "长滨乡",
			"merger_name": "中国,台湾,台东县,长滨乡",
			"level": 3
		}, {
			"id": 3675,
			"pid": 3666,
			"name": "太麻里乡",
			"merger_name": "中国,台湾,台东县,太麻里乡",
			"level": 3
		}, {
			"id": 3676,
			"pid": 3666,
			"name": "大武乡",
			"merger_name": "中国,台湾,台东县,大武乡",
			"level": 3
		}, {
			"id": 3677,
			"pid": 3666,
			"name": "绿岛乡",
			"merger_name": "中国,台湾,台东县,绿岛乡",
			"level": 3
		}, {
			"id": 3678,
			"pid": 3666,
			"name": "海端乡",
			"merger_name": "中国,台湾,台东县,海端乡",
			"level": 3
		}, {
			"id": 3679,
			"pid": 3666,
			"name": "延平乡",
			"merger_name": "中国,台湾,台东县,延平乡",
			"level": 3
		}, {
			"id": 3680,
			"pid": 3666,
			"name": "金峰乡",
			"merger_name": "中国,台湾,台东县,金峰乡",
			"level": 3
		}, {
			"id": 3681,
			"pid": 3666,
			"name": "达仁乡",
			"merger_name": "中国,台湾,台东县,达仁乡",
			"level": 3
		}, {
			"id": 3682,
			"pid": 3666,
			"name": "兰屿乡",
			"merger_name": "中国,台湾,台东县,兰屿乡",
			"level": 3
		}]
	}, {
		"id": 3683,
		"pid": 3325,
		"name": "花莲县",
		"merger_name": "中国,台湾,花莲县",
		"level": 2,
		"areas": [{
			"id": 3684,
			"pid": 3683,
			"name": "花莲市",
			"merger_name": "中国,台湾,花莲县,花莲市",
			"level": 3
		}, {
			"id": 3685,
			"pid": 3683,
			"name": "凤林镇",
			"merger_name": "中国,台湾,花莲县,凤林镇",
			"level": 3
		}, {
			"id": 3686,
			"pid": 3683,
			"name": "玉里镇",
			"merger_name": "中国,台湾,花莲县,玉里镇",
			"level": 3
		}, {
			"id": 3687,
			"pid": 3683,
			"name": "新城乡",
			"merger_name": "中国,台湾,花莲县,新城乡",
			"level": 3
		}, {
			"id": 3688,
			"pid": 3683,
			"name": "吉安乡",
			"merger_name": "中国,台湾,花莲县,吉安乡",
			"level": 3
		}, {
			"id": 3689,
			"pid": 3683,
			"name": "寿丰乡",
			"merger_name": "中国,台湾,花莲县,寿丰乡",
			"level": 3
		}, {
			"id": 3690,
			"pid": 3683,
			"name": "光复乡",
			"merger_name": "中国,台湾,花莲县,光复乡",
			"level": 3
		}, {
			"id": 3691,
			"pid": 3683,
			"name": "丰滨乡",
			"merger_name": "中国,台湾,花莲县,丰滨乡",
			"level": 3
		}, {
			"id": 3692,
			"pid": 3683,
			"name": "瑞穗乡",
			"merger_name": "中国,台湾,花莲县,瑞穗乡",
			"level": 3
		}, {
			"id": 3693,
			"pid": 3683,
			"name": "富里乡",
			"merger_name": "中国,台湾,花莲县,富里乡",
			"level": 3
		}, {
			"id": 3694,
			"pid": 3683,
			"name": "秀林乡",
			"merger_name": "中国,台湾,花莲县,秀林乡",
			"level": 3
		}, {
			"id": 3695,
			"pid": 3683,
			"name": "万荣乡",
			"merger_name": "中国,台湾,花莲县,万荣乡",
			"level": 3
		}, {
			"id": 3696,
			"pid": 3683,
			"name": "卓溪乡",
			"merger_name": "中国,台湾,花莲县,卓溪乡",
			"level": 3
		}]
	}, {
		"id": 3697,
		"pid": 3325,
		"name": "澎湖县",
		"merger_name": "中国,台湾,澎湖县",
		"level": 2,
		"areas": [{
			"id": 3698,
			"pid": 3697,
			"name": "马公市",
			"merger_name": "中国,台湾,澎湖县,马公市",
			"level": 3
		}, {
			"id": 3699,
			"pid": 3697,
			"name": "湖西乡",
			"merger_name": "中国,台湾,澎湖县,湖西乡",
			"level": 3
		}, {
			"id": 3700,
			"pid": 3697,
			"name": "白沙乡",
			"merger_name": "中国,台湾,澎湖县,白沙乡",
			"level": 3
		}, {
			"id": 3701,
			"pid": 3697,
			"name": "西屿乡",
			"merger_name": "中国,台湾,澎湖县,西屿乡",
			"level": 3
		}, {
			"id": 3702,
			"pid": 3697,
			"name": "望安乡",
			"merger_name": "中国,台湾,澎湖县,望安乡",
			"level": 3
		}, {
			"id": 3703,
			"pid": 3697,
			"name": "七美乡",
			"merger_name": "中国,台湾,澎湖县,七美乡",
			"level": 3
		}]
	}, {
		"id": 3704,
		"pid": 3325,
		"name": "金门县",
		"merger_name": "中国,台湾,金门县",
		"level": 2,
		"areas": [{
			"id": 3705,
			"pid": 3704,
			"name": "金城镇",
			"merger_name": "中国,台湾,金门县,金城镇",
			"level": 3
		}, {
			"id": 3706,
			"pid": 3704,
			"name": "金湖镇",
			"merger_name": "中国,台湾,金门县,金湖镇",
			"level": 3
		}, {
			"id": 3707,
			"pid": 3704,
			"name": "金沙镇",
			"merger_name": "中国,台湾,金门县,金沙镇",
			"level": 3
		}, {
			"id": 3708,
			"pid": 3704,
			"name": "金宁乡",
			"merger_name": "中国,台湾,金门县,金宁乡",
			"level": 3
		}, {
			"id": 3709,
			"pid": 3704,
			"name": "烈屿乡",
			"merger_name": "中国,台湾,金门县,烈屿乡",
			"level": 3
		}, {
			"id": 3710,
			"pid": 3704,
			"name": "乌丘乡",
			"merger_name": "中国,台湾,金门县,乌丘乡",
			"level": 3
		}]
	}, {
		"id": 3711,
		"pid": 3325,
		"name": "连江县",
		"merger_name": "中国,台湾,连江县",
		"level": 2,
		"areas": [{
			"id": 3712,
			"pid": 3711,
			"name": "南竿乡",
			"merger_name": "中国,台湾,连江县,南竿乡",
			"level": 3
		}, {
			"id": 3713,
			"pid": 3711,
			"name": "北竿乡",
			"merger_name": "中国,台湾,连江县,北竿乡",
			"level": 3
		}, {
			"id": 3714,
			"pid": 3711,
			"name": "莒光乡",
			"merger_name": "中国,台湾,连江县,莒光乡",
			"level": 3
		}, {
			"id": 3715,
			"pid": 3711,
			"name": "东引乡",
			"merger_name": "中国,台湾,连江县,东引乡",
			"level": 3
		}]
	}]
}, {
	"id": 3716,
	"pid": 0,
	"name": "香港特别行政区",
	"merger_name": "中国,香港特别行政区",
	"level": 1,
	"cities": [{
		"id": 3717,
		"pid": 3716,
		"name": "香港岛",
		"merger_name": "中国,香港特别行政区,香港岛",
		"level": 2,
		"areas": [{
			"id": 3718,
			"pid": 3717,
			"name": "中西区",
			"merger_name": "中国,香港特别行政区,香港岛,中西区",
			"level": 3
		}, {
			"id": 3719,
			"pid": 3717,
			"name": "湾仔区",
			"merger_name": "中国,香港特别行政区,香港岛,湾仔区",
			"level": 3
		}, {
			"id": 3720,
			"pid": 3717,
			"name": "东区",
			"merger_name": "中国,香港特别行政区,香港岛,东区",
			"level": 3
		}, {
			"id": 3721,
			"pid": 3717,
			"name": "南区",
			"merger_name": "中国,香港特别行政区,香港岛,南区",
			"level": 3
		}]
	}, {
		"id": 3722,
		"pid": 3716,
		"name": "九龙",
		"merger_name": "中国,香港特别行政区,九龙",
		"level": 2,
		"areas": [{
			"id": 3723,
			"pid": 3722,
			"name": "油尖旺区",
			"merger_name": "中国,香港特别行政区,九龙,油尖旺区",
			"level": 3
		}, {
			"id": 3724,
			"pid": 3722,
			"name": "深水埗区",
			"merger_name": "中国,香港特别行政区,九龙,深水埗区",
			"level": 3
		}, {
			"id": 3725,
			"pid": 3722,
			"name": "九龙城区",
			"merger_name": "中国,香港特别行政区,九龙,九龙城区",
			"level": 3
		}, {
			"id": 3726,
			"pid": 3722,
			"name": "黄大仙区",
			"merger_name": "中国,香港特别行政区,九龙,黄大仙区",
			"level": 3
		}, {
			"id": 3727,
			"pid": 3722,
			"name": "观塘区",
			"merger_name": "中国,香港特别行政区,九龙,观塘区",
			"level": 3
		}]
	}, {
		"id": 3728,
		"pid": 3716,
		"name": "新界",
		"merger_name": "中国,香港特别行政区,新界",
		"level": 2,
		"areas": [{
			"id": 3729,
			"pid": 3728,
			"name": "荃湾区",
			"merger_name": "中国,香港特别行政区,新界,荃湾区",
			"level": 3
		}, {
			"id": 3730,
			"pid": 3728,
			"name": "屯门区",
			"merger_name": "中国,香港特别行政区,新界,屯门区",
			"level": 3
		}, {
			"id": 3731,
			"pid": 3728,
			"name": "元朗区",
			"merger_name": "中国,香港特别行政区,新界,元朗区",
			"level": 3
		}, {
			"id": 3732,
			"pid": 3728,
			"name": "北区",
			"merger_name": "中国,香港特别行政区,新界,北区",
			"level": 3
		}, {
			"id": 3733,
			"pid": 3728,
			"name": "大埔区",
			"merger_name": "中国,香港特别行政区,新界,大埔区",
			"level": 3
		}, {
			"id": 3734,
			"pid": 3728,
			"name": "西贡区",
			"merger_name": "中国,香港特别行政区,新界,西贡区",
			"level": 3
		}, {
			"id": 3735,
			"pid": 3728,
			"name": "沙田区",
			"merger_name": "中国,香港特别行政区,新界,沙田区",
			"level": 3
		}, {
			"id": 3736,
			"pid": 3728,
			"name": "葵青区",
			"merger_name": "中国,香港特别行政区,新界,葵青区",
			"level": 3
		}, {
			"id": 3737,
			"pid": 3728,
			"name": "离岛区",
			"merger_name": "中国,香港特别行政区,新界,离岛区",
			"level": 3
		}]
	}]
}, {
	"id": 3738,
	"pid": 0,
	"name": "澳门特别行政区",
	"merger_name": "中国,澳门特别行政区",
	"level": 1,
	"cities": [{
		"id": 3739,
		"pid": 3738,
		"name": "澳门半岛",
		"merger_name": "中国,澳门特别行政区,澳门半岛",
		"level": 2,
		"areas": [{
			"id": 3740,
			"pid": 3739,
			"name": "花地玛堂区",
			"merger_name": "中国,澳门特别行政区,澳门半岛,花地玛堂区",
			"level": 3
		}, {
			"id": 3741,
			"pid": 3739,
			"name": "圣安多尼堂区",
			"merger_name": "中国,澳门特别行政区,澳门半岛,圣安多尼堂区",
			"level": 3
		}, {
			"id": 3742,
			"pid": 3739,
			"name": "大堂区",
			"merger_name": "中国,澳门特别行政区,澳门半岛,大堂区",
			"level": 3
		}, {
			"id": 3743,
			"pid": 3739,
			"name": "望德堂区",
			"merger_name": "中国,澳门特别行政区,澳门半岛,望德堂区",
			"level": 3
		}, {
			"id": 3744,
			"pid": 3739,
			"name": "风顺堂区",
			"merger_name": "中国,澳门特别行政区,澳门半岛,风顺堂区",
			"level": 3
		}]
	}, {
		"id": 3745,
		"pid": 3738,
		"name": "氹仔岛",
		"merger_name": "中国,澳门特别行政区,氹仔岛",
		"level": 2,
		"areas": [{
			"id": 3746,
			"pid": 3745,
			"name": "嘉模堂区",
			"merger_name": "中国,澳门特别行政区,氹仔岛,嘉模堂区",
			"level": 3
		}]
	}, {
		"id": 3747,
		"pid": 3738,
		"name": "路环岛",
		"merger_name": "中国,澳门特别行政区,路环岛",
		"level": 2,
		"areas": [{
			"id": 3748,
			"pid": 3747,
			"name": "圣方济各堂区",
			"merger_name": "中国,澳门特别行政区,路环岛,圣方济各堂区",
			"level": 3
		}]
	}]
}, {
	"id": 3749,
	"pid": 0,
	"name": "钓鱼岛",
	"merger_name": "中国,钓鱼岛",
	"level": 1,
	"cities": [{
		"id": 3750,
		"pid": 3749,
		"name": "钓鱼岛",
		"merger_name": "中国,钓鱼岛",
		"level": 2,
		"areas": [{
			"id": 3751,
			"pid": 3750,
			"name": "钓鱼岛",
			"merger_name": "中国,钓鱼岛",
			"level": 3
		}]
	}]
}];

export default chinaCities